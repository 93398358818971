import * as React from "react";

function FolderMoveIcon(props) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="currentColor"
            height="1em"
            width="1em"
            {...props}
        >
            <path d="M14 18v-3h-4v-4h4V8l5 5m1-7h-8l-2-2H4c-1.11 0-2 .89-2 2v12a2 2 0 002 2h16a2 2 0 002-2V8a2 2 0 00-2-2z" />
        </svg>
    );
}

export default FolderMoveIcon;
