import React from "react";
import {
    ResponsiveContainer,
    LineChart,
    AreaChart,
    ComposedChart,
    Line,
    Area,
    YAxis,
    XAxis,
    CartesianGrid,
    Tooltip,
    Label,
    Legend,
} from "recharts";
import TooltipComp from "../UIComponents/TooltipComp";

class LineChartComp extends React.Component {
    data = [
        {
            name: "Jan 2019",
            "Product A": 3432,
            "Procuct B": 2342,
        },
        {
            name: "Feb 2019",
            "Product A": 2342,
            "Procuct B": 3246,
        },
        {
            name: "Mar 2019",
            "Product A": 4565,
            "Procuct B": 4556,
        },
        {
            name: "Apr 2019",
            "Product A": 6654,
            "Procuct B": 4465,
        },
        {
            name: "May 2019",
            "Product A": 8765,
            "Procuct B": 4553,
        },
        {
            name: "June 2019",
            "Product A": 9765,
            "Procuct B": 5553,
        },
        {
            name: "July 2019",
            "Product A": 10000,
            "Procuct B": 6553,
        },
        {
            name: "Mar 2019",
            "Product A": 4565,
            "Procuct B": 4556,
        },
        {
            name: "Apr 2019",
            "Product A": 6654,
            "Procuct B": 4465,
        },
        {
            name: "May 2019",
            "Product A": 8765,
            "Procuct B": 4553,
        },
        {
            name: "June 2019",
            "Product A": 9765,
            "Procuct B": 5553,
        },
        {
            name: "July 2019",
            "Product A": 10000,
            "Procuct B": 6553,
        },
    ];

    customTooltip = (e) => {
        if (e.active && e.payload !== null && e.payload[0] != null) {
            return (
                <div className="customTooltipBox">
                    <h4>{e.label}</h4>
                    <p>{`Average: ${e.payload[0].payload["average"]}%`}</p>
                </div>
            );
        } else {
            return "";
        }
    };

    render() {
        return (
            <div className="lineChart fadeInUp">
                <ResponsiveContainer>
                    <ComposedChart
                        width={600}
                        height={300}
                        data={this.props.data}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                        {/* <CartesianGrid strokeDasharray="3 3" /> */}
                        <defs>
                            <linearGradient
                                id="areaGrad"
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="1"
                            >
                                <stop
                                    offset="5%"
                                    stopColor="#0c63ce"
                                    stopOpacity={0.4}
                                />
                                <stop
                                    offset="95%"
                                    stopColor="#FFFFFF"
                                    stopOpacity={0.1}
                                />
                            </linearGradient>
                        </defs>
                        <XAxis dataKey="label" top={10}>
                            <Label
                                //angle={-90}
                                value="Months"
                                //position="insideBottom"
                                style={{
                                    textAnchor: "middle",
                                    transform: "translateY(15px)",
                                }}
                            />
                        </XAxis>
                        <YAxis
                            domain={[0, 100]}
                            unit="%"
                            // formatter={function (value) {
                            //     return `${value}%`;
                            // }}
                        >
                            <Label
                                angle={-90}
                                value="Performance"
                                position="insideLeft"
                                style={{
                                    textAnchor: "middle",
                                    //right: 15,
                                    //transform: "translateX(10px)",
                                }}
                            />
                        </YAxis>
                        {/* <Tooltip
                            formatter={function (value, name, props) {
                                console.log(props);
                                if (props.unit) {
                                    return [`${value}`, "Average"];
                                } else {
                                    // return null;
                                }
                            }}
                        /> */}
                        <Tooltip content={this.customTooltip} />
                        <CartesianGrid vertical={false} stroke="#DDD" />
                        {/* <Legend /> */}
                        <Line
                            //style={{ strokeDasharray: `40% 60%` }}
                            // type="monotone"
                            //stroke="#006991"
                            type="monotone"
                            unit="%"
                            strokeLinecap="round"
                            strokeWidth={2}
                            dot={false}
                            dataKey="average"
                            stroke="#0c63ce"
                            legendType="none"
                        />
                        <Area
                            type="monotone"
                            dataKey="average"
                            // stroke={false}
                            strokeWidth={2}
                            fillOpacity={1}
                            fill="url(#areaGrad)"
                            Tooltip={null}
                        />
                        {/* <Area
                            type="monotone"
                            dataKey="average"
                            stroke="#8884d8"
                            fill="#8884d8"
                        /> */}
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        );
    }
}

export default LineChartComp;
