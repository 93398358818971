import React from "react";
import {
    Card,
    CardActionArea,
    Typography,
    CardContent,
    LinearProgress,
    Button,
    Divider,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { colors } from "../../utilities/constants";

import Avatar from "react-avatar";

const PerformanceRow = (props) => {
    return (
        <div className="performerRow">
            <ListItem alignItems="center" disableGutters>
                <ListItemText
                    primary={props.count}
                    primaryTypographyProps={{
                        style: {
                            fontSize: "1.7em",
                            fontWeight: 900,
                            marginRight: 10,
                            width: 20,
                            opacity: "0.6",
                            color: colors.BODY2,
                        },
                    }}
                    style={{
                        flex: "unset",
                    }}
                />
                <ListItemAvatar>
                    <Avatar
                        alt={props.item.fullName}
                        //src="/static/images/avatar/2.jpg"
                        //name={`${userData.name} ${userData.family_name}`}
                        name={props.item.fullName}
                        round={true}
                        size={35}
                        textSizeRatio={3}
                        className="icon"
                    />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <span
                            className="memberName retroLink"
                            onClick={() => props.visitMemberProfile(props.item)}
                        >
                            {props.item.fullName}
                        </span>
                    }
                    secondary={
                        <>
                            <span style={{ display: "block" }}>
                                Average
                                {` — ${(
                                    (props.item.marksObtained /
                                        Number(props.item.totalMarks)) *
                                    100
                                ).toFixed(2)}%`}
                            </span>
                            <span style={{ display: "block" }}>
                                Exams Attempted — {`${props.item.exams.length}`}
                            </span>
                        </>
                    }
                />
            </ListItem>
            <Divider variant="inset" />
        </div>
    );
};

export default PerformanceRow;
