import React from "react";
import {
    Card,
    CardActionArea,
    Typography,
    CardContent,
    LinearProgress,
    Button,
    Divider,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import { colors } from "../../Utilities/Constants";
import PerformanceRow from "../../components/UIComponents/PerformerRow";
import TooltipComp from "../UIComponents/TooltipComp";
import {
    ResponsiveContainer,
    BarChart,
    Bar,
    Text,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from "recharts";
import MembersListing from "../UIComponents/MembersListing";
import DonutSmallIcon from "@material-ui/icons/DonutSmall";
import CircularLoader from "../UIComponents/CircularLoader";
import EmptyComp from "../UIComponents/EmptyComp";

const InsightCard = (props) => {
    const { data, readOnly, type, list } = props;
    const visitMemberProfile = (data) => {
        props.history.push({
            pathname: `/profile/${data.userId}`,
        });
    };
    return (
        <Card
            className={`insightCard fadeInUp ${props.className}`}
            elevation={props.elevation >= 0 ? props.elevation : 2}
            style={props.style ? props.style : {}}
        >
            <CardContent style={{ padding: 0 }}>
                {props.title && (
                    <div className="title">
                        <span style={{ display: "flex" }}>
                            {!props.noTitleIcon && (
                                <>
                                    {type === "top" && (
                                        <TrendingUpIcon
                                            style={{ color: colors.GREEN }}
                                        />
                                    )}
                                    {type === "bottom" && (
                                        <TrendingDownIcon
                                            style={{ color: colors.DANGER }}
                                        />
                                    )}
                                    {type === "graph" && (
                                        <EqualizerIcon
                                            style={{ color: colors.PRIMARY }}
                                        />
                                    )}
                                    {type === "table" && (
                                        <DonutSmallIcon
                                            style={{ color: colors.PRIMARY }}
                                        />
                                    )}
                                </>
                            )}
                            {props.title}
                        </span>
                        {props.secondaryTitle && (
                            <span className="subtitle">
                                {props.secondaryTitle}
                            </span>
                        )}
                    </div>
                )}

                {props.description && (
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        className={`description ${readOnly ? "readOnly" : ""}`}
                    >
                        {props.description}
                    </Typography>
                )}

                <div>
                    {data ? (
                        <>
                            {(type === "top" || type === "bottom") && (
                                <div className="insightCard__main">
                                    {data.length > 0 ? (
                                        // Student Info Row
                                        data.map((item, i) => (
                                            <PerformanceRow
                                                key={`${type}-${i}`}
                                                count={i + 1}
                                                item={item}
                                                visitMemberProfile={
                                                    visitMemberProfile
                                                }
                                            />
                                        ))
                                    ) : (
                                        <EmptyComp
                                            art={props.art}
                                            message="No data available."
                                            description={
                                                props.emptyDesc
                                                    ? props.emptyDesc
                                                    : ""
                                            }
                                        />
                                    )}
                                </div>
                            )}
                            {type === "graph" && (
                                <div style={{ width: "100%", height: 350 }}>
                                    <ResponsiveContainer>
                                        <BarChart
                                            // width={400}
                                            // height={300}
                                            data={gData}
                                            margin={{
                                                top: 40,
                                                left: 10,
                                                bottom: 30,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis
                                                label={
                                                    <Text
                                                        x={200}
                                                        y={345}
                                                        dx={0}
                                                        dy={0}
                                                        offset={0}
                                                    >
                                                        Years
                                                    </Text>
                                                }
                                                dataKey="year"
                                            />
                                            <YAxis
                                                label={
                                                    <Text
                                                        x={0}
                                                        y={0}
                                                        dx={15}
                                                        dy={175}
                                                        offset={0}
                                                        angle={-90}
                                                    >
                                                        %age
                                                    </Text>
                                                }
                                                domain={[0, 100]}
                                            />
                                            {/* <Tooltip cursor={false} /> */}
                                            <Tooltip
                                                content={<CustomTooltip />}
                                                cursor={false}
                                            />

                                            <Bar
                                                dataKey="percentage"
                                                fill={colors.PRIMARY}
                                                barSize={25}
                                            />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            )}
                            {type === "table" && (
                                <MembersListing
                                    {...props.tableProps}
                                    data={data}
                                />
                            )}
                        </>
                    ) : (
                        <CircularLoader wrapperClass="cloader" />
                    )}
                </div>
            </CardContent>
        </Card>
    );
};

export default InsightCard;

const CustomTooltip = ({ active, payload, label }) => {
    let style = {
        background: "#000000",
        color: "#ffffff",
        padding: "10px",
        fontSize: "0.9em",
        borderRadius: 8,
    };
    if (active) {
        return (
            <div style={style}>
                <div style={{ padding: "2px 0" }}>{`Year: ${label}`}</div>
                <div
                    style={{ padding: "2px 0" }}
                >{`Aggregate: ${payload[0].value}%`}</div>
            </div>
        );
    }

    return null;
};
