import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
    InputAdornment,
    CssBaseline,
    TextField,
    Box,
    Typography,
    Container,
    IconButton,
    Paper,
    Icon,
} from "@material-ui/core";
import ButtonComp from "../../components/UIComponents/ButtonComp";
import CircularLoader from "../../components/UIComponents/CircularLoader";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { colors } from "../../utilities/Constants";
import { isValidEmail, renderCopyright } from "../../utilities/helper";
import {
    validateOrg,
    isLoading,
    updateAuthentication,
    updateMessage,
} from "../../store/action";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import PasswordValidator from "../../components/UIComponents/PasswordValidator";

const useStyles = (theme) => ({
    root: {
        textAlign: "center",
    },
    logo: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        maxWidth: 200,
    },
    paper: {
        marginTop: theme.spacing(2),
    },
    eyeIcon: {
        cursor: "pointer",
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    resend: {
        fontWeight: 700,
        cursor: "pointer",
        color: colors.PRIMARY,
    },
    buttonWrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -8,
        marginLeft: -12,
    },
});

class TemporaryPasswordChange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.location.data,
            password: "",
            showPassword: false,
            loading: false,
            validPassword: 0,
            error: {
                email: false,
                password: false,
                confirmPassword: false,
            },
        };
    }

    componentDidMount() {
        if (!this.props.location.data) {
            this.props.history.push("/login");
        }
    }

    onChange = (e) => {
        e.preventDefault();
        e.persist();

        this.setState({
            [e.target.name]: e.target.value,
            error: {
                [e.target.name]: false,
            },
        });
    };

    toggleState = (e, name) => {
        this.setState({
            [name]: !this.state[name],
        });
    };

    savePassword = () => {
        const { user, password, confirmPassword, error } = this.state;

        if (password && !error.password && !error.confirmPassword) {
            this.setState({
                loading: true,
            });
            Auth.completeNewPassword(user, password).then((res) => {
                if (res && res.signInUserSession) {
                    this.setState(
                        {
                            loading: false,
                        },
                        () => {
                            toast.success("Password successfully changed");
                            this.props.validateForRedirect(
                                res.signInUserSession.idToken.payload,
                                "/"
                            );
                        }
                    );

                    //this.props.history.push("/");
                }
            });
        }
    };

    checkValidity = (validRules) => {
        this.setState({
            validPassword: validRules,
        });
    };

    handleValidateField = (event) => {
        const { error } = this.state;
        const eventTarget = event.target;
        // catch delete and backspace
        let KeyID = event.keyCode;
        if (
            (KeyID === 8 || KeyID === 46) &&
            this.state.password === "" &&
            eventTarget.name === "password"
        ) {
            this.setState({
                error: {
                    password: false,
                },
            });
        } else if (
            (KeyID === 8 || KeyID === 46) &&
            this.state.confirmPassword === "" &&
            eventTarget.name === "confirmPassword"
        ) {
            this.setState({
                error: {
                    confirPassword: false,
                },
            });
        } else {
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout((event) => {
                let err = {};
                if (eventTarget.name === "password") {
                    err.password =
                        this.state.validPassword === 2 ? false : true;
                } else {
                    err.confirmPassword =
                        this.state.password === this.state.confirmPassword
                            ? false
                            : true;
                }

                this.setState({
                    error: {
                        ...this.state.error,
                        ...err,
                    },
                });
            }, 800);
        }
    };

    handleBack = () => {
        this.props.history.push("/login");
    };

    render() {
        const { classes } = this.props;
        return (
            <Container
                component="main"
                maxWidth="xs"
                className={`${classes.root} startScreenContainer`}
            >
                <CssBaseline />
                <Paper
                    className={`${classes.paper} startScreenPaper`}
                    elevation={3}
                >
                    <div className="startScreenPaper__header">
                        {/* <div className="paper-top-navigation">
                            <IconButton onClick={this.handleBack}>
                                <KeyboardBackspaceIcon />
                            </IconButton>
                        </div> */}
                        <h2 className="paper-title">
                            Change Temporary Password
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                            >
                                As this is your first login, it is required to
                                change the password.
                            </Typography>
                        </h2>
                    </div>
                    <form className={classes.form} noValidate>
                        <TextField
                            error={this.state.error.password}
                            variant="outlined"
                            margin="dense"
                            required
                            fullWidth
                            name="password"
                            label="New Password"
                            type={this.state.showPassword ? "text" : "password"}
                            id="password"
                            //autoComplete="current-password"
                            value={this.state.password}
                            onChange={this.onChange}
                            onKeyUp={this.handleValidateField}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon
                                            aria-label="toggle password visibility"
                                            size="small"
                                            onClick={(e) =>
                                                this.toggleState(
                                                    e,
                                                    "showPassword"
                                                )
                                            }
                                            className={classes.eyeIcon}
                                        >
                                            {!this.state.showPassword ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </Icon>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <PasswordValidator
                            password={this.state.password}
                            checkValidity={this.checkValidity}
                        />
                        <TextField
                            error={this.state.error.confirmPassword}
                            helperText={
                                this.state.error.confirmPassword
                                    ? "Confirm password doesn't match."
                                    : ""
                            }
                            variant="outlined"
                            margin="dense"
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Confirm Password"
                            type={this.state.showPassword ? "text" : "password"}
                            id="password"
                            //autoComplete="current-password"
                            value={this.state.confirPassword}
                            onChange={this.onChange}
                            onKeyUp={this.handleValidateField}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon
                                            aria-label="toggle password visibility"
                                            size="small"
                                            onClick={(e) =>
                                                this.toggleState(
                                                    e,
                                                    "showPassword"
                                                )
                                            }
                                            className={classes.eyeIcon}
                                        >
                                            {!this.state.showPassword ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </Icon>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <div className={classes.buttonWrapper}>
                            <ButtonComp
                                // type="submit"
                                fullWidth
                                variant="contained"
                                bckColor={colors.PRIMARY}
                                color="#ffffff"
                                style={{ fontWeight: "bold" }}
                                disabled={
                                    this.state.loading ||
                                    this.state.error.password ||
                                    this.state.error.confirmPassword
                                        ? true
                                        : false
                                }
                                classes={{
                                    root: `${classes.submit}`,
                                }}
                                onClick={this.savePassword}
                            >
                                Save New Password
                            </ButtonComp>
                            {this.state.loading && (
                                <CircularLoader
                                    size={24}
                                    className={classes.buttonProgress}
                                    color={colors.PRIMARY}
                                />
                            )}
                        </div>
                    </form>
                </Paper>
                <Box mt={8}>{renderCopyright()}</Box>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        orgData: state.appState.orgData,
        isLoading: state.appState.isLoading,
        message: state.appState.message,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        validateForRedirect: (user) => (
            dispatch(validateOrg(user)), dispatch(isLoading(true))
        ),
        updateLoading: (bool) => dispatch(isLoading(bool)),
        updateMessage: (msg) => dispatch(updateMessage(msg)),
        updateAuth: (bool, data) => dispatch(updateAuthentication(bool, data)),
    };
};

export default withStyles(useStyles)(
    connect(mapStateToProps, mapDispatchToProps)(TemporaryPasswordChange)
);
