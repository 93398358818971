import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Avatar from "react-avatar";
import moment from "moment";
import queryString from "query-string";
import { STUDENT_RESULTS_TBL_COL } from "../../utilities/material-table-contants";
import userBookReading from "../../assets/userBookReading.svg";

// import { fetchData, isLoading, getResults } from "../store/action";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    Popper,
    MenuList,
    MenuItem,
    Collapse,
    ClickAwayListener,
    IconButton,
} from "@material-ui/core";
import { fetchGroups, updateGroup, deleteStudent } from "../../store/action";
import CircularLoader from "../../components/UIComponents/CircularLoader";
import { toast } from "react-toastify";
import { colors } from "../../utilities/constants";
import ManageGroupMembersDialog from "../../components/Dialogs/ManageGroupMembersDialog";
import ConfirmDialog from "../../components/Dialogs/ConfirmDialog";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ButtonComp from "../../components/UIComponents/ButtonComp";
import OrgServices from "../../services/org.services";
import EmptyComp from "../../components/UIComponents/EmptyComp";
import TabsComp from "../../components/UIComponents/TabsComp";
import InsightCard from "../../components/Insights/InsightCard";
import LiveTvIcon from "@material-ui/icons/LiveTv";

const useStyles = (theme) => ({
    cellRoot: {
        padding: theme.spacing(1),
        width: "100px",
    },
    col2: {
        fontWeight: 500,
        marginLeft: 10,
    },
});

class SessionDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.location.data,
            changePasswordDialog: false,
            memberData: null,
            currentTab: 0,
            menuOpen: false,
            anchorEl: null,
            isManageMembersDialogOpen: false,
            confirmDialog: false,
            controlType: "",
            controlData: {},
            controlMessage: "",
        };
        this.OrgServices = new OrgServices();
        this.tabs = [
            {
                label: "About",
            },
            {
                label: "Results",
            },
            // {
            //     label: "Insights",
            // },
        ];
    }
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        let queryObj = queryString.parse(props.location.search);
        if (queryObj.tab) {
            newState.currentTab = parseInt(queryObj.tab);
        }
        return {
            ...state,
            ...newState,
        };
    }

    toggleMenu = (e) => {
        this.setState({
            menuOpen: !this.state.menuOpen,
            anchorEl: !this.state.menuOpen ? e.currentTarget : null,
        });
    };

    componentDidMount() {
        // if (this.props.location.data) {
        //     this.setState(
        //         {
        //             memberData: this.props.location.data,
        //         },
        //         () => {
        //             if (this.state.memberData.mygroup && !this.props.groups) {
        //                 this.props.fetchGroups(this.props.orgData.orgId);
        //             }
        //         }
        //     );
        // } else {
        //     let studentId = this.props.match.params.memberId;
        //     this.OrgServices.getMember({
        //         orgId: this.props.orgData.orgId,
        //         userId: studentId,
        //     })
        //         .then((res) => {
        //             this.setState(
        //                 {
        //                     memberData: res.data,
        //                 },
        //                 () => {
        //                     if (
        //                         this.state.memberData.mygroup &&
        //                         !this.props.groups
        //                     ) {
        //                         this.props.fetchGroups(
        //                             this.props.orgData.orgId
        //                         );
        //                     }
        //                 }
        //             );
        //         })
        //         .catch((error) => {
        //             toast.error(error.message);
        //         });
        // }
    }

    componentDidUpdate(prevProps) {
        const { memberData } = this.state;
        if (this.state.currentTab === 1 && memberData && !memberData.results) {
            let params = {
                userId: memberData.userId,
            };

            this.fetchMemberResults(params);
        }
    }

    toggleManageMembersDialog = (e, type, data) => {
        e.preventDefault();
        e.stopPropagation();
        if (type) {
            if (
                type === "move" &&
                (!this.props.groups || this.props.groups.length === 1)
            ) {
                // Fetch Groups data if not avai;able.
                this.props.fetchGroups(this.props.orgData.orgId);
            }
            this.setState((prevState) => {
                return {
                    menuOpen: false,
                    isManageMembersDialogOpen:
                        !prevState.isManageMembersDialogOpen,
                    controlData: data,
                    controlType: type,
                    // controlMessage: msg,
                };
            });
        } else {
            this.setState((prevState) => {
                return {
                    menuOpen: false,
                    isManageMembersDialogOpen:
                        !prevState.isManageMembersDialogOpen,
                    controlData: {},
                    controlType: "",
                };
            });
        }
    };

    toggleConfirmDialog = (data) => {
        this.setState((prevProps) => {
            return {
                confirmDialog: !prevProps.confirmDialog,
                controlData: data ? data : {},
            };
        });
    };

    handleConfirm = () => {
        const { controlData } = this.state;
        this.toggleConfirmDialog();
        this.props.deleteStudent({
            userId: controlData.userId,
            username: controlData.username,
            orgId: this.props.orgData.orgId,
        });
    };

    fetchMemberResults = (params) => {
        const { memberData } = this.state;
        this.OrgServices.getStudentResults(params).then((res) => {
            memberData.results = res.data;
            this.setState({
                memberData,
            });
        });
    };

    handleTabChange = (e, newValue) => {
        e.preventDefault();
        this.props.history.push(
            `${this.props.history.location.pathname}?tab=${newValue}`
        );
    };

    handleBack = () => {
        if (this.props.history.goBack) {
            this.props.history.goBack();
        }

        //this.props.history.push("/manageMembers");
    };

    renderTabComponent = () => {
        const { currentTab, group, memberData } = this.state;
        const { classes, orgData } = this.props;
        let userData = this.state.memberData;
        switch (currentTab) {
            case 0:
                return (
                    <Table
                        aria-label="user profile details"
                        className="mgmt__mainPaper__details"
                    >
                        <TableBody>
                            {/* <TableRow>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    classes={{
                                        root: classes.cellRoot,
                                    }}
                                >
                                    Organisation:
                                </TableCell>
                                <TableCell
                                    classes={{
                                        root: `${classes.cellRoot} ${classes.col2}`,
                                    }}
                                >
                                    {orgData.orgName}
                                </TableCell>
                            </TableRow> */}
                            <TableRow>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    classes={{
                                        root: classes.cellRoot,
                                    }}
                                >
                                    Name:
                                </TableCell>
                                <TableCell
                                    classes={{
                                        root: `${classes.cellRoot} ${classes.col2}`,
                                    }}
                                >
                                    {` ${userData.name} ${
                                        userData.family_name
                                            ? userData.family_name
                                            : ""
                                    }`}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    classes={{
                                        root: classes.cellRoot,
                                    }}
                                >
                                    Email:
                                </TableCell>
                                <TableCell
                                    classes={{
                                        root: `${classes.cellRoot} ${classes.col2}`,
                                    }}
                                >
                                    <a
                                        href={`mailto:${userData.email}`}
                                        className="link"
                                    >{` ${userData.email}`}</a>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    classes={{
                                        root: classes.cellRoot,
                                    }}
                                >
                                    Mobile:
                                </TableCell>
                                <TableCell
                                    classes={{
                                        root: `${classes.cellRoot} ${classes.col2}`,
                                    }}
                                >
                                    <a
                                        href={`tel:${
                                            userData.mobile
                                                ? userData.mobile
                                                : userData.phone_number
                                        }`}
                                        className="link"
                                    >{` ${
                                        userData.mobile
                                            ? userData.mobile
                                            : userData.phone_number
                                    }`}</a>
                                </TableCell>
                            </TableRow>
                            {userData.mygroup && (
                                <TableRow>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        classes={{
                                            root: classes.cellRoot,
                                        }}
                                    >
                                        Group:
                                    </TableCell>
                                    <TableCell
                                        classes={{
                                            root: `${classes.cellRoot} ${classes.col2}`,
                                        }}
                                    >
                                        {this.props.groups
                                            ? this.props.groups.find(
                                                  (g) =>
                                                      g.groupId ===
                                                      userData.mygroup
                                              ).title
                                            : "Loading..."}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                );
            case 1:
                return (
                    <>
                        {userData && userData.results ? (
                            <InsightCard
                                //title={`${this.state.student.name}'s Exam results`}
                                //description={`All the results of ${this.state.student.name} in the selected duration.`}
                                data={userData.results}
                                type="table"
                                style={{
                                    minWidth: "100%",
                                    width: "100%",
                                }}
                                tableProps={{
                                    noControls: true,
                                    noElevation: true,
                                    columns: STUDENT_RESULTS_TBL_COL,
                                }}
                            />
                        ) : (
                            <CircularLoader wrapperClass="cloader" />
                        )}
                    </>
                );
            case 2:
                return (
                    <EmptyComp message="Group insights are coming soon. Tab 2" />
                );
        }
    };

    render() {
        const { orgData, classes } = this.props;
        let userData = this.state.memberData;
        let data = this.state.data;
        return (
            <>
                <div className="listPage profilePage">
                    {this.props.history.goBack && (
                        <div
                            className="container-top-navigation"
                            style={{ position: "unset" }}
                        >
                            <ButtonComp onClick={this.handleBack} size="small">
                                <ChevronLeftIcon />
                                Back
                            </ButtonComp>
                        </div>
                    )}
                    {data ? (
                        <>
                            {data ? (
                                <div className="profile mgmt">
                                    <Paper className="mgmt__headPaper">
                                        <div className="header">
                                            <div className="title">
                                                <LiveTvIcon
                                                    style={{
                                                        fontSize: 50,
                                                    }}
                                                />
                                                <div>
                                                    <Typography
                                                        variant="h1"
                                                        component="h1"
                                                        className="page-header"
                                                    >
                                                        {`${data.title}`}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        component="p"
                                                        className="subText"
                                                    >
                                                        {`Created by ${
                                                            data.creatorName
                                                        } on ${moment
                                                            .unix(
                                                                data.createdAt
                                                            )
                                                            .format(
                                                                "MMMM DD, YYYY"
                                                            )}`}
                                                    </Typography>
                                                </div>

                                                {/* <span className="since">
                                    {`user since ${moment
                                        .unix(userData.createdAt)
                                        .format("MMMM DD, YYYY")}`}
                                </span> */}
                                                {/* </div> */}
                                            </div>
                                            {/* <div className="controls">
                                                <IconButton
                                                    aria-label="Group control menu"
                                                    aria-controls="series-row"
                                                    aria-haspopup="true"
                                                    style={{
                                                        marginLeft: "auto",
                                                    }}
                                                    onClick={this.toggleMenu}
                                                    color="inherit"
                                                    edge="end"
                                                >
                                                    <MoreVertIcon
                                                        ref={this.anchorRef}
                                                    />

                                                    <Popper
                                                        open={
                                                            this.state.menuOpen
                                                        }
                                                        anchorEl={
                                                            this.state.anchorEl
                                                        }
                                                        role={undefined}
                                                        transition
                                                        disablePortal={false}
                                                        placement="bottom-end"
                                                        className="menuPopper"
                                                        modifiers={{
                                                            flip: {
                                                                enabled: true,
                                                            },
                                                            preventOverflow: {
                                                                enabled: true,
                                                                boundariesElement:
                                                                    "scrollParent",
                                                            },
                                                        }}
                                                    >
                                                        {({
                                                            TransitionProps,
                                                        }) => (
                                                            <Collapse
                                                                {...TransitionProps}
                                                                collapsedHeight={
                                                                    20
                                                                }
                                                            >
                                                                <Paper
                                                                    elevation={
                                                                        10
                                                                    }
                                                                >
                                                                    <ClickAwayListener
                                                                        onClickAway={
                                                                            this
                                                                                .toggleMenu
                                                                        }
                                                                    >
                                                                        <MenuList
                                                                            autoFocusItem={
                                                                                this
                                                                                    .state
                                                                                    .open
                                                                            }
                                                                            id="menu-list-grow"
                                                                            onKeyDown={() =>
                                                                                null
                                                                            }
                                                                        >
                                                                            {userData.mygroup && (
                                                                                <MenuItem
                                                                                    className="menuItem"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        this.toggleManageMembersDialog(
                                                                                            e,
                                                                                            "move",
                                                                                            userData
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    Change
                                                                                    Group
                                                                                </MenuItem>
                                                                            )}
                                                                            {orgData.rolePermissions.some(
                                                                                (
                                                                                    r
                                                                                ) =>
                                                                                    r <=
                                                                                    1
                                                                            ) && (
                                                                                <MenuItem
                                                                                    className="menuItem"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        this.toggleConfirmDialog(
                                                                                            userData
                                                                                        );
                                                                                    }}
                                                                                    style={{
                                                                                        color: colors.DANGER,
                                                                                    }}
                                                                                >
                                                                                    Delete
                                                                                    Permanently
                                                                                </MenuItem>
                                                                            )}
                                                                        </MenuList>
                                                                    </ClickAwayListener>
                                                                </Paper>
                                                            </Collapse>
                                                        )}
                                                    </Popper>
                                                </IconButton>
                                                                                </div> */}
                                        </div>

                                        {/* <div className="mgmt__main"></div> */}
                                        <div className="profilePage__main">
                                            {/* <TabsComp
                                                handleChange={
                                                    this.handleTabChange
                                                }
                                                value={this.state.currentTab}
                                                tabs={this.tabs}
                                            ></TabsComp>
                                            <div className="view">
                                                {this.renderTabComponent()}
                                            </div> */}
                                            <Table
                                                aria-label="user profile details"
                                                className="mgmt__mainPaper__details"
                                            >
                                                <TableBody>
                                                    {/* <TableRow>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    classes={{
                                        root: classes.cellRoot,
                                    }}
                                >
                                    Organisation:
                                </TableCell>
                                <TableCell
                                    classes={{
                                        root: `${classes.cellRoot} ${classes.col2}`,
                                    }}
                                >
                                    {orgData.orgName}
                                </TableCell>
                            </TableRow> */}
                                                    {/* <TableRow>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            classes={{
                                                                root: classes.cellRoot,
                                                            }}
                                                        >
                                                            Name:
                                                        </TableCell>
                                                        <TableCell
                                                            classes={{
                                                                root: `${classes.cellRoot} ${classes.col2}`,
                                                            }}
                                                        >
                                                            {` ${
                                                                userData.name
                                                            } ${
                                                                userData.family_name
                                                                    ? userData.family_name
                                                                    : ""
                                                            }`}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            classes={{
                                                                root: classes.cellRoot,
                                                            }}
                                                        >
                                                            Email:
                                                        </TableCell>
                                                        <TableCell
                                                            classes={{
                                                                root: `${classes.cellRoot} ${classes.col2}`,
                                                            }}
                                                        >
                                                            <a
                                                                href={`mailto:${userData.email}`}
                                                                className="link"
                                                            >{` ${userData.email}`}</a>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            classes={{
                                                                root: classes.cellRoot,
                                                            }}
                                                        >
                                                            Mobile:
                                                        </TableCell>
                                                        <TableCell
                                                            classes={{
                                                                root: `${classes.cellRoot} ${classes.col2}`,
                                                            }}
                                                        >
                                                            <a
                                                                href={`tel:${
                                                                    userData.mobile
                                                                        ? userData.mobile
                                                                        : userData.phone_number
                                                                }`}
                                                                className="link"
                                                            >{` ${
                                                                userData.mobile
                                                                    ? userData.mobile
                                                                    : userData.phone_number
                                                            }`}</a>
                                                        </TableCell>
                                                    </TableRow>
                                                    {userData.mygroup && (
                                                        <TableRow>
                                                            <TableCell
                                                                component="th"
                                                                scope="row"
                                                                classes={{
                                                                    root: classes.cellRoot,
                                                                }}
                                                            >
                                                                Group:
                                                            </TableCell>
                                                            <TableCell
                                                                classes={{
                                                                    root: `${classes.cellRoot} ${classes.col2}`,
                                                                }}
                                                            >
                                                                {this.props
                                                                    .groups
                                                                    ? this.props.groups.find(
                                                                          (g) =>
                                                                              g.groupId ===
                                                                              userData.mygroup
                                                                      ).title
                                                                    : "Loading..."}
                                                            </TableCell>
                                                        </TableRow> */}
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </Paper>
                                </div>
                            ) : (
                                <EmptyComp
                                    art={userBookReading}
                                    message="Student not found."
                                />
                            )}
                        </>
                    ) : (
                        <CircularLoader wrapperClass="cloader" />
                    )}
                </div>
                {this.state.isManageMembersDialogOpen && (
                    <ManageGroupMembersDialog
                        open={this.state.isManageMembersDialogOpen}
                        handleClose={this.toggleManageMembersDialog}
                        type={this.state.controlType}
                        //message={this.state.controlMessage}
                        data={this.state.controlData}
                        orgId={this.props.orgData.orgId}
                        isLoading={this.props.isLoading}
                        //studentsData={this.props.studentsData}
                        groupsData={this.props.groups}
                        group={
                            this.props.groups &&
                            this.props.groups.find(
                                (g) =>
                                    g.groupId === this.state.memberData.mygroup
                            )
                        }
                        updateGroup={this.props.updateGroup}
                        fetchGroups={this.props.fetchGroups}
                    />
                )}
                {this.state.confirmDialog && (
                    <ConfirmDialog
                        open={this.state.confirmDialog}
                        handleConfirm={this.handleConfirm}
                        handleClose={this.toggleConfirmDialog}
                        type="delete"
                        message={`Are your sure, you want to permanently delete ${this.state.controlData.name}'s account from your portal. Please note that the results data will not be deleted. `}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.appState.userData,
        orgData: state.appState.orgData,
        isLoading: state.appState.isLoading,
        groups: state.groupsState.groups,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchGroups: (orgId) => dispatch(fetchGroups(orgId)),
        updateGroup: (params) => dispatch(updateGroup(params)),
        deleteStudent: (params) => dispatch(deleteStudent(params)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(useStyles)(SessionDetails));
