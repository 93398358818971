import React, { Component } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { withStyles } from "@material-ui/core/styles";
import {
    InputAdornment,
    CssBaseline,
    TextField,
    FormControlLabel,
    Checkbox,
    Link,
    Grid,
    Box,
    Typography,
    Container,
    IconButton,
    Paper,
    Icon,
    LinearProgress,
    Backdrop,
} from "@material-ui/core";
import ButtonComp from "../components/UIComponents/ButtonComp";
import CircularLoader from "../components/UIComponents/CircularLoader";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { colors } from "../utilities/Constants";
import { isValidEmail, renderCopyright } from "../utilities/helper";
import * as CryptoJS from "crypto-js";
import Logo from "../assets/logo-2x.png";
import {
    validateOrg,
    isLoading,
    updateAuthentication,
    updateMessage,
} from "../store/action";

const useStyles = (theme) => ({
    root: {
        textAlign: "center",
    },
    logo: {
        marginBottom: theme.spacing(1),
        maxWidth: 200,
    },
    paper: {
        marginTop: theme.spacing(2),
    },
    eyeIcon: {
        cursor: "pointer",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    buttonWrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -8,
        marginLeft: -12,
    },
});

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            showPassword: false,
            rememberMe: false,
            loading: false,
            error: {
                email: false,
                password: false,
                phone: false,
                fname: false,
                lname: false,
            },
        };
    }

    onChange = (e) => {
        e.preventDefault();
        e.persist();
        if (this.props.message) {
            this.props.updateMessage("");
        }
        if (this.state.error.email || this.state.error.password) {
            this.setState({
                error: {
                    email: false,
                    password: false,
                },
            });
        }
        this.setState(
            {
                [e.target.name]: e.target.value.trim(),
            },
            () => {
                if (
                    e.target.name === "email" &&
                    window.localStorage.getItem(`${event.target.value}`)
                ) {
                    // const encryptedPass = window.localStorage.getItem(
                    //     `${event.target.value}`
                    // );
                    const decryptPass = CryptoJS.AES.decrypt(
                        window.localStorage.getItem(`${event.target.value}`),
                        event.target.value
                    );
                    const password = decryptPass.toString(CryptoJS.enc.Utf8);
                    this.setState({
                        password,
                    });
                }
            }
        );
    };
    toggleState = (e, name) => {
        this.setState({
            [name]: !this.state[name],
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { email, password, rememberMe } = this.state;
        let errorState = this.state.error;

        if (email.length > 0 && isValidEmail(email)) {
            if (password.length) {
                this.setState({
                    loading: true,
                });
                try {
                    Auth.signIn(email.toLowerCase(), password)
                        .then((user) => {
                            if (
                                user.challengeName ===
                                    "NEW_PASSWORD_REQUIRED" ||
                                user.challengeName === "FORCE_CHANGE_PASSWORD"
                            ) {
                                // this.props.updateAuth(true, user);
                                this.props.history.push({
                                    pathname: "/passwordchange",
                                    data: user,
                                });
                            } else {
                                this.setState({
                                    loading: false,
                                });
                                if (rememberMe) {
                                    const encryptedPass = CryptoJS.AES.encrypt(
                                        password,
                                        email
                                    ).toString();
                                    window.localStorage.setItem(
                                        this.state.email,
                                        encryptedPass
                                    );
                                }
                                //this.props.updateAuth(true, user);
                                this.props.validateForRedirect(
                                    user.signInUserSession.idToken.payload
                                );
                                //this.props.history.push("/");
                            }
                        })
                        .catch((err) => {
                            this.setState({
                                loading: false,
                            });
                            toast.error(err.message);
                        });
                } catch (e) {
                    this.setState({
                        loading: false,
                    });
                    toast.error("Network Problem!");
                }
            } else {
                errorState.password = true;
                this.setState({
                    error: errorState,
                });
                this.props.updateMessage("Please enter valid password");
            }
        } else {
            errorState.email = true;
            this.setState({
                error: errorState,
            });
            this.props.updateMessage("Please enter valid email address");
        }
    };

    render() {
        const { classes } = this.props;
        return (
            <Container
                component="main"
                maxWidth="xs"
                className={`${classes.root} startScreenContainer`}
            >
                {/* <CssBaseline /> */}
                <img
                    src={Logo}
                    className={classes.logo}
                    alt="gradout logo"
                ></img>
                <Paper
                    className={`${classes.paper} startScreenPaper`}
                    elevation={3}
                >
                    <div className="startScreenPaper__header">
                        <h2 className="paper-title">Admin Log In</h2>
                    </div>

                    <form
                        className={classes.form}
                        noValidate
                        onSubmit={this.handleSubmit}
                    >
                        <TextField
                            error={this.state.error.email}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={this.state.email}
                            onChange={this.onChange}
                            autoFocus
                            helperText={
                                this.props.message &&
                                this.state.error.email && (
                                    <span className="error">
                                        {this.props.message}
                                    </span>
                                )
                            }
                        />
                        <TextField
                            error={this.state.error.password}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type={this.state.showPassword ? "text" : "password"}
                            id="password"
                            value={this.state.password}
                            onChange={this.onChange}
                            autoComplete="current-password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon
                                            aria-label="toggle password visibility"
                                            size="small"
                                            onClick={(e) =>
                                                this.toggleState(
                                                    e,
                                                    "showPassword"
                                                )
                                            }
                                            className={classes.eyeIcon}
                                        >
                                            {!this.state.showPassword ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </Icon>
                                    </InputAdornment>
                                ),
                            }}
                            helperText={
                                this.props.message &&
                                this.state.error.password && (
                                    <span className="error">
                                        {this.props.message}
                                    </span>
                                )
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.rememberMe}
                                    onChange={(e) =>
                                        this.toggleState(e, "rememberMe")
                                    }
                                    value="remember"
                                    color="default"
                                />
                            }
                            label="Remember me"
                        />
                        <div className={classes.buttonWrapper}>
                            <ButtonComp
                                type="submit"
                                fullWidth
                                variant="contained"
                                bckColor={colors.PRIMARY}
                                color="#ffffff"
                                style={{ fontWeight: "bold" }}
                                disabled={
                                    this.state.loading ||
                                    this.state.error.email ||
                                    this.state.error.password
                                        ? true
                                        : false
                                }
                                classes={{
                                    root: `${classes.submit}`,
                                }}
                                onClick={this.handleSubmit}
                            >
                                Sign In
                            </ButtonComp>
                            {this.state.loading && (
                                <CircularLoader
                                    size={24}
                                    className={classes.buttonProgress}
                                    color={colors.PRIMARY}
                                />
                            )}
                        </div>
                        <Grid container>
                            <Grid item xs>
                                <Link
                                    to="/forgotpassword"
                                    component={RouterLink}
                                    variant="body2"
                                >
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                New User?
                                <Link
                                    variant="body2"
                                    to="/signup"
                                    component={RouterLink}
                                >
                                    {" Register Here"}
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                <Box mt={8}>{renderCopyright()}</Box>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    console.log(state);
    return {
        isLoading: state.appState.isLoading,
        message: state.appState.message,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        validateForRedirect: (user) => (
            dispatch(validateOrg(user)), dispatch(isLoading(true))
        ),
        updateLoading: (bool) => dispatch(isLoading(bool)),
        updateMessage: (msg) => dispatch(updateMessage(msg)),
        updateAuth: (bool, data) => dispatch(updateAuthentication(bool, data)),
    };
};

export default withStyles(useStyles)(
    connect(mapStateToProps, mapDispatchToProps)(Login)
);
