import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
    InputAdornment,
    CssBaseline,
    TextField,
    Box,
    Typography,
    Container,
    IconButton,
    Paper,
    Icon,
} from "@material-ui/core";
import ButtonComp from "../../components/UIComponents/ButtonComp";
import CircularLoader from "../../components/UIComponents/CircularLoader";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { colors } from "../../utilities/Constants";
import Logo from "../../assets/logo-2x.png";
import { isValidEmail, renderCopyright } from "../../utilities/helper";
import {
    validateUser,
    isLoading,
    updateAuthentication,
    updateMessage,
} from "../../store/action";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";

const useStyles = (theme) => ({
    root: {
        textAlign: "center",
    },
    logo: {
        marginBottom: theme.spacing(1),
        maxWidth: 200,
    },
    paper: {
        marginTop: theme.spacing(2),
    },
    eyeIcon: {
        cursor: "pointer",
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    resend: {
        fontWeight: 700,
        cursor: "pointer",
        color: colors.PRIMARY,
    },
    buttonWrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -8,
        marginLeft: -12,
    },
});

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            otp: "",
            password: "",
            confirmpassword: "",
            otpSent: false,
            showPassword: false,
            loading: false,
            error: {
                email: false,
                password: false,
                confirmpassword: false,
                otp: false,
            },
        };
    }

    componentDidMount() {
        let loginEmail = localStorage.getItem("loginEmail");
        if (this.state.email.length === 0 && loginEmail) {
            this.setState({
                email: loginEmail,
            });
        }
    }

    onChange = (e) => {
        e.preventDefault();
        e.persist();
        if (this.props.message) {
            this.props.updateMessage("");
        }
        if (this.state.error.email || this.state.error.password) {
            this.setState({
                error: {
                    email: false,
                    password: false,
                },
            });
        }
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    toggleState = (e, name) => {
        this.setState({
            [name]: !this.state[name],
        });
    };

    sendOTP = async (event) => {
        event.preventDefault();
        const { email, error } = this.state;

        if (!error.email && email.length > 0) {
            Auth.forgotPassword(email.toLowerCase().toString().trim())
                .then((data) => {
                    if (data.hasOwnProperty("CodeDeliveryDetails")) {
                        this.setState(
                            {
                                otpSent: true,
                            },
                            () => {
                                toast.success(
                                    "OTP send to " +
                                        data.CodeDeliveryDetails.Destination
                                );
                            }
                        );
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        } else {
            this.setState({
                error: {
                    email: true,
                },
            });
        }
    };

    saveNewPassword = (event) => {
        event.preventDefault();
        const { error, email, password, confirmpassword, otp } = this.state;

        if (
            !error.password &&
            !error.confirmpassword &&
            password === confirmpassword &&
            password.length > 0
        ) {
            this.setState({
                loading: true,
            });
            Auth.forgotPasswordSubmit(
                email.toLowerCase().toString().trim(),
                otp.toString().trim(),
                password.toString().trim()
            )
                .then((data) => {
                    // this.setState({ details: details }, () => {
                    //     this.resetForm(true); // isUpdated True
                    // });
                    this.setState(
                        {
                            loading: false,
                        },
                        () => {
                            toast.success("password updated successfully.");
                            const locationObj = {
                                pathname: "/login",
                                state: {
                                    passwordUpdated: true,
                                },
                            };
                            this.props.history.push(locationObj);
                        }
                    );
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        } else {
            this.setState({
                error: {
                    email: true,
                },
            });
        }
    };

    handleValidateField = (event) => {
        const { error } = this.state;
        const eventTarget = event.target;
        // catch delete and backspace
        let KeyID = event.keyCode;
        if (
            (KeyID === 8 || KeyID === 46) &&
            this.state.email === "" &&
            eventTarget.name === "email"
        ) {
            this.setState({
                error: {
                    email: false,
                },
            });
        } else if (
            (KeyID === 8 || KeyID === 46) &&
            this.state.password === "" &&
            eventTarget.name === "password"
        ) {
            this.setState({
                error: {
                    password: false,
                },
            });
        } else if (
            (KeyID === 8 || KeyID === 46) &&
            this.state.password === "" &&
            eventTarget.name === "confirmpassword"
        ) {
            this.setState({
                error: {
                    confirmpassword: false,
                },
            });
        } else if (
            (KeyID === 8 || KeyID === 46) &&
            this.state.otp === "" &&
            eventTarget.name === "otp"
        ) {
            this.setState({
                error: {
                    otp: false,
                },
            });
        } else {
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout((event) => {
                let err = {};
                if (eventTarget.name === "email") {
                    err.email = isValidEmail(eventTarget.value.trim())
                        ? false
                        : true;
                } else if (eventTarget.name === "password") {
                    // err.password =
                    //     this.state.validRulesLength === 4 ? false : true;
                }
                this.setState({
                    error: {
                        ...this.state.error,
                        ...err,
                    },
                });
            }, 800);
        }
    };

    handleBack = () => {
        if (this.state.otpSent) {
            this.setState({
                otpSent: false,
            });
        } else {
            this.props.history.push("/login");
        }
    };

    render() {
        const { classes } = this.props;
        return (
            <Container
                component="main"
                maxWidth="xs"
                className={`${classes.root} startScreenContainer`}
            >
                <img src={Logo} className={classes.logo}></img>
                <CssBaseline />
                <Paper
                    className={`${classes.paper} startScreenPaper`}
                    elevation={3}
                >
                    <div className="startScreenPaper__header">
                        <div className="paper-top-navigation">
                            <IconButton onClick={this.handleBack}>
                                <KeyboardBackspaceIcon />
                            </IconButton>
                        </div>
                        <h2 className="paper-title">
                            Forgot Password?
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                            >
                                {this.state.otpSent
                                    ? `OTP has been sent to the ${this.state.email}`
                                    : "Don't worry. we got you covered."}
                            </Typography>
                        </h2>
                    </div>
                    <form
                        className={classes.form}
                        noValidate
                        onSubmit={this.handleSubmit}
                    >
                        {!this.state.otpSent ? (
                            <>
                                <TextField
                                    error={this.state.error.email}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    value={this.state.email}
                                    onChange={this.onChange}
                                    onKeyUp={this.handleValidateField}
                                    autoFocus
                                    helperText={
                                        this.props.message &&
                                        this.state.error.email && (
                                            <span className="error">
                                                {this.props.message}
                                            </span>
                                        )
                                    }
                                />
                                <div className={classes.buttonWrapper}>
                                    <ButtonComp
                                        fullWidth
                                        variant="contained"
                                        bckColor={colors.PRIMARY}
                                        color="#ffffff"
                                        style={{ fontWeight: "bold" }}
                                        disabled={
                                            this.state.loading ||
                                            this.state.error.email ||
                                            this.state.error.password
                                                ? true
                                                : false
                                        }
                                        classes={{
                                            root: `${classes.submit}`,
                                        }}
                                        onClick={this.sendOTP}
                                    >
                                        Reset
                                    </ButtonComp>
                                    {this.state.loading && (
                                        <CircularLoader
                                            size={24}
                                            className={classes.buttonProgress}
                                            color={colors.PRIMARY}
                                        />
                                    )}
                                </div>
                            </>
                        ) : (
                            <>
                                <TextField
                                    error={this.state.error.password}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="otp"
                                    label="OTP"
                                    type="text"
                                    id="otp"
                                    value={this.state.otp}
                                    onChange={this.onChange}
                                    //onKeyUp={this.handleValidateField}

                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <span
                                                    className={classes.resend}
                                                    onClick={this.sendOTP}
                                                >
                                                    RESEND OTP
                                                </span>
                                            </InputAdornment>
                                        ),
                                    }}
                                    helperText=""
                                />
                                <TextField
                                    error={this.state.error.password}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="New Password"
                                    type={
                                        this.state.showPassword
                                            ? "text"
                                            : "password"
                                    }
                                    id="password"
                                    value={this.state.password}
                                    onChange={this.onChange}
                                    onKeyUp={this.handleValidateField}
                                    autoComplete="current-password"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Icon
                                                    aria-label="toggle password visibility"
                                                    size="small"
                                                    onClick={(e) =>
                                                        this.toggleState(
                                                            e,
                                                            "showPassword",
                                                            "password"
                                                        )
                                                    }
                                                    className={classes.eyeIcon}
                                                >
                                                    {!this.state
                                                        .showPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                    helperText=""
                                />
                                <TextField
                                    error={this.state.error.confirmpassword}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="confirmpassword"
                                    label="Confirm Password"
                                    type={
                                        this.state.showPassword
                                            ? "text"
                                            : "password"
                                    }
                                    id="confirmpassword"
                                    value={this.state.confirmpassword}
                                    onChange={this.onChange}
                                    onKeyUp={this.handleValidateField}
                                    autoComplete="current-password"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Icon
                                                    aria-label="toggle password visibility"
                                                    size="small"
                                                    onClick={(e) =>
                                                        this.toggleState(
                                                            e,
                                                            "showPassword",
                                                            "confirmpassword"
                                                        )
                                                    }
                                                    className={classes.eyeIcon}
                                                >
                                                    {!this.state
                                                        .showPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                    helperText=""
                                />
                                <div className={classes.buttonWrapper}>
                                    <ButtonComp
                                        fullWidth
                                        variant="contained"
                                        bckColor={colors.PRIMARY}
                                        color="#ffffff"
                                        style={{ fontWeight: "bold" }}
                                        disabled={
                                            this.state.loading ||
                                            this.state.error.email ||
                                            this.state.error.password
                                                ? true
                                                : false
                                        }
                                        classes={{
                                            root: `${classes.submit}`,
                                        }}
                                        onClick={this.saveNewPassword}
                                    >
                                        Save New Password
                                    </ButtonComp>
                                    {this.state.loading && (
                                        <CircularLoader
                                            size={24}
                                            className={classes.buttonProgress}
                                            color={colors.PRIMARY}
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </form>
                </Paper>
                <Box mt={8}>{renderCopyright()}</Box>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    console.log(state);
    return {
        isLoading: state.reducer.isLoading,
        message: state.reducer.message,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        validateUser: (username, password) => (
            dispatch(validateUser()), dispatch(isLoading(true))
        ),
        updateLoading: (bool) => dispatch(isLoading(bool)),
        updateMessage: (msg) => dispatch(updateMessage(msg)),
        updateAuth: (bool, data) => dispatch(updateAuthentication(bool, data)),
    };
};

export default withStyles(useStyles)(ForgotPassword);
