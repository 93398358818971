import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
} from "@material-ui/core";
import ResultRow from "./ResultRow";
import MainServices from "../../Services/main.services";
import { toast } from "react-toastify";
import CircularLoader from "../UIComponents/CircularLoader";
import EmptyComp from "../UIComponents/EmptyComp";
import { copyToClipBoard } from "../../utilities/helper";

class ResultListing extends Component {
    componentDidMount() {
        if (!this.props.resultsData) {
            this.props.fetchStudentResult();
            console.log("Fetching student's results");
        }
    }

    render() {
        const { resultsData, isLoading, sName } = this.props;
        // let filteredExams;
        // if (this.state.sort) {
        //     filteredExams = this.sortSeries(examData);
        // } else {
        //     filteredExams = examData;
        // }

        return (
            <>
                {!isLoading ? (
                    <>
                        {resultsData && resultsData.length > 0 ? (
                            <TableContainer
                                component={Paper}
                                className="result-list"
                                elevation={0}
                            >
                                <Table
                                    aria-label={`${sName} results `}
                                    size="small"
                                    stickyHeader
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Exam Title</TableCell>
                                            <TableCell align="right">
                                                Total Marks
                                            </TableCell>
                                            <TableCell align="right">
                                                Marks Obtained
                                            </TableCell>
                                            <TableCell align="right">
                                                Date
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {resultsData.map((row) => (
                                            <ResultRow
                                                key={row.resultId}
                                                data={row}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <EmptyComp message="No Result Data Available." />
                        )}
                    </>
                ) : (
                    <CircularLoader wrapperClass="cloader" />
                )}
            </>
        );
    }
}

export default ResultListing;
