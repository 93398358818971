import React from "react";
export const DRAWER_WIDTH = 180;
export const DRAWER_WIDTH_MOBILE = 210;
export const AUTO_CLOSE_TOAST = 5000;
export const APP_VERSION = "Beta";

import Img1 from "../assets/1-min.jpg";
import Img3 from "../assets/2-min.jpg";
import Img2 from "../assets/3-min.jpg";
import Img5 from "../assets/5-min.jpg";

import GroupWorkIcon from "@material-ui/icons/GroupWork";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";

export const actionConstants = {
    UPDATE_AUTHENTICATION: "UPDATE_AUTHENTICATION",
    USER_LOGOUT: "USER_LOGOUT",
    UPDATE_THEME: "UPDATE_THEME",
    UPDATE_MESSAGE: "UPDATE_MESSAGE",
    IS_LOADING: "IS_LOADING",
    UPDATE_ORG_DATA: "UPDATE_ORG_DATA",
    UPDATE_ORG_CONFIG: "UPDATE_ORG_CONFIG",
    UPDATE_ORG_RESOURCES: "UPDATE_ORG_RESOURCES",
    UPDATE_USER_DATA: "UPDATE_USER_DATA",
    FETCH_SERIES_DATA: "FETCH_SERIES_DATA",
    UPDATE_SERIES_DATA: "UPDATE_SERIES_DATA",
    UPDATE_QUESTIONS_DATA: "UPDATE_QUESTIONS_DATA",
    REMOVE_QUESTION_FROM_EXAM: "REMOVE_QUESTION_FROM_EXAM",
    FETCH_EXAMS_DATA: "FETCH_EXAMS_DATA",
    UPDATE_EXAMS_DATA: "UPDATE_EXAMS_DATA",
    FETCH_QUESTIONS_DATA: "FETCH_QUESTIONS_DATA",
    DELETE_SERIES: "DELETE_SERIES",
    DELETE_EXAM: "DELETE_EXAM",
    REGISTER_MEMBER: "REGISTER_MEMBER",
    DELETE_STUDENT: "DELETE_STUDENT",
    FETCH_QBANK_DATA: "FETCH_QBANK_DATA",
    UPDATE_DASHBOARD_DATA: "UPDATE_DASHBOARD_DATA",
    FETCH_STUDENTS_DATA: "FETCH_STUDENTS_DATA",
    UPDATE_STUDENT_RESULT: "UPDATE_STUDENT_RESULT",
    RESET_EXAMS_DATA: "RESET_EXAMS_DATA",
    UPDATE_QBANK_DATA: "UPDATE_QBANK_DATA",
    UPDATE_INTRO_DIALOG: "UPDATE_INTRO_DIALOG",
    FETCH_GROUPS_DATA: "FETCH_GROUPS_DATA",
    ADD_NEW_GROUP: "ADD_NEW_GROUP",
    UPDATE_GROUP_DATA: "UPDATE_GROUP_DATA",
    DELETE_GROUP: "DELETE_GROUP",
    ADD_GROUP_MEMBERS: "ADD_GROUP_MEMBERS",
    FETCH_INSIGHT: "FETCH_INSIGHT",
    FETCH_ORG_INSIGHT: "FETCH_ORG_INSIGHT",
    FETCH_MONTHLY_PERF: "FETCH_MONTHLY_PERF",
    FETCH_GROUP_INSIGHT: "FETCH_GROUP_INSIGHT",
    FETCH_SERIES_ARCHIVE_DATA: "FETCH_SERIES_ARCHIVE_DATA",
    FETCH_EXAM_ARCHIVE_DATA: "FETCH_EXAM_ARCHIVE_DATA",
    RESTORE_SERIES: "RESTORE_SERIES",
    RESTORE_EXAM: "RESTORE_EXAM",
    UPDATE_PLANS_DATA: "UPDATE_PLANS_DATA",
    UPDATE_LICENSE: "UPDATE_LICENSE",
    FETCH_LIBRARY_QS_DATA: "FETCH_LIBRARY_QS_DATA",
    UPDATE_DASHBOARD_QUOTE: "UPDATE_DASHBOARD_QUOTE",
    UPDATE_BANK_DATA: "UPDATE_BANK_DATA",
    UPDATE_MEMBER_TRANX: "UPDATE_MEMBER_TRANX",
    UPDATE_LAST_PAYOUT: "UPDATE_LAST_PAYOUT",
    UPDATE_KEYS: "UPDATE_KEYS",
    FETCH_STAFF_MEMBERS: "FETCH_STAFF_MEMBERS",
    DELETE_STAFF_MEMBER: "DELETE_STAFF_MEMBER",
    UPDATE_STAFF_MEMBER: "UPDATE_STAFF_MEMBER",
    UPDATE_EVENT_DATA: "AUPDATE_EVENT_DATA",
    FETCH_EVENTS_DATA: "FETCH_EVENTS_DATA",
};

export const colors = {
    //PRIMARY: "#0b9ac2",
    PRIMARY: "#0c63ce",
    DANGER: "#f50057",
    GREEN: "#21bf73",
    WARNING: "#ffd571",
    BODY0: "rgba(0, 0, 0, 0.74)",
    BODY1: "rgba(0, 0, 0, 0.54)",
    BODY2: "rgba(0, 0, 0, 0.23)",
    BODY3: "rgba(232, 232, 232, 1)",
};

export const CREATE_NEW = [
    {
        id: "cn-series",
        type: "series",
        title: "New Series",
        description: "Series is a collection of multiple exams.",
        image: Img1,
    },
    {
        id: "cn-exam",
        type: "exam",
        title: "New Exam",
        description: "Create a new exam and add questions.",
        image: Img2,
    },
    {
        id: "cn-que",
        type: "que",
        title: "New Questions",
        description: "Add questions to the Question Bank.",
        image: Img3,
    },
    // {
    //     id: "cn-group",
    //     type: "group",
    //     title: "New Group",
    //     description: "Group is a collection of students.",
    //     image: Img5,
    // },
    {
        id: "cn-session",
        type: "events",
        title: "New Session",
        description: "Schedule new live session",
        image: Img5,
    },
];

export const TAGS = ["SSC", "IBPS", "Class 12", "Class 10"];

export const SIDEBAR_MENU = {
    main: [
        {
            id: "dashboard",
            name: "Dashboard",
            path: "/",
            icon: "DashboardIcon",
        },
        {
            id: "session",
            name: "Live Sessions",
            path: "/session",
        },
        // {
        //     id: "classroom",
        //     name: "Classroom",
        //     path: "/classroom",
        //     icon: "PeopleIcon",
        //     // subMenu: [
        //     //     {
        //     //         id: 2.1,
        //     //         name: "Session",
        //     //         path: "/sessions",
        //     //         icon: <OndemandVideoIcon />,
        //     //     },
        //     // ],
        // },

        {
            id: "exams",
            name: "Exams",
            path: "/manageSeries",
            icon: "AvTimerIcon",
        },
        // {
        //     id: "events",
        //     name: "Events",
        //     path: "/events",
        //     icon: "PeopleIcon",
        // },
        {
            id: "central",
            name: "Students",
            subMenu: [
                {
                    id: 33.1,
                    name: "Manage",
                    path: "/manageMembers",
                    icon: <AssignmentIndIcon />,
                },
                {
                    id: 33.2,
                    name: "Groups",
                    path: "/groups",
                    icon: <GroupWorkIcon />,
                },
            ],
        },
        {
            id: "insights",
            name: "Insights",
            path: "/insights",
            icon: "TimelineIcon",
        },
    ],
    others: [
        {
            id: "org",
            name: "Organisation",
            path: "/org",
        },
        // {
        //     id: 102,
        //     name: "Profile",
        //     path: "/profile",
        // },
        // {
        //     id: 102,
        //     name: "Payments",
        //     path: "/payments",
        // },
        {
            id: 103,
            name: "Need Help?",
            path: "/support",
        },
    ],
};

export const QUESTION_TYPES = [
    {
        label: "Single choice",
        value: "scq",
    },
    {
        label: "Multiple choice",
        value: "mcq",
    },
    {
        label: "True/False",
        value: "bool",
    },
    // {
    //     label: "Fill in the blanks",
    //     value: "text",
    // },
    // {
    //     label: "Subjective",
    //     value: "text",
    // },
];

export const QUESTION_TAGS = [
    "GK",
    "Quantitative Aptitude",
    "Reasoning",
    "English",
    "DI",
    "Current Affairs",
];

export const RESERVED_SUBDOMAINS = [
    "www",
    "mail",
    "email",
    "admin",
    "gradout",
    "students",
    "teachers",
    "school",
    "coaching",
    "college",
    "qa",
    "dev",
    "stage",
    "develop",
    "india",
    "xyz",
    "abc",
    "help",
    "hc",
    "support",
    "work",
    "guides",
    "about",
    "jobs",
    "careers",
    "blog",
    "shop",
    "market",
    "store",
    "web",
    "cloud",
    "cdn",
    "news",
    "app",
    "gov",
    "api",
    "forum",
    "test",
    "mx",
    "portal",
    "m",
    "server",
    "ftp",
    "clients",
    "system",
    "host",
];

export const MONTH_LIST = [
    {
        title: "January",
        value: "jan",
    },
    {
        title: "February",
        value: "feb",
    },
    {
        title: "March",
        value: "mar",
    },
    {
        title: "April",
        value: "apr",
    },
    {
        title: "May",
        value: "may",
    },
    {
        title: "June",
        value: "jun",
    },
    {
        title: "July",
        value: "jul",
    },
    {
        title: "August",
        value: "aug",
    },
    {
        title: "September",
        value: "sep",
    },
    {
        title: "October",
        value: "oct",
    },
    {
        title: "November",
        value: "nov",
    },
    {
        title: "December",
        value: "dec",
    },
];

export const RolesData = [
    {
        title: "Superuser",
        code: 0,
    },
    {
        title: "Admin",
        code: 1,
    },
    {
        title: "Teacher",
        code: 10,
    },
];
