import React, { Component } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import {
    Avatar,
    Paper,
    Button,
    CssBaseline,
    TextField,
    FormControlLabel,
    Checkbox,
    Link,
    Grid,
    Box,
    Container,
    Typography,
    InputAdornment,
    Icon,
    IconButton,
    FormHelperText,
} from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import { withStyles } from "@material-ui/core/styles";
import ButtonComp from "../../components/UIComponents/ButtonComp";
import CircularLoader from "../../components/UIComponents/CircularLoader";
import Visibility from "@material-ui/icons/Visibility";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { colors } from "../../utilities/Constants";
import {
    isValidEmail,
    isValidPhoneNumber,
    renderCopyright,
} from "../../utilities/helper";
import PasswordValidator from "../../components/UIComponents/PasswordValidator";

const useStyles = (theme) => ({
    paper: {
        marginTop: theme.spacing(4),
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    eyeIcon: {
        cursor: "pointer",
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    buttonWrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -8,
        marginLeft: -12,
    },
});

const initialState = {
    email: "",
    fName: "",
    lName: "",
    password: "",
    showPassword: false,
    optMarketing: false,
    validPassword: 0,
    phone: "",
    loading: false,
    submitEnable: false,
    acceptPolicy: false,
    error: {
        email: false,
        phone: false,
        password: false,
        acceptPolicy: false,
    },
};

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    handlePhone = (val) => {
        this.setState({
            phone: val,
        });
    };

    onChange = (e) => {
        // e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        if (e.target.name === "acceptPolicy") {
            this.setState({
                error: {
                    email: false,
                    password: false,
                    fname: false,
                    phone: false,
                    lname: false,
                    acceptPolicy: false,
                },
                [e.target.name]: !this.state[e.target.name],
            });
        } else {
            this.setState({
                error: {
                    email: false,
                    password: false,
                    fname: false,
                    phone: false,
                    lname: false,
                    acceptPolicy: false,
                },
                [e.target.name]: e.target.value.trim(),
            });
        }
    };

    toggleState = (e, name) => {
        this.setState({
            [name]: !this.state[name],
        });
    };

    resetForm = () => {
        this.setState(initialState);
    };

    checkValidity = (validRules) => {
        this.setState({
            validPassword: validRules,
        });
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({ loading: true });

        const { email, password, phone, fName, lName, error } = this.state;
        if (error.email || email.length === 0) {
            // toast.error("Email address is not valid.");
            this.setState({
                loading: false,
                error: {
                    ...this.state.error,
                    email: true,
                },
            });
        } else if (error.password || password.length === 0) {
            // toast.error("Password is not valid.");
            this.setState({
                loading: false,
                error: {
                    ...this.state.error,
                    password: true,
                },
            });
        } else if (error.phone || phone.length === 0) {
            // toast.error("Phone Number is not valid");
            this.setState({
                loading: false,
                error: {
                    ...this.state.error,
                    phone: true,
                },
            });
        } else if (!this.state.acceptPolicy) {
            toast.error("Please accept policy.");
            this.setState({
                loading: false,
                error: {
                    ...this.state.error,
                    acceptPolicy: true,
                },
            });
        } else {
            let params = {
                username: email.toLowerCase().trim(),
                password: password.trim(),
                attributes: {
                    phone_number: phone,
                    name: fName.trim(),
                    family_name: lName.trim(),
                },
            };
            try {
                const response = await Auth.signUp({
                    ...params,
                });
                if (response && response.user) {
                    const user = response.user;
                    this.setState({ loading: false });
                    toast.success(
                        `Your account has been created successfully. Verification link sent to your email ${user.username}`,
                        {
                            autoClose: 8000,
                        }
                    );
                    const locationObj = {
                        pathname: "/login",
                        state: { email: email.trim() },
                    };
                    this.props.history.push(locationObj);
                    this.resetForm();
                }
            } catch (error) {
                this.setState({ loading: false });
                if (error.code === "UsernameExistsException") {
                    this.setState({
                        error: { ...this.state.error, email: true },
                    });
                    toast.error(
                        "Email is already registered! Please try again with different account.",
                        {
                            autoClose: 8000,
                        }
                    );
                } else {
                    toast.error(error.message, {
                        autoClose: 8000,
                    });
                }
            }
        }
    };

    handleValidateField = (event) => {
        const { error } = this.state;
        const target = event.target;
        // catch delete and backspace
        let KeyID = event.keyCode;
        if (
            (KeyID === 8 || KeyID === 46) &&
            this.state.email === "" &&
            target.name === "email"
        ) {
            this.setState({
                error: {
                    email: false,
                },
            });
        } else if (
            (KeyID === 8 || KeyID === 46) &&
            this.state.password === "" &&
            target.name === "password"
        ) {
            this.setState({
                error: {
                    password: false,
                },
            });
        } else if (
            (KeyID === 8 || KeyID === 46) &&
            this.state.phone === "" &&
            target.name === "phone"
        ) {
            this.setState({
                error: {
                    phone: false,
                },
            });
        } else {
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout((event) => {
                let err = {};
                if (target.name === "email") {
                    err.email = isValidEmail(target.value.trim())
                        ? false
                        : true;
                } else if (target.name === "phone") {
                    err.phone = isValidPhoneNumber(target.value.trim()).valid
                        ? false
                        : true;
                } else {
                    err.password =
                        this.state.validPassword === 4 ? false : true;
                }
                this.setState({
                    error: {
                        ...this.state.error,
                        ...err,
                    },
                });
            }, 800);
        }
    };

    handleBack = () => {
        this.props.history.push("/login");
    };

    render() {
        const { classes } = this.props;
        return (
            <Container
                component="main"
                maxWidth="sm"
                className={`${classes.root} startScreenContainer`}
            >
                <CssBaseline />
                <Paper
                    className={`${classes.paper} startScreenPaper`}
                    elevation={3}
                >
                    <div className="startScreenPaper__header">
                        <div className="paper-top-navigation">
                            <IconButton onClick={this.handleBack}>
                                <KeyboardBackspaceIcon />
                            </IconButton>
                        </div>
                        <h2 className="paper-title">
                            Sign Up
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                            >
                                Register yourself here.
                            </Typography>
                        </h2>
                    </div>

                    <form
                        className={classes.form}
                        noValidate
                        onSubmit={this.handleSubmit}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    error={this.state.error.fName}
                                    autoComplete="fname"
                                    name="fName"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    autoFocus
                                    onChange={this.onChange}
                                    //onKeyUp={this.handleValidateField}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    error={this.state.error.lName}
                                    variant="outlined"
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lName"
                                    autoComplete="lname"
                                    onChange={this.onChange}
                                    //onKeyUp={this.handleValidateField}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={this.state.error.email}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    onChange={this.onChange}
                                    onKeyUp={this.handleValidateField}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={this.state.error.password}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type={
                                        this.state.showPassword
                                            ? "text"
                                            : "password"
                                    }
                                    id="password"
                                    autoComplete="current-password"
                                    onChange={this.onChange}
                                    onKeyUp={this.handleValidateField}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Icon
                                                    aria-label="toggle password visibility"
                                                    size="small"
                                                    onClick={(e) =>
                                                        this.toggleState(
                                                            e,
                                                            "showPassword"
                                                        )
                                                    }
                                                    className={classes.eyeIcon}
                                                >
                                                    {!this.state
                                                        .showPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <PasswordValidator
                                    password={this.state.password}
                                    checkValidity={this.checkValidity}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MuiPhoneNumber
                                    error={this.state.error.phone}
                                    variant="outlined"
                                    required
                                    name="phone"
                                    label="Mobile Number"
                                    fullWidth
                                    defaultCountry="in"
                                    onlyCountries={["in"]}
                                    value={this.state.phone}
                                    onChange={this.handlePhone}
                                    onKeyDown={this.handleValidateField}
                                    autoFormat={false}
                                    countryCodeEditable={false}
                                />
                                {/* <TextField
                                    error={this.state.error.phone}
                                    variant="outlined"
                                    required
                                    name="phone"
                                    label="Mobile Number"
                                    fullWidth
                                    // defaultCountry={"in"}
                                    // onlyCountries={["in"]}
                                    value={this.state.phone}
                                    // onChange={this.handlePhone}
                                    onChange={this.onChange}
                                    onKeyUp={this.handleValidateField}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                +91
                                            </InputAdornment>
                                        ),
                                    }}
                                /> */}
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    onChange={this.onChange}
                                    name="acceptPolicy"
                                    control={
                                        <Checkbox
                                            error={
                                                this.state.error.acceptPolicy
                                            }
                                            color="default"
                                            checked={this.state.acceptPolicy}
                                        />
                                    }
                                    label={
                                        <div className="policy-text">
                                            <span>I accept the </span>
                                            <a
                                                href="https://gradout.com/tnc.html"
                                                target="_blank"
                                            >
                                                terms of use
                                            </a>
                                            <span> and </span>
                                            <a
                                                href="https://gradout.com/privacy.html"
                                                target="_blank"
                                            >
                                                privacy policy.
                                            </a>
                                        </div>
                                    }
                                />
                            </Grid>
                        </Grid>
                        <div className={classes.buttonWrapper}>
                            <ButtonComp
                                type="submit"
                                fullWidth
                                variant="contained"
                                bckColor={colors.PRIMARY}
                                color="#ffffff"
                                className={classes.submit}
                                disabled={this.state.loading}
                            >
                                Sign Up
                            </ButtonComp>
                            {this.state.loading && (
                                <CircularLoader
                                    size={24}
                                    className={classes.buttonProgress}
                                    color={colors.PRIMARY}
                                />
                            )}
                        </div>
                        <Grid container justify="flex-end">
                            <Grid item>
                                Already have an account?
                                <Link
                                    to="/login"
                                    variant="body2"
                                    component={RouterLink}
                                >
                                    {` Sign in`}
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                <Box mt={5}>{renderCopyright()}</Box>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    console.log(state);
    return {
        isLoading: state.appState.isLoading,
        message: state.appState.message,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        validateUser: (username, password) => (
            dispatch(validateUser()), dispatch(isLoading(true))
        ),
        updateLoading: (bool) => dispatch(isLoading(bool)),
        updateMessage: (msg) => dispatch(updateMessage(msg)),
        updateAuth: (bool, data) => (
            dispatch(updateAuthentication(bool, data)),
            dispatch(isLoading(true))
        ),
    };
};

export default withStyles(useStyles)(
    connect(mapStateToProps, mapDispatchToProps)(SignUp)
);
