import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducers from "./rootReducers.js";
import { routerMiddleware } from "connected-react-router";
import { history } from "../store/rootReducers";

export default function configureStore() {
    let composeEnhancers = compose;

    let enhancers = [applyMiddleware(thunk, routerMiddleware(history))];

    const store = createStore(rootReducers, composeEnhancers(...enhancers));

    return store;
}
