import React, { Component } from "react";
import { lighten, withStyles } from "@material-ui/core/styles";
import { Dialog, DialogTitle, SvgIcon, Typography } from "@material-ui/core";
import { colors, TAGS } from "../../utilities/constants";
import ButtonComp from "../UIComponents/ButtonComp";
import DeleteIcon from "@material-ui/icons/Delete";
import PublicIcon from "@material-ui/icons/Public";
import ErrorIcon from "@material-ui/icons/Error";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SearchSelector from "../UIComponents/SearchSelector";
import FaceIcon from "@material-ui/icons/Face";
import FolderMoveIcon from "../../assets/svgIcons/FolderMoveIcon";
import AccountRemoveIcon from "../../assets/svgIcons/AccountRemoveIcon";

const useStyles = (theme) => ({
    rightMargin: {
        marginRight: 20,
    },

    icon: {
        fontSize: 80,
    },
    content: {
        textAlign: "center",
        paddingBottom: theme.spacing(1),
    },
    controls: {
        marginTop: theme.spacing(2),
    },
});

class AddMembersDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            members: [],
            targetGroup: null,
            errorMessage: "",
        };
    }

    onChange = (e, value, name) => {
        if (this.state.errorMessage.length > 0) {
            this.setState({
                errorMessage: "",
            });
        }
        if (name === "members") {
            if (
                value[value.length - 1].mygroup &&
                value[value.length - 1].mygroup.length > 0
            ) {
                let currentGroup = this.props.groupsData.find(
                    (g) => g.groupId === value[value.length - 1].mygroup
                );

                this.setState({
                    errorMessage: `${value[value.length - 1].name} ${
                        value[value.length - 1].family_name
                    } is already registered with ${
                        currentGroup && currentGroup.title
                            ? currentGroup.title
                            : "another"
                    } Group`,
                });
            } else {
                if (value.length > 20) {
                    this.setState({
                        errorMessage:
                            "Only 20 members are allowed in one time. Add them and can always start again adding.",
                    });
                } else {
                    this.setState({
                        [name]: value,
                    });
                }
            }
        } else {
            this.setState({
                [name]: value,
            });
        }
    };

    updateGroup = (e, group) => {
        e.persist();
        e.preventDefault();
        let params = {
            type: "members",
            action: this.props.type,
            groupId: group.groupId,
            orgId: this.props.orgId,
        };
        if (this.props.type === "add") {
            params.members = this.state.members.map((m) => m.userId);
        } else if (this.props.type === "move") {
            params.members = [this.props.data.userId];
            params.targetGroupId = this.state.targetGroup.groupId;
        } else {
            params.members = [this.props.data.userId];
        }

        this.props.updateGroup(params);
        this.props.handleClose(e);
    };

    render() {
        const { classes, studentsData, groupsData, group, type, data } =
            this.props;

        let selectorData;
        if (type === "add") {
            selectorData = studentsData
                ? studentsData.filter((s) => !group.members.includes(s.userId))
                : undefined;
        } else if (type === "move") {
            selectorData =
                groupsData && groupsData.length > 1
                    ? groupsData.filter((g) => g.groupId !== group.groupId)
                    : undefined;
        }

        return (
            <Dialog
                //onClose={this.props.handleClose}
                aria-labelledby="create-new-dialog"
                open={this.props.open}
                classes={{ root: "dialog", paper: "delete-dialog" }}
                BackdropProps={{
                    style: { backgroundColor: "rgba(0,0,0,0.7)" },
                }}
                maxWidth="xs"
                fullWidth
            >
                {type === "move" && (
                    <FolderMoveIcon className={`${classes.icon} deleteIcon`} />
                )}
                {type === "add" && (
                    <SvgIcon
                        fontSize="inherit"
                        className={`${classes.icon} deleteIcon`}
                    >
                        <PersonAddIcon />
                    </SvgIcon>
                )}
                {type === "remove" && (
                    <AccountRemoveIcon
                        className={`${classes.icon} deleteIcon`}
                    />
                )}
                <DialogTitle id="create-new-dialog" className={"paper-title"}>
                    {type === "add" && <>{`Add Members to ${group.title}`}</>}
                    {type === "move" && "Move Member"}
                    {type === "remove" && (
                        <>{`Remove ${data.name} from ${group.title}`}</>
                    )}
                </DialogTitle>
                <Typography
                    variant="body1"
                    component="p"
                    className={classes.content}
                >
                    {type === "add" && (
                        <>
                            {`Add members from registered students to the group ${group.title}`}
                        </>
                    )}
                    {type === "move" && (
                        <>
                            {`Move the member ${data.name} ${
                                data.family_name ? data.family_name : ""
                            } from group ${group.title} to the other group.`}
                            <span
                                style={{ fontWeight: "bold", display: "block" }}
                            >
                                Select the Target group.
                            </span>
                        </>
                    )}
                    {type === "remove" && (
                        <>{`Confirm removal of ${data.name} ${
                            data.family_name ? data.family_name : ""
                        } from ${
                            group.title
                        }. User will not be mapped to any group if confirmed.`}</>
                    )}
                </Typography>
                {type !== "remove" && (
                    <SearchSelector
                        error={this.state.errorMessage.length > 0}
                        id="members"
                        name={type === "add" ? "members" : "targetGroup"}
                        fullWidth
                        multiple={type === "add" ? true : false}
                        noOptionsText={
                            type === "add"
                                ? "No user found."
                                : "No group found."
                        }
                        data={selectorData ? selectorData : []}
                        loading={selectorData ? false : true}
                        variant="outlined"
                        margin="dense"
                        onChange={this.onChange}
                        value={
                            type === "add"
                                ? this.state.members
                                : this.state.targetGroup
                        }
                        label={`${
                            type === "add" ? "Add Members" : "Target Group"
                        }`}
                        ChipProps={{
                            icon: <FaceIcon />,
                        }}
                        helperText={this.state.errorMessage}
                    />
                )}

                <div className={`controls ${classes.controls}`}>
                    <ButtonComp
                        variant="contained"
                        bckColor={colors.PRIMARY}
                        color="#ffffff"
                        onClick={(e) => this.updateGroup(e, group)}
                        classes={{ root: classes.rightMargin }}
                    >
                        {type === "add"
                            ? "Add Members"
                            : type === "move"
                            ? "Move Member"
                            : "Remove Member"}
                    </ButtonComp>

                    <ButtonComp
                        variant="outlined"
                        color="#000000"
                        onClick={this.props.handleClose}
                    >
                        Close
                    </ButtonComp>
                </div>
            </Dialog>
        );
    }
}

export default withStyles(useStyles)(AddMembersDialog);
