import React from "react";

/**
 * A HOC to repeat a component N times.
 *
 * @param {React.Component} Component The original component
 * @param {Number} times How many times it has to be repeated
 * @param {String} keyPrefix The prefix for the key and index
 */
const Repeater = React.forwardRef((props, ref) => {
    const { component, times, state, ...rest } = props;
    const Repeated = [];
    Repeated.length = times;
    Repeated.fill(component);
    return (
        <div className="repeater" ref={ref}>
            {Repeated.map((Comp, index) => (
                <Comp
                    {...rest}
                    st={state && state[index]}
                    num={index}
                    key={`qq-${index}`}
                />
            ))}
        </div>
    );
});

export default Repeater;
