import React, { Component } from "react";
import { toast } from "react-toastify";
import { lighten, withStyles } from "@material-ui/core/styles";
import {
    Dialog,
    DialogTitle,
    MenuItem,
    InputAdornment,
    TextField,
    Divider,
    ClickAwayListener,
    IconButton,
    Drawer,
    Slide,
    FormControl,
    FormLabel,
    RadioGroup,
    FormHelperText,
    Input,
    InputLabel,
    FormGroup,
    FormControlLabel,
    endAdornment,
} from "@material-ui/core";
import SearchSelector from "../UIComponents/SearchSelector";
import { colors, TAGS } from "../../utilities/constants";
import { v4 as uuidv4 } from "uuid";
import password from "secure-random-password";
import ButtonComp from "../UIComponents/ButtonComp";
import GreenCheckbox from "../UIComponents/GreenCheckbox";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import CloseIcon from "@material-ui/icons/Close";
import GreenRadio from "../UIComponents/GreenRadio";
import MainServices from "../../services/main.services";
import { isLoading } from "../../store/action";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import TooltipComp from "../UIComponents/TooltipComp";
import { copyToClipBoard } from "../../utilities/helper";

const useStyles = (theme) => ({
    drawer: {
        padding: "30px",
        maxWidth: 500,
    },
    field: {
        margin: "8px 0px",
        color: colors.PRIMARY,
    },
    rightMargin: {
        marginRight: 20,
    },
    seriesSelectBox: {
        margin: "10px 0 15px",
    },
});

const initialState = {
    series: null,
    title: "",
    description: "",
    tags: [],
    examDuration: "",
    marks: "",
    marksPerQ: 2,
    NegMarksPerQ: 0.5,
    showResultOnCompletion: true,
    allowCalci: false,
    emailResult: true,
    access: "free",
    amount: 0,
    offer: 0,
    scope: "public",
    password: null,
    group: null,
};

class CreateEditEntity extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.MainServices = new MainServices();
    }

    static getDerivedStateFromProps(props, state) {
        if (!props.open) {
            return initialState;
        }

        let newState = {};
        if (props.data && props.action === "edit") {
            if (props.type === "exam" && !state.series) {
                const {
                    series,
                    title,
                    description,
                    tags,
                    examDuration,
                    marks,
                    marksPerQ,
                    NegMarksPerQ,
                    showResultOnCompletion,
                    allowCalci,
                    emailResult,
                    eAccess,
                    eScope,
                } = props.data;
                newState = {
                    series,
                    title,
                    description,
                    tags,
                    examDuration,
                    marks,
                    marksPerQ,
                    NegMarksPerQ,
                    showResultOnCompletion,
                    allowCalci: allowCalci ? allowCalci : false,
                    emailResult,
                    access: eAccess,
                    scope: eScope,
                    password: props.data.ePassword
                        ? props.data.ePassword
                        : null,
                };
            } else if (props.type === "series" && !state.title) {
                const { title, description, sAccess, sScope } = props.data;
                newState = {
                    title,
                    description,
                    access: sAccess,
                    scope: sScope,
                    password: props.data.sPassword
                        ? props.data.sPassword
                        : null,
                    amount: props.data.sAmount ? props.data.sAmount : 0,
                    offer: props.data.sOffer ? props.data.sOffer : 0,
                };
            }
        }
        // map group
        if (
            !state.group &&
            props.type === "series" &&
            props.data &&
            props.data.sScope === "group" &&
            props.groupsData
        ) {
            newState.group = props.groupsData.find(
                (g) => g.groupId === props.data.groupId
            );
        }
        //fallback if groups has been disabled
        if (
            props.type === "series" &&
            !props.orgData.config.groupsEnabled &&
            props.data.sScope === "group"
        ) {
            newState.sScope = "public";
        }
        if (
            props.data &&
            props.action === "add" &&
            props.type === "exam" &&
            !state.series
        ) {
            newState = {
                ...state,
                series: props.data.series,
            };
        }

        if (state.scope === "private" && !state.password) {
            newState.password = password.randomPassword({
                length: 6,
                characters: password.digits,
            });
        }
        if (state.scope === "public" && state.password) {
            newState.password = null;
        }

        return {
            ...state,
            ...newState,
        };
    }

    // componentDidMount() {
    //     if (!this.props.orgData.licRules.sellSeries) {
    //         this.setState({
    //             access: "free",
    //             amount: 0,
    //         });
    //     }
    // }

    handleChange = (e, value, type) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        if (e.target.name === "scope") {
            // Fetch Groups
            if (!this.props.groupsData || this.props.groupsData.length === 1) {
                this.props.fetchGroups(this.props.orgData.orgId);
            }
        }
        if (
            e.target.name === "showResultOnCompletion" ||
            e.target.name === "emailResult" ||
            e.target.name === "allowCalci"
        ) {
            this.setState({
                [e.target.name]: !this.state[e.target.name],
            });
        } else if (value && type) {
            this.setState({
                [type]: value,
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value,
            });
        }

        if (
            e.target.name === "access" &&
            e.target.value === "paid" &&
            this.state.scope !== "public"
        ) {
            this.setState({
                scope: "public",
            });
        }
    };

    handleTagChange = (e, value) => {
        this.setState({
            tags: value,
        });
    };

    handleSubmit = async (e, type) => {
        e.preventDefault();
        const {
            series,
            title,
            description,
            tags,
            examDuration,
            marks,
            marksPerQ,
            NegMarksPerQ,
            showResultOnCompletion,
            allowCalci,
            emailResult,
            access,
            scope,
            password,
            group,
            amount,
            offer,
        } = this.state;

        if (this.props.action === "add") {
            if (type === "exam") {
                try {
                    await this.props.saveExam(
                        {
                            seriesId: series.seriesId,
                            orgId: this.props.orgData.orgId,
                            title,
                            description,
                            tags,
                            examDuration,
                            marks,
                            marksPerQ,
                            NegMarksPerQ,
                            //showResultOnCompletion,
                            allowCalci,
                            emailResult,
                            eScope: scope,
                            eAccess: access,
                            ePassword: password,
                        },
                        this.props.source
                    );
                    // if (scope === "private") {
                    // }
                    // if (this.props.message !== "ERROR") {
                    //this.reset();
                    //this.props.updateExamsData(res.item);
                    //toast.success("🎉 Exam Successfully Created!");
                    this.props.handleClose();
                    // }
                } catch (e) {
                    // toast.error(e.message);
                    this.props.handleClose();
                }
            } else {
                try {
                    let params = {
                        title,
                        orgId: this.props.orgData.orgId,
                        description,
                        sAccess: access,
                        sScope: scope,
                        sPassword: password,
                        groupId: group ? group.groupId : null,
                        sAmount: Number(amount),
                        sOffer: Number(offer),
                    };

                    if (access === "free") {
                        params["sAmount"] = 0;
                        params["sOffer"] = 0;
                    }

                    await this.props.saveSeries(params, this.props.source);
                    // if (res) {
                    //this.reset();
                    this.props.handleClose();
                    // this.props.updateSeriesData(res.item);
                    // toast.success("🎉 Series Successfully Created!");
                    // this.props.history.push({
                    //     pathtitle: `/manage/seriesDetails/${res.item.seriesId}`,
                    //     data: res.item,
                    // });
                    // }
                } catch (e) {
                    // toast.error(e.message);
                    this.props.handleClose();
                }
            }
        } else {
            //handle type edit
            if (type === "exam") {
                let params = {
                    type: "item",
                    examId: this.props.data.examId,
                    seriesId: this.props.data.seriesId,
                    title,
                    description,
                    tags,
                    examDuration,
                    marks,
                    marksPerQ,
                    NegMarksPerQ,
                    //showResultOnCompletion,
                    allowCalci,
                    emailResult,
                    itemStatus: this.props.data.itemStatus,
                    eAccess: access,
                    eScope: scope,
                    ePassword: this.props.data.ePassword,
                };
                if (this.props.data.ePassword !== password) {
                    params["ePassword"] = password;
                }

                // if (this.props.data.series.seriesId !== series.seriesId) {
                //     params["old_seriesId"] = this.props.data.series.seriesId;
                // }
                try {
                    if (this.props.data.series.seriesId === series.seriesId) {
                        await this.props.updateExam(params);
                        //this.reset();
                        this.props.handleClose();
                    } else {
                        throw new Error("Parent series can't be changed.");
                    }

                    // toast.success("🎉 Exam updated successfully!");
                } catch (e) {
                    toast.error(e.message);
                    //this.props.handleClose();
                }
            } else {
                try {
                    let params = {
                        type: "item",
                        orgId: this.props.orgData.orgId,
                        seriesId: this.props.data.seriesId,
                        title,
                        description,
                        examsCount: this.props.data.examsCount,
                        itemStatus: this.props.data.itemStatus,
                        sAccess: access,
                        sScope: scope,
                        sPassword: this.props.data.sPassword,
                        sAmount: Number(amount),
                        sOffer: Number(offer),
                    };
                    if (this.props.data.sPassword !== password) {
                        params["sPassword"] = password;
                    }

                    if (access === "free") {
                        params["sAmount"] = 0;
                    }

                    if (scope === "group") {
                        params["groupId"] = group.groupId;
                    } else {
                        params["groupId"] = null;
                    }

                    await this.props.updateSeries(params);

                    this.props.handleClose();
                } catch (e) {
                    console.log(e);
                    this.props.handleClose();
                }
            }
        }
    };

    onClose = (e) => {
        e.persist();
        this.props.handleClose(e);
        // if (this.props.action === "add") {
        //     this.reset();
        // }
    };

    changePassword = () => {
        this.setState({
            password: password.randomPassword({
                length: 6,
                characters: password.digits,
            }),
        });
    };

    copyToClipBoard = () => {
        const { password } = this.state;
        copyToClipBoard(password);
    };

    render() {
        const { classes, type, seriesData, groupsData, data } = this.props;

        return (
            <Drawer
                anchor="right"
                open={this.props.open}
                onClose={this.onClose}
                transitionDuration={200}
                BackdropProps={{
                    style: { backgroundColor: "rgba(0,0,0,0.7)" },
                }}
                elevation={2}
                classes={{
                    root: "addEditExam",
                    paper: classes.drawer,
                }}
            >
                <div className="paper-top-navigation">
                    {this.props.source === "topMenu" && (
                        <IconButton onClick={this.props.handleBack}>
                            <KeyboardBackspaceIcon />
                        </IconButton>
                    )}

                    <IconButton onClick={this.onClose} className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                </div>
                <DialogTitle id="create-new-dialog" className={"paper-title"}>
                    {type === "exam" &&
                        `${
                            this.props.action === "edit" ? "Edit" : "Create New"
                        } Exam`}
                    {type === "series" &&
                        `${
                            this.props.action === "edit" ? "Edit" : "Create New"
                        } Series`}
                </DialogTitle>
                <form onSubmit={(e) => this.handleSubmit(e, type)}>
                    {type === "exam" && (
                        <>
                            <SearchSelector
                                id="series"
                                name="series"
                                required
                                disabled={this.props.action === "edit"}
                                data={seriesData ? seriesData : []}
                                loading={
                                    seriesData || !isLoading ? false : true
                                }
                                variant="outlined"
                                margin="dense"
                                onChange={this.handleChange}
                                value={this.state.series}
                                label="Select Series"
                            />
                            <Divider variant="middle" className={"divider"} />
                        </>
                    )}

                    <TextField
                        id="title"
                        label={type === "exam" ? "Exam Name" : "Series Name"}
                        name="title"
                        value={this.state.title}
                        required
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        className={`${classes.field}`}
                        helperText="Example: Class 12 MidTerms, SSC CGL 2020 etc."
                        onChange={this.handleChange}
                    />
                    <TextField
                        id="description"
                        name="description"
                        label="Description"
                        multiline
                        rows={2}
                        value={this.state.description}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        className={`${classes.field}`}
                        helperText="Small description for your future use. This is optional."
                        onChange={this.handleChange}
                    />

                    {type === "exam" && (
                        <>
                            <Divider variant="middle" className={"divider"} />
                            <div className="rowWrapper">
                                <SearchSelector
                                    id="tags"
                                    name="tags"
                                    freeSolo={true}
                                    freeTags
                                    multiple={true}
                                    data={TAGS}
                                    loading={TAGS.length > 0 ? false : true}
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                    size="small"
                                    fullWidth
                                    onChange={this.handleTagChange}
                                    value={this.state.tags}
                                    label="Select Tags"
                                />
                                <TextField
                                    id="examDuration"
                                    type="number"
                                    label="Duration"
                                    name="examDuration"
                                    required
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    className={`${classes.field}`}
                                    value={this.state.examDuration}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                min
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={this.handleChange}
                                    helperText="Enter duration in Minutes"
                                ></TextField>
                            </div>
                            <section>
                                <div className="sectionTitle">
                                    Marks Scheme:
                                </div>
                                <TextField
                                    id="marks"
                                    type="number"
                                    name="marks"
                                    label="Total Marks"
                                    required
                                    margin="dense"
                                    variant="outlined"
                                    className={`${classes.field}`}
                                    value={this.state.marks}
                                    onChange={this.handleChange}
                                ></TextField>
                                <div className="rowWrapper">
                                    <TextField
                                        id="marksPerQ"
                                        type="number"
                                        label="Correct answer marks"
                                        name="marksPerQ"
                                        margin="dense"
                                        variant="outlined"
                                        required
                                        className={`${classes.field} ${classes.rightMargin}`}
                                        value={this.state.marksPerQ}
                                        onChange={this.handleChange}
                                        helperText="Marks assigned for each correct answer"
                                    ></TextField>
                                    <TextField
                                        id="NegMarksPerQ"
                                        type="number"
                                        label="Wrong answer marks"
                                        name="NegMarksPerQ"
                                        margin="dense"
                                        variant="outlined"
                                        required
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    -
                                                </InputAdornment>
                                            ),
                                        }}
                                        className={`${classes.field} `}
                                        value={this.state.NegMarksPerQ}
                                        onChange={this.handleChange}
                                        helperText="Marks deducted for each wrong answer"
                                    ></TextField>
                                </div>
                            </section>
                            <section>
                                <div className="sectionTitle">Permissions:</div>
                                <FormGroup row>
                                    {/* <FormControlLabel
                                        onChange={this.handleChange}
                                        name="showResultOnCompletion"
                                        control={
                                            <GreenCheckbox
                                                checked={
                                                    this.state
                                                        .showResultOnCompletion
                                                }
                                            />
                                        }
                                        label="Show result on exam completion"
                                    /> */}
                                    <FormControlLabel
                                        onChange={this.handleChange}
                                        name="allowCalci"
                                        control={
                                            <GreenCheckbox
                                                checked={this.state.allowCalci}
                                            />
                                        }
                                        label="Allow calculator during the exam."
                                    />
                                    <FormControlLabel
                                        onChange={this.handleChange}
                                        name="emailResult"
                                        control={
                                            <GreenCheckbox
                                                checked={this.state.emailResult}
                                            />
                                        }
                                        label="Email result to student"
                                    />
                                </FormGroup>
                            </section>
                        </>
                    )}

                    <section>
                        <div className="sectionTitle">{`${type} Scope:`}</div>
                        <RadioGroup
                            aria-label="Scope"
                            name="scope"
                            value={this.state.scope}
                            onChange={this.handleChange}
                            row
                        >
                            <FormControlLabel
                                value="public"
                                control={<GreenRadio />}
                                label="Public"
                            />
                            <FormControlLabel
                                value="private"
                                control={<GreenRadio />}
                                label="Private"
                                disabled={this.state.access === "paid"}
                            />
                            {type === "series" &&
                                this.props.orgData.config.groupsEnabled && (
                                    <FormControlLabel
                                        value="group"
                                        control={<GreenRadio />}
                                        label="Group"
                                        disabled={this.state.access === "paid"}
                                    />
                                )}
                        </RadioGroup>
                    </section>
                    {this.state.scope === "private" && (
                        <div className="password-section">
                            <FormControl disabled className="spassword">
                                <InputLabel htmlFor="component-disabled">
                                    {`${type} Password:`}
                                </InputLabel>
                                <Input
                                    className={`${classes.field}`}
                                    id="sPassword"
                                    value={this.state.password}
                                    endAdornment={
                                        <InputAdornment
                                            position="end"
                                            className="change-password"
                                            onClick={this.changePassword}
                                        >
                                            Change password
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>
                                    {`Password is required to access private
                                    ${type}.`}
                                </FormHelperText>
                            </FormControl>
                            <TooltipComp
                                placement="top"
                                title={`Copy ${type} password`}
                            >
                                <IconButton onClick={this.copyToClipBoard}>
                                    <FileCopyIcon />
                                </IconButton>
                            </TooltipComp>
                        </div>
                    )}

                    {this.state.scope === "group" && (
                        <SearchSelector
                            id="group"
                            name="group"
                            required
                            data={groupsData ? groupsData : []}
                            loading={groupsData || !isLoading ? false : true}
                            variant="outlined"
                            margin="dense"
                            onChange={this.handleChange}
                            value={this.state.group}
                            label="Select Group"
                            helperText="Only the members of group will have access to this series."
                        />
                    )}

                    <section>
                        <div className="sectionTitle">{`${type} Type:`}</div>
                        <RadioGroup
                            aria-label="Series Type"
                            name="access"
                            value={this.state.access}
                            onChange={this.handleChange}
                            row
                        >
                            <FormControlLabel
                                value="free"
                                control={<GreenRadio />}
                                label="Free"
                            />
                            <FormControlLabel
                                value="paid"
                                control={<GreenRadio />}
                                label="Paid"
                                disabled={
                                    !this.props.orgData.licRules.sellSeries
                                }
                            />
                        </RadioGroup>
                    </section>
                    {this.state.access === "paid" && (
                        <>
                            <div
                                className="rowWrapper"
                                style={{ justifyContent: "flex-start" }}
                            >
                                <TextField
                                    id="amount"
                                    type="number"
                                    label="Amount"
                                    name="amount"
                                    margin="dense"
                                    variant="outlined"
                                    required
                                    className={`${classes.field} ${classes.rightMargin}`}
                                    value={this.state.amount}
                                    onChange={this.handleChange}
                                    helperText="Enter the amount in INR"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                ₹
                                            </InputAdornment>
                                        ),
                                    }}
                                ></TextField>
                                <div className="finalAmount">
                                    <div
                                        style={{ color: "rgba(0, 0, 0, 0.54)" }}
                                    >
                                        Effective Amount:
                                    </div>{" "}
                                    ₹
                                    {this.state.offer
                                        ? this.state.amount -
                                          (this.state.offer *
                                              this.state.amount) /
                                              100
                                        : this.state.amount}
                                </div>
                            </div>
                            <TextField
                                id="offer"
                                type="number"
                                label="Offer"
                                name="offer"
                                margin="dense"
                                variant="outlined"
                                // style={{ width: "40%" }}
                                className={`${classes.field} ${classes.rightMargin}`}
                                value={this.state.offer}
                                onChange={this.handleChange}
                                helperText="Offer in percentage"
                                InputProps={{
                                    // startAdornment: (
                                    //     <InputAdornment position="start">
                                    //         ₹
                                    //     </InputAdornment>
                                    // ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            %
                                        </InputAdornment>
                                    ),
                                }}
                            ></TextField>
                        </>
                    )}

                    <div className={`controls ${classes.field}`}>
                        <ButtonComp
                            variant="contained"
                            bckColor={colors.PRIMARY}
                            color="#ffffff"
                            type="submit"
                            classes={{ root: classes.rightMargin }}
                        >
                            {this.props.action === "add" ? "Save" : "Update"}
                        </ButtonComp>
                        <ButtonComp
                            variant="outlined"
                            color="#000000"
                            onClick={this.onClose}
                        >
                            Close
                        </ButtonComp>
                    </div>
                </form>
            </Drawer>
        );
    }
}

export default withStyles(useStyles)(CreateEditEntity);
