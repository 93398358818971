import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import queryString from "query-string";
import {
    Paper,
    Container,
    Typography,
    IconButton,
    TextField,
} from "@material-ui/core";
import {
    isLoading,
    fetchGroups,
    fetchStudents,
    registerMember,
} from "../../store/action";
import ConfirmDialog from "../../components/Dialogs/ConfirmDialog";
import TooltipComp from "../../components/UIComponents/TooltipComp";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import MActionCard from "../../components/UIComponents/MActionCard";
import ButtonComp from "../../components/UIComponents/ButtonComp";
import CircularLoader from "../../components/UIComponents/CircularLoader";
import EmptyComp from "../../components/UIComponents/EmptyComp";
import RegisterMember from "../../components/Dialogs/RegisterMember";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import SearchSelector from "../../components/UIComponents/SearchSelector";
import OrgServices from "../../services/org.services";
import { debounce } from "../../utilities/helper";

class StudentsDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isRegisterMemberOpen: false,
            selectedMember: null,
            membersList: props.studentsData,
            term: "",
        };
        this.OrgServices = new OrgServices();
    }

    static getDerivedStateFromProps(props, state) {
        return null;
    }

    onChange = (e, value, type) => {
        if (type) {
            this.setState({
                [type]: value.trim(),
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value,
            });
        }
    };

    visitMemberProfile = () => {
        const { selectedMember } = this.state;
        this.props.history.push({
            pathname: `/profile/${selectedMember.userId}`,
            data: selectedMember,
        });
    };

    toggleRegisterMemberDialog = () => {
        if (!this.props.groups) {
            this.props.fetchGroups(this.props.orgData.orgId);
        }
        this.setState((prevState) => {
            return {
                isRegisterMemberOpen: !prevState.isRegisterMemberOpen,
            };
        });
    };

    onChangeSeletor = (e, item) => {
        this.setState({
            selectedMember: item,
            term: "",
        });
    };

    fetchMembers = (e) => {
        console.log("fetch members -->" + e.target.value);
        if (!this.state.membersList) {
            this.OrgServices.getMembers({
                orgId: this.props.orgData.orgId,
                term: this.state.term,
            })
                .then((resp) => {
                    this.setState({
                        membersList: resp.data,
                    });
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        }
    };

    componentDidMount() {}

    handleBack = (e) => {
        this.props.history.push("/classroom");
    };

    render() {
        const { orgData, groups } = this.props;
        return (
            <>
                <div className="members-dashboard">
                    {/* <div
                        className="container-top-navigation"
                        style={{ position: "unset" }}
                    >
                        <ButtonComp onClick={this.handleBack} size="small">
                            <ChevronLeftIcon />
                            Back
                        </ButtonComp>
                    </div> */}

                    <div className="page-title">
                        <Typography
                            variant="h1"
                            component="h1"
                            className="page-header"
                        >
                            Manage Students
                        </Typography>
                        <ButtonComp
                            variant="outlined"
                            color="#000000"
                            onClick={() => null}
                            startIcon={<AddCircleIcon />}
                            onClick={(e) => this.toggleRegisterMemberDialog(e)}
                            className="page-create-btn"
                            style={{
                                margin: "5px 0 0",
                                marginLeft: "auto",
                            }}
                        >
                            Register New Student
                        </ButtonComp>
                        <TooltipComp
                            placement="top"
                            title="Register New Member"
                        >
                            <IconButton
                                aria-label="Register New Member"
                                className="mobile-only-page-create-btn"
                                style={{
                                    margin: "0",
                                    marginLeft: "auto",
                                }}
                                onClick={(e) =>
                                    this.toggleRegisterMemberDialog(e)
                                }
                            >
                                <AddCircleIcon
                                    fontSize="inherit"
                                    // style={{ color: colors.DANGER }}
                                />
                            </IconButton>
                        </TooltipComp>
                    </div>
                    <Paper elevation={0} className={`cardList`}>
                        <MActionCard
                            data={{
                                media: (
                                    <PersonAddIcon style={{ fontSize: 50 }} />
                                ),
                                title: "Register New Student",
                                description:
                                    "New admision or manual registration of the student. The new registered student will recieve username and temporary password to their email address.",
                            }}
                            btnLabel="Register New Student"
                            onClick={this.toggleRegisterMemberDialog}
                            noEllipsis={true}
                        />
                        <MActionCard
                            data={{
                                media: (
                                    <AccountCircleIcon
                                        style={{ fontSize: 50 }}
                                    />
                                ),
                                title: "Student Profile",
                                description:
                                    "Search and select the student to visit the profile.",
                            }}
                            btnLabel="Visit Now"
                            btnDisabledStatus={
                                this.state.selectedMember ? false : true
                            }
                            onClick={this.visitMemberProfile}
                        >
                            <SearchSelector
                                id="members"
                                name={this.state.insightType}
                                className="member-search"
                                size="small"
                                openOnFocus
                                data={this.state.membersList}
                                loading={
                                    !this.state.term || this.state.membersList
                                        ? false
                                        : true
                                }
                                inputValue={this.state.term}
                                onInputChange={(e, val) =>
                                    this.onChange(e, val, "term")
                                }
                                noOptionsText={
                                    this.state.term
                                        ? "No Members Found"
                                        : "Search result will appear as you type."
                                }
                                variant="outlined"
                                onKeyDown={this.fetchMembers}
                                onChange={this.onChangeSeletor}
                                value={this.state.selectedMember}
                                label="Search the Student"
                            />
                        </MActionCard>
                        {/* <MActionCard
                            data={{
                                media: (
                                    <ImportExportIcon
                                        style={{ fontSize: 50 }}
                                    />
                                ),
                                title: "Import Students",
                                description:
                                    "Import list of students from an excel file. Only .xlsx, .xls formats are allowed.",
                            }}
                            btnLabel="Import Now"
                            onClick={this.visitMemberProfile}
                        /> */}
                        <MActionCard
                            data={{
                                media: (
                                    <GroupWorkIcon style={{ fontSize: 50 }} />
                                ),
                                title: "Create New Group",
                                description:
                                    "Create a New Group to manage students collectively.",
                            }}
                            btnLabel="Create New Group"
                            onClick={(e) =>
                                this.props.toggleDialogsWithType(
                                    e,
                                    "group",
                                    null,
                                    "add",
                                    "manageStudents"
                                )
                            }
                            noEllipsis={true}
                        />
                    </Paper>
                </div>

                <RegisterMember
                    open={this.state.isRegisterMemberOpen}
                    handleClose={this.toggleRegisterMemberDialog}
                    groupsData={this.props.groups}
                    registerMember={this.props.registerMember}
                    orgData={this.props.orgData}
                    isLoading={this.props.isLoading}
                    message={this.props.message}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        orgData: state.appState.orgData,
        isLoading: state.appState.isLoading,
        groups: state.groupsState.groups,
        studentsData: state.appState.studentsData,
        message: state.appState.message,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchGroups: (orgId) => (
            dispatch(fetchGroups(orgId)), dispatch(isLoading(true))
        ),
        fetchStudents: (orgId, term) => dispatch(fetchStudents(orgId, term)),
        registerMember: (params) => (
            dispatch(registerMember(params)), dispatch(isLoading(true))
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentsDashboard);
