import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import {
    Paper,
    Card,
    TextField,
    Grid,
    FormGroup,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    RadioGroup,
    FormControlLabel,
    Radio,
    Checkbox,
    TextareaAutosize,
    Typography,
    FormHelperText,
} from "@material-ui/core";
import {
    QUESTION_TYPES,
    QUESTION_TAGS,
    colors,
} from "../../utilities/constants";
import SearchSelector from "../UIComponents/SearchSelector";
import Repeater from "../../hoc/Repeater";
import Option from "../UIComponents/Option";
import GreenRadio from "../UIComponents/GreenRadio";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import OrgServices from "../../services/org.services";
import { toast } from "react-toastify";
import ButtonComp from "./ButtonComp";

const useStyles = (theme) => ({
    root: {
        flexGrow: 1,
        padding: 20,
        maxWidth: 1000,
    },
    textArea: {
        margin: 9,
    },
    removeMargin: {
        margin: 0,
    },
    helptext: {
        position: "absolute",
        bottom: "-10px",
    },
});

const GreenCheckbox = withStyles({
    root: {
        "&$checked": {
            color: colors.GREEN,
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

class QCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: null,
        };
        this.OrgServices = new OrgServices();
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.editorState) {
            const contentBlock = htmlToDraft(props.st.q);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(
                    contentBlock.contentBlocks
                );
                const editorState = EditorState.createWithContent(contentState);
                return {
                    editorState,
                };
            }
        }
        return null;
    }

    renderOptions = (type) => {
        switch (type) {
            case "scq":
                return (
                    <RadioGroup
                        aria-label="Options"
                        name="options"
                        value={this.props.st.qAnswer.get("scq")}
                        onChange={(e) =>
                            this.props.handleAnswerChange(e, this.props.num)
                        }
                    >
                        <Repeater
                            type="scq"
                            component={Option}
                            times={this.props.st.qOptions.length}
                            control={<GreenRadio />}
                            optionText={this.props.st.qOptions}
                            handleOptionChange={this.props.handleOptionChange}
                            qIndex={this.props.num}
                        />
                    </RadioGroup>
                );
            case "mcq":
                return (
                    <FormGroup aria-label="options" name="options">
                        <Repeater
                            type="mcq"
                            component={Option}
                            times={this.props.st.qOptions.length}
                            selected={this.props.st.qAnswer.get("mcq")}
                            handleAnswerChange={this.props.handleAnswerChange}
                            control={<GreenCheckbox />}
                            optionText={this.props.st.qOptions}
                            handleOptionChange={this.props.handleOptionChange}
                            qIndex={this.props.num}
                        />
                    </FormGroup>
                );
            case "bool":
                return (
                    <RadioGroup
                        aria-label="boolen"
                        name="boolen"
                        value={this.props.st.qAnswer.get("bool")}
                        onChange={(e) =>
                            this.props.handleAnswerChange(e, this.props.num)
                        }
                    >
                        <Repeater
                            type="bool"
                            component={Option}
                            times={2}
                            control={<GreenRadio />}
                            handleOptionChange={this.props.handleOptionChange}
                            qIndex={this.props.num}
                        />
                    </RadioGroup>
                );
            case "text":
                return (
                    <FormGroup
                        aria-label="text"
                        name="text"
                        value={this.props.st.qAnswer.get("text")}
                        onChange={(e) =>
                            this.props.handleAnswerChange(e, this.props.num)
                        }
                    >
                        <FormControlLabel
                            control={
                                <TextField
                                    variant="outlined"
                                    aria-label="Subjective answer"
                                    fullWidth
                                    multiline
                                    disabled
                                    placeholder="Type answer here..."
                                    className={this.props.classes.textArea}
                                />
                            }
                        />
                    </FormGroup>
                );
            case "default":
                return null;
        }
    };

    onEditorStateChange = (editorState) => {
        this.setState(
            {
                editorState,
            },
            () => {
                this.props.handleQEditorChange(
                    draftToHtml(convertToRaw(editorState.getCurrentContent())),
                    this.props.num
                );
            }
        );
    };

    uploadImage = (file) => {
        console.log(file);
        let fileParts = file.name.split(".");
        let fileName = `${this.props.orgData.orgId}-${moment().unix()}`;
        let fileType = fileParts[1];
        return new Promise((resolve, reject) => {
            this.OrgServices.getPreSignedUrl({
                fileName,
                fileType,
                dir: "question-photos",
            })
                .then((response) => {
                    const url = response.fileUploadURL;
                    axios({
                        method: "PUT",
                        url: url,
                        data: file,
                        headers: { "Content-Type": "multipart/form-data" },
                    })
                        .then((res) => {
                            console.log("file uploaded successfully");
                            resolve({
                                data: {
                                    link: res.config.url.split("?")[0],
                                },
                            });
                        })
                        .catch((err) => {
                            reject();
                            console.log(
                                "Error Occured while uploading the file"
                            );
                        });
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <Card className="qcard">
                <div className="qcard__grid">
                    <div className="qcard__grid__number">
                        {`Q${this.props.num + 1}`}
                    </div>
                    <div className="qcard__grid__main">
                        <div className="q">
                            {/* <TextField
                            id="q"
                            name="q"
                            label="Question"
                            variant="outlined"
                            className={`qcard__outlined `}
                            value={this.props.st.q}
                            onChange={(e) =>
                                this.props.handleChange(e, this.props.num)
                            }
                            fullWidth
                            multiline
                        /> */}
                            <Editor
                                editorState={this.state.editorState}
                                wrapperClassName="draft-editor-wrapper"
                                editorClassName="editor-class"
                                toolbarClassName="draft-editor-toolbar"
                                // wrapperStyle={<wrapperStyleObject>}
                                stripPastedStyles={true}
                                onEditorStateChange={this.onEditorStateChange}
                                editorStyle={{
                                    borderRadius: 0,
                                    border: `1px solid ${colors.BODY2}`,
                                    padding: "0 8px",
                                    margin: 0,
                                }}
                                toolbarStyle={{
                                    marginBottom: 0,
                                    borderColor: colors.BODY2,
                                }}
                                toolbar={{
                                    options: ["inline", "image", "emoji"],
                                    inline: {
                                        inDropdown: false,
                                        options: [
                                            "bold",
                                            "italic",
                                            "underline",
                                            "strikethrough",
                                            "superscript",
                                            "subscript",
                                            "monospace",
                                        ],
                                        monospace: {
                                            className: "draft-code",
                                        },
                                    },
                                    // blockType: {
                                    //     inDropdown: false,
                                    //     options: ["code"],
                                    // },
                                    image: {
                                        className: "draft-image",
                                        component: undefined,
                                        popupClassName: undefined,
                                        urlEnabled: true,
                                        uploadEnabled: true,
                                        alignmentEnabled: true,

                                        uploadCallback: this.uploadImage,
                                        previewImage: true,
                                        inputAccept:
                                            "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                                        alt: {
                                            present: false,
                                            mandatory: false,
                                        },
                                        defaultSize: {
                                            height: "auto",
                                            width: "300",
                                        },
                                    },
                                }}
                                spellCheck
                                textAlignment="left"
                                placeholder="Type question here..."
                                stripPastedStyles
                                textArea={
                                    <TextField
                                        id="q"
                                        name="q"
                                        label="Question"
                                        variant="outlined"
                                        className={`textFieldWrapper removeMargin`}
                                        value={this.props.st.q}
                                        onChange={(e) =>
                                            this.props.handleChange(
                                                e,
                                                this.props.num
                                            )
                                        }
                                        fullWidth
                                        multiline
                                        rows={4}
                                    />
                                }
                            />
                        </div>
                        <div className="config">
                            {/* <div className="qcard__grid__type"> */}
                            <FormControl
                                variant="outlined"
                                className={`textFieldWrapper `}
                                //fullWidth
                            >
                                <InputLabel id="q_type">
                                    Question type
                                </InputLabel>
                                <Select
                                    labelId="q_type"
                                    name="qType"
                                    label="Question type"
                                    margin="dense"
                                    value={this.props.st.qType}
                                    onChange={(e) =>
                                        this.props.handleChange(
                                            e,
                                            this.props.num
                                        )
                                    }
                                >
                                    {QUESTION_TYPES.map((t) => (
                                        <MenuItem key={t.value} value={t.value}>
                                            {t.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {/* </div> */}
                            <FormControl
                                variant="outlined"
                                className={`textFieldWrapper `}
                                //fullWidth
                            >
                                {/* <InputLabel id="q_subject">
                                Related Subject
                            </InputLabel> */}
                                {/* <Select
                                labelId="q_subject"
                                name="qSubject"
                                label="Related Subject"
                                margin="dense"
                                value={this.props.st.qSubject}
                                onChange={(e) =>
                                    this.props.handleChange(e, this.props.num)
                                }
                            >
                                {QUESTION_SUBJECTS.map((t) => (
                                    <MenuItem key={t.value} value={t.value}>
                                        {t.label}
                                    </MenuItem>
                                ))}
                            </Select> */}
                                <SearchSelector
                                    id="qTags"
                                    name="qTags"
                                    freeSolo={true}
                                    freeTags
                                    multiple={true}
                                    data={QUESTION_TAGS}
                                    loading={
                                        QUESTION_TAGS &&
                                        QUESTION_TAGS.length > 0
                                            ? false
                                            : true
                                    }
                                    className="tagSelector"
                                    variant="outlined"
                                    size="small"
                                    onChange={this.props.handleTagChange}
                                    value={this.props.st.qTags}
                                    index={this.props.num}
                                    label="Tags"
                                />
                                {/* <FormHelperText className={classes.helptext}>
                                    Help search questions in bank.
                                </FormHelperText> */}
                            </FormControl>
                        </div>

                        <div className="qOptions">
                            {this.renderOptions(this.props.st.qType)}
                            {this.props.st.qType !== "bool" && (
                                <ButtonComp
                                    variant="outlined"
                                    style={{
                                        width: "84.5%",
                                        marginLeft: "2.1em",
                                    }}
                                    onClick={(e) =>
                                        this.props.handleOptionChange(
                                            e,
                                            this.props.num,
                                            null,
                                            "add"
                                        )
                                    }
                                >
                                    Add New Option
                                </ButtonComp>
                            )}

                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                                style={{ maginTop: 10 }}
                            >
                                * Select the correct answer. Which will be used
                                to calculate final result of the student.
                            </Typography>
                        </div>
                    </div>
                </div>
            </Card>
        );
    }
}

export default withStyles(useStyles)(QCard);
