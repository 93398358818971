import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Paper, Typography } from "@material-ui/core";
import moment from "moment";
import arraySort from "array-sort";
import MainServices from "../../Services/main.services";
import { toast } from "react-toastify";
import { colors } from "../../utilities/constants";
import CircularLoader from "../UIComponents/CircularLoader";
import EmptyComp from "../UIComponents/EmptyComp";
import ListPageControls from "../UIComponents/ListPageControls";
import { copyToClipBoard } from "../../utilities/helper";
import emptyEntity from "../../assets/emptyEntity.svg";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import GroupWorkOutlinedIcon from "@material-ui/icons/GroupWorkOutlined";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import MActionCard from "../UIComponents/MActionCard";
import ScheduleIcon from "@material-ui/icons/Schedule";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import ChipComp from "../../components/UIComponents/ChipComp";
import sessionArt from "../../assets/sessionArt.svg";

class SessionListing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTab: 0,
            sort: false,
            filter: "all",
        };
        this.MainServices = new MainServices();
    }

    componentDidMount() {
        // if (this.props.currentTab === 1 && !this.props.seriesData) {
        //     this.props.fetchSeriesArchives();
        // }
    }

    editSeries = (e, data) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        e.preventDefault();
        this.props.toggleDialogsWithType(e, "series", data, "edit");
    };

    deleteSeries = (e, series) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        e.preventDefault();
        this.props.confirmDialog(series, "delete");
    };

    changeSeriesStatus = (e, series) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        e.preventDefault();
        this.props.confirmDialog(series, "status");
    };

    handleStateChange = (e, key, value) => {
        this.setState({
            [key]: value,
        });
    };

    goToSessionDetails = (ev) => {
        this.props.history.push({
            pathname: `/session/${ev.eventId}`,
            data: ev,
        });
    };

    getDurationInHours = (ev) => {
        let duration = moment
            .unix(ev.endTime)
            .diff(moment.unix(ev.startTime), "hours", true);

        let result = duration - Math.floor(duration) !== 0;

        if (result) {
            return duration.toFixed(1);
        }
        return duration;
    };

    getLiveStatus = (ev) => {
        return (
            moment(ev.startTime).isBefore(moment().unix()) &&
            !moment(ev.endTime).isBefore(moment().unix())
        );
    };

    sortEvents = (data) => {
        return arraySort(data, "startTime");
    };

    goToLinkUrl = (ev) => {
        window.open(ev.url, "_blank", "location=yes");
    };

    render() {
        const { eventsData, isLoading, currentTab, orgData, groups } =
            this.props;
        const DATE_OPTIONS = {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        };
        let sortedEventsData, filteredEventsData;
        if (eventsData) {
            if (this.state.sort) {
                sortedEventsData = this.sortEvents(eventsData);
            } else {
                sortedEventsData = eventsData;
            }

            if (this.state.filter !== "all") {
                filteredEventsData = sortedEventsData.filter((ev) =>
                    this.getLiveStatus(ev)
                );
            } else {
                filteredEventsData = sortedEventsData;
            }
        }
        return (
            <>
                {filteredEventsData ? (
                    <>
                        <ListPageControls
                            showSort={this.props.showSort}
                            showSearch={this.props.showSearch}
                            handleChange={this.handleStateChange}
                            showFilterDropdown={this.props.showFilter}
                            menuItems={[
                                {
                                    value: "live",
                                    label: "Live Sessions",
                                },
                            ]}
                            filterValue={this.state.filter}
                            style={{ padding: "10px 0" }}
                        />
                        {filteredEventsData.length > 0 ? (
                            <>
                                <div className="cardList">
                                    {filteredEventsData.map((ev) => (
                                        <MActionCard
                                            key={ev.eventId}
                                            data={{
                                                media: (
                                                    <LiveTvIcon
                                                        style={{
                                                            fontSize: 50,
                                                        }}
                                                    />
                                                ),
                                                title: ev.title,
                                                description: ev.description,
                                            }}
                                            btnLabel="Join Now"
                                            btnDisabledStatus={
                                                this.getLiveStatus(ev)
                                                    ? false
                                                    : true
                                            }
                                            onClick={() => this.goToLinkUrl(ev)}
                                            noEllipsis={true}
                                            //editButton
                                        >
                                            <div className="cardDetailsRow">
                                                <PermIdentityIcon className="cardDetailsRow__icon" />
                                                Assigned to:
                                                <span>
                                                    {ev.host && ev.hostName
                                                        ? ev.hostName
                                                        : `${this.props.userData.name} ${this.props.userData.family_name}`}
                                                </span>
                                            </div>
                                            {this.props.groups && (
                                                <div className="cardDetailsRow">
                                                    <GroupWorkOutlinedIcon className="cardDetailsRow__icon" />
                                                    Access:
                                                    <span>
                                                        {ev.groups &&
                                                        ev.groups.length > 0 ? (
                                                            <>
                                                                {ev.groups
                                                                    .map(
                                                                        (g) =>
                                                                            groups.find(
                                                                                (
                                                                                    gp
                                                                                ) =>
                                                                                    gp.groupId ===
                                                                                    g
                                                                            )
                                                                                .title
                                                                    )
                                                                    .join(", ")}
                                                            </>
                                                        ) : (
                                                            "Open for all"
                                                        )}
                                                    </span>
                                                </div>
                                            )}

                                            <div className="cardDetailsRow">
                                                <ScheduleIcon className="cardDetailsRow__icon" />
                                                Start Time:
                                                <span>
                                                    {new Date(
                                                        ev.startTime * 1000
                                                    ).toLocaleDateString(
                                                        "en-US",
                                                        DATE_OPTIONS
                                                    )}
                                                </span>
                                            </div>

                                            <ChipComp
                                                variant="outlined"
                                                size="small"
                                                color={colors.PRIMARY}
                                                style={{
                                                    position: "absolute",
                                                    left: 5,
                                                    top: 5,
                                                }}
                                                label={`${this.getDurationInHours(
                                                    ev
                                                )} hours session`}
                                            />
                                            {this.getLiveStatus(ev) && (
                                                <div
                                                    //className="cardDetailsRow"
                                                    style={{
                                                        position: "absolute",
                                                        top: 5,
                                                        right: 5,
                                                        color: colors.DANGER,
                                                        display: " flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <span className="blinkingDot"></span>
                                                    Live
                                                </div>
                                            )}

                                            <div className="cardDetailsRow">
                                                <ScheduleIcon className="cardDetailsRow__icon" />
                                                End Time:
                                                <span>
                                                    {new Date(
                                                        ev.endTime * 1000
                                                    ).toLocaleDateString(
                                                        "en-US",
                                                        DATE_OPTIONS
                                                    )}
                                                </span>
                                            </div>
                                        </MActionCard>
                                    ))}
                                    {/* <MActionCard
                                        addAction
                                        cardClick
                                        onClick={this.toggleEventDialog}
                                        //className="new-session-background"
                                    >
                                        <AddCircleOutlineIcon fontSize="large" />
                                        <Typography
                                            gutterBottom
                                            variant="body2"
                                            component="p"
                                            className="mACard__main__title"
                                        >
                                            Create New Sessions
                                        </Typography>
                                    </MActionCard> */}
                                </div>
                            </>
                        ) : (
                            <EmptyComp
                                art={sessionArt}
                                message="No Live session found."
                            />
                        )}
                    </>
                ) : (
                    <CircularLoader wrapperClass="cloader" />
                )}
            </>
        );
    }
}

export default SessionListing;
