import React, { Component, forwardRef } from "react";
import { connect } from "react-redux";
import {
    Container,
    TextField,
    Typography,
    Dialog,
    Paper,
    Grow,
    Collapse,
    Slide,
    TableContainer,
    TableBody,
    Table,
    TableFooter,
    TableRow,
    TablePagination,
    IconButton,
    Chip,
} from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { colors } from "../../utilities/constants";
import arrayMove from "array-move";
import CircularLoader from "./CircularLoader";
import MaterialTable, { MTableToolbar } from "material-table";
import {
    STUDENT_TBL_COL,
    STUDENT_RESULTS_TBL_COL,
} from "../../utilities/material-table-contants";
import ResultListing from "../students/ResultListing";
import ButtonComp from "./ButtonComp";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import FolderMoveIcon from "../../assets/svgIcons/FolderMoveIcon";
import AccountRemoveIcon from "../../assets/svgIcons/AccountRemoveIcon";
import ConfirmDialog from "../Dialogs/ConfirmDialog";
import LinearLoader from "./LinearLoader";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Move: forwardRef((props, ref) => <FolderMoveIcon {...props} ref={ref} />),
    AccountRemoveIcon: forwardRef((props, ref) => (
        <AccountRemoveIcon {...props} ref={ref} />
    )),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => (
        <DeleteForeverIcon
            {...props}
            ref={ref}
            style={{ color: colors.DANGER }}
        />
    )),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = (theme) => ({
    seriesSelectBox: {
        minWidth: 300,
    },
    title: {
        margin: "10px auto 5px",
    },
    description: {
        margin: "20px auto 40px",
        textAlign: "center",
        flex: "0 0 100%",
    },
    table: {
        minWidth: 500,
    },
});

class MemberListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowsPerPage: 10,
            page: 0,
            confirmDialog: false,
            controlData: {},
        };
    }

    // componentDidMount() {
    //     if (!this.props.data) {
    //         this.props.fetchStudents(this.props.orgData.orgId);
    //     }
    // }

    toggleEdit = (e, index, data, type) => {
        e.stopPropagation();
        if (e.nativeEvent) {
            e.nativeEvent.stopImmediatePropagation();
        }

        if (data) {
            this.setState({
                editType: type ? type : "",
                controlIndex: index,
                controlData: data,
                openEditQDialog: !this.state.openEditQDialog,
            });
        } else {
            this.setState({
                openEditQDialog: !this.state.openEditQDialog,
            });
        }
    };

    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState(({ data }) => ({
            data: arrayMove(data, oldIndex, newIndex),
        }));
    };

    toggleConfirmDialog = (data) => {
        this.setState((prevProps) => {
            return {
                confirmDialog: !prevProps.confirmDialog,
                controlData: data ? data : {},
            };
        });
    };

    handleConfirm = () => {
        const { controlData } = this.state;
        this.toggleConfirmDialog();
        this.props.deleteMember({
            userId: controlData.userId,
            username: controlData.username,
            orgId: this.props.orgData.orgId,
            name: controlData.name,
        });
    };

    render() {
        const { classes, data, orgData } = this.props;
        let actions = [];
        let detailPanel = [];
        if (!this.props.noControls) {
            if (this.props.showMemberControls) {
                actions = [
                    ...actions,
                    {
                        icon: tableIcons.Move,
                        tooltip: "Move to Other Group",
                        onClick: (event, rowData) => {
                            event.preventDefault();
                            this.props.toggleManageMembersDialog(
                                event,
                                "move",
                                rowData
                            );
                        },
                    },
                    {
                        icon: tableIcons.AccountRemoveIcon,
                        tooltip: "Remove from Group",
                        onClick: (event, rowData) => {
                            event.preventDefault();
                            this.props.toggleManageMembersDialog(
                                event,
                                "remove",
                                rowData
                            );
                        },
                    },
                ];
            }
            //forever delete action
            if (orgData.rolePermissions.some((r) => r <= 1)) {
                actions.push({
                    icon: tableIcons.Delete,
                    tooltip: "Delete forever",
                    onClick: (event, rowData) => {
                        event.preventDefault();
                        // alert(rowData.userId);
                        this.toggleConfirmDialog(rowData);
                    },
                });
            }

            detailPanel = [
                {
                    tooltip: "Show Results",
                    render: (rowData) => {
                        return (
                            <ResultListing
                                isLoading={rowData.results ? false : true}
                                sName={rowData.name}
                                resultsData={rowData.results}
                                fetchStudentResult={() =>
                                    this.props.fetchStudentResults(
                                        rowData.userId
                                    )
                                }
                            />
                        );
                    },
                },
            ];
        } else if (this.props.staffMembers) {
            actions = [
                {
                    icon: tableIcons.Move,
                    tooltip: "Change Role & Permissions",
                    onClick: (event, rowData) => {
                        event.preventDefault();
                        this.props.changeRole(event, rowData);
                    },
                },
                {
                    icon: tableIcons.Delete,
                    tooltip: "Delete forever",
                    onClick: (event, rowData) => {
                        event.preventDefault();
                        // alert(rowData.userId);
                        this.toggleConfirmDialog(rowData);
                    },
                },
            ];
        }

        return (
            <>
                <div className="memberList">
                    {data ? (
                        <MaterialTable
                            icons={tableIcons}
                            columns={
                                this.props.columns
                                    ? this.props.columns
                                    : STUDENT_TBL_COL
                            }
                            data={data}
                            // onRowClick={(event, rowData, togglePanel) => {
                            //     if (this.props.type === "results") {
                            //         return false;
                            //     }
                            //     return togglePanel();
                            // }}
                            // title={
                            //     <Typography
                            //         variant="h1"
                            //         component="h1"
                            //         className="page-header"
                            //     >
                            //         {this.props.title
                            //             ? this.props.title
                            //             : "Student List"}
                            //     </Typography>
                            // }
                            title=""
                            localization={{
                                body: {
                                    editRow: {
                                        deleteText:
                                            "Are you sure you want to delete this student?",
                                    },
                                },
                            }}
                            options={{
                                //exportButton: true,
                                showTitle: this.props.noTitle ? false : true,
                                draggable: false,
                                actionsColumnIndex: -1,
                                sorting: true,
                                pageSize: this.state.rowsPerPage,
                                pageSizeOptions: [10, 20, 50],
                                search: this.props.noControls ? false : true,
                                toolbar: false,
                                // selection: true,
                            }}
                            // onSelectionChange={(rows) => {
                            //     if (rows.length > 1) {
                            //         rows.splice(1, rows.length);
                            //     }
                            //     return rows;
                            // }}
                            components={{
                                OverlayLoading: (props) => (
                                    <CircularLoader {...props} />
                                ),
                                Container: (props) => (
                                    <Paper
                                        {...props}
                                        elevation={
                                            this.props.noElevation ? 0 : 2
                                        }
                                    />
                                ),
                                // Toolbar: (props) => (
                                //     <div>
                                //         {!this.props.noControls && (
                                //             <>
                                //                 <MTableToolbar {...props} />
                                //                 {this.props
                                //                     .showMemberControls && (
                                //                     <div
                                //                         style={{
                                //                             padding: "0px 10px",
                                //                         }}
                                //                     >
                                //                         <Chip
                                //                             icon={
                                //                                 <PersonAddIcon fontSize="small" />
                                //                             }
                                //                             color="primary"
                                //                             label="Add New Members"
                                //                             onClick={(e) =>
                                //                                 this.props.toggleManageMembersDialog(
                                //                                     e,
                                //                                     "add"
                                //                                 )
                                //                             }
                                //                             // disabled={
                                //                             //     props.disableCondition
                                //                             // }
                                //                         ></Chip>
                                //                     </div>
                                //                 )}
                                //             </>
                                //         )}
                                //     </div>
                                // ),
                            }}
                            actions={actions}
                            // editable={{
                            //     //isEditable: (rowData) => rowData.name === "Name", // only name(a) rows would be editable
                            //     // isDeletable: (rowData) => rowData.name === "b", // only name(b) rows would be deletable
                            //     // onRowAdd: (newData) =>
                            //     //     new Promise((resolve, reject) => {
                            //     //         setTimeout(() => {
                            //     //             /* setData([...data, newData]); */

                            //     //             resolve();
                            //     //         }, 1000);
                            //     //     }),
                            //     // onRowUpdate: (newData, oldData) =>
                            //     //     new Promise((resolve, reject) => {
                            //     //         setTimeout(() => {
                            //     //             const dataUpdate = [...data];
                            //     //             const index = oldData.tableData.id;
                            //     //             dataUpdate[index] = newData;
                            //     //             setData([...dataUpdate]);

                            //     //             resolve();
                            //     //         }, 1000);
                            //     //     }),
                            //     onRowDelete: (oldData) =>
                            //         new Promise((resolve, reject) => {
                            //             // setTimeout(() => {
                            //             //     const dataDelete = [...data];
                            //             //     const index = oldData.tableData.id;
                            //             //     dataDelete.splice(index, 1);
                            //             //     setData([...dataDelete]);

                            //             //     resolve();
                            //             // }, 1000);

                            //             this.props.deleteStudent({
                            //                 userId: oldData.userId,
                            //                 username: oldData.username,
                            //             });
                            //             resolve();
                            //         }),
                            // }}
                            // detailPanel={detailPanel}
                        />
                    ) : (
                        <CircularLoader wrapperClass="cloader" />
                    )}
                </div>
                <ConfirmDialog
                    open={this.state.confirmDialog}
                    handleConfirm={this.handleConfirm}
                    handleClose={this.toggleConfirmDialog}
                    type="delete"
                    message={`Are your sure, you want to permanently delete ${
                        this.state.controlData.name
                    }'s account from your portal. ${
                        !this.props.staffMembers
                            ? "Please note that the results data will not be deleted."
                            : ""
                    }`}
                />
            </>
        );
    }
}

export default withStyles(useStyles)(MemberListing);
