import React from "react";
import { TextField, FormControlLabel, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import TooltipComp from "./TooltipComp";

const useStyles = (theme) => ({});

const Option = (props) => {
    let childProps = {
        value: `${props.num}`,
        control: props.control,
    };
    let label;
    let charCode = String.fromCharCode(65 + props.num);
    if (props.type === "scq") {
        // add props specific to scq
        label = (
            <TextField
                variant="outlined"
                placeholder={`Option ${charCode}`}
                value={props.optionText[props.num]}
                classes={{
                    root: "optionRoot",
                }}
                multiline
                onChange={(e) =>
                    props.handleOptionChange(e, props.qIndex, props.num)
                }
                classes={{
                    root: "textFieldWrapper",
                }}
                inputProps={{
                    className: "optionField",
                }}
            />
        );
    } else if (props.type === "mcq") {
        // add props specific to mcq
        label = (
            <TextField
                variant="outlined"
                placeholder={`Option ${charCode}`}
                value={props.optionText[props.num]}
                multiline
                onChange={(e) =>
                    props.handleOptionChange(e, props.qIndex, props.num)
                }
                classes={{
                    root: "textFieldWrapper",
                }}
                inputProps={{
                    className: "optionField",
                }}
            />
        );
        childProps.checked = props.selected.includes(`${props.num}`);
        childProps.onChange = (e) => props.handleAnswerChange(e, props.qIndex);
    } else if (props.type === "bool") {
        // add props specific to boolean
        label = props.num === 0 ? "true" : "false";
        childProps.value = props.num === 0 ? "true" : "false";
    }
    return (
        <div
            className="optionWrapper"
            style={{ display: "flex", width: "100%" }}
        >
            <FormControlLabel
                {...childProps}
                label={label}
                classes={{
                    label: "textFieldLabel",
                }}
            />
            {props.type !== "bool" && (
                <TooltipComp
                    title={`Delete option ${charCode}`}
                    placement="top"
                >
                    <IconButton
                        aria-label={`Delete option ${charCode}`}
                        onClick={(e) =>
                            props.handleOptionChange(
                                e,
                                props.qIndex,
                                props.num,
                                "delete"
                            )
                        }
                    >
                        <DeleteIcon />
                    </IconButton>
                </TooltipComp>
            )}
        </div>
    );
};

export default withStyles(useStyles)(Option);
