import React, { Component } from "react";
import { lighten, withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { colors } from "../../Utilities/Constants";

/*
 * * @props
 * * label: string
 * * variant: string (values: contained/outlined)
 * * classes:
 * * onClick: function
 * * startIcon: component
 * * endIcon: component
 * * fullWidth: bool
 */

const useStyles = (props) => ({
    root: {
        background: (props) =>
            props.bckColor ? lighten(props.bckColor, 0.1) : "inherit",
        color: (props) => (props.color ? props.color : "inherit"),
        "&:hover": {
            background: (props) =>
                props.bckColor ? props.bckColor : "inherit",
        },
    },
});

const ButtonComp = React.forwardRef((props, ref) => {
    const {
        color,
        bckColor,
        textColor,
        indicator,
        selectionFollowsFocus,
        className,
        ...rest
    } = props;
    return (
        <Button
            ref={ref}
            className={`${props.classes.root} ${className ? className : ""}`}
            {...rest}
        >
            {props.children}
        </Button>
    );
});

export default withStyles(useStyles)(ButtonComp);
