import HttpClient from "./HttpClient";

class GroupServices {
    constructor() {
        this.HttpClient = new HttpClient();
    }

    addGroup(params = {}) {
        return this.HttpClient.post("manageOrg", "/group", params);
    }

    deleteGroup(params = {}) {
        const { groupId, orgId } = params;
        let path;
        if (groupId && orgId) {
            path = `/group?groupId=${groupId}&orgId=${orgId}`;
        } else {
            return Promise.reject(new Error("Invalid Parameters passed!"));
        }
        return this.HttpClient.delete("manageOrg", path, {});
    }

    fetchGroups(params = {}) {
        const { orgId } = params;
        let path;
        if (orgId) {
            path = `/groups?orgId=${orgId}`;
        } else {
            return Promise.reject(new Error("Invalid Parameters passed!"));
        }
        return this.HttpClient.get("manageOrg", path, params);
    }

    fetchGroup(params = {}) {
        const { orgId, groupId } = params;
        let path;
        if (orgId && groupId) {
            path = `/group?groupId=${groupId}&orgId=${orgId}`;
        } else {
            return Promise.reject(new Error("Invalid Parameters passed!"));
        }
        return this.HttpClient.get("manageOrg", path, params);
    }

    getGroupStudents(params = {}) {
        const { groupId, orgId } = params;
        let path;
        if (groupId && orgId) {
            path = `/groupStudents?groupId=${groupId}&orgId=${orgId}`;
        } else {
            return Promise.reject(new Error("Invalid Parameters passed!"));
        }
        return this.HttpClient.get("manageOrg", path, nParams);
    }

    updateGroup(params = {}) {
        const { type, action, ...newParams } = params;
        let path;
        if (type) {
            path = `/group?type=${type}`;
            if (action) {
                path += `&action=${action}`;
            }
        } else {
            return Promise.reject(new Error("Invalid Parameters passed!"));
        }
        return this.HttpClient.put("manageOrg", path, newParams);
    }

    updateGroupMembers(params = {}) {
        const { type, ...newParams } = params;
        let path;
        if (type) {
            path = `/members?type=${type}`;
        } else {
            return Promise.reject(new Error("Invalid Parameters passed!"));
        }
        return this.HttpClient.put("manageOrg", path, newParams);
    }
}

export default GroupServices;
