import HttpClient from "./HttpClient";

class InsightsServices {
    constructor() {
        this.HttpClient = new HttpClient();
    }

    getInsight(params = {}) {
        const { id, type } = params;
        let path;
        if (id && type) {
            path = `/insight?id=${id}&type=${type}`;
        } else {
            return Promise.reject(new Error("Invalid Parameters passed!"));
        }
        return this.HttpClient.get("manageOrg", path, params);
    }
}

export default InsightsServices;
