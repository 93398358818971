import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import queryString from "query-string";
import {
    Paper,
    Container,
    Typography,
    IconButton,
    TextField,
} from "@material-ui/core";
import {
    isLoading,
    fetchGroups,
    addEvent,
    updateEvent,
    fetchEvents,
    fetchStaffMembers,
} from "../../store/action";
import ConfirmDialog from "../../components/Dialogs/ConfirmDialog";
import TooltipComp from "../../components/UIComponents/TooltipComp";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ButtonComp from "../../components/UIComponents/ButtonComp";

import OrgServices from "../../services/org.services";

import CreateEvent from "../../components/Dialogs/CreateEvent";

import TabsComp from "../../components/UIComponents/TabsComp";
import SessionListing from "../../components/Sessions/SessionListing";

class SessionDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            term: "",
            currentTab: 0,
        };
        this.tabs = [
            {
                label: "Live & Upcoming",
            },
            // {
            //     label: "Past Sessions",
            // },
        ];
        this.OrgServices = new OrgServices();
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        let queryObj = queryString.parse(props.location.search);
        if (queryObj.tab) {
            newState.currentTab = parseInt(queryObj.tab);
        }
        return {
            ...state,
            ...newState,
        };
    }

    handleTabChange = (e, newValue) => {
        e.preventDefault();
        this.props.history.push(
            `${this.props.history.location.pathname}?tab=${newValue}`
        );
    };

    componentDidMount() {
        if (!this.props.groups) {
            this.props.fetchGroups(this.props.orgData.orgId);
        }
        if (!this.props.eventsData) {
            this.props.fetchEvents(
                this.props.orgData.orgId,
                this.props.keys.events
            );
        }
    }

    onChange = (e, value, type) => {
        if (type) {
            this.setState({
                [type]: value.trim(),
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value,
            });
        }
    };

    renderMainComponent = () => {
        const { currentTab } = this.state;

        switch (currentTab) {
            case 0:
                return (
                    <SessionListing
                        eventsData={this.props.eventsData}
                        confirmDialog={this.toggleConfirmDialog}
                        groups={this.props.groups}
                        orgData={this.props.orgData}
                        userData={this.props.userData}
                        // changeExamStatus={this.changeExamStatus}
                        currentTab={this.state.currentTab}
                        isLoading={this.props.isLoading}
                        showFilter
                        //showSort
                        loadingMore={this.state.loadingMoreData}
                        history={this.props.history}
                    />
                );
            case 1:
                // if (!this.props.examArchives) {
                //     this.props.fetchExamArchives(this.props.orgData.orgId);
                // }
                return (
                    <SessionListing
                        eventsData={this.props.eventsData}
                        confirmDialog={this.toggleConfirmDialog}
                        groups={this.props.groups}
                        orgData={this.props.orgData}
                        userData={this.props.userData}
                        // changeExamStatus={this.changeExamStatus}
                        currentTab={this.state.currentTab}
                        isLoading={this.props.isLoading}
                        showFilter
                        //showSort
                        loadingMore={this.state.loadingMoreData}
                        history={this.props.history}
                    />
                );
        }
    };

    render() {
        const { orgData, groups } = this.props;

        return (
            <>
                <div className="members-dashboard">
                    <div className="page-title">
                        <Typography
                            variant="h1"
                            component="h1"
                            className="page-header"
                        >
                            Live Sessions
                        </Typography>
                    </div>
                    <TabsComp
                        handleChange={this.handleTabChange}
                        value={this.state.currentTab}
                        tabs={this.tabs}
                    >
                        {(this.state.currentTab === 0 ||
                            this.state.currentTab === 1) && [
                            <ButtonComp
                                key="cnee-1"
                                variant="outlined"
                                color="#000000"
                                onClick={() => null}
                                startIcon={<AddCircleIcon />}
                                onClick={(e) =>
                                    this.props.toggleDialogsWithType(
                                        e,
                                        "events",
                                        null
                                    )
                                }
                                className="page-create-btn"
                                style={{
                                    margin: "5px 0 0",
                                    marginLeft: "auto",
                                }}
                            >
                                Create New Session
                            </ButtonComp>,
                            <TooltipComp
                                key="cnee-2"
                                placement="top"
                                title="Create New Session"
                            >
                                <IconButton
                                    aria-label="Create New Session"
                                    className="mobile-only-page-create-btn"
                                    style={{
                                        margin: "0",
                                        marginLeft: "auto",
                                    }}
                                    onClick={(e) =>
                                        this.props.toggleDialogsWithType(
                                            e,
                                            "events",
                                            null
                                        )
                                    }
                                >
                                    <AddCircleIcon
                                        fontSize="inherit"
                                        // style={{ color: colors.DANGER }}
                                    />
                                </IconButton>
                            </TooltipComp>,
                        ]}
                    </TabsComp>

                    <Paper elevation={0} className="listPaper">
                        {this.renderMainComponent()}
                    </Paper>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        orgData: state.appState.orgData,
        userData: state.appState.userData,
        isLoading: state.appState.isLoading,
        groups: state.groupsState.groups,
        eventsData: state.appState.eventsData,
        staffMembersData: state.appState.staffMembersData,
        message: state.appState.message,
        keys: state.appState.keys,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchGroups: (orgId) => (
            dispatch(fetchGroups(orgId)), dispatch(isLoading(true))
        ),
        fetchEvents: (orgId, key) => (
            dispatch(fetchEvents(orgId, key)), dispatch(isLoading(true))
        ),
        addEvent: (params) => dispatch(addEvent(params)),
        updateEvent: (params) => dispatch(updateEvent(params)),
        fetchStaffMembers: (orgId) => dispatch(fetchStaffMembers(orgId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionDashboard);
