import React from "react";
import { hydrate, render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import App from "./App";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import "./sass/main.scss";
import { ConnectedRouter } from "connected-react-router";
import { history } from "./store/rootReducers";
import { colors } from "./utilities/constants";
import TagManager from "react-gtm-module";

// import registerServiceWorker from "react-service-worker";
import registerServiceWorker from "./registerServiceWorker";

const tagManagerArgs = {
    gtmId: "GTM-KWH8VG4",
};

if (process.env.REACT_APP_ENV === "PROD") {
    TagManager.initialize(tagManagerArgs);
}

const store = configureStore();

const theme = createMuiTheme({
    palette: {
        primary: {
            main: colors.PRIMARY,
        },
        secondary: {
            main: colors.DANGER,
            contrastText: "#ffffff",
        },
    },
});

// const appSW = registerServiceWorker();

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </ConnectedRouter>
        </Provider>,
        rootElement
    );
} else {
    render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </ConnectedRouter>
        </Provider>,
        rootElement
    );
}

registerServiceWorker(); // Runs register() as default function
