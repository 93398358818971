import React, { useState } from "react";
import { Link } from "react-router-dom";
import whiteLogo from "../assets/white-logo-1x.png";
import { isMobile } from "react-device-detect";
import {
    Drawer,
    Toolbar,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    SvgIcon,
    Typography,
    ClickAwayListener,
    Backdrop,
    SwipeableDrawer,
    Collapse,
} from "@material-ui/core/";
import { fade, makeStyles, withStyles } from "@material-ui/core/styles";
import {
    DRAWER_WIDTH,
    DRAWER_WIDTH_MOBILE,
    SIDEBAR_MENU,
    APP_VERSION,
    colors,
} from "../utilities/constants";
import DashboardIcon from "@material-ui/icons/Dashboard";
import TimelineIcon from "@material-ui/icons/Timeline";
import PeopleIcon from "@material-ui/icons/People";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PuchaseButtonIcon from "../assets/svgIcons/PuchaseButtonIcon";
import ButtonComp from "./UIComponents/ButtonComp";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = (theme) => ({
    drawer: {
        width: isMobile ? DRAWER_WIDTH_MOBILE : DRAWER_WIDTH,
        flexShrink: 0,
        zIndex: 998,
    },
    backdrop: {
        zIndex: 997,
        backgroundColor: colors.BODY1,
    },
    drawerPaper: {
        width: isMobile ? DRAWER_WIDTH_MOBILE : DRAWER_WIDTH,
        background: "#718093",
        color: "#ffffff",
    },
    drawerContainer: {
        overflow: "auto",
    },

    appDetails: {
        width: "100%",
        margin: "0 auto",
        position: "absolute",
        bottom: 5,
        textAlign: "center",
        fontSize: "0.6em",
    },
    lighterText: {
        opacity: "0.4",
    },
    gradout: {
        maxWidth: "100px",
    },
    nested: {
        paddingLeft: theme.spacing(4),
        fontSize: "0.7em",
    },
});

const Sidebar = (props) => {
    const [openSubMenu, setOpenSubMenu] = useState({
        central: false,
        classroom: false,
    });
    const { classes } = props;
    const SIDEBAR_MAIN_MENU_FILTERED = SIDEBAR_MENU.main.filter((menu) => {
        if (props.orgData.config.groupsEnabled) {
            // Hide student's Menu Item
            // if (menu.id === 5) {
            //     return false;
            // }
            return true;
        } else {
            // Hide Groups Menu Item
            if (menu.id === 4) {
                return false;
            }
            return true;
        }
    });
    const handleItemClick = (item) => {
        if (item.subMenu) {
            setOpenSubMenu({
                ...openSubMenu,
                [item.id]: !openSubMenu[item.id],
            });
        }
        if (item.path) {
            props.history.push(`${item.path}`);
            if (!window.matchMedia("(min-width: 768px)").matches) {
                props.toggleSidebar(true);
            }
        }
    };
    return (
        <div className="sidebar">
            {/* <Backdrop
                className={classes.backdrop}
                open={props.open}
                onClick={() => props.toggleSidebar(true)}
                invisible={true}
            > */}
            {/* <ClickAwayListener
                onClickAway={() => props.toggleSidebar(true)}
                mouseEvent={false}
            > */}
            <SwipeableDrawer
                className={classes.drawer}
                variant={isMobile ? "temporary" : "persistent"}
                // onEscapeKeyDown={() => props.toggleSidebar(true)}
                // onBackdropClick={() => props.toggleSidebar(true)}
                open={props.open}
                onClose={() => props.toggleSidebar(true)}
                onOpen={() => props.toggleSidebar()}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                {!isMobile ? (
                    <Toolbar />
                ) : (
                    <Toolbar style={{ borderBottom: "2px solid" }}>
                        <AccountCircleIcon style={{ marginRight: 10 }} />
                        {`Hello, ${props.userData.name}`}
                    </Toolbar>
                )}

                <div className={classes.drawerContainer}>
                    <List
                        component="nav"
                        aria-labelledby="Sidebar Navigation"
                        className="sidebarMenu"
                    >
                        {SIDEBAR_MAIN_MENU_FILTERED.map((item, index) => (
                            <React.Fragment key={item.id}>
                                <ListItem
                                    button
                                    className="list-item"
                                    onClick={() => handleItemClick(item)}
                                >
                                    <ListItemText
                                        primary={item.name}
                                        classes={{
                                            primary: "sidebar-text",
                                        }}
                                    />
                                    {item.subMenu && (
                                        <>
                                            {openSubMenu[item.id] ? (
                                                <ExpandLess />
                                            ) : (
                                                <ExpandMore />
                                            )}
                                        </>
                                    )}
                                </ListItem>
                                {item.subMenu && (
                                    <Collapse
                                        in={openSubMenu[item.id]}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <List
                                            component="div"
                                            disablePadding
                                            className="subMenu"
                                        >
                                            {item.subMenu &&
                                                item.subMenu.map((subItem) => (
                                                    <ListItem
                                                        button
                                                        className={
                                                            classes.nested
                                                        }
                                                        key={subItem.id}
                                                        onClick={() =>
                                                            handleItemClick(
                                                                subItem
                                                            )
                                                        }
                                                    >
                                                        <ListItemIcon className="subMenu__icon">
                                                            {subItem.icon}
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                subItem.name
                                                            }
                                                            // classes={{
                                                            //     primary:
                                                            //         "sidebar-text",
                                                            // }}
                                                        />
                                                    </ListItem>
                                                ))}
                                        </List>
                                    </Collapse>
                                )}
                            </React.Fragment>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {SIDEBAR_MENU.others.map((item, index) => (
                            <React.Fragment key={item.id}>
                                <ListItem
                                    button
                                    className="list-item"
                                    onClick={() => handleItemClick(item)}
                                >
                                    <ListItemText
                                        primary={item.name}
                                        classes={{
                                            primary: "sidebar-text",
                                        }}
                                    />
                                    {item.subMenu && (
                                        <>
                                            {openSubMenu[item.id] ? (
                                                <ExpandLess />
                                            ) : (
                                                <ExpandMore />
                                            )}
                                        </>
                                    )}
                                </ListItem>
                                {item.subMenu && (
                                    <Collapse
                                        in={openSubMenu[item.id]}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <List component="div" disablePadding>
                                            {item.subMenu &&
                                                item.subMenu.map((subItem) => (
                                                    <ListItem
                                                        button
                                                        className={
                                                            classes.nested
                                                        }
                                                        key={subItem.id}
                                                        onClick={() =>
                                                            handleItemClick(
                                                                subItem
                                                            )
                                                        }
                                                    >
                                                        {/* <ListItemIcon>
                                                     <StarBorder />
                                                 </ListItemIcon> */}
                                                        <ListItemText
                                                            primary={
                                                                subItem.name
                                                            }
                                                            // classes={{
                                                            //     primary:
                                                            //         "sidebar-text",
                                                            // }}
                                                        />
                                                    </ListItem>
                                                ))}
                                        </List>
                                    </Collapse>
                                )}
                            </React.Fragment>
                        ))}
                    </List>
                    <div className={`app-details ${classes.appDetails}`}>
                        {/* {props.orgData.license === "free" && (
                            <ButtonComp
                                bckColor={colors.PRIMARY}
                                color="#ffffff"
                                startIcon={<PuchaseButtonIcon />}
                                fullWidth
                                size="small"
                                onClick={() =>
                                    handleItemClick(props, "/upgrade")
                                }
                                style={{
                                    width: "90%",
                                    opacity: 1,
                                    marginBottom: 10,
                                    textTransform: "none",
                                    // margin: "5px 5px 10px",
                                }}
                            >
                                Upgrade Now
                            </ButtonComp>
                        )} */}

                        <div className={`${classes.lighterText}`}>
                            <img
                                src={whiteLogo}
                                alt="gradout logo"
                                className={classes.gradout}
                            />
                            <Typography variant="body2" component="p">
                                {`${APP_VERSION}`}
                            </Typography>
                        </div>
                    </div>
                </div>
            </SwipeableDrawer>
            {/* </ClickAwayListener> */}
            {/* </Backdrop> */}
        </div>
    );
};

export default withStyles(useStyles)(Sidebar);
