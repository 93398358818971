import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import emptyArt from "../../assets/emptyArt.svg";

const useStyles = (props) => ({
    text: {
        fontSize: "1.1em",
    },
    desc: {
        marginTop: 10,
        fontSize: "1em",
        opacity: 0.6,
    },
});

const EmptyComp = (props) => {
    const { classes } = props;
    return (
        <div className="empty fadeInUp">
            {(props.showEmptyArt || props.art) && (
                <img
                    style={{ maxWidth: 250, margin: "0 auto 10px" }}
                    src={props.showEmptyArt ? emptyArt : props.art}
                    alt="No Data"
                />
            )}
            {props.icon && <div style={{ fontSize: 50 }}>{props.icon}</div>}
            <Typography
                variant="body1"
                color="textSecondary"
                component="p"
                className={classes.text}
            >
                {props.message ? props.message : "Coming Soon!"}
            </Typography>
            {props.description && (
                <Typography
                    variant="body1"
                    color="textSecondary"
                    component="p"
                    className={classes.desc}
                >
                    {props.description}
                </Typography>
            )}
        </div>
    );
};

export default withStyles(useStyles)(EmptyComp);
