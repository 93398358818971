import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import { colors } from "../../utilities/constants";

const ChipComp = withStyles({
    root: {
        color: (props) => (props.color ? props.color : "inherit"),
        borderColor: (props) => (props.color ? props.color : "inherit"),
    },
})(({ color, ...rest }) => <Chip color="default" {...rest} />);

export default ChipComp;
