import React from "react";
import {
    withStyles,
    createMuiTheme,
    ThemeProvider,
} from "@material-ui/core/styles";
import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    CardActionArea,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import CircularLoader from "./CircularLoader";

const useStyles = (theme) => ({
    num: {
        fontSize: 40,
        fontWeight: 300,
        display: "block",
        marginTop: 5,
        marginBottom: 5,
    },
    media: {
        height: 80,
    },
});

const theme = createMuiTheme({
    Typography: {
        fontFamily: "inherit !important",
    },
});

const DashboardCard = (props) => {
    const { classes, data } = props;
    return (
        <ThemeProvider theme={theme}>
            <Card
                className={`dCard fadeInUp ${props.wrapperClass}`}
                onClick={(e) => props.onClick(e, data.type)}
            >
                {props.title}
                <span className={classes.num}>
                    {props.data ? props.data : <CircularLoader size={30} />}
                </span>
            </Card>
        </ThemeProvider>
    );
};

export default withStyles(useStyles)(DashboardCard);
