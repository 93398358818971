import React from "react";
import {
    withStyles,
    createMuiTheme,
    ThemeProvider,
} from "@material-ui/core/styles";
import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    CardActionArea,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";

const useStyles = (theme) => ({
    root: {
        width: 150,
    },
    media: {
        height: 80,
    },
});

const theme = createMuiTheme({
    Typography: {
        fontFamily: "inherit !important",
    },
});

const ListItemCard = (props) => {
    const { classes, data } = props;
    return (
        <ThemeProvider theme={theme}>
            <Card
                className={`${classes.root} ${props.wrapperClass}`}
                onClick={(e) =>
                    props.onClick(e, data.type, null, "add", "topMenu")
                }
            >
                <CardActionArea>
                    {/* {props.showCover &&
                        window.matchMedia("(min-width: 500px)").matches && (
                            <CardMedia
                                className={classes.media}
                                image={data.image}
                            />
                        )} */}
                    {props.showCover && (
                        <CardMedia
                            className={classes.media}
                            image={data.image}
                        />
                    )}
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="h2">
                            {data.title}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                        >
                            {data.description}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </ThemeProvider>
    );
};

export default withStyles(useStyles)(ListItemCard);
