import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import queryString from "query-string";
import {
    Paper,
    Container,
    Typography,
    IconButton,
    TextField,
} from "@material-ui/core";
import {
    isLoading,
    fetchGroups,
    addEvent,
    updateEvent,
    fetchEvents,
} from "../../store/action";
import ConfirmDialog from "../../components/Dialogs/ConfirmDialog";
import TooltipComp from "../../components/UIComponents/TooltipComp";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import MActionCard from "../../components/UIComponents/MActionCard";
import ButtonComp from "../../components/UIComponents/ButtonComp";
import CircularLoader from "../../components/UIComponents/CircularLoader";
import EmptyComp from "../../components/UIComponents/EmptyComp";
import GroupArt from "../../assets/groupArt.svg";
import RegisterMember from "../../components/Dialogs/RegisterMember";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import EventIcon from "@material-ui/icons/Event";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import SearchSelector from "../../components/UIComponents/SearchSelector";
import OrgServices from "../../services/org.services";
import { debounce } from "../../utilities/helper";
import CreateEvent from "../../components/Dialogs/CreateEvent";

class EventsDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEventDialogOpen: false,
            term: "",
        };
        this.OrgServices = new OrgServices();
    }

    static getDerivedStateFromProps(props, state) {
        return null;
    }

    componentDidMount() {
        if (!this.props.eventsData) {
            this.props.fetchEvents(
                this.props.orgData.orgId,
                this.props.keys.events
            );
        }
    }

    onChange = (e, value, type) => {
        if (type) {
            this.setState({
                [type]: value.trim(),
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value,
            });
        }
    };

    toggleEventDialog = () => {
        if (!this.props.groups) {
            this.props.fetchGroups(this.props.orgData.orgId);
        }
        this.setState((prevState) => {
            return {
                isEventDialogOpen: !prevState.isEventDialogOpen,
            };
        });
    };

 

    render() {
        const { orgData, groups } = this.props;
        return (
            <>
                <div className="members-dashboard">
                    <div className="page-title">
                        <Typography
                            variant="h1"
                            component="h1"
                            className="page-header"
                        >
                            Events
                        </Typography>
                        <ButtonComp
                            variant="outlined"
                            color="#000000"
                            onClick={() => null}
                            startIcon={<AddCircleIcon />}
                            onClick={(e) => this.toggleEventDialog(e)}
                            className="page-create-btn"
                            style={{
                                margin: "5px 0 0",
                                marginLeft: "auto",
                            }}
                        >
                            Create New Event
                        </ButtonComp>
                        <TooltipComp placement="top" title="Register New Event">
                            <IconButton
                                aria-label="Register New Event"
                                className="mobile-only-page-create-btn"
                                style={{
                                    margin: "0",
                                    marginLeft: "auto",
                                }}
                                onClick={(e) => this.toggleEventDialog(e)}
                            >
                                <AddCircleIcon
                                    fontSize="inherit"
                                    // style={{ color: colors.DANGER }}
                                />
                            </IconButton>
                        </TooltipComp>
                    </div>
                    <Paper elevation={0} className={`cardList`}>
                        <MActionCard
                            data={{
                                media: <LiveTvIcon style={{ fontSize: 50 }} />,
                                title: "Schedule Live Session ",
                                description:
                                    "New admision or manual registration of the student.",
                            }}
                            btnLabel="Create Live session"
                            onClick={this.toggleEventDialog}
                            noEllipsis={true}
                        />
                        <MActionCard
                            data={{
                                media: <EventIcon style={{ fontSize: 50 }} />,
                                title: "New Events",
                                description:
                                    "New admision or manual registration of the student.",
                            }}
                            btnLabel="Create an Event"
                            onClick={this.toggleEventDialog}
                            noEllipsis={true}
                        />
                    </Paper>
                </div>

                <CreateEvent
                    open={this.state.isEventDialogOpen}
                    handleClose={this.toggleEventDialog}
                    groupsData={this.props.groups}
                    addEvent={this.props.addEvent}
                    updateEvent={this.props.updateEvent}
                    orgData={this.props.orgData}
                    isLoading={this.props.isLoading}
                    message={this.props.message}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        orgData: state.appState.orgData,
        isLoading: state.appState.isLoading,
        groups: state.groupsState.groups,
        message: state.appState.message,
        keys: state.appState.keys,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchGroups: (orgId) => (
            dispatch(fetchGroups(orgId)), dispatch(isLoading(true))
        ),
        fetchEvents: (orgId, key) => (
            dispatch(fetchEvents(orgId, key)), dispatch(isLoading(true))
        ),
        addEvent: (params) => dispatch(addEvent(params)),
        updateEvent: (params) => dispatch(updateEvent(params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsDashboard);
