import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { colors } from "../../utilities/Constants";

const StyledLinearProgress = makeStyles({
    root: {
        width: (props) =>
            props.size ? `${props.size}px !important` : "40px !important",
        height: (props) =>
            props.size ? `${props.size}px !important` : "40px !important",
        color: (props) => (props.color ? props.color : colors.PRIMARY),
        position: "relative",
        // left: "calc(50% - 25px)",
    },
    circle: {
        strokeWidth: 4,
    },
});

const CircularLoader = (props) => {
    const { color, size, className, wrapperClass, message, ...rest } = props;
    let styleClasses = StyledLinearProgress(props);
    //const [waiting, setWaiting] = useState("");
    // setTimeout(() => {
    //     setWaiting(<div className="loadingText">still loading...</div>);
    // }, 3500);
    return (
        <div className={`${wrapperClass ? wrapperClass : ""}`}>
            <CircularProgress
                variant="indeterminate"
                thickness={props.thickness ? props.thickness : 6}
                classes={{
                    root: `${styleClasses.root} ${className}`,
                    circle: styleClasses.circle,
                }}
                {...rest}
            />
            {/* {waiting} */}
            {message && <div className="loadingText">{message}</div>}
        </div>
    );
};

export default CircularLoader;
