import React, { Component } from "react";
import { toast } from "react-toastify";
import { lighten, withStyles } from "@material-ui/core/styles";
import {
    Paper,
    Dialog,
    DialogTitle,
    MenuItem,
    InputAdornment,
    TextField,
    Divider,
    ClickAwayListener,
    IconButton,
    Drawer,
    Grid,
    Avatar,
    Chip,
    Slide,
    FormControl,
    FormLabel,
    RadioGroup,
    FormHelperText,
    Input,
    InputLabel,
    FormGroup,
    FormControlLabel,
    endAdornment,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
    DateTimePicker,
} from "@material-ui/pickers";
import SearchSelector from "../UIComponents/SearchSelector";
import { colors, TAGS } from "../../utilities/constants";
import { v5 as uuidv5 } from "uuid";
import password from "secure-random-password";
import ButtonComp from "../UIComponents/ButtonComp";
import GreenCheckbox from "../UIComponents/GreenCheckbox";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import CloseIcon from "@material-ui/icons/Close";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import GreenRadio from "../UIComponents/GreenRadio";
import MainServices from "../../services/main.services";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import TooltipComp from "../UIComponents/TooltipComp";
import { copyToClipBoard } from "../../utilities/helper";
import ChipComp from "../UIComponents/ChipComp";
import CircularLoader from "../UIComponents/CircularLoader";

const MY_NAMESPACE = "8492526a-ae71-5a00-ad9d-cd40cf6f9bfa";

const useStyles = (theme) => ({
    drawer: {
        padding: "30px",
        maxWidth: 500,
    },
    field: {
        margin: "8px 0px",
        color: colors.PRIMARY,
    },
    rightMargin: {
        marginRight: 20,
    },
    buttonWrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -20,
    },
});

const initialState = {
    title: "",
    description: "",
    groups: [],
    // host: null,
    url: "",
    startTime: new Date(),
    endTime: new Date(),
    errors: {
        titleError: "",
        endTime: "",
    },
    loading: false,
    fetchingStaff: false,
    selectorPopup: {
        startTime: false,
        startTime: false,
        endTime: false,
        endTime: false,
    },
};

class CreateEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            host: { ...props.userData, userId: props.userData.sub },
        };
        this.MainServices = new MainServices();
        this.eventType =
            props.action == "edit"
                ? props.data.type
                : props.eventType
                ? props.eventType
                : "event";
    }

    static getDerivedStateFromProps(props, state) {
        if (!props.open) {
            return initialState;
        }
        let newState = {};
        if (props.data && props.action === "edit") {
            if (!state.title) {
                const { title, description, url, startTime, endTime, groups } =
                    props.data;
                newState = {
                    title,
                    description,
                    url: url ? url : "",
                    startTime: new Date(startTime * 1000),
                    endTime: new Date(endTime * 1000),
                    groups: groups ? groups : [],
                };
            }
        }
        return {
            ...state,
            ...newState,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.open) {
            if (
                !prevProps.message.type &&
                this.props.message.type == "SUCCESS"
            ) {
                this.props.handleClose();
            } else if (
                !prevProps.message.type &&
                this.props.message.type == "ERROR"
            ) {
                this.setState({
                    loading: false,
                });
            }
            // End time shoulln't be before start time
            // if (
            //     !this.state.errors.endTime &&
            //     new Date(this.state.endTime) < new Date(this.state.startTime)
            // ) {
            //     this.setState({
            //         errors: {
            //             ...this.state.errors,
            //             endTime: "End Time can't be before start Time",
            //         },
            //     });
            // }
        }

        if (!prevProps.staffMembersData && this.props.staffMembersData) {
            this.setState({
                fetchingStaff: false,
            });
        }
    }

    handleChange = (e, value, type) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        if (this.state.errors.titleError.length) {
            this.setState({
                errors: initialState.errors,
            });
        }
        if (type && value) {
            if (type === "host" && !this.props.staffMembersData) {
                this.props.fetchStaffMembers(this.props.orgData.orgId);
            }
            this.setState({
                [type]: value,
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value,
            });
        }
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const { title, description, groups, host } = this.state;
        this.setState({
            loading: true,
        });
        if (this.props.action === "edit") {
            //handle type edit
            try {
                let params = {
                    etype: "item",
                    eventType: this.props.data.eventType,
                    orgId: this.props.orgData.orgId,
                    groups: this.state.groups.map((g) => g.groupId),
                    startTime: new Date(this.state.startTime).getTime() / 1000,
                    endTime: new Date(this.state.endTime).getTime() / 1000,
                    url: this.state.url,
                    host: host.userId,
                    title,
                    description,
                };
                this.props.updateEvent(params);
                //this.props.handleClose();
            } catch (e) {
                // toast.error(e.message);
                //this.props.handleClose();
            }
        } else {
            try {
                let params = {
                    eventType: "session",
                    orgId: this.props.orgData.orgId,
                    orgName: this.props.orgData.orgName,
                    startTime: new Date(this.state.startTime).getTime() / 1000,
                    endTime: new Date(this.state.endTime).getTime() / 1000,
                    url: this.state.url,
                    groups: this.state.groups.map((g) => g.groupId),
                    title,
                    host: host.userId,
                    description,
                };
                await this.props.addEvent(params);
                //this.props.handleClose();
            } catch (e) {
                //toast.error(e.message);
                //this.props.handleClose();
            }
            // } else {
            //     this.setState({
            //         errors: {
            //             ...this.state.errors,
            //             titleError: `${title} group already exists.`,
            //         },
            //     });
            // }
        }
    };

    onClose = (e) => {
        e.persist();
        this.props.handleClose(e);
    };

    handleDateTimeChange = (data, key) => {
        this.setState(
            {
                [key]: data,
                errors: { ...initialState.errors },
            },
            () => {
                if (
                    new Date(this.state.endTime) <
                    new Date(this.state.startTime)
                ) {
                    this.setState({
                        errors: {
                            ...this.state.errors,
                            endTime: "End Time can't be before start Time",
                        },
                    });
                }
            }
        );
    };

    // onFocusDateTime = (key) => {
    //     this.setState((prevProps) => {
    //         return {
    //             selectorPopup: {
    //                 ...this.state.selectorPopup,
    //                 [key]: !prevProps[key],
    //             },
    //         };
    //     });
    // };

    render() {
        const { classes, data, groupsData, eventType } = this.props;

        return (
            <Drawer
                anchor="right"
                open={this.props.open}
                onClose={this.onClose}
                transitionDuration={200}
                elevation={2}
                BackdropProps={{
                    style: { backgroundColor: "rgba(0,0,0,0.7)" },
                }}
                classes={{
                    root: "addEditExam",
                    paper: classes.drawer,
                }}
            >
                <div className="paper-top-navigation">
                    {this.props.source === "topMenu" && (
                        <IconButton onClick={this.props.handleBack}>
                            <KeyboardBackspaceIcon />
                        </IconButton>
                    )}

                    <IconButton onClick={this.onClose} className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                </div>
                <DialogTitle id="create-new-dialog" className={"paper-title"}>
                    {this.props.action === "edit" ? "Edit " : "Create New "}
                    {this.eventType}
                </DialogTitle>
                <form onSubmit={this.handleSubmit}>
                    <TextField
                        error={this.state.errors.titleError.length > 0}
                        id="title"
                        label={`${this.eventType} Name`}
                        name="title"
                        value={this.state.title}
                        required
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        className={`${classes.field}`}
                        helperText="Example: Class 10, SSC 2020 Batch1, IBPS 2020, etc."
                        onChange={this.handleChange}
                        helperText={this.state.errors.titleError}
                    />
                    <TextField
                        id="description"
                        name="description"
                        label="Description"
                        multiline
                        rows={2}
                        value={this.state.description}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        className={`${classes.field}`}
                        helperText="Small description to describe the event. This is optional."
                        onChange={this.handleChange}
                    />
                    <Divider variant="middle" className={"divider"} />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container wrap="nowrap" justify="space-between">
                            <DateTimePicker
                                //variant="inline"
                                // fullWidth
                                className={`${classes.field}`}
                                style={{ width: "calc(50% - 5px)" }}
                                required
                                disablePast
                                minDate={new Date()}
                                name="startTime"
                                label="Start Time"
                                inputVariant="outlined"
                                allowKeyboardControl
                                format="MMM dd, yyyy  hh:mm a"
                                value={this.state.startTime}
                                onChange={(data) =>
                                    this.handleDateTimeChange(data, "startTime")
                                }
                            />
                            <DateTimePicker
                                error={this.state.errors.endTime.length > 0}
                                helperText={this.state.errors.endTime}
                                //fullWidth
                                style={{ width: "calc(50% - 5px)" }}
                                className={`${classes.field}`}
                                required
                                minDate={this.state.startTime}
                                name="endTime"
                                label="End Time"
                                inputVariant="outlined"
                                allowKeyboardControl
                                format="MMM dd, yyyy  hh:mm a"
                                value={this.state.endTime}
                                onChange={(data) =>
                                    this.handleDateTimeChange(data, "endTime")
                                }
                            />
                            {/* <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="Date picker inline"
                                value={this.state.startTime}
                                onChange={this.handleDateTimeChange}
                                KeyboardButtonProps={{
                                    "aria-label": "change start date",
                                    onFocus: () =>
                                        this.onFocusDateTime("startTime"),
                                }}
                                PopoverProps={{
                                    disableRestoreFocus: true,
                                    onClose: () =>
                                        this.onFocusDateTime("startTime"),
                                }}
                                // InputProps={{
                                //     onFocus: () =>
                                //         this.onFocusDateTime("startTime"),
                                // }}
                                // open={this.state.selectorPopup.startTime}
                            />
                            <KeyboardTimePicker
                                margin="normal"
                                id="time-picker"
                                label="Time picker"
                                value={this.state.startTime}
                                onChange={this.handleDateTimeChange}
                                KeyboardButtonProps={{
                                    "aria-label": "change start time",
                                    // onFocus: () =>
                                    //this.onFocusDateTime("startTime"),
                                }}
                                // PopoverProps={{
                                //     disableRestoreFocus: true,
                                //     onClose: () =>
                                //         this.onFocusDateTime("startTime"),
                                // }}
                                // InputProps={{
                                //     onFocus: () =>
                                //         this.onFocusDateTime("startTime"),
                                // }}
                                // open={this.state.selectorPopup.startTime}
                            /> */}
                        </Grid>
                    </MuiPickersUtilsProvider>

                    <SearchSelector
                        id="host"
                        name="host"
                        required
                        className={`${classes.field}`}
                        data={
                            !this.state.fetchingStaff
                                ? this.props.staffMembersData
                                    ? this.props.staffMembersData
                                    : this.state.host
                                    ? [this.state.host]
                                    : []
                                : []
                        }
                        loading={!this.state.fetchingStaff ? false : true}
                        loadingText="Loading staff members..."
                        variant="outlined"
                        margin="dense"
                        onChange={(e, val) => this.handleChange(e, val, "host")}
                        onFocus={() => {
                            if (!this.props.staffMembersData) {
                                this.setState(
                                    {
                                        fetchingStaff: true,
                                    },
                                    () => {
                                        this.props.fetchStaffMembers(
                                            this.props.orgData.orgId
                                        );
                                    }
                                );
                            }
                        }}
                        value={this.state.host}
                        label="Assign To"
                        helperText="Assign To other staff member. "
                    />

                    <TextField
                        id="event-url"
                        name="url"
                        label={`${this.eventType} Url`}
                        type="url"
                        rows={2}
                        value={this.state.url}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        className={`${classes.field}`}
                        helperText="Enter the zoom, google meet etc. url. This is optional."
                        onChange={this.handleChange}
                    />
                    {/* <TextField
                        error={this.state.errors.titleError.length > 0}
                        id="location"
                        label="Location"
                        name="title"
                        value={this.state.title}
                        required
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        className={`${classes.field}`}
                        helperText="Example: Class 10, SSC 2020 Batch1, IBPS 2020, etc."
                        onChange={this.handleChange}
                        helperText={this.state.errors.titleError}
                    /> */}
                    <SearchSelector
                        id="groups"
                        name="groups"
                        multiple
                        //required
                        className={`${classes.field}`}
                        data={groupsData ? groupsData : []}
                        loading={groupsData ? false : true}
                        variant="outlined"
                        margin="dense"
                        onChange={(e, val) =>
                            this.handleChange(e, val, "groups")
                        }
                        value={this.state.groups}
                        label="Select Groups"
                        ChipProps={{
                            icon: <GroupWorkIcon />,
                        }}
                        helperText="(optional)Map the event to the group. "
                    />

                    {/* <Divider variant="middle" className={"divider"} />
                    <div className="rowWrapper">
                        <SearchSelector
                            id="tags"
                            name="tags"
                            freeSolo={true}
                            freeTags
                            multiple={true}
                            data={TAGS}
                            loading={TAGS.length > 0 ? false : true}
                            variant="outlined"
                            style={{ width: "100%" }}
                            size="small"
                            onChange={this.handleTagChange}
                            value={this.state.tags}
                            label="Add Tags"
                        />
                    </div> */}

                    {/* <SearchSelector
                        error={this.state.errors.membersError.length > 0}
                        id="members"
                        name="members"
                        fullWidth
                        multiple
                        noOptionsText="No user found."
                        data={filteredStudents ? filteredStudents : []}
                        loading={filteredStudents ? false : true}
                        variant="outlined"
                        margin="dense"
                        onChange={this.handleMembersChange}
                        value={this.state.members}
                        label="Add Members"
                        ChipProps={{
                            icon: <FaceIcon />,
                        }}
                        helperText={this.state.errors.membersError}
                    /> */}

                    {/* <section>
                        <div className="sectionTitle">Permissions:</div>
                        <FormGroup row>
                            <FormControlLabel
                                onChange={this.handleChange}
                                name="showResultOnCompletion"
                                control={
                                    <GreenCheckbox
                                        checked={
                                            this.state.showResultOnCompletion
                                        }
                                    />
                                }
                                label="Show result on exam completion"
                            />
                            <FormControlLabel
                                onChange={this.handleChange}
                                name="emailResult"
                                control={
                                    <GreenCheckbox
                                        checked={this.state.emailResult}
                                    />
                                }
                                label="Email result to student"
                            />
                        </FormGroup>
                    </section> */}

                    <div className={`controls ${classes.field}`}>
                        <div className={classes.buttonWrapper}>
                            <ButtonComp
                                variant="contained"
                                bckColor={colors.PRIMARY}
                                color="#ffffff"
                                type="submit"
                                disabled={this.state.loading}
                                classes={{ root: classes.rightMargin }}
                            >
                                {this.props.action === "edit"
                                    ? "Update"
                                    : "Save"}
                            </ButtonComp>
                            {this.state.loading && (
                                <CircularLoader
                                    size={24}
                                    className={classes.buttonProgress}
                                    color={colors.PRIMARY}
                                />
                            )}
                        </div>
                        <ButtonComp
                            variant="outlined"
                            color="#000000"
                            onClick={this.onClose}
                        >
                            Close
                        </ButtonComp>
                    </div>
                </form>
            </Drawer>
        );
    }
}

export default withStyles(useStyles)(CreateEvent);
