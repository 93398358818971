import { actionButton } from "aws-amplify";
import moment from "moment";
import { actionConstants } from "../Utilities/Constants";

const defaultState = {
    isLoading: true,
    isAuthenticated: false,
    quote: "",
    message: {},
    orgData: null,
    tranx: null,
    userData: {},
    eventsData: null,
    studentsData: null,
    introDialogData: {
        showDialog: false,
        type: "",
        data: null,
    },
    plans: null,
    lastPayout: null,
    keys: {},
    staffMembersData: null,
};

const appStateReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actionConstants.UPDATE_AUTHENTICATION:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: action.payload.status,
                userData: action.payload.user,
            };
        case actionConstants.USER_LOGOUT:
            return {
                ...defaultState,
                introDialogData: {
                    ...defaultState.introDialogData,
                },
                isLoading: false,
            };
        case actionConstants.UPDATE_USER_DATA:
            return {
                ...state,
                userData: action.payload,
            };
        case actionConstants.UPDATE_ORG_DATA:
            if (action.payload) {
                localStorage.setItem(
                    "orgData",
                    JSON.stringify({ ...state.orgData, ...action.payload })
                );
            } else {
                localStorage.removeItem("orgData");
            }

            return {
                ...state,
                isLoading: action.payload ? false : true,
                isAuthenticated: action.payload ? true : false,
                orgData: action.payload
                    ? { ...state.orgData, ...action.payload }
                    : action.payload,
            };
        case actionConstants.UPDATE_ORG_CONFIG:
            localStorage.setItem(
                "orgData",
                JSON.stringify({ ...state.orgData, config: action.payload })
            );
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                orgData: { ...state.orgData, config: action.payload },
            };
        case actionConstants.UPDATE_DASHBOARD_QUOTE:
            return {
                ...state,
                quote: action.payload,
            };
        case actionConstants.UPDATE_DASHBOARD_DATA:
            let ooData = {
                ...state.orgData,
                dashboard: {
                    leaderBoardView: action.payload,
                },
            };
            localStorage.setItem("orgData", JSON.stringify(ooData));
            return {
                ...state,
                orgData: ooData,
            };
        case actionConstants.UPDATE_ORG_RESOURCES:
            let orggg = { ...state.orgData };
            orggg.resources = { ...orggg.resources };
            orggg.resources[action.payload.type] =
                orggg.resources[action.payload.type] + action.payload.value;
            localStorage.setItem("orgData", JSON.stringify(orggg));
            return {
                ...state,
                isLoading: false,
                orgData: orggg,
            };
        case actionConstants.UPDATE_MESSAGE:
            return { ...state, isLoading: false, message: action.payload };
        case actionConstants.IS_LOADING:
            return { ...state, isLoading: action.payload };
        case actionConstants.FETCH_STUDENTS_DATA:
            return { ...state, isLoading: false, studentsData: action.payload };

        case actionConstants.DELETE_STUDENT:
            let allStudents = state.studentsData ? [...state.studentsData] : [];
            let newList = allStudents.filter((s) => s.userId !== action.userId);
            return {
                ...state,
                studentsData: newList,
            };
        case actionConstants.UPDATE_STUDENT_RESULT:
            let studentList = state.studentsData ? [...state.studentsData] : [];
            let st = studentList.find((s) => s.userId === action.userId);
            if (st) {
                st["results"] = action.payload;
            }
            return { ...state, studentsData: studentList };
        case actionConstants.UPDATE_INTRO_DIALOG:
            return {
                ...state,
                introDialogData: action.payload,
            };
        case actionConstants.UPDATE_GROUP_DATA:
            let studentsList = state.studentsData
                ? [...state.studentsData]
                : null;
            const { type, memAction, members, ...rest } = action.payload;
            let newSList;
            if (studentsList) {
                if (type === "item" || memAction === "add") {
                    newSList = studentsList.map((s) => {
                        if (members.includes(s.userId)) {
                            return {
                                ...s,
                                mygroup: action.payload.groupId,
                            };
                        }
                        return s;
                    });
                } else if (type === "members") {
                    if (memAction === "remove") {
                        let objToBeModified = studentsList.find(
                            (s) => s.userId === members[0]
                        );
                        objToBeModified.mygroup = null;
                    } else if (memAction === "move") {
                        let objToBeMoved = studentsList.find(
                            (s) => s.userId === members[0]
                        );
                        if (objToBeMoved) {
                            objToBeMoved.mygroup = rest.targetGroupId;
                        }
                    }
                    newSList = studentsList;
                }
            } else {
                newSList = studentstList;
            }

            return {
                ...state,
                studentsData: newSList,
            };

        // case actionConstants.ADD_NEW_GROUP:
        //     let orggData = { ...state.orgData };
        //     let newGroup = {
        //         id: action.payload.groupId,
        //         title: action.payload.title,
        //         description: action.payload.description,
        //     };
        //     let newGList = [...orggData.groups]
        //         ? [...state.orgData.groups, newGroup]
        //         : [newGroup];

        //     orggData.groups = newGList;
        //     localStorage.setItem("orgData", JSON.stringify(orggData));

        //     return {
        //         ...state,
        //         orgData: orggData,
        //     };
        // case actionConstants.DELETE_GROUP:
        //     let gId = action.payload;
        //     let gData = [...state.orgData.groups];
        //     let indexToBeDeleted = gData.findIndex((g) => g.id === gId);
        //     if (indexToBeDeleted !== -1) {
        //         gData.splice(indexToBeDeleted, 1);
        //     }
        //     return {
        //         ...state,
        //         orgData: {
        //             ...state.orgData,
        //             groups: gData,
        //         },
        //     };
        // case actionConstants.UPDATE_GROUP_DATA:
        //     let ggId = action.payload.groupId;
        //     let ggData = [...state.orgData.groups];
        //     let indexToBeUpdated = ggData.findIndex((g) => g.id === ggId);
        //     if (indexToBeUpdated !== -1) {
        //         //update the old data
        //         ggData[indexToBeUpdated] = {
        //             ...ggData[indexToBeUpdated],
        //             title: action.payload.title,
        //             description: action.payload.description,
        //         };
        //     }
        //     return {
        //         ...state,
        //         orgData: {
        //             ...state.orgData,
        //             groups: ggData,
        //         },
        //     };
        case actionConstants.UPDATE_PLANS_DATA:
            return {
                ...state,
                plans: action.payload,
            };
        case actionConstants.UPDATE_LICENSE:
            console.log(action.payload);
            return {
                ...state,
                orgData: {
                    ...state.orgData,
                    license: action.payload.id,
                    licRules: action.payload.licRules,
                },
            };
        case actionConstants.UPDATE_BANK_DATA:
            return {
                ...state,
                orgData: {
                    ...state.orgData,
                    bankDetails: {
                        ...action.payload,
                        updatedOn: action.payload.updatedOn
                            ? action.payload.updatedOn
                            : moment().unix(),
                    },
                },
            };
        case actionConstants.UPDATE_MEMBER_TRANX:
            return {
                ...state,
                tranx: action.payload,
            };
        case actionConstants.UPDATE_LAST_PAYOUT:
            return {
                ...state,
                lastPayout: action.payload,
            };
        case actionConstants.UPDATE_KEYS:
            return {
                ...state,
                keys: {
                    ...state.keys,
                    [action.payload.type]: action.payload.key,
                },
            };
        case actionConstants.FETCH_STAFF_MEMBERS:
            return {
                ...state,
                isLoading: false,
                staffMembersData: action.payload,
            };
        case actionConstants.DELETE_STAFF_MEMBER:
            return {
                ...state,
                isLoading: false,
                staffMembersData: state.staffMembersData.filter(
                    (mem) => mem.userId !== action.userId
                ),
            };
        case actionConstants.UPDATE_STAFF_MEMBER:
            let sUsers = state.staffMembersData
                ? [...state.staffMembersData]
                : null;
            if (sUsers) {
                if (action.payload.type === "roles") {
                    let user = sUsers.find(
                        (u) => u.userId === action.payload.userId
                    );
                    if (user) {
                        user.rolePermissions = action.payload.rolePermissions;
                    }
                }
            }
            return {
                ...state,
                staffMembersData: sUsers,
            };
        case actionConstants.FETCH_EVENTS_DATA:
            return {
                ...state,
                eventsData:
                    state.eventsData && state.eventsData.length > 1
                        ? [...state.eventsData, ...action.payload]
                        : action.payload,
            };
        case actionConstants.UPDATE_EVENT_DATA:
            const { eAction, etype, ...edata } = action.payload;
            let newEventsData = state.eventsData ? [...state.eventsData] : [];
            if (eAction === "add") {
                newEventsData.unshift(edata);
            } else if (eAction === "update") {
                let index = newEventsData.findIndex((s) => s.seriesId === id);
                if (index !== -1) {
                    if (etype === "time") {
                        newEventsData[index] = {
                            ...newEventsData[index],
                            ...edata,
                        };
                    }
                }
            }

            return {
                ...state,
                eventsData: newEventsData,
            };
        default:
            return state;
    }
};

export default appStateReducer;
