import React from "react";
import { lighten, withStyles } from "@material-ui/core/styles";
import { Dialog, DialogTitle, SvgIcon, Typography } from "@material-ui/core";
import { colors, TAGS } from "../../utilities/constants";
import ButtonComp from "../UIComponents/ButtonComp";
import DeleteIcon from "@material-ui/icons/Delete";
import PublicIcon from "@material-ui/icons/Public";
import ErrorIcon from "@material-ui/icons/Error";

const useStyles = (theme) => ({
    rightMargin: {
        marginRight: 20,
    },

    deleteIcon: {
        fontSize: 80,
    },
    content: {
        textAlign: "center",
        paddingBottom: theme.spacing(2),
    },
});

const ConfirmDialog = (props) => {
    const { classes } = props;

    return (
        <Dialog
            onClose={props.handleClose}
            aria-labelledby="create-new-dialog"
            open={props.open}
            classes={{ root: "dialog", paper: "delete-dialog" }}
            BackdropProps={{
                style: { backgroundColor: "rgba(0,0,0,0.7)" },
            }}
            maxWidth="xs"
            fullWidth
        >
            <SvgIcon
                fontSize="inherit"
                className={`${classes.deleteIcon} deleteIcon`}
            >
                {props.type === "error" ? (
                    <ErrorIcon style={{ color: colors.DANGER }} />
                ) : (
                    <>
                        {props.type === "status" && <PublicIcon />}
                        {props.type === "delete" && <DeleteIcon />}
                    </>
                )}
            </SvgIcon>
            <DialogTitle id="create-new-dialog" className={"paper-title"}>
                {props.title ? (
                    props.title
                ) : (
                    <>
                        {props.type === "status" && "Confirm Status Change"}{" "}
                        {props.type === "delete" && "Confirm Deletion"}
                        {props.type === "error" && "Permission Denied"}
                    </>
                )}
            </DialogTitle>
            {props.message && (
                <Typography
                    variant="body1"
                    component="p"
                    className={classes.content}
                >
                    {props.message}
                </Typography>
            )}

            <div className={`controls ${classes.field}`}>
                {props.type !== "error" && (
                    <ButtonComp
                        variant="contained"
                        bckColor={colors.DANGER}
                        color="#ffffff"
                        onClick={props.handleConfirm}
                        classes={{ root: classes.rightMargin }}
                    >
                        Confirm
                    </ButtonComp>
                )}

                <ButtonComp
                    variant="outlined"
                    color="#000000"
                    onClick={props.handleClose}
                >
                    Close
                </ButtonComp>
            </div>
        </Dialog>
    );
};

export default withStyles(useStyles)(ConfirmDialog);
