import { QUESTION_TYPES, RESERVED_SUBDOMAINS } from "./constants";
import React from "react";
import { Auth } from "aws-amplify";
import { Typography, Link } from "@material-ui/core";
import DOMPurify from "dompurify";
import { toast } from "react-toastify";

export const updateDocTitle = (titleText, isFullTitle) => {
    if (isFullTitle) {
        document.title = titleText;
    } else {
        document.title = `${titleText} • Admin Console`;
    }
};

export const saveQDataToLS = (data) => {
    let localData = [];
    data.forEach((q) => {
        localData.push({
            ...q,
            qAnswer: [...q.qAnswer],
        });
    });
    localStorage.setItem("qData", JSON.stringify(localData));
};
export const hyderateQDataFromLS = () => {
    let qData = JSON.parse(localStorage.getItem("qData"));
    if (qData) {
        let parsedData = qData.map((q) => {
            return {
                ...q,
                qAnswer: new Map(q.qAnswer),
            };
        });
        return parsedData;
    }
    return null;
};

export const hasUpperCase = (value) => {
    return new RegExp(/[A-Z]/).test(value);
};

export const hasNumber = (value) => {
    return new RegExp(/[0-9]/).test(value);
};

export const hasSpecialChar = (value) => {
    return new RegExp(/[!#@$%^&*)(+=._-]/).test(value);
};

export const isValidEmail = (email) => {
    let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const isValidURL = (str) => {
    let pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
            "((\\d{1,3}\\.){3}\\d{1,3}))" +
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
            "(\\?[;&a-z\\d%_.~+=-]*)?" +
            "(\\#[-a-z\\d_]*)?$",
        "i"
    );
    return !!pattern.test(str);
};

// Check whether the phone number has a valid 10 digit code
export const isValidPhoneNumber = (phoneNumber, countryCode) => {
    let slicedPhone;
    let err = "";
    const phoneRegex = /[^0-9]+/g;
    const checkCountryCode = phoneNumber.match(phoneRegex);

    // if (checkCountryCode && checkCountryCode.length > 0)
    //     slicedPhone = phoneNumber
    //         .replace(phoneRegex, "")
    //         .slice(countryCode.length);
    // else slicedPhone = phoneNumber;
    slicedPhone = phoneNumber.substring(3);

    let valid = false;
    if (slicedPhone.length === 10) {
        valid = true;
    } else if (slicedPhone.length > 10) {
        err = "more than 10 digits";
    } else {
        err = "less than 10 digits";
    }
    return {
        valid,
        err,
    };
};

export const qTypeToFullName = (type) => {
    let q = [];
    q = QUESTION_TYPES.filter((q) => q.value === type);
    if (q[0]) {
        return q[0].label;
    }
    return "xx";
};

export const renderCopyright = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://gradout.com/">
                Gradout
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
};

export const getCurrentAuthenticatedUserDetails = async () => {
    let userData = {
        token: "",
        username: "",
        region: "",
    };

    try {
        const user = await Auth.currentAuthenticatedUser();
        userData = {
            token: user.signInUserSession.idToken.jwtToken,
            username: user.username,
            //region: split(user.pool.userPoolId, "_", 1)[0],
        };
    } catch (e) {
        userData = {
            token: null,
            username: null,
            region: null,
        };
    }
    return userData;
};

export const getTenantURL = (subdomain, naked) => {
    if (subdomain.length > 0) {
        if (naked) {
            return `${subdomain}.gradout.com`;
        }
        return `https://${subdomain}.gradout.com`;
    }
};

export const sanitizeHTML = (html, sanitize = true) => {
    let cleanHTML;
    if (sanitize) {
        cleanHTML = DOMPurify.sanitize(html);
    } else {
        cleanHTML = html;
    }
    return cleanHTML;
};

export const formatQAnswerData = (qsArray) => {
    const formattedData = qsArray
        .filter((obj) => obj.q.replace(/<p>\s*<\/p>/gi, "").trim().length > 0)
        .map((obj) => {
            switch (obj.qType) {
                case "scq":
                    return {
                        ...obj,
                        qAnswer: obj.qAnswer.get("scq"),
                    };
                case "mcq":
                    return {
                        ...obj,
                        qAnswer: obj.qAnswer.get("mcq"),
                    };
                case "bool":
                    return {
                        ...obj,
                        qAnswer: obj.qAnswer.get("bool"),
                    };
                case "text":
                    return {
                        ...obj,
                        qAnswer: obj.qAnswer.get("text"),
                    };
            }
        });

    return formattedData;
};

export const toggleItemStatus = (data) => {
    let newStatus = "";
    if (data.itemStatus === "draft") {
        newStatus = "live";
    } else if (data.itemStatus === "live") {
        newStatus = "draft";
    } else {
        newStatus = "draft";
    }

    return newStatus;
};

export const copyToClipBoard = async (text) => {
    // const { sPassword } = this.state;
    if (navigator.clipboard) {
        try {
            await navigator.clipboard.writeText(text);
            toast.success("Series password copied.");
        } catch (err) {
            toast.error("Series password copied failed.");
        }
    } else {
        toast.error("Series password copied failed.");
    }
};

export const isReserved = (value) => {
    if (RESERVED_SUBDOMAINS.includes(value)) {
        return true;
    }
    return false;
};

export const debounce = (func, wait, immediate) => {
    var timeout;

    return function () {
        var context = this,
            args = arguments;

        var callNow = immediate && !timeout;

        clearTimeout(timeout);
        timeout = setTimeout(function () {
            timeout = null;

            if (!immediate) {
                func.apply(context, args);
            }
        }, wait);

        if (callNow) func.apply(context, args);
    };
};

function getFaviconEl() {
    return document.getElementById("favicon");
}
