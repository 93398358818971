import React, { useState } from "react";
import {
    Paper,
    Card,
    CardActionArea,
    Typography,
    CardContent,
    LinearProgress,
    Button,
    IconButton,
    Divider,
    CardMedia,
    CardActions,
    Popper,
    MenuList,
    MenuItem,
    Collapse,
    ClickAwayListener,
} from "@material-ui/core";
import TouchRipple from "@material-ui/core/ButtonBase/TouchRipple";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import TooltipComp from "./TooltipComp";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { colors } from "../../utilities/constants";

const MActionCard = (props) => {
    const { data, readOnly } = props;
    const [menuOpen, setMenuOpen] = useState(false);
    const anchorRef = React.createRef();
    let anchorEl;
    let style = {};
    let onCardClick = null;
    if (props.addGroup) {
        style.background = "transparent";
        style.cursor = "pointer";
        onCardClick = props.onClick;
    }

    const toggleMenu = (e) => {
        e.persist();
        anchorEl = anchorRef;
        setMenuOpen(!menuOpen);
    };

    return (
        <Card
            className={`mACard fadeInUp ${props.className}`}
            elevation={props.elevation >= 0 ? props.elevation : 2}
            style={style}
            onClick={props.cardClick ? props.onClick : null}
            style={{
                cursor: props.cardClick ? "pointer" : "inherit",
            }}
            //onClick={onCardClick}
        >
            {!props.addAction ? (
                <>
                    <CardContent
                        className={`mACard__main ${props.contentClass}`}
                    >
                        <div style={{ textAlign: "center" }}>{data.media}</div>
                        <Typography
                            gutterBottom
                            variant="h6"
                            component="h4"
                            className="mACard__main__title"
                        >
                            {data.title}
                        </Typography>

                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="div"
                            className={`mACard__main__description ${
                                readOnly ? "readOnly" : ""
                            }`}
                        >
                            {data.description ? (
                                data.description
                            ) : (
                                <i
                                    style={{
                                        fontSize: "0.8em",
                                        opacity: 0.5,
                                    }}
                                >
                                    No description available
                                </i>
                            )}
                        </Typography>

                        {/* {data.members && (
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="div"
                                className={`dataRow`}
                            >
                                {`Members: ${new Intl.NumberFormat(
                                    "en-IN"
                                ).format(data.members.length)}`}
                            </Typography>
                        )} */}
                        {props.children && props.children}
                    </CardContent>
                    {!props.cardClick && (
                        <CardActions className="mACard__actions">
                            <Button
                                variant="contained"
                                fullWidth
                                color="primary"
                                className="cta-btn"
                                endIcon={<ArrowForwardIcon />}
                                onClick={(e) => props.onClick(e, data)}
                                disabled={
                                    props.btnDisabledStatus
                                        ? props.btnDisabledStatus
                                        : false
                                }
                            >
                                {props.btnLabel}
                            </Button>
                            {props.editButton && (
                                <IconButton
                                    aria-label="Group control menu"
                                    aria-controls="series-row"
                                    aria-haspopup="true"
                                    style={{
                                        marginLeft: "auto",
                                    }}
                                    onClick={toggleMenu}
                                    color="inherit"
                                    edge="end"
                                >
                                    <MoreVertIcon ref={anchorRef} />

                                    <Popper
                                        open={menuOpen}
                                        anchorEl={anchorEl}
                                        role={undefined}
                                        transition
                                        disablePortal={false}
                                        placement="bottom-end"
                                        className="menuPopper"
                                        modifiers={{
                                            flip: {
                                                enabled: true,
                                            },
                                            preventOverflow: {
                                                enabled: true,
                                                boundariesElement:
                                                    "scrollParent",
                                            },
                                        }}
                                    >
                                        {({ TransitionProps }) => (
                                            <Collapse
                                                {...TransitionProps}
                                                collapsedHeight={20}
                                            >
                                                <Paper elevation={10}>
                                                    <ClickAwayListener
                                                        onClickAway={toggleMenu}
                                                    >
                                                        <MenuList
                                                            // autoFocusItem={
                                                            //     this.state.open
                                                            // }
                                                            id="menu-list-grow"
                                                            onKeyDown={() =>
                                                                null
                                                            }
                                                        >
                                                            <MenuItem
                                                                className="menuItem"
                                                                // onClick={(
                                                                //     e
                                                                // ) => {
                                                                //     this.toggleConfirmDialog(
                                                                //         userData
                                                                //     );
                                                                // }}
                                                                style={{
                                                                    color: colors.DANGER,
                                                                }}
                                                            >
                                                                Edit Session
                                                            </MenuItem>
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Collapse>
                                        )}
                                    </Popper>
                                </IconButton>
                            )}
                        </CardActions>
                    )}
                </>
            ) : (
                <CardContent
                    className={`mACard__main addAction ${props.contentClass}`}
                >
                    {props.children && props.children}
                </CardContent>
            )}
        </Card>
    );
};

export default MActionCard;
