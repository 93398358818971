import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import Amplify, { Auth, API } from "aws-amplify";
import Routes from "./routes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./containers/Header";
import Sidebar from "./components/Sidebar";
import {
    Container,
    LinearProgress,
    Backdrop,
    CircularProgress,
} from "@material-ui/core";
import CreateQuestionDialog from "./components/Dialogs/CreateQuestionDialog";
import CreateEditEntity from "./components/Dialogs/CreateEditEntity";
import CreateMenuDialog from "./components/Dialogs/CreateMenuDialog";
import IntroDialog from "./components/Dialogs/IntroDialog";
import { DRAWER_WIDTH, colors, AUTO_CLOSE_TOAST } from "./utilities/constants";
import { updateDocTitle } from "./utilities/helper";
import {
    updateAuthentication,
    isLoading,
    userLogout,
    validateOrg,
    updateOrgData,
    updateUserData,
    fetchSeriesData,
    updateSeriesData,
    updateExamsData,
    updateSeries,
    updateExam,
    updateMessage,
    saveExam,
    saveSeries,
    saveQuestions,
    updateIntroDialog,
    fetchStudents,
    fetchGroups,
    addGroup,
    updateGroup,
    switchOrgs,
    addEvent,
    updateEvent,
    fetchStaffMembers,
} from "./store/action";
import awsConfig from "./config/aws-config";
import FullscreenBranding from "./components/UIComponents/FullScreenBranding";
import LicenseUpgradeDialog from "./components/Dialogs/LicenseUpgradeDialog";
import CreateGroup from "./components/Dialogs/CreateGroup";
import CreateEvent from "./components/Dialogs/CreateEvent";
import InternetConnectionDialog from "./components/Dialogs/InternetConnectionDialog";
import offlineArt from "./assets/OfflineArt.svg";
import onlineArt from "./assets/OnlineArt.svg";
import LinearLoader from "./components/UIComponents/LinearLoader";
import CircularLoader from "./components/UIComponents/CircularLoader";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSidebarOpen: window.matchMedia("(min-width: 769px)").matches,
            isCreateEditEntity: false,
            isCreateEditGroup: false,
            isCreateQuestionOpen: false,
            isCreateMenuOpen: false,
            isCreateEventOpen: false,
            licNotifDialogOpen: false,
            dialogType: "",
            dialogData: null,
            dialogAction: "",
            dialogSource: null,
            showToast: {},
            introDialogOpen: false,
            isDisconnected: false,
            isInternetDialog: false,
        };

        const cognito = {
            mandatorySignIn: true,
            region: awsConfig.cognito.region,
            userPoolId: awsConfig.cognito.userPoolId,
            userPoolWebClientId: awsConfig.cognito.userPoolWebClientId,
            IdentityPoolId: awsConfig.cognito.identityPoolId,
        };

        Amplify.configure({
            Auth: cognito,
            API: {
                endpoints: awsConfig.apiGateway,
            },
        });
    }

    static getDerivedStateFromProps(props, state) {
        let showToast = {};
        if (props.message !== state.showToast) {
            showToast = props.message;
        } else {
            showToast = {};
        }

        return {
            ...state,
            showToast,
        };
    }

    async componentDidMount() {
        this.handleConnectionChange();
        window.addEventListener("online", this.handleConnectionChange);
        window.addEventListener("offline", this.handleConnectionChange);
        if (!this.props.isAuthenticated) {
            try {
                let data = await Auth.currentSession();
                let orgData = JSON.parse(localStorage.getItem("orgData"));
                if (orgData) {
                    this.props.updateUserData(data.idToken.payload);
                    this.props.updateOrgData(orgData);
                    this.props.validateOrg(
                        data.idToken.payload,
                        true,
                        orgData.orgId
                    );
                    updateDocTitle(this.props.orgData.orgName);
                } else {
                    this.props.validateOrg(data.idToken.payload);
                }
            } catch (e) {
                this.props.updateLoading(false);
            }
        } else {
            updateDocTitle(this.props.orgData.orgName);
        }

        //load internet connect images
        const img = new Image();
        const img2 = new Image();
        img.src = offlineArt; // by setting an src, you trigger browser download
        img2.src = onlineArt;
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.introDialogData &&
            this.props.introDialogData.showDialog &&
            !this.state.introDialogOpen
        ) {
            this.setState({
                introDialogOpen: true,
            });
        }
        if (
            this.props.introDialogData &&
            !this.props.introDialogData.showDialog &&
            this.state.introDialogOpen
        ) {
            this.setState({
                introDialogOpen: false,
            });
        }
        // if (prevState.isDisconnected && !this.state.isDisconnected) {
        //     setTimeout(() => {
        //         window.location.reload();
        //     }, 1000);
        // }
    }

    handleConnectionChange = () => {
        const condition = navigator.onLine ? "online" : "offline";
        if (condition === "online") {
            const webPing = setInterval(() => {
                fetch("//google.com", {
                    mode: "no-cors",
                })
                    .then(() => {
                        this.setState({ isDisconnected: false }, () => {
                            setTimeout(() => {
                                this.setState({
                                    isInternetDialog: false,
                                });
                            }, 1100);
                            return clearInterval(webPing);
                        });
                    })
                    .catch(() => this.setState({ isDisconnected: true }));
            }, 2000);
            return;
        }

        return this.setState({ isDisconnected: true, isInternetDialog: true });
    };

    toggleSidebar = (isClose) => {
        console.log("sidebar clicked --> " + isClose);
        this.setState((prevState) => {
            return {
                isSidebarOpen: isClose ? false : !prevState.isSidebarOpen,
            };
        });
    };

    toggleCreateMenu = (e) => {
        e.stopPropagation();
        if (e.nativeEvent) {
            e.nativeEvent.stopImmediatePropagation();
        }
        this.setState((prevState) => {
            return {
                isCreateEventOpen: false,
                isCreateEditEntity: false,
                isCreateQuestionOpen: false,
                isCreateEditGroup: false,
                isCreateMenuOpen: !prevState.isCreateMenuOpen,
            };
        });
    };

    toggleLicNotifDialog = (e) => {
        this.setState((prevProps) => {
            return {
                isCreateMenuOpen: false,
                isCreateEditEntity: false,
                isCreateEditGroup: false,
                dialogData: null,
                isCreateQuestionOpen: false,
                licNotifDialogOpen: !prevProps.licNotifDialogOpen,
            };
        });
    };

    toggleCreateQuestionDialog = (e) => {
        this.setState((prevState) => {
            if (prevState.isCreateQuestionOpen) {
                return {
                    isCreateMenuOpen: false,
                    isCreateEditEntity: false,
                    isCreateEditGroup: false,
                    dialogData: null,
                    isCreateQuestionOpen: !prevState.isCreateQuestionOpen,
                };
            } else {
                return {
                    isCreateMenuOpen: false,
                    isCreateEditEntity: false,
                    isCreateEditGroup: false,
                    isCreateQuestionOpen: !prevState.isCreateQuestionOpen,
                };
            }
        });
    };

    toggleDialogsWithType = (e, type, data, action = "add", source) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        let resKey;
        if (type === "que") {
            resKey = "questions";
        } else if (type === "group") {
            resKey = "groups";
        } else {
            resKey = type;
        }
        if (
            this.props.orgData.resources[resKey] >=
                this.props.orgData.licRules[resKey] &&
            action === "add"
        ) {
            this.setState(
                {
                    dialogType: resKey,
                },
                () => {
                    this.toggleLicNotifDialog(e);
                }
            );
        } else {
            if (type === "que") {
                if (data) {
                    this.setState(
                        {
                            dialogData: data,
                        },
                        () => this.toggleCreateQuestionDialog()
                    );
                } else {
                    this.toggleCreateQuestionDialog();
                }
            } else if (type === "group") {
                this.setState(
                    {
                        dialogAction: action,
                        dialogType: type,
                        dialogData: data ? data : null,
                        dialogSource: source ? source : "",
                    },
                    () => {
                        if (!this.props.studentsData) {
                            this.props.fetchStudents(this.props.orgData.orgId);
                        }
                        this.toggleCreateEditGroupDialog();
                    }
                );
            } else if (type === "events") {
                this.setState(
                    {
                        dialogAction: action,
                        dialogType: type,
                        dialogData: data ? data : null,
                        dialogSource: source ? source : "",
                    },
                    () => {
                        this.toggleEventDialog();
                    }
                );
            } else {
                this.setState(
                    {
                        dialogAction: action,
                        dialogType: type,
                        dialogData: data ? data : null,
                        dialogSource: source ? source : "",
                    },
                    () => {
                        if (type === "exam" && !this.props.seriesData) {
                            this.props.fetchSeries(this.props.orgData.orgId);
                        } else if (
                            type === "series" &&
                            data &&
                            data.sScope === "group" &&
                            !this.props.groups
                        ) {
                            //fetch groups
                            this.props.fetchGroups(this.props.orgData.orgId);
                        }
                        this.toggleCreateEditDialog();
                    }
                );
            }
        }
    };

    toggleCreateEditDialog = (e) => {
        this.setState((prevState) => {
            return {
                isCreateMenuOpen: false,
                isCreateQuestionOpen: false,
                isCreateEditGroup: false,
                isCreateEditEntity: !prevState.isCreateEditEntity,
            };
        });
    };

    toggleEventDialog = () => {
        this.setState((prevState) => {
            return {
                isCreateMenuOpen: false,
                isCreateQuestionOpen: false,
                isCreateEditGroup: false,
                isCreateEditEntity: false,
                isCreateEventOpen: !prevState.isCreateEventOpen,
            };
        });
    };

    toggleCreateEditGroupDialog = (e) => {
        this.setState((prevState) => {
            return {
                isCreateMenuOpen: false,
                isCreateQuestionOpen: false,
                isCreateEditEntity: false,
                isCreateEditGroup: !prevState.isCreateEditGroup,
            };
        });
    };

    handleLogout = async () => {
        try {
            this.props.history.push("/login");
            console.log("logging out...");
            await Auth.signOut();
            this.props.userLogout();
            localStorage.clear();
        } catch (e) {
            toast.error("Sign-out Failed!");
        }
    };

    addQuestionsToExam = (newQs) => {
        const { dialogData } = this.state;
        let params = {
            type: "questions",
            examId: dialogData.examId,
            title: dialogData.title,
            seriesId: dialogData.seriesId,
            questions: [
                ...dialogData.questions,
                ...newQs.map((q) => {
                    return {
                        qId: q.qId,
                        orgId: q.orgId,
                        timestamp: moment().unix(),
                    };
                }),
            ],
        };
        this.props.saveQuestions({ qs: newQs, examId: dialogData.examId });
        this.props.updateExam(params);
        this.toggleCreateQuestionDialog();
        this.setState({
            dialogType: "",
            dialogData: null,
            dialogAction: "",
        });
    };

    renderToast = () => {
        const { type, content } = this.state.showToast;
        switch (type) {
            case "SUCCESS":
                return toast.success(content, {
                    toastId: content,
                    onClose: setTimeout(() => this.props.updateMessage(), 0),
                });
            case "ERROR":
                return toast.error(content, {
                    toastId: content,
                    onClose: setTimeout(() => this.props.updateMessage(), 0),
                });
        }
    };

    render() {
        let childProps = {
            handleLogout: this.handleLogout,
            isAuthenticated: this.props.isAuthenticated,
            toggleDialogsWithType: this.toggleDialogsWithType,
            toggleLicNotifDialog: this.toggleLicNotifDialog,
        };
        return (
            <div className={`app light`}>
                {/* <LinearLoader className="univ-loader" /> */}
                {!this.props.isLoading || this.props.isAuthenticated ? (
                    <>
                        {this.props.isAuthenticated && this.props.orgData && (
                            <Header
                                lightTheme={true}
                                updateTheme={this.props.toggleTheme}
                                logout={this.logout}
                                history={this.props.history}
                                isAuthenticated={this.props.isAuthenticated}
                                handleLogout={this.handleLogout}
                                userData={this.props.userData}
                                orgData={this.props.orgData}
                                toggleSidebar={this.toggleSidebar}
                                toggleCreateMenu={this.toggleCreateMenu}
                                toggleDialogsWithType={
                                    this.toggleDialogsWithType
                                }
                                switchOrgs={() =>
                                    this.props.switchOrgs(this.props.userData)
                                }
                            />
                        )}
                        <div className="main">
                            {/* <Backdrop
                                style={{ zIndex: 10000 }}
                                open={this.props.isLoading}
                                // onClick={() => {
                                //     setOpen(false);
                                // }}
                            >
                                <CircularLoader size={50} />
                            </Backdrop> */}
                            {this.props.isAuthenticated &&
                                this.props.orgData && (
                                    <Sidebar
                                        open={this.state.isSidebarOpen}
                                        toggleSidebar={this.toggleSidebar}
                                        orgData={this.props.orgData}
                                        userData={this.props.userData}
                                        history={this.props.history}
                                    />
                                )}
                            <Container
                                classes={{ root: "main__container" }}
                                maxWidth={false}
                                style={{
                                    marginLeft:
                                        this.state.isSidebarOpen &&
                                        this.props.isAuthenticated &&
                                        window.matchMedia("(min-width: 769px)")
                                            .matches
                                            ? DRAWER_WIDTH
                                            : "auto",
                                }}
                            >
                                <Routes childProps={childProps} />
                            </Container>
                        </div>
                        {this.props.isAuthenticated && this.props.orgData && (
                            <>
                                {
                                    <CreateEditEntity
                                        open={this.state.isCreateEditEntity}
                                        handleClose={
                                            this.toggleCreateEditDialog
                                        }
                                        data={this.state.dialogData}
                                        orgData={this.props.orgData}
                                        history={this.props.history}
                                        isLoading={this.props.isLoading}
                                        type={this.state.dialogType}
                                        action={this.state.dialogAction}
                                        source={this.state.dialogSource}
                                        handleBack={this.toggleCreateMenu}
                                        seriesData={this.props.seriesData}
                                        updateExam={this.props.updateExam}
                                        updateSeries={this.props.updateSeries}
                                        saveExam={this.props.saveExam}
                                        saveSeries={this.props.saveSeries}
                                        groupsData={this.props.groups}
                                        fetchGroups={this.props.fetchGroups}
                                    />
                                }
                                {
                                    <CreateGroup
                                        open={this.state.isCreateEditGroup}
                                        handleClose={
                                            this.toggleCreateEditGroupDialog
                                        }
                                        data={this.state.dialogData}
                                        orgData={this.props.orgData}
                                        history={this.props.history}
                                        isLoading={this.props.isLoading}
                                        type={this.state.dialogType}
                                        handleBack={this.toggleCreateMenu}
                                        action={this.state.dialogAction}
                                        source={this.state.dialogSource}
                                        addGroup={this.props.addGroup}
                                        updateGroup={this.props.updateGroup}
                                        studentsData={this.props.studentsData}
                                        groupsData={this.props.groups}
                                        // updateGroupMembers={
                                        //     this.props.updateGroupMembers
                                        // }
                                        //handleBack={this.toggleCreateMenu}
                                    />
                                }
                                {this.state.isCreateQuestionOpen && (
                                    <CreateQuestionDialog
                                        open={this.state.isCreateQuestionOpen}
                                        addToExam={this.state.dialogData}
                                        source={this.state.dialogSource}
                                        addQuestionsToExam={
                                            this.addQuestionsToExam
                                        }
                                        handleClose={
                                            this.toggleCreateQuestionDialog
                                        }
                                        orgData={this.props.orgData}
                                        history={this.props.history}
                                        handleBack={this.toggleCreateMenu}
                                        saveQuestions={this.props.saveQuestions}
                                    />
                                )}

                                <CreateEvent
                                    eventType="Session"
                                    source={this.state.dialogSource}
                                    data={this.state.dialogData}
                                    action={this.state.dialogAction}
                                    handleBack={this.toggleCreateMenu}
                                    open={this.state.isCreateEventOpen}
                                    handleClose={this.toggleEventDialog}
                                    groupsData={this.props.groups}
                                    addEvent={this.props.addEvent}
                                    updateEvent={this.props.updateEvent}
                                    orgData={this.props.orgData}
                                    userData={this.props.userData}
                                    isLoading={this.props.isLoading}
                                    message={this.props.message}
                                    staffMembersData={
                                        this.props.staffMembersData
                                    }
                                    fetchStaffMembers={
                                        this.props.fetchStaffMembers
                                    }
                                />

                                <CreateMenuDialog
                                    open={this.state.isCreateMenuOpen}
                                    handleClose={this.toggleCreateMenu}
                                    orgData={this.props.orgData}
                                    toggleDialogsWithType={
                                        this.toggleDialogsWithType
                                    }
                                />

                                {this.state.introDialogOpen && (
                                    <IntroDialog
                                        open={this.state.introDialogOpen}
                                        handleClose={() =>
                                            this.props.updateIntroDialog({})
                                        }
                                        introData={this.props.introDialogData}
                                        orgData={this.props.orgData}
                                    />
                                )}
                                {
                                    <LicenseUpgradeDialog
                                        open={this.state.licNotifDialogOpen}
                                        handleClose={this.toggleLicNotifDialog}
                                        history={this.props.history}
                                        orgData={this.props.orgData}
                                        type={this.state.dialogType}
                                    />
                                }
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <LinearProgress />
                        <FullscreenBranding message="Loading App Data" />
                    </>
                )}

                {this.state.showToast.type && this.renderToast()}

                <ToastContainer
                    position="top-right"
                    autoClose={AUTO_CLOSE_TOAST}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    toastStyle={{ zIndex: 999999 }}
                ></ToastContainer>
                {this.state.isInternetDialog && (
                    <InternetConnectionDialog
                        connection={!this.state.isDisconnected}
                        open={this.state.isInternetDialog}
                        handleClose={() =>
                            this.setState({ isInternetDialog: false })
                        }
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    console.log(state);
    return {
        message: state.appState.message,
        orgData: state.appState.orgData,
        studentsData: state.appState.studentsData,
        userData: state.appState.userData,
        staffMembersData: state.appState.staffMembersData,
        isAuthenticated: state.appState.isAuthenticated,
        isLoading: state.appState.isLoading,
        seriesData: state.examsState.seriesData,
        introDialogData: state.appState.introDialogData,
        groups: state.groupsState.groups,
        keys: state.appState.keys,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAuth: (bool, user) => dispatch(updateAuthentication(bool, user)),
        userLogout: () => dispatch(userLogout()),
        updateLoading: (bool) => dispatch(isLoading(bool)),
        updateMessage: (obj) => dispatch(updateMessage(obj)),
        validateOrg: (user, isLoggedIn, selectedOrgId) => (
            dispatch(isLoading(true)),
            dispatch(validateOrg(user, isLoggedIn, selectedOrgId))
        ),
        updateUserData: (user) => dispatch(updateUserData(user)),
        updateOrgData: (org) => dispatch(updateOrgData(org)),
        updateExamsData: (item) => dispatch(updateExamsData(item)),
        updateSeriesData: (item) => dispatch(updateSeriesData(item)),
        updateSeries: (item) => (
            dispatch(updateSeries(item)), dispatch(isLoading(true))
        ),
        updateExam: (item) => (
            dispatch(updateExam(item)), dispatch(isLoading(true))
        ),

        fetchSeries: (orgId, nextKey) => (
            dispatch(fetchSeriesData(orgId, nextKey)), dispatch(isLoading(true))
        ),
        saveExam: (params, source) => (
            dispatch(saveExam(params, source)), dispatch(isLoading(true))
        ),
        saveSeries: (params, source) => (
            dispatch(saveSeries(params, source)), dispatch(isLoading(true))
        ),
        saveQuestions: (params) => (
            dispatch(saveQuestions(params)), dispatch(isLoading(true))
        ),
        updateIntroDialog: (params) => dispatch(updateIntroDialog(params)),
        fetchStudents: (orgId) => dispatch(fetchStudents(orgId)),
        fetchGroups: (orgId) => dispatch(fetchGroups(orgId)),
        addGroup: (params, source) => (
            dispatch(addGroup(params, source)), dispatch(isLoading(true))
        ),
        updateGroup: (params) => dispatch(updateGroup(params)),
        switchOrgs: (user) => (
            dispatch(isLoading(true)), dispatch(switchOrgs(user))
        ),
        addEvent: (params) => dispatch(addEvent(params)),
        updateEvent: (params) => dispatch(updateEvent(params)),
        fetchStaffMembers: (orgId) => dispatch(fetchStaffMembers(orgId)),
        //updateGroupMembers: (params) => dispatch(updateGroupMembers(params)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
