import { actionConstants } from "../Utilities/Constants";

const defaultState = {
    groups: null,
};

const groupsStateReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actionConstants.ADD_NEW_GROUP:
            let g = state.groups
                ? [...state.groups, action.payload]
                : [action.payload];
            return {
                ...state,
                groups: g,
            };
        case actionConstants.FETCH_GROUPS_DATA:
            return {
                ...state,
                groups: action.payload,
            };
        case actionConstants.DELETE_GROUP:
            let gId = action.payload;
            let gData = [...state.groups];
            let indexToBeDeleted = gData.findIndex((g) => g.groupId === gId);
            if (indexToBeDeleted !== -1) {
                gData.splice(indexToBeDeleted, 1);
            }
            return {
                ...state,
                groups: gData,
            };
        case actionConstants.REGISTER_MEMBER:
            let groupps = state.groups ? [...state.groups] : null;
            if (groupps && action.groupId) {
                let groupToChange = groupps.find(
                    (g) => g.groupId === action.groupId
                );
                groupToChange.members.push(action.userId);
            }
            return {
                ...state,
                groups: groupps,
            };
        case actionConstants.DELETE_STUDENT:
            let groupss = state.groups ? [...state.groups] : null;
            if (groupss) {
                let index;
                let gg = groupss.find((g, i) => {
                    return g.members.includes(action.userId);
                });
                if (gg) {
                    gg.members.splice(
                        gg.members.findIndex((g) => g === action.userId),
                        1
                    );
                }
            }
            return {
                ...state,
                groups: groupss,
            };

        case actionConstants.UPDATE_GROUP_DATA:
            let ggId = action.payload.groupId;
            const { type, memAction, members, ...rest } = action.payload;
            let ggData = state.groups ? [...state.groups] : [];
            let indexToBeUpdated = ggData.findIndex((g) => g.groupId === ggId);
            if (type === "item") {
                if (indexToBeUpdated !== -1) {
                    //update the old data
                    ggData[indexToBeUpdated] = {
                        ...ggData[indexToBeUpdated],
                        ...rest,
                        members: [
                            ...ggData[indexToBeUpdated].members,
                            ...members,
                        ],
                    };
                } else {
                    //add new data
                    let newData = {
                        ...rest,
                        members: members,
                    };
                    ggData.push(newData);
                }
            } else if (type === "members") {
                if (indexToBeUpdated !== -1) {
                    //update the old data
                    if (memAction === "add") {
                        ggData[indexToBeUpdated] = {
                            ...ggData[indexToBeUpdated],
                            members: [
                                ...ggData[indexToBeUpdated].members,
                                ...members,
                            ],
                        };
                    } else if (memAction === "remove") {
                        ggData[indexToBeUpdated] = {
                            ...ggData[indexToBeUpdated],
                            members: ggData[indexToBeUpdated].members.filter(
                                (m) => !members.includes(m)
                            ),
                        };
                    } else if (memAction === "move") {
                        let newGroupIndex = ggData.findIndex(
                            (g) => g.groupId === action.payload.targetGroupId
                        );
                        ggData[indexToBeUpdated] = {
                            ...ggData[indexToBeUpdated],
                            members: ggData[indexToBeUpdated].members.filter(
                                (m) => !members.includes(m)
                            ),
                        };
                        ggData[newGroupIndex] = {
                            ...ggData[newGroupIndex],
                            members: [
                                ...ggData[newGroupIndex].members,
                                ...members,
                            ],
                        };
                    }
                }
            }

            return {
                ...state,
                groups: ggData,
            };
        case actionConstants.USER_LOGOUT:
            return {
                ...defaultState,
            };

        default:
            return state;
    }
};

export default groupsStateReducer;
