import React from "react";
import { lighten, withStyles } from "@material-ui/core/styles";
import {
    Dialog,
    DialogTitle,
    SvgIcon,
    Typography,
    IconButton,
} from "@material-ui/core";
import { colors, TAGS } from "../../utilities/constants";
import ButtonComp from "../UIComponents/ButtonComp";
import CloseIcon from "@material-ui/icons/Close";
import UpgradeArt2 from "../../assets/UpgradeArt2.svg";
import PuchaseButtonIcon from "../../assets/svgIcons/PuchaseButtonIcon";

const useStyles = (theme) => ({
    rightMargin: {
        marginRight: 20,
    },

    deleteIcon: {
        fontSize: 80,
    },
    content: {
        textAlign: "center",
        paddingBottom: theme.spacing(2),
        //fontFamily: `"DM Serif Display", serif !important`,
    },
});

const LicenseUpgradeDialog = (props) => {
    const { classes } = props;

    return (
        <Dialog
            //onClose={props.handleClose}
            aria-labelledby="upgrade the license"
            open={props.open}
            classes={{ root: "dialog", paper: "delete-dialog" }}
            BackdropProps={{
                style: { backgroundColor: "rgba(0,0,0,0.7)" },
            }}
            maxWidth="xs"
            fullWidth
        >
            <div className="paper-top-navigation">
                <IconButton onClick={props.handleClose} className="closeIcon">
                    <CloseIcon />
                </IconButton>
            </div>
            <img src={UpgradeArt2} alt="upgrade plan" width="300" />
            <DialogTitle
                id="create-new-dialog"
                className={"paper-title"}
                style={{ color: colors.DANGER }}
            >
                Upgrade the License
            </DialogTitle>

            <Typography
                variant="body1"
                component="p"
                className={classes.content}
            >
                {`The limit of ${
                    props.orgData.licRules[props.type]
                } for creating new ${
                    props.type
                } has already been exhausted under ${
                    props.orgData.license
                } license. Upgrade the plan now.`}
            </Typography>

            <div className={`controls ${classes.field}`}>
                {props.type !== "error" && (
                    <ButtonComp
                        variant="contained"
                        bckColor={colors.PRIMARY}
                        color="#ffffff"
                        fullWidth
                        onClick={() => {
                            props.handleClose();
                            props.history.push("/upgrade");
                        }}
                        // classes={{ root: classes.rightMargin }}
                        startIcon={<PuchaseButtonIcon />}
                    >
                        Upgrade Now
                    </ButtonComp>
                )}

                {/* <ButtonComp
                    variant="outlined"
                    color="#000000"
                    onClick={props.handleClose}
                >
                    Close
                </ButtonComp> */}
            </div>
        </Dialog>
    );
};

export default withStyles(useStyles)(LicenseUpgradeDialog);
