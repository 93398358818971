import React, { Component } from "react";
import { Link } from "react-router-dom";
import { lighten, withStyles } from "@material-ui/core/styles";
import {
    Dialog,
    DialogTitle,
    SvgIcon,
    Typography,
    IconButton,
    Fade,
} from "@material-ui/core";

import { toast } from "react-toastify";
import { colors, TAGS, IntroDialogContent } from "../../utilities/constants";
import ButtonComp from "../UIComponents/ButtonComp";
import offlineArt from "../../assets/OfflineArt.svg";
import onlineArt from "../../assets/OnlineArt.svg";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = (theme) => ({
    rightMargin: {
        marginRight: 20,
    },

    deleteIcon: {
        fontSize: 80,
    },
    content: {
        textAlign: "center",
        paddingBottom: theme.spacing(2),
    },
    buttonWrapper: {
        margin: "20px 0 0",
        position: "relative",
        textAlign: "center",
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -8,
        marginLeft: -12,
        textTransform: "none",
    },
});

class InternetConnectionDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidUpdate() {}

    goHome = (e) => {
        e.preventDefault();
        this.props.handleClose();
    };

    render() {
        const { props } = this;
        const { classes } = this.props;

        return (
            <Dialog
                //onClose={props.handleClose}
                aria-labelledby="create-new-dialog"
                open={props.open}
                classes={{ root: "dialog" }}
                BackdropProps={{
                    style: { backgroundColor: "rgba(0,0,0,0.7)" },
                }}
                maxWidth="sm"
                fullWidth
                transition={Fade}
                transitionDuration={400}
            >
                <div className="paper-top-navigation">
                    <IconButton
                        onClick={props.handleClose}
                        className="closeIcon"
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <img
                    style={{ maxWidth: 200, margin: "0 auto" }}
                    src={props.connection ? onlineArt : offlineArt}
                />
                <Typography
                    variant="h2"
                    component="h2"
                    id="create-new-dialog"
                    className={`paper-title`}
                    style={{
                        color: props.connection ? colors.GREEN : colors.DANGER,
                        fontSize: "1.5rem",
                    }}
                >
                    {props.connection ? "Back online" : "Offline"}
                </Typography>

                <Typography
                    variant="body1"
                    component="p"
                    className={classes.content}
                >
                    {props.connection
                        ? "The connection has been restored."
                        : "You are offline. Check your connection and try again."}
                </Typography>
                {props.connection && (
                    <ButtonComp
                        tvariant="contained"
                        bckColor={colors.PRIMARY}
                        color="#ffffff"
                        fullWidth
                        onClick={props.handleClose}
                        style={{ textTransform: "none" }}
                    >
                        Continue
                    </ButtonComp>
                )}
            </Dialog>
        );
    }
}

export default withStyles(useStyles)(InternetConnectionDialog);
