import React from "react";
import moment from "moment";
import { TableRow, TableCell } from "@material-ui/core";

const ResultRow = (props) => {
    const { classes, data } = props;
    return (
        <TableRow className="resultRow listRow">
            <TableCell component="th" scope="row">
                {data.title}
            </TableCell>
            <TableCell align="right">{data.totalMarks}</TableCell>
            <TableCell align="right">{data.marksObtained}</TableCell>
            <TableCell align="right">
                {moment.unix(data.timestamp).format("MMMM DD, YYYY")}
            </TableCell>
        </TableRow>
    );
};

export default ResultRow;
