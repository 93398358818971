import React, { Component } from "react";
import { toast } from "react-toastify";
import { lighten, withStyles } from "@material-ui/core/styles";
import {
    Dialog,
    DialogTitle,
    MenuItem,
    InputAdornment,
    TextField,
    Divider,
    ClickAwayListener,
    IconButton,
    Drawer,
    Avatar,
    Chip,
    Slide,
    FormControl,
    FormLabel,
    RadioGroup,
    FormHelperText,
    Input,
    InputLabel,
    FormGroup,
    FormControlLabel,
    endAdornment,
} from "@material-ui/core";
import SearchSelector from "../UIComponents/SearchSelector";
import { colors, TAGS } from "../../utilities/constants";
import { v5 as uuidv5 } from "uuid";
import password from "secure-random-password";
import ButtonComp from "../UIComponents/ButtonComp";
import GreenCheckbox from "../UIComponents/GreenCheckbox";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import CloseIcon from "@material-ui/icons/Close";
import FaceIcon from "@material-ui/icons/Face";
import GreenRadio from "../UIComponents/GreenRadio";
import MainServices from "../../services/main.services";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import TooltipComp from "../UIComponents/TooltipComp";
import { copyToClipBoard } from "../../utilities/helper";
import ChipComp from "../UIComponents/ChipComp";

const MY_NAMESPACE = "8492526a-ae71-5a00-ad9d-cd40cf6f9bfa";

const useStyles = (theme) => ({
    drawer: {
        padding: "30px",
        maxWidth: 500,
    },
    field: {
        margin: "8px 0px",
        color: colors.PRIMARY,
    },
    rightMargin: {
        marginRight: 20,
    },
    seriesSelectBox: {
        margin: "10px 0 15px",
    },
});

const initialState = {
    title: "",
    description: "",
    tags: [],
    members: [],
    errors: {
        titleError: "",
        membersError: "",
    },
};

class CreateGroup extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.MainServices = new MainServices();
    }

    static getDerivedStateFromProps(props, state) {
        if (!props.open) {
            return initialState;
        }
        let newState = {};
        if (props.data && props.action === "edit") {
            if (!state.title) {
                const { title, description, tags } = props.data;
                newState = {
                    title,
                    description,
                    tags,
                };
            }
        }
        return {
            ...state,
            ...newState,
        };
    }

    handleChange = (e, value, type) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        if (this.state.errors.titleError.length) {
            this.setState({
                errors: initialState.errors,
            });
        }

        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleMembersChange = (e, value) => {
        if (this.state.errors.membersError.length > 0) {
            this.setState({
                errors: initialState.errors,
            });
        }

        if (
            value[value.length - 1] &&
            value[value.length - 1].mygroup &&
            value[value.length - 1].mygroup.length > 0
        ) {
            let currentGroup;
            if (this.props.groupsData) {
                currentGroup = this.props.groupsData.find(
                    (g) => g.groupId === value[value.length - 1].mygroup
                );
            }
            this.setState({
                errors: {
                    ...this.state.errors,
                    membersError: `${value[value.length - 1].name} ${
                        value[value.length - 1].family_name
                    } is already registered with ${
                        currentGroup && currentGroup.title
                            ? currentGroup.title
                            : "another"
                    } Group`,
                },
            });
        } else {
            if (value.length > 20) {
                this.setState({
                    errors: {
                        ...this.state.errors,
                        membersError:
                            "Only 20 members are allowed in one time. Add them and can always start again adding.",
                    },
                });
            } else {
                this.setState({
                    members: value,
                });
            }
        }
    };

    handleTagChange = (e, value) => {
        this.setState({
            tags: value,
        });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const { title, description, tags, members } = this.state;
        if (this.props.action === "edit") {
            //handle type edit
            try {
                let params = {
                    type: "item",
                    orgId: this.props.orgData.orgId,
                    groupId: this.props.data.groupId,
                    members: members.map((m) => m.userId),
                    tags,
                    title,
                    description,
                };
                this.props.updateGroup(params);
                this.props.handleClose();
            } catch (e) {
                this.props.handleClose();
            }
        } else {
            // add item
            // if (
            //     this.props.groupsData &&
            //     this.props.groupsData.findIndex(
            //         (g) => g.groupId === uuidv5(title, MY_NAMESPACE)
            //     ) === -1
            // ) {
            try {
                let params = {
                    title,
                    orgId: this.props.orgData.orgId,
                    description,
                    tags,
                    members: members.map((m) => m.userId),
                };
                await this.props.addGroup(params, this.props.source);
                this.props.handleClose();
            } catch (e) {
                toast.error(e.message);
                this.props.handleClose();
            }
            // } else {
            //     this.setState({
            //         errors: {
            //             ...this.state.errors,
            //             titleError: `${title} group already exists.`,
            //         },
            //     });
            // }
        }
    };

    onClose = (e) => {
        e.persist();
        this.props.handleClose(e);
    };

    render() {
        const { classes, data, studentsData } = this.props;
        let filteredStudents;
        if (studentsData) {
            if (data && data.members) {
                filteredStudents = data
                    ? studentsData.filter(
                          (s) => !data.members.includes(s.userId)
                      )
                    : studentsData;
            } else {
                filteredStudents = studentsData;
            }
        }

        return (
            <Drawer
                anchor="right"
                open={this.props.open}
                onClose={this.onClose}
                transitionDuration={200}
                elevation={2}
                BackdropProps={{
                    style: { backgroundColor: "rgba(0,0,0,0.7)" },
                }}
                classes={{
                    root: "addEditExam",
                    paper: classes.drawer,
                }}
            >
                <div className="paper-top-navigation">
                    {this.props.source === "topMenu" && (
                        <IconButton onClick={this.props.handleBack}>
                            <KeyboardBackspaceIcon />
                        </IconButton>
                    )}

                    <IconButton onClick={this.onClose} className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                </div>
                <DialogTitle id="create-new-dialog" className={"paper-title"}>
                    {this.props.action === "edit" ? "Edit " : "Create New "}
                    Group
                </DialogTitle>
                <form onSubmit={this.handleSubmit}>
                    <TextField
                        error={this.state.errors.titleError.length > 0}
                        id="title"
                        label="Group Name"
                        name="title"
                        value={this.state.title}
                        required
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        className={`${classes.field}`}
                        helperText="Example: Class 10, SSC 2020 Batch1, IBPS 2020, etc."
                        onChange={this.handleChange}
                        helperText={this.state.errors.titleError}
                    />
                    <TextField
                        id="description"
                        name="description"
                        label="Description"
                        multiline
                        rows={2}
                        value={this.state.description}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        className={`${classes.field}`}
                        helperText="Small description to describe the group. This is optional."
                        onChange={this.handleChange}
                    />

                    <Divider variant="middle" className={"divider"} />
                    <div className="rowWrapper">
                        <SearchSelector
                            id="tags"
                            name="tags"
                            freeSolo={true}
                            freeTags
                            multiple={true}
                            data={TAGS}
                            loading={TAGS.length > 0 ? false : true}
                            variant="outlined"
                            style={{ width: "100%" }}
                            size="small"
                            onChange={this.handleTagChange}
                            value={this.state.tags}
                            label="Add Tags"
                        />
                    </div>

                    <SearchSelector
                        error={this.state.errors.membersError.length > 0}
                        id="members"
                        name="members"
                        fullWidth
                        multiple
                        noOptionsText="No user found."
                        data={filteredStudents ? filteredStudents : []}
                        loading={filteredStudents ? false : true}
                        variant="outlined"
                        margin="dense"
                        onChange={this.handleMembersChange}
                        value={this.state.members}
                        label="Add Members"
                        ChipProps={{
                            icon: <FaceIcon />,
                        }}
                        helperText={this.state.errors.membersError}
                    />

                    {/* <section>
                        <div className="sectionTitle">Permissions:</div>
                        <FormGroup row>
                            <FormControlLabel
                                onChange={this.handleChange}
                                name="showResultOnCompletion"
                                control={
                                    <GreenCheckbox
                                        checked={
                                            this.state.showResultOnCompletion
                                        }
                                    />
                                }
                                label="Show result on exam completion"
                            />
                            <FormControlLabel
                                onChange={this.handleChange}
                                name="emailResult"
                                control={
                                    <GreenCheckbox
                                        checked={this.state.emailResult}
                                    />
                                }
                                label="Email result to student"
                            />
                        </FormGroup>
                    </section> */}
                    <div className={`controls ${classes.field}`}>
                        <ButtonComp
                            variant="contained"
                            bckColor={colors.PRIMARY}
                            color="#ffffff"
                            type="submit"
                            classes={{ root: classes.rightMargin }}
                        >
                            {this.props.action === "add" ? "Save" : "Update"}
                        </ButtonComp>
                        <ButtonComp
                            variant="outlined"
                            color="#000000"
                            onClick={this.onClose}
                        >
                            Close
                        </ButtonComp>
                    </div>
                </form>
            </Drawer>
        );
    }
}

export default withStyles(useStyles)(CreateGroup);
