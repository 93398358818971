const awsSettings = {
    DEV: {
        apiGateway: [
            {
                name: "manageExams",
                endpoint:
                    "https://p98tj1keib.execute-api.ap-south-1.amazonaws.com/dev",
                region: "ap-south-1",
            },
            {
                name: "manageOrg",
                endpoint:
                    "https://p98tj1keib.execute-api.ap-south-1.amazonaws.com/dev",
                region: "ap-south-1",
            },
            {
                name: "manageStudents",
                endpoint:
                    "https://p98tj1keib.execute-api.ap-south-1.amazonaws.com/dev",
                region: "ap-south-1",
            },
        ],
        cognito: {
            region: "ap-south-1",
            userPoolId: "ap-south-1_Ri1yXksQx",
            userPoolWebClientId: "6h7oo25a08pcvskm4rdik6obtv",
            identityPoolId: "ap-south-1:5a5207f8-f015-4027-b4ab-2bac9627cbcf",
        },
    },
    DEV2: {
        apiGateway: [
            {
                name: "manageExams",
                endpoint:
                    "https://fnlkipqpc3.execute-api.ap-south-1.amazonaws.com/dev2",
                region: "ap-south-1",
            },
            {
                name: "manageOrg",
                endpoint:
                    "https://fnlkipqpc3.execute-api.ap-south-1.amazonaws.com/dev2",
                region: "ap-south-1",
            },
            {
                name: "manageStudents",
                endpoint:
                    "https://fnlkipqpc3.execute-api.ap-south-1.amazonaws.com/dev2",
                region: "ap-south-1",
            },
        ],
        cognito: {
            region: "us-east-2",
            userPoolId: "us-east-2_sIyoEQKYk",
            userPoolWebClientId: "7rd5b3be3dbu144lss3jp9md41",
            identityPoolId: "ap-south-1:5a5207f8-f015-4027-b4ab-2bac9627cbcf",
        },
    },
    PROD: {
        apiGateway: [
            {
                name: "manageExams",
                endpoint:
                    "https://hojhyf8t11.execute-api.ap-south-1.amazonaws.com/prod",
                region: "ap-south-1",
            },
            {
                name: "manageOrg",
                endpoint:
                    "https://hojhyf8t11.execute-api.ap-south-1.amazonaws.com/prod",
                region: "ap-south-1",
            },
            {
                name: "manageStudents",
                endpoint:
                    "https://hojhyf8t11.execute-api.ap-south-1.amazonaws.com/prod",
                region: "ap-south-1",
            },
        ],
        cognito: {
            region: "ap-south-1",
            userPoolId: "ap-south-1_Ri1yXksQx",
            userPoolWebClientId: "6h7oo25a08pcvskm4rdik6obtv",
            identityPoolId: "ap-south-1:5a5207f8-f015-4027-b4ab-2bac9627cbcf", //not correct
        },
    },
};

// console.log(process.env.REACT_APP_ENV);
//export const AppEnv = process.env.REACT_APP_ENV;
const awsConfig = awsSettings[process.env.REACT_APP_ENV];
// const awsConfig = awsSettings["DEV"];

export default awsConfig;
