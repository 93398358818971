import React from "react";
import { lighten, withStyles } from "@material-ui/core/styles";
import {
    Dialog,
    DialogTitle,
    List,
    IconButton,
    ListItemText,
    ListItemAvatar,
    Avatar,
    ClickAwayListener,
} from "@material-ui/core";
import ListItemCard from "../UIComponents/ListItemCard";
import CloseIcon from "@material-ui/icons/Close";
import { colors, CREATE_NEW } from "../../utilities/constants";

const useStyles = (theme) => ({
    avatar: {
        backgroundColor: lighten(colors.PRIMARY, 0.8),
        color: colors.PRIMARY,
    },
});

const CreateMenuDialog = (props) => {
    const { classes } = props;
    const CREATE_NEW_FILTERED = CREATE_NEW.filter((menu) => {
        if (props.orgData.config.groupsEnabled) {
            return true;
        } else {
            // Hide Groups Menu Item
            if (menu.id === "cn-group") {
                return false;
            }
            return true;
        }
    });
    return (
        <ClickAwayListener onClickAway={props.handleClose}>
            <Dialog
                onClose={props.handleClose}
                aria-labelledby="create-new-dialog"
                open={props.open}
                classes={{ root: "dialog" }}
                BackdropProps={{
                    style: { backgroundColor: "rgba(0,0,0,0.7)" },
                }}
                maxWidth="xl"
            >
                <div className="paper-top-navigation">
                    <IconButton
                        onClick={props.handleClose}
                        className="closeIcon"
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <DialogTitle id="create-new-dialog" className={"paper-title"}>
                    CREATE
                </DialogTitle>

                <List classes={{ root: "dialog__list" }}>
                    {CREATE_NEW_FILTERED.map((obj) => (
                        <ListItemCard
                            key={obj.id}
                            data={obj}
                            showCover={true}
                            wrapperClass="dialog__card"
                            onClick={props.toggleDialogsWithType}
                        />
                    ))}
                </List>
            </Dialog>
        </ClickAwayListener>
    );
};

export default withStyles(useStyles)(CreateMenuDialog);
