import React, { Component } from "react";
import { toast } from "react-toastify";
import { lighten, withStyles } from "@material-ui/core/styles";
import {
    Dialog,
    DialogTitle,
    MenuItem,
    InputAdornment,
    TextField,
    Divider,
    ClickAwayListener,
    IconButton,
    Drawer,
} from "@material-ui/core";
import SearchSelector from "../UIComponents/SearchSelector";
import { colors, TAGS, RolesData } from "../../utilities/constants";
import password from "secure-random-password";
import ButtonComp from "../UIComponents/ButtonComp";
import CloseIcon from "@material-ui/icons/Close";
import MainServices from "../../services/main.services";
import MuiPhoneNumber from "material-ui-phone-number";
import { isValidEmail, isValidPhoneNumber } from "../../utilities/helper";
import CircularLoader from "../UIComponents/CircularLoader";

const useStyles = (theme) => ({
    drawer: {
        padding: "30px",
        maxWidth: 500,
    },
    field: {
        margin: "8px 0px",
        color: colors.PRIMARY,
    },
    rightMargin: {
        marginRight: 20,
    },
    buttonWrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -20,
    },
});

const initialState = {
    fName: "",
    lName: "",
    email: "",
    phone: "",
    loading: false,
    errors: {
        fNamer: false,
        lName: false,
        email: false,
        phone: false,
    },
};

class RegisterMember extends Component {
    constructor(props) {
        super(props);
        this.state = { ...initialState };
        this.MainServices = new MainServices();
    }

    static getDerivedStateFromProps(props, state) {
        if (!props.open) {
            return initialState;
        }
        let newState = {};
        if (props.data && props.action === "edit") {
            if (!state.title) {
                const { title, description, tags } = props.data;
                newState = {
                    title,
                    description,
                    tags,
                };
            }
        }
        return {
            ...state,
            ...newState,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.open) {
            if (
                !prevProps.message.type &&
                this.props.message.type == "SUCCESS"
            ) {
                this.props.handleClose();
            } else if (
                !prevProps.message.type &&
                this.props.message.type == "ERROR"
            ) {
                this.setState({
                    loading: false,
                });
            }
        }
    }

    handleChange = (e, value, type) => {
        if (e) {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
        }

        this.setState({
            errors: { ...initialState.errors },
        });

        if (type) {
            this.setState({
                [type]: value,
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value.trim(),
            });
        }
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const { fName, lName, email, phone, group, role } = this.state;
        this.setState({
            loading: true,
        });

        let params = {
            fName,
            lName,
            email,
            phone,
            orgId: this.props.orgData.orgId,
        };
        if (this.props.isUser) {
            params.rolePermissions = [role.code];
        } else {
            if (group && group.groupId) {
                params.mygroup = group.groupId;
            }
        }

        this.props.registerMember(params);
    };

    handleValidateField = (event) => {
        const { errors } = this.state;
        const target = event.target;
        // catch delete and backspace
        let KeyID = event.keyCode;
        if (
            (KeyID === 8 || KeyID === 46) &&
            this.state.email === "" &&
            target.name === "email"
        ) {
            this.setState({
                errors: {
                    email: false,
                },
            });
        } else if (
            (KeyID === 8 || KeyID === 46) &&
            this.state.password === "" &&
            target.name === "password"
        ) {
            this.setState({
                errors: {
                    password: false,
                },
            });
        } else if (
            (KeyID === 8 || KeyID === 46) &&
            this.state.phone === "" &&
            target.name === "phone"
        ) {
            this.setState({
                errors: {
                    phone: false,
                },
            });
        } else {
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout((event) => {
                let err = {};
                if (target.name === "email") {
                    err.email = isValidEmail(target.value.trim())
                        ? false
                        : true;
                } else if (target.name === "phone") {
                    err.phone = isValidPhoneNumber(target.value.trim()).valid
                        ? false
                        : true;
                } else {
                    err.password =
                        this.state.validPassword === 4 ? false : true;
                }
                this.setState({
                    errors: {
                        ...this.state.error,
                        ...err,
                    },
                });
            }, 800);
        }
    };

    onClose = (e) => {
        e.persist();
        this.props.handleClose(e);
    };

    render() {
        const { classes, groupsData } = this.props;

        return (
            <Drawer
                anchor="right"
                open={this.props.open}
                onClose={this.onClose}
                transitionDuration={200}
                elevation={2}
                BackdropProps={{
                    style: { backgroundColor: "rgba(0,0,0,0.7)" },
                }}
                classes={{
                    root: "addEditExam",
                    paper: classes.drawer,
                }}
            >
                <div className="paper-top-navigation">
                    {/* {this.props.action === "add" && (
                        <IconButton onClick={this.props.handleBack}>
                            <KeyboardBackspaceIcon />
                        </IconButton>
                    )} */}

                    <IconButton onClick={this.onClose} className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                </div>
                <DialogTitle id="create-new-dialog" className={"paper-title"}>
                    {this.props.action === "edit" ? "Edit " : "Register New "}
                    {this.props.isUser ? "Staff Member" : "Student"}
                </DialogTitle>
                <form onSubmit={this.handleSubmit}>
                    <TextField
                        error={this.state.errors.fName}
                        id="fName"
                        label="First Name"
                        name="fName"
                        value={this.state.fName}
                        required
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        className={`${classes.field}`}
                        onChange={this.handleChange}
                        // helperText={this.state.errors.titleError}
                    />
                    <TextField
                        error={this.state.errors.lName}
                        id="lName"
                        label="Last Name"
                        name="lName"
                        value={this.state.lName}
                        //required
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        className={`${classes.field}`}
                        onChange={this.handleChange}
                        // helperText={this.state.errors.titleError}
                    />
                    <TextField
                        error={this.state.errors.email}
                        variant="outlined"
                        margin="dense"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        onChange={this.handleChange}
                        onKeyUp={this.handleValidateField}
                    />
                    <MuiPhoneNumber
                        error={this.state.errors.phone}
                        variant="outlined"
                        required
                        margin="dense"
                        name="phone"
                        label="Mobile Number"
                        fullWidth
                        defaultCountry="in"
                        onlyCountries={["in"]}
                        value={this.state.phone}
                        onChange={(val) =>
                            this.handleChange(null, val, "phone")
                        }
                        onKeyDown={this.handleValidateField}
                        autoFormat={false}
                        countryCodeEditable={false}
                    />
                    {this.props.isUser ? (
                        <SearchSelector
                            id="role"
                            name="role"
                            required
                            data={RolesData}
                            loading={RolesData ? false : true}
                            variant="outlined"
                            margin="dense"
                            onChange={(e, val) =>
                                this.handleChange(e, val, "role")
                            }
                            //value={this.state.group}
                            label="Select Role/Permission"
                            helperText="Roles are used to provide necessary permissions. "
                        />
                    ) : (
                        <SearchSelector
                            id="group"
                            name="group"
                            //required
                            data={groupsData ? groupsData : []}
                            loading={groupsData ? false : true}
                            variant="outlined"
                            margin="dense"
                            onChange={(e, val) =>
                                this.handleChange(e, val, "group")
                            }
                            //value={this.state.group}
                            label="Select Group"
                            helperText="(optional)Add the member to the group. "
                        />
                    )}

                    <div className={`controls ${classes.field}`}>
                        <div className={classes.buttonWrapper}>
                            <ButtonComp
                                variant="contained"
                                bckColor={colors.PRIMARY}
                                color="#ffffff"
                                type="submit"
                                classes={{ root: classes.rightMargin }}
                                disabled={this.state.loading ? true : false}
                            >
                                {this.props.action === "edit"
                                    ? "Update"
                                    : "Register"}
                            </ButtonComp>
                            {this.state.loading && (
                                <CircularLoader
                                    size={24}
                                    className={classes.buttonProgress}
                                    color={colors.PRIMARY}
                                />
                            )}
                        </div>
                        <ButtonComp
                            variant="outlined"
                            color="#000000"
                            onClick={this.onClose}
                        >
                            Close
                        </ButtonComp>
                    </div>
                </form>
            </Drawer>
        );
    }
}

export default withStyles(useStyles)(RegisterMember);
