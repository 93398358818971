import React from "react";
import { makeStyles, lighten } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { colors } from "../../utilities/Constants";
import logo from "../../assets/logo-2x.png";

const FullscreenBranding = (props) => {
    return (
        <div className="fullscreen-branding">
            {logo ? (
                <img src={logo} alt="logo" className="fullscreen-logo" />
            ) : (
                <Typography variant="h3" component="h3">
                    Gradout
                </Typography>
            )}

            {props.message && (
                <Typography
                    variant="body2"
                    component="p"
                    style={{
                        textAlign: "center",
                        color: colors.BODY2,
                        marginTop: 15,
                        fontSize: "1.2em",
                    }}
                >
                    {props.message}
                </Typography>
            )}
        </div>
    );
};

export default FullscreenBranding;
