import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import LinearLoader from "../components/UIComponents/LinearLoader";
import { Box, Typography } from "@material-ui/core/";
import { colors } from "./constants";
export const STUDENT_TBL_COL = [
    {
        title: "Name",
        field: "name",
        type: "string",
        defaultSort: "asc",
        render: (rowData) =>
            rowData.fullName ? (
                <Link to={`/profile/${rowData.userId}`} className="retroLink">
                    {rowData.fullName}
                </Link>
            ) : (
                <Link
                    to={`/profile/${rowData.userId}`}
                    className="retroLink"
                >{`${rowData.name} ${rowData.family_name}`}</Link>
            ),
    },
    {
        title: "Mobile",
        field: "mobile",
        type: "numeric",
    },
    {
        title: "Email",
        field: "email",
        type: "string",
    },
    {
        title: "Exams Attempted",
        field: "attemptedExams",
        type: "numeric",
        render: (rowData) => rowData.attemptedExams.length,
    },
];

export const STUDENT_RESULTS_TBL_COL = [
    {
        title: "Exam Name",
        field: "title",
        type: "string",
        defaultSort: "asc",
    },
    {
        title: "Total Marks",
        field: "totalMarks",
        type: "numeric",
        width: "15%",
    },
    {
        title: "Marks Obtained",
        field: "marksObtained",
        type: "numeric",
        width: "15%",
    },
    {
        title: "Attempted on",
        field: "timestamp",
        type: "string",
        width: "15%",
        //alight: "right",
        render: (rowData) =>
            `${moment.unix(rowData.createdAt).format("MMMM DD, YYYY")}`,
    },
    {
        title: "Percentage",
        field: "percentage",
        type: "numeric",
        width: "30%",
        customSort: (a, b) => {
            return (
                Number(
                    ((a.marksObtained / Number(a.totalMarks)) * 100).toFixed(2)
                ) -
                Number(
                    ((b.marksObtained / Number(b.totalMarks)) * 100).toFixed(2)
                )
            );
        },
        render: (rowData) => {
            let value = (
                (rowData.marksObtained / Number(rowData.totalMarks)) *
                100
            ).toFixed(0);
            return (
                <Box
                    width={`90%`}
                    display="flex"
                    alignItems="center"
                    //justifyContent="flex-end"
                    marginLeft="auto"
                    //marginRight={5}
                >
                    <Box width="100%" mr={1}>
                        <LinearLoader
                            variant="determinate"
                            borderRadius={5}
                            size={10}
                            value={Number(value)}
                            color={
                                value > 60.0
                                    ? colors.GREEN
                                    : value < 33.33
                                    ? colors.DANGER
                                    : colors.WARNING
                            }
                        />
                    </Box>

                    <Box minWidth={35} textAlign="left">
                        <Typography
                            variant="body2"
                            color="textSecondary"
                        >{`${value}%`}</Typography>
                    </Box>
                </Box>
            );
        },
    },
];

export const STUDENT_PERF_TBL_COL = [
    {
        title: "Name",
        field: "fullName",
        type: "string",
        defaultSort: "asc",
        align: "left",
        render: (rowData) =>
            rowData.fullName ? (
                <Link to={`/profile/${rowData.userId}`} className="retroLink">
                    {rowData.fullName}
                </Link>
            ) : (
                <Link
                    to={`/profile/${rowData.userId}`}
                    className="retroLink"
                >{`${rowData.name} ${rowData.family_name}`}</Link>
            ),
    },
    {
        title: "Exams",
        field: "attemptedExams",
        type: "numeric",
        align: "left",
        width: "10%",
        render: (rowData) => rowData.exams.length,
    },
    {
        title: "Avg. Percentage",
        field: "percentage",
        type: "numeric",
        width: "50%",
        customSort: (a, b) => {
            return (
                Number(
                    ((a.marksObtained / Number(a.totalMarks)) * 100).toFixed(2)
                ) -
                Number(
                    ((b.marksObtained / Number(b.totalMarks)) * 100).toFixed(2)
                )
            );
        },
        // render: (rowData) =>
        //     `${(
        //         (rowData.marksObtained / Number(rowData.totalMarks)) *
        //         100
        //     ).toFixed(2)}%`,
        render: (rowData) => {
            let value = (
                (rowData.marksObtained / Number(rowData.totalMarks)) *
                100
            ).toFixed(0);
            return (
                <Box
                    width={`90%`}
                    display="flex"
                    alignItems="center"
                    //justifyContent="flex-end"
                    marginLeft="auto"
                >
                    <Box width="100%" mr={1}>
                        <LinearLoader
                            variant="determinate"
                            borderRadius={5}
                            size={10}
                            value={Number(value)}
                            color={
                                value > 60.0
                                    ? colors.GREEN
                                    : value < 33.33
                                    ? colors.DANGER
                                    : colors.WARNING
                            }
                        />
                    </Box>

                    <Box minWidth={35} textAlign="left">
                        <Typography
                            variant="body2"
                            color="textSecondary"
                        >{`${value}%`}</Typography>
                    </Box>
                </Box>
            );
        },
    },
];

export const STAFF_MEMBERS_TBL_COL = [
    {
        title: "Name",
        field: "fullName",
        type: "string",
        defaultSort: "asc",
        align: "left",
        render: (rowData) =>
            rowData.fullName ? (
                <Link to={`/profile/${rowData.userId}`} className="retroLink">
                    {rowData.fullName}
                </Link>
            ) : (
                <Link
                    to={`/profile/${rowData.userId}`}
                    className="retroLink"
                >{`${rowData.name} ${rowData.family_name}`}</Link>
            ),
    },
    {
        title: "Roles",
        field: "rolePermissions",
        type: "string",
        render: (rowData) => rowData.rolePermissions.join(", "),
    },
    {
        title: "Since",
        field: "timestamp",
        type: "string",
        width: "15%",
        render: (rowData) =>
            `${moment.unix(rowData.createdAt).format("MMMM DD, YYYY")}`,
    },
    {
        title: "Mobile",
        field: "phone_number",
        type: "numeric",
    },
    {
        title: "Email",
        field: "email",
        type: "string",
    },
    {},
];
