import HttpClient from "./HttpClient";

class OrgServices {
    constructor() {
        this.HttpClient = new HttpClient();
    }

    getOrgData(params = {}) {
        return this.HttpClient.get("manageOrg", "/org", params);
    }

    saveOrgData(params = {}) {
        return this.HttpClient.post("manageOrg", "/org", params);
    }

    updateOrgData(params = {}, type) {
        let path = `/org`;
        if (type) {
            path = `${path}?type=${type}`;
        } else {
            path = `${path}?type=info`;
        }
        return this.HttpClient.put("manageOrg", path, params);
    }

    updateBranding(params = {}) {
        const { type, action, ...newParams } = params;
        let path = `/branding?type=${params.type}&action=${action}`;
        return this.HttpClient.put("manageOrg", path, newParams);
    }

    updateDashboard(params = {}) {
        return this.HttpClient.post("manageOrg", "/dashboard", params);
    }

    verifyOrg(params = {}) {
        let path = `/verifyOrg?orgId=${params.orgId}`;
        return this.HttpClient.get("manageOrg", path);
    }

    getUsers(params = {}) {
        const { orgId, term = "", ...nParams } = params;
        let path = `/users?orgId=${orgId}&term=${term}`;
        return this.HttpClient.get("manageOrg", path, nParams);
    }

    getMembers(params = {}) {
        const { orgId, term = "", ...nParams } = params;
        let path = `/members?orgId=${orgId}&term=${term}`;
        return this.HttpClient.get("manageStudents", path, nParams);
    }

    getMember(params = {}) {
        const { orgId, userId } = params;
        let path = `/member?orgId=${orgId}&userId=${userId}`;
        return this.HttpClient.get("manageStudents", path, {});
    }

    deleteMember(params = {}) {
        const { userId, username, orgId } = params;
        let path = `/member?userId=${userId}&username=${username}&orgId=${orgId}`;
        return this.HttpClient.delete("manageStudents", path, {});
    }

    deleteUser(params = {}) {
        const { userId, username, orgId } = params;
        let path = `/user?userId=${userId}&username=${username}&orgId=${orgId}`;
        return this.HttpClient.delete("manageOrg", path, {});
    }

    addMember(params = {}) {
        return this.HttpClient.post("manageStudents", "/member", params);
    }
    //add staff user
    addUser(params = {}) {
        return this.HttpClient.post("manageOrg", "/user", params);
    }

    updateUser(params = {}) {
        const { type, action, name, ...newParams } = params;
        let path = `/user?type=${params.type}`;
        return this.HttpClient.put("manageOrg", path, newParams);
    }

    getStudentResults(params = {}) {
        const { userId, type, year, period } = params;
        let path = `/results?userId=${userId}`;
        if (type) {
            path = `${path}&type=${type}`;
            if (year) {
                path = `${path}&id=${
                    type === "month" ? `${year}-${period}` : `${year}`
                }`;
            }
        }
        return this.HttpClient.get("manageStudents", path, {});
    }

    getPreSignedUrl(params = {}) {
        let path;
        if (params.fileName && params.fileType) {
            path = `/presignedurl?fileName=${params.fileName}&fileType=${params.fileType}&dir=${params.dir}`;
        } else {
            return Promise.reject(new Error("Invalid Parameters passed!"));
        }
        return this.HttpClient.get("manageOrg", path, params);
    }

    getPlans(params = {}) {
        return this.HttpClient.get("manageOrg", "/plans", params);
    }

    getOrder(params = {}) {
        let path = `/order?id=${params.id}&period=${params.period}&count=${params.count}&mems=${params.mems}`;
        return this.HttpClient.get("manageOrg", path, params);
    }
    capturePayment(params = {}) {
        // let path = `/payment/${params.razorpay_payment_id}`;
        return this.HttpClient.post("manageOrg", "/verifyPay", params);
    }
    verifyIFSC(params = {}) {
        let path = `/verifyifsc?ifsc=${params.ifsc}`;
        return this.HttpClient.get("manageOrg", path, params);
    }

    getBankData(params = {}) {
        let path = `/bank?orgId=${params.orgId}`;
        return this.HttpClient.get("manageOrg", path, params);
    }

    updateBankData(params = {}) {
        return this.HttpClient.post("manageOrg", "/bank", params);
    }

    getMemberTranx(params = {}) {
        let path = `/transactions?orgId=${params.orgId}`;
        return this.HttpClient.get("manageOrg", path, params);
    }
    getLastPayout(params = {}) {
        let path = `/lastPayout?orgId=${params.orgId}`;
        return this.HttpClient.get("manageOrg", path, params);
    }
    addEvent(params = {}) {
        return this.HttpClient.post("manageOrg", "/event", params);
    }

    fetchEvents(params = {}) {
        const { orgId } = params;
        let path;
        if (orgId) {
            path = `/events?orgId=${orgId}`;
        } else {
            return Promise.reject(new Error("Invalid Parameters passed!"));
        }
        return this.HttpClient.get("manageOrg", path, params);
    }

    updateEvent(params = {}) {
        const { type, action, ...newParams } = params;
        let path;
        if (type) {
            path = `/event?type=${type}`;
            if (action) {
                path += `&action=${action}`;
            }
        } else {
            return Promise.reject(new Error("Invalid Parameters passed!"));
        }
        return this.HttpClient.put("manageOrg", path, newParams);
    }
}

export default OrgServices;
