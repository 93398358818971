import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { lighten, withStyles } from "@material-ui/core/styles";
import {
    Dialog,
    Slide,
    Container,
    ClickAwayListener,
    IconButton,
    AppBar,
    Typography,
    Toolbar,
} from "@material-ui/core";
import SearchSelector from "../UIComponents/SearchSelector";
import { colors, TAGS } from "../../utilities/constants";
import ButtonComp from "../UIComponents/ButtonComp";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import QCard from "../UIComponents/QCard";
import FloatingActionButton from "../UIComponents/FloatingActionButton";
import Repeater from "../../hoc/Repeater";
import {
    saveQDataToLS,
    hyderateQDataFromLS,
    getCurrentAuthenticatedUserDetails,
    sanitizeHTML,
    formatQAnswerData,
} from "../../utilities/helper";
import MainServices from "../../services/main.services";
import UpgradeComp from "../UpgradeComp";

const useStyles = (theme) => ({
    title: {
        textAlign: "center",
        paddingTop: 0,
    },
    field: {
        margin: "8px 0px",
        color: colors.PRIMARY,
    },
    smallField: {
        maxWidth: 200,
        minWidth: 100,
    },
    rightMargin: {
        marginRight: 20,
    },
    seriesSelectBox: {
        margin: "10px 0 15px",
    },
    appBar: {
        backgroundColor: "#ffffff",
        color: "#000000",
        width: "100%",
        position: "sticky",
        top: 0,
    },
    errorMessage: {
        flex: "1 0 100%",
        maxWidth: "100%",
        textAlign: "center",
        color: colors.DANGER,
        margin: "10px auto",
        fontSize: "0.8rem",
    },
});

const aMap = new Map();
aMap.set("scq", "");
aMap.set("mcq", []);
aMap.set("bool", "");
aMap.set("text", "");

const initialState = {
    series: null,
    test: null,
    qs: [
        {
            q: "",
            qType: "scq",
            qOptions: ["", "", "", ""],
            qAnswer: aMap,
            qTags: [],
        },
    ],
    repeat: 0,
    enableSave: false,
    showError: false,
    errorMessage: "",
};
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class CreateQuestionDialog extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.MainServices = new MainServices();
        this.scrollingEl = React.createRef();
    }

    handleChange = (e, val, type) => {
        this.setState({
            [type]: val,
        });
    };

    resetState = () => {
        this.setState({
            ...initialState,
            qs: [
                {
                    q: "",
                    qType: "scq",
                    qOptions: ["", "", "", ""],
                    qAnswer: aMap,
                    qTags: [],
                },
            ],
        });
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        const formattedData = formatQAnswerData(this.state.qs);
        formattedData.forEach((q) => {
            q.orgId = this.props.orgData.orgId;
            q.qId = uuidv4();
        });

        if (formattedData.length === 1) {
            if (
                formattedData[0].qType !== "bool" &&
                !formattedData[0].qOptions[0].length > 0
            ) {
                alert("Question without options can't be saved.");
                return;
            }
            if (!formattedData[0].qAnswer) {
                alert("Question without answer can't be saved.");
                return;
            }
        }

        if (!formattedData.length) {
            alert("No questions found to save.");
            return;
        }

        if (this.props.addToExam) {
            this.props.addQuestionsToExam(formattedData);
        } else {
            this.props.saveQuestions({
                qs: formattedData,
            });
            localStorage.removeItem("qData");
            this.props.handleClose();
        }
    };

    handleClear = (e) => {
        e.preventDefault();
        this.resetState();
        localStorage.removeItem("qData");
    };

    componentDidMount() {
        let qsState = hyderateQDataFromLS();
        if (qsState) {
            this.setState({
                repeat: qsState.length - 1,
                qs: qsState,
            });
        } else {
            this.resetState();
        }
    }

    handleChange = (e, index) => {
        e.stopPropagation();
        //e.nativeEvent.stopImmediatePropagation();
        if (this.state.showError) {
            this.closeError();
        }
        this.setState({
            qs: this.state.qs.map((o, i) =>
                i === index ? { ...o, [e.target.name]: e.target.value } : o
            ),
        });
    };

    handleTagChange = (e, value, name, index) => {
        e.stopPropagation();
        //e.nativeEvent.stopImmediatePropagation();
        if (this.state.showError) {
            this.closeError();
        }
        this.setState({
            qs: this.state.qs.map((o, i) =>
                i === index ? { ...o, [name]: value } : o
            ),
        });
    };

    handleOptionChange = (e, index, oIndex, action) => {
        let newQsList = this.state.qs;
        let options = newQsList[index].qOptions;

        if (this.state.showError) {
            this.closeError();
        }
        if (action === "add") {
            options.push("");
        } else if (action === "delete") {
            if (options.length > 2) {
                options.splice(oIndex, 1);
            } else {
                this.setState({
                    showError: true,
                    errorMessage: "Minimum options limit has been reached.",
                });
            }
        } else {
            options[oIndex] = e.target.value;
        }
        this.setState({
            qs: newQsList,
        });
    };

    handleQEditorChange = (data, index) => {
        if (this.state.showError) {
            this.closeError();
        }
        let newData = data.replace(/<p>\s*<\/p>/gi, "");
        this.setState({
            qs: this.state.qs.map((o, i) =>
                i === index
                    ? {
                          ...o,
                          q: sanitizeHTML(newData, true).replace(/\n/g, ""),
                      }
                    : o
            ),
        });
    };

    handleAnswerChange = (e, index) => {
        let newAnswerState = this.state.qs[index].qAnswer;
        if (this.state.showError) {
            this.closeError();
        }
        if (this.state.qs[index].qType === "scq") {
            newAnswerState.set("scq", e.target.value);
        } else if (this.state.qs[index].qType === "mcq") {
            let checkedArr = newAnswerState.get("mcq");
            if (checkedArr.includes(e.target.value)) {
                checkedArr = newAnswerState
                    .get("mcq")
                    .filter((x) => x != e.target.value);
            } else {
                checkedArr.push(e.target.value);
            }
            newAnswerState.set("mcq", checkedArr);
        } else if (this.state.qs[index].qType === "bool") {
            newAnswerState.set("bool", e.target.value);
        } else if (this.state.qs[index].qType === "text") {
            newAnswerState.set("text", e.target.value);
        }

        this.setState({
            qs: this.state.qs.map((o, i) =>
                i === index ? { ...o, qAnswer: newAnswerState } : o
            ),
        });
    };

    addMoreQ = () => {
        let arr = this.state.qs;
        let lastQ = arr[arr.length - 1];
        if (
            (lastQ.q.length > 0 &&
                lastQ.qAnswer.get(lastQ.qType).length > 0 &&
                lastQ.qType === "bool") ||
            (lastQ.qType !== "bool" &&
                lastQ.qOptions &&
                lastQ.qOptions[0].length > 0)
        ) {
            const newMap = new Map();
            newMap.set("scq", "");
            newMap.set("mcq", []);
            newMap.set("bool", "");
            newMap.set("text", "");
            const obj = {
                q: "",
                qType: "scq",
                qOptions: ["", "", "", ""],
                qTags: [],
                qAnswer: newMap,
            };
            arr.push(obj);
            this.setState(
                {
                    repeat: this.state.repeat + 1,
                    qs: arr,
                },
                () => {
                    saveQDataToLS(this.state.qs);
                    this.scrollingEl.current.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                    });
                }
            );
        } else {
            //toast.error("Please mark the answer of the question.");
            let msg = "";
            if (!lastQ.q.length > 0) {
                msg = "Question can't be empty.";
            } else if (
                lastQ.qType !== "bool" &&
                (!lastQ.qOptions || !lastQ.qOptions[0].length > 0)
            ) {
                msg = "Option has not been provided for the question.";
            } else {
                msg = "Correct answer for the question has not been marked.";
            }
            this.setState({
                showError: true,
                errorMessage: msg,
            });
        }
    };
    closeError = () => {
        this.setState({
            showError: false,
            errorMessage: "",
        });
    };

    render() {
        const { classes, type, orgData } = this.props;
        return (
            <>
                <Dialog
                    fullScreen
                    scroll="paper"
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    TransitionComponent={Transition}
                    className="full-screen-dialog create-questions-dialog"
                    BackdropProps={{
                        style: { backgroundColor: "rgba(0,0,0,0.7)" },
                    }}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar className="full-screen-dialog__toolbar">
                            <IconButton
                                edge="start"
                                size="medium"
                                color="inherit"
                                onClick={this.props.handleBack}
                                aria-label="close"
                                className="close-button"
                            >
                                <KeyboardBackspaceIcon />
                            </IconButton>
                            <Typography
                                variant="h2"
                                component="h2"
                                className="title"
                            >
                                Add Question Portal
                            </Typography>
                            <ButtonComp
                                variant="contained"
                                bckColor={colors.PRIMARY}
                                color="#ffffff"
                                autoFocus
                                onClick={this.handleSubmit}
                                className="save-button"
                            >
                                {this.props.addToExam ? "Add to Exam" : "Save"}
                            </ButtonComp>
                            {/* <ButtonComp
                                variant="outlined"
                                color={colors.DANGER}
                                autoFocus
                                onClick={this.handleClear}
                                className="clear-button"
                            >
                                Clear
                            </ButtonComp> */}
                            <IconButton
                                edge="start"
                                size="medium"
                                color="inherit"
                                onClick={this.props.handleClose}
                                aria-label="close"
                                className="close-button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Container className="full-screen-dialog__content">
                        {orgData.resources.questions <
                        orgData.licRules.questions ? (
                            <>
                                <Repeater
                                    component={QCard}
                                    times={this.state.repeat + 1}
                                    state={this.state.qs}
                                    handleAnswerChange={this.handleAnswerChange}
                                    handleQEditorChange={
                                        this.handleQEditorChange
                                    }
                                    handleChange={this.handleChange}
                                    handleTagChange={this.handleTagChange}
                                    handleOptionChange={this.handleOptionChange}
                                    orgData={orgData}
                                />
                                {this.state.showError && (
                                    <div className={classes.errorMessage}>
                                        {this.state.errorMessage}
                                    </div>
                                )}
                                <ButtonComp
                                    ref={this.scrollingEl}
                                    aria-label="add more question"
                                    variant="contained"
                                    bckColor={colors.PRIMARY}
                                    color="#ffffff"
                                    autoFocus
                                    onClick={this.addMoreQ}
                                    className="add-more-button"
                                >
                                    Add More
                                </ButtonComp>
                                <FloatingActionButton
                                    aria-label="add more question"
                                    onClick={this.addMoreQ}
                                    bckColor={colors.DANGER}
                                    color="#ffffff"
                                    title="Add more questions"
                                >
                                    <AddIcon fontSize="large" />
                                </FloatingActionButton>
                            </>
                        ) : (
                            <UpgradeComp
                                handleClose={this.props.handleClose}
                                orgData={this.props.orgData}
                                message={`The ${this.props.orgData.license} license for your organisation has been exhausted. Consider upgrading to next plan or buy question bundles.`}
                            />
                        )}
                    </Container>
                </Dialog>
            </>
        );
    }
}

export default withStyles(useStyles)(CreateQuestionDialog);
