import React, { Component } from "react";
import { Link } from "react-router-dom";
import { lighten, withStyles } from "@material-ui/core/styles";
import {
    Dialog,
    DialogTitle,
    SvgIcon,
    Typography,
    IconButton,
    Fade,
} from "@material-ui/core";

import { toast } from "react-toastify";
import { colors, TAGS, IntroDialogContent } from "../../utilities/constants";
import { getTenantURL } from "../../utilities/helper";
import ButtonComp from "../UIComponents/ButtonComp";
import CircularLoader from "../UIComponents/CircularLoader";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CloseIcon from "@material-ui/icons/Close";
import welcomeArt from "../../assets/welcomeArt.svg";
import planExpireArt from "../../assets/PlanExpireArt.svg";
import LaunchIcon from "@material-ui/icons/Launch";

const useStyles = (theme) => ({
    rightMargin: {
        marginRight: 20,
    },

    deleteIcon: {
        fontSize: 80,
    },
    content: {
        textAlign: "center",
        paddingBottom: theme.spacing(2),
    },
    buttonWrapper: {
        margin: "20px 0 0",
        position: "relative",
        textAlign: "center",
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -8,
        marginLeft: -12,
        textTransform: "none",
    },
});

const getResources = (props) => {
    let resources = {
        WELCOME: {
            art: welcomeArt,
            title: "Welcome",
            titleColor: colors.PRIMARY,
            isAnimation: true,
            description: (
                <>
                    {`Congratulations! ${props.orgData.orgName} has been registered successfully. Explore the gradout.`}
                    <div>
                        Your student portal is live on{" "}
                        <a
                            href={getTenantURL(props.orgData.orgId)}
                            className="link"
                            target="_blank"
                        >
                            {getTenantURL(props.orgData.orgId, true)}
                            <LaunchIcon size="small" className="linkIcon" />
                        </a>
                    </div>
                </>
            ),
            path: "/",
        },
        PLAN_EXPIRE: {
            art: planExpireArt,
            title: `Plan ${
                props.introData.data + 1 >= 0
                    ? "is about to expire"
                    : "has been expired"
            }`,
            titleColor: colors.DANGER,
            isAnimation: false,
            description: (
                <>
                    {`${props.orgData.orgName}'s ${
                        props.orgData.license
                    } plan ${
                        props.introData.data + 1 >= 0
                            ? `will expire in next ${
                                  props.introData.data + 1
                              } days.`
                            : "has been expired."
                    }
                    `}
                    <div>
                        consider upgrading to continue uninterrupted services.
                        <div>
                            Checkout{" "}
                            <Link to="/upgrade" className="link">
                                Plans
                                <LaunchIcon size="small" className="linkIcon" />
                            </Link>
                        </div>
                    </div>
                </>
            ),
            path: "/upgrade",
        },
    };
    return resources[props.introData.type];
};

class IntroDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidUpdate() {}

    goHome = (e) => {
        e.preventDefault();
        this.props.handleClose();
    };

    render() {
        const { props } = this;
        const { classes } = this.props;

        let data = props.introData.type ? getResources(props) : {};

        return (
            <Dialog
                //onClose={props.handleClose}
                aria-labelledby="create-new-dialog"
                open={props.open}
                classes={{ root: "dialog introDialog", paper: "" }}
                BackdropProps={{
                    style: { backgroundColor: "rgba(0,0,0,0.7)" },
                }}
                maxWidth="sm"
                transition={Fade}
                transitionDuration={400}
                fullWidth
            >
                {/* <div className="paper-top-navigation">
                    <IconButton
                        onClick={() =>
                            props.handleClose({
                                showDialog: false,
                                type: "",
                            })
                        }
                        className="closeIcon"
                    >
                        <CloseIcon />
                    </IconButton>
                </div> */}
                <img
                    style={{ maxWidth: 300, margin: "0 auto" }}
                    src={data.art}
                />
                <Typography
                    variant="h2"
                    component="h2"
                    id="create-new-dialog"
                    className={`paper-title ${
                        data.isAnimation ? "tracking-in-expand-animation" : ""
                    }`}
                    style={{
                        color: data.titleColor ? data.titleColor : "inherit",
                    }}
                >
                    {data.title}
                </Typography>

                <Typography
                    variant="body1"
                    component="p"
                    className={classes.content}
                >
                    {data.description}
                </Typography>

                <div className={classes.buttonWrapper}>
                    {/* <ButtonComp
                        tvariant="contained"
                        bckColor={colors.PRIMARY}
                        color="#ffffff"
                        onClick={() => null}
                        classes={{ root: classes.rightMargin }}
                    >
                        Guides
                    </ButtonComp> */}
                    <ButtonComp
                        tvariant="contained"
                        bckColor={colors.PRIMARY}
                        color="#ffffff"
                        onClick={this.goHome}
                        style={{ textTransform: "none" }}
                        fullWidth
                    >
                        Continue
                    </ButtonComp>
                </div>
            </Dialog>
        );
    }
}

export default withStyles(useStyles)(IntroDialog);
