import React from "react";
import { Link } from "react-router-dom";
import whiteLogo from "../assets/white-logo-1x.png";
import { isMobile } from "react-device-detect";
import {
    Drawer,
    Toolbar,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    SvgIcon,
    Typography,
    ClickAwayListener,
    Backdrop,
    SwipeableDrawer,
} from "@material-ui/core/";
import { fade, makeStyles, withStyles } from "@material-ui/core/styles";
import { colors } from "../utilities/constants";
import ButtonComp from "./UIComponents/ButtonComp";
import UpgradeArt from "../assets/UpgradeArt.svg";
import PuchaseButtonIcon from "../assets/svgIcons/PuchaseButtonIcon";

const useStyles = (theme) => ({
    lighterText: {
        opacity: "0.4",
    },
    gradout: {
        maxWidth: "100px",
    },
    art: {
        margin: "0 auto 20px",
    },
    text: {
        marginBottom: 10,
    },
});

const UpgradeComp = (props) => {
    const { classes } = props;

    return (
        <div className="upgradePlan">
            <img src={UpgradeArt} width="300" className={classes.art} />
            <Typography component="p" variant="body1" className={classes.text}>
                {props.message}
            </Typography>
            <Link to="/upgrade" onClick={props.handleClose}>
                <ButtonComp
                    bckColor={colors.PRIMARY}
                    color="#ffffff"
                    startIcon={<PuchaseButtonIcon />}
                    fullWidth
                    size={props.size ? size : "medium"}
                    style={{
                        width: "90%",
                        opacity: 1,
                        marginBottom: 10,
                        textTransform: "none",
                        // margin: "5px 5px 10px",
                    }}
                >
                    Upgrade Now
                </ButtonComp>
            </Link>
        </div>
    );
};

export default withStyles(useStyles)(UpgradeComp);
