import React, { lazy, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import CircularLoader from "./components/UIComponents/CircularLoader";
import PrivateRoute from "./routes/PrivateRoute";
import NonPrivateRoute from "./routes/NonPrivateRoute";
import Login from "./containers/Login";
import Dashboard from "./containers/Dashboard";
//import Profile from "./containers/Account/Profile";
//import Organisation from "./containers/Account/Organisation";
//import Manage from "./containers/Manage";
//import EmptyComp from "./components/UIComponents/EmptyComp";
//import Series from "./containers/Series";
import SignUp from "./containers/onboarding/signup";
import setupOrg from "./containers/onboarding/setupOrg";
import SelectOrg from "./containers/onboarding/SelectOrg";
import ForgotPassword from "./containers/onboarding/ForgotPassword";
import StudentsDashboard from "./containers/Students/StudentsDashboard";
import SessionDashboard from "./containers/Sessions/SessionDashboard";
import EventsDashboard from "./containers/Events/EventsDashboard";
import RolesDashboard from "./containers/Roles/RolesDashboard";
import TemporaryPasswordChange from "./containers/onboarding/TemporaryPasswordChange";
import SessionDetails from "./containers/Sessions/SessionDetails";
//import manageDashboard from "./containers/ManageExams/manageDashboard";
//import SeriesDetails from "./containers/ManageExams/SeriesDetails";
//import ExamDetails from "./containers/ManageExams/ExamDetails";
//import Support from "./containers/Support";
//import InsightsDashboard from "./containers/Reports/InsightsDashboard";
//import GroupsDashboard from "./containers/Groups/GroupsDashboard";
//import GroupDetails from "./containers/Groups/GroupDetails";
//import UpgradePlans from "./containers/UpgradePlans";

const InsightsDashboard = lazy(() =>
    import("./containers/Reports/InsightsDashboard")
);
const manageDashboard = lazy(() =>
    import("./containers/ManageExams/manageDashboard")
);
const ExamDetails = lazy(() => import("./containers/ManageExams/ExamDetails"));
const SeriesDetails = lazy(() =>
    import("./containers/ManageExams/SeriesDetails")
);
const GroupsDashboard = lazy(() =>
    import("./containers/Groups/GroupsDashboard")
);
const GroupDetails = lazy(() => import("./containers/Groups/GroupDetails"));
const Organisation = lazy(() => import("./containers/Account/Organisation"));
const Profile = lazy(() => import("./containers/Account/Profile"));
const StudentProfile = lazy(() =>
    import("./containers/Students/StudentProfile")
);
const UpgradePlans = lazy(() => import("./containers/UpgradePlans"));
const Support = lazy(() => import("./containers/Support"));
const EmptyComp = lazy(() => import("./components/UIComponents/EmptyComp"));
const Payments = lazy(() => import("./containers/Account/Payments"));

const Routes = ({ childProps }) => {
    return (
        <Suspense fallback={<CircularLoader wrapperClass="cloader" />}>
            <Switch>
                <Route
                    exact
                    path="/index.html"
                    render={() => <Redirect to="/" />}
                />
                <NonPrivateRoute
                    exact
                    path="/login"
                    component={Login}
                    props={childProps}
                />
                <NonPrivateRoute
                    exact
                    path="/signup"
                    component={SignUp}
                    props={childProps}
                />
                <NonPrivateRoute
                    exact
                    path="/forgotpassword"
                    component={ForgotPassword}
                    props={childProps}
                />
                <NonPrivateRoute
                    exact
                    path="/passwordchange"
                    component={TemporaryPasswordChange}
                    props={childProps}
                />
                <NonPrivateRoute
                    exact
                    path="/onboarding"
                    component={setupOrg}
                    props={childProps}
                />
                <NonPrivateRoute
                    exact
                    path="/selectOrg"
                    component={SelectOrg}
                    props={childProps}
                />
                <PrivateRoute
                    exact
                    path="/"
                    component={Dashboard}
                    props={childProps}
                />
                <PrivateRoute
                    exact
                    path="/manageSeries"
                    component={manageDashboard}
                    props={childProps}
                />
                <PrivateRoute
                    exact
                    path="/archivedExams"
                    component={EmptyComp}
                    props={childProps}
                />
                <PrivateRoute
                    exact
                    path="/seriesDetails/:seriesId"
                    component={SeriesDetails}
                    props={childProps}
                />
                <PrivateRoute
                    exact
                    path="/:seriesId/examDetails/:examId"
                    component={ExamDetails}
                    props={childProps}
                />
                <PrivateRoute
                    exact
                    path="/profile"
                    component={Profile}
                    props={childProps}
                />
                <PrivateRoute
                    exact
                    path="/profile/:memberId"
                    component={StudentProfile}
                    props={childProps}
                />
                <PrivateRoute
                    exact
                    path="/org"
                    component={Organisation}
                    props={childProps}
                />
                {/* <PrivateRoute
                    exact
                    path="/payments"
                    component={Payments}
                    props={childProps}
                /> */}
                <PrivateRoute
                    exact
                    path="/insights"
                    component={InsightsDashboard}
                    props={childProps}
                />
                <PrivateRoute
                    exact
                    path="/manageMembers"
                    component={StudentsDashboard}
                    props={childProps}
                />

                <PrivateRoute
                    exact
                    path="/manageRoles"
                    component={RolesDashboard}
                    props={childProps}
                />
                <PrivateRoute
                    exact
                    path="/session"
                    component={SessionDashboard}
                    props={childProps}
                />
                <PrivateRoute
                    exact
                    path="/session/:eventId"
                    component={SessionDetails}
                    props={childProps}
                />
                {/* <PrivateRoute
                    exact
                    path="/events"
                    component={EventsDashboard}
                    props={childProps}
                /> */}

                <PrivateRoute
                    exact
                    path="/groups"
                    component={GroupsDashboard}
                    props={childProps}
                />
                <PrivateRoute
                    exact
                    path="/groupDetails/:groupId"
                    component={GroupDetails}
                    props={childProps}
                />
                <PrivateRoute
                    exact
                    path="/settings"
                    component={EmptyComp}
                    props={childProps}
                />
                <PrivateRoute
                    exact
                    path="/support"
                    component={Support}
                    props={childProps}
                />
                {/* <PrivateRoute
                    exact
                    path="/series"
                    component={Series}
                    props={childProps}
                /> */}
                <PrivateRoute
                    exact
                    path="/upgrade"
                    component={UpgradePlans}
                    props={childProps}
                />
                {/* <PrivateRoute
                path={`/${childProps.currentContainer}`}
                component={
                    childProps.currentContainer.length !== 0
                        ? childProps.currentContainer.charAt(0).toUpperCase() +
                          childProps.currentContainer.slice(1)
                        : Dashboard
                }
                props={childProps}
            /> */}
            </Switch>
        </Suspense>
    );
};

export default Routes;
