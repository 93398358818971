import React, { Component } from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
    hasNumber,
    hasUpperCase,
    hasSpecialChar,
} from "../../utilities/helper";
import { colors } from "../../utilities/constants";

class PasswordValidator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rules: [
                {
                    id: "uppercase",
                    label: "Must have uppercase letter",
                    isPassed: false,
                },
                { id: "number", label: "Must have numbers", isPassed: false },
                {
                    id: "sChar",
                    label: "Must have special character",
                    isPassed: false,
                },
                { id: "8char", label: "Minimum 8 characters", isPassed: false },
            ],
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.password !== prevProps.password) {
            this.validatePassword();
        }
    }

    validatePassword = () => {
        const { password } = this.props;
        let modifiedRules = this.state.rules.map((rule, key) => {
            switch (rule.id) {
                case "uppercase":
                    rule.isPassed = hasUpperCase(password);
                    break;
                case "number":
                    rule.isPassed = hasNumber(password);
                    break;
                case "sChar":
                    rule.isPassed = hasSpecialChar(password);
                    break;
                case "8char":
                    rule.isPassed = password.length >= 8;
                    break;
            }
            return rule;
        });

        let validRules = modifiedRules.filter((rule) => rule.isPassed).length;

        this.setState(
            {
                rules: modifiedRules,
            },
            () => {
                this.props.checkValidity(validRules);
            }
        );
    };

    render() {
        return (
            <div className="passwordValidatorWrapper">
                {this.state.rules.map((rule, key) => {
                    return (
                        <div key={key}>
                            <CheckCircleIcon
                                className="icon"
                                style={{
                                    color: rule.isPassed
                                        ? colors.GREEN
                                        : colors.BODY1,
                                }}
                            />
                            {rule.label}
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default PasswordValidator;
