import React, { Component } from "react";
import * as CryptoJS from "crypto-js";
import {
    Avatar,
    Paper,
    Button,
    CssBaseline,
    TextField,
    FormControlLabel,
    Checkbox,
    Icon,
    Grid,
    Box,
    Container,
    Typography,
    InputAdornment,
    IconButton,
    Stepper,
    Step,
    StepLabel,
    RadioGroup,
    Radio,
    Backdrop,
    Divider,
    FormHelperText,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Switch,
    FormGroup,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ButtonComp from "./UIComponents/ButtonComp";
import CircularLoader from "./UIComponents/CircularLoader";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import BusinessIcon from "@material-ui/icons/Business";
import GroupIcon from "@material-ui/icons/Group";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import MuiPhoneNumber from "material-ui-phone-number";
import { colors } from "../utilities/constants";
import {
    isValidURL,
    getTenantURL,
    isReserved,
    isValidEmail,
    isValidPhoneNumber,
} from "../utilities/helper";
import OrgServices from "../services/org.services";
import { toast } from "react-toastify";
import GreenRadio from "./UIComponents/GreenRadio";
import SearchSelector from "./UIComponents/SearchSelector";
import ChipComp from "./UIComponents/ChipComp";
import { ErrorSharp } from "@material-ui/icons";

const useStyles = (theme) => ({
    paper: {
        marginTop: theme.spacing(4),
        paddingTop: 70,
    },
    logo: {
        marginBottom: theme.spacing(1),
        maxWidth: 200,
    },
    stepper: {
        width: "100%",
        padding: "0 0 30px 0",
    },
    chars: {
        width: 30,
        height: 30,
        border: `1px solid ${colors.BODY1}`,
        borderRadius: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    active: {
        color: `${colors.PRIMARY} !important`,
    },
    completed: {
        color: `${colors.GREEN} !important`,
    },
    button: {
        margin: theme.spacing(2, 0, 0),
    },
    buttonWrapper: {
        width: "100%",
        margin: theme.spacing(1),
        position: "relative",
    },
    fileInput: {
        display: "none",
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -5,
        marginLeft: -12,
    },
    divider: {
        margin: "10px 0",
    },
    cellRoot: {
        padding: theme.spacing(1),
    },
    col2: {
        fontWeight: 500,
        marginLeft: 10,
    },
});

const errorsObj = {
    type: false,
    org: false,
    subdomain: false,
    access: false,
    email: false,
    phone: false,
};

let steps = [
    { name: "Select Type", id: "type" },
    { name: "Name", id: "org" },
    { name: "SubDomain", id: "subdomain" },
    { name: "Summary" },
];

class OrgStepper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "",
            category: "",
            org: "",
            location: "",
            email: "",
            phone: "",
            subdomain: "",
            config: {
                access: "private",
                accessType: "none",
                groupsEnabled: true,
                allowGroupJoining: false,
            },
            logo: "",
            activeStep: 0,
            loading: false,
            error: { ...errorsObj },
            enableNext: false,
            newGroupText: "",
        };
        // this.stepsArray = [
        //     { name: "Select Type", id: "type" },
        //     { name: "Name", id: "org" },
        //     { name: "SubDomain", id: "subdomain" },
        //     { name: "Configuration", id: "config" },
        //     { name: "Summary" },
        // ];
        this.stepsArray = props.orgData ? steps.slice(0, 2) : steps;
        this.OrgServices = new OrgServices();
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        if (props.orgData && !state.subdomain) {
            return {
                ...state,
                type: props.orgData.orgType,
                category: props.orgData.category,
                org: props.orgData.orgName,
                location: props.orgData.orgLocation
                    ? props.orgData.orgLocation
                    : "",
                email: props.orgData.orgEmail ? props.orgData.orgEmail : "",
                phone: props.orgData.orgPhone ? props.orgData.orgPhone : "",
                subdomain: props.orgData.orgId,
                config: {
                    ...props.orgData.config,
                    groupsEnabled: props.orgData.config.groupsEnabled
                        ? props.orgData.config.groupsEnabled
                        : false,
                    allowGroupJoining: props.orgData.config.allowGroupJoining
                        ? props.orgData.config.allowGroupJoining
                        : false,
                },

                logo: props.orgData.logo,
                enableNext: true,
            };
        }
        return null;
    }

    componentDidMount() {
        if (!this.props.userData) {
            this.props.history.push("/login");
        }
    }

    // onKeyDown = (e) => {
    //     let code = e.keyCode ? e.keyCode : e.which;
    //     if (code == 13) {
    //         if (e.target.name === "newGroupText") {
    //             let cryptoId = CryptoJS.SHA3(
    //                 this.state.newGroupText.toLowerCase()
    //             ).toString();
    //             let gIndex = this.state.config.groups.findIndex(
    //                 (g) => g.id === cryptoId
    //             );
    //             if (gIndex === -1) {
    //                 this.setState(
    //                     {
    //                         config: {
    //                             ...this.state.config,
    //                             groups: [
    //                                 ...this.state.config.groups,
    //                                 {
    //                                     id: cryptoId,
    //                                     title: this.state.newGroupText.toLowerCase(),
    //                                 },
    //                             ],
    //                         },
    //                         newGroupText: "",
    //                     },
    //                     () => this.enableNextButton()
    //                 );
    //             } else {
    //                 this.setState({
    //                     newGroupText: "",
    //                 });
    //             }
    //         }
    //     }
    // };

    onChange = (e, specialType, type) => {
        e.preventDefault();
        e.persist();

        this.setState({
            error: { ...errorsObj },
        });

        if (specialType === "config") {
            if (e.target.name === "allowGroupJoining") {
                this.setState(
                    {
                        config: {
                            ...this.state.config,
                            allowGroupJoining:
                                !this.state.config.allowGroupJoining,
                        },
                    },
                    () => this.enableNextButton()
                );
            } else if (e.target.name === "groupsEnabled") {
                this.setState(
                    {
                        config: {
                            ...this.state.config,
                            [e.target.name]: JSON.parse(e.target.value),
                        },
                    },

                    () => this.enableNextButton()
                );
            } else {
                this.setState(
                    {
                        config: {
                            ...this.state.config,
                            [e.target.name]: e.target.value,
                        },
                    },

                    () => this.enableNextButton()
                );
            }
        } else if (specialType === "subdomain") {
            let valuee = e.target.value.toLowerCase().trim();
            if (isReserved(valuee)) {
                this.setState(
                    {
                        [e.target.name]: valuee,
                    },
                    () => this.enableNextButton()
                );
                toast.error(
                    `${valuee} is a reserved domain. Please try something else.`
                );
            } else {
                if (
                    !/[~`!#$%\^@_\s&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(valuee)
                ) {
                    this.setState(
                        {
                            [e.target.name]: valuee,
                        },
                        () => this.enableNextButton()
                    );
                } else {
                    toast.error("Only Alphabets and numbers are allowed.");
                }
            }
        } else {
            this.setState(
                {
                    [e.target.name]: e.target.value,
                },
                () => this.enableNextButton()
            );
        }
    };

    handlePhoneChange = (val) => {
        this.setState({
            error: { ...errorsObj },
            phone: val,
        });
    };

    // handleDeleteGroup = (id) => {
    //     const { groups } = this.state.config;
    //     let ind = groups.findIndex((g) => g.id === id);
    //     if (groups[ind].users && groups[ind].users > 0) {
    //         toast.error(
    //             `Can't delete ${groups[ind].title}. The ${groups[ind].users} are assigned to it.`
    //         );
    //     } else {
    //         groups.splice(ind, 1);
    //         this.setState({
    //             config: {
    //                 ...this.state.config,
    //                 groups,
    //             },
    //         });
    //     }
    // };

    enableNextButton = () => {
        const { activeStep, error } = this.state;
        let newError = error;
        switch (activeStep) {
            case 0:
                if (this.state.type.length === 0) {
                    newError.type = true;
                }
                break;
            case 1:
                if (this.state.org.length === 0) {
                    newError.org = true;
                }
                break;
            case 2:
                if (
                    this.state.subdomain.length === 0 ||
                    isReserved(this.state.subdomain)
                ) {
                    newError.subdomain = true;
                }
                break;
            case 3:
                if (this.state.config.access.length === 0) {
                    newError.access = true;
                }
                break;
        }
        if (
            newError.type ||
            newError.org ||
            newError.subdomain ||
            newError.access
        ) {
            this.setState({
                error: newError,
                enableNext: false,
            });
        } else {
            this.setState({
                enableNext: true,
            });
        }
    };

    handleValidateField = (event) => {
        const { error } = this.state;
        const target = event.target;
        // catch delete and backspace
        let KeyID = event.keyCode;
        if (
            (KeyID === 8 || KeyID === 46) &&
            this.state.email === "" &&
            target.name === "email"
        ) {
            this.setState({
                error: {
                    email: false,
                },
            });
        } else if (
            (KeyID === 8 || KeyID === 46) &&
            this.state.phone === "" &&
            target.name === "phone"
        ) {
            this.setState({
                error: {
                    phone: false,
                },
            });
        } else {
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout((event) => {
                let err = {};
                if (target.name === "email") {
                    err.email = isValidEmail(target.value.trim())
                        ? false
                        : true;
                } else if (target.name === "phone") {
                    err.phone = isValidPhoneNumber(target.value.trim()).valid
                        ? false
                        : true;
                }
                this.setState({
                    error: {
                        ...this.state.error,
                        ...err,
                    },
                });
            }, 800);
        }
    };

    handleNext = () => {
        const nextStep = this.stepsArray[this.state.activeStep + 1];
        if (this.state.activeStep === this.stepsArray.length - 1) {
            this.setState(
                {
                    enableNext: false,
                    loading: true,
                },
                () => this.handleSubmit()
            );
        } else {
            if (this.stepsArray[this.state.activeStep].id === "subdomain") {
                if (
                    this.props.orgData &&
                    this.props.orgData.orgId === this.state.subdomain
                ) {
                    let eNext = false;
                    if (nextStep.id) {
                        if (nextStep.id === "config") {
                            eNext = this.state.config.access.length > 0;
                        } else {
                            eNext = this.state[nextStep.id].length > 0;
                        }
                    } else {
                        eNext = true;
                    }
                    this.setState({
                        activeStep: this.state.activeStep + 1,
                        enableNext: eNext,
                    });
                } else {
                    if (this.state.subdomain.length > 0) {
                        this.setState({
                            loading: true,
                            enableNext: false,
                        });
                        this.OrgServices.verifyOrg({
                            orgId: this.state.subdomain,
                        }).then((res) => {
                            if (res.isOrgAvailable) {
                                this.setState({
                                    loading: false,
                                    activeStep: this.state.activeStep + 1,
                                    enableNext: nextStep.id
                                        ? nextStep.id === "config"
                                            ? this.state.config.access.length >
                                              0
                                            : this.state[nextStep.id].length > 0
                                            ? true
                                            : false
                                        : true,
                                });
                            } else {
                                // toast.error(
                                //     "Domain is already taken. Please try again."
                                // );
                                this.setState({
                                    error: {
                                        ...this.state.error,
                                        subdomain: true,
                                    },
                                    enableNext: false,
                                    loading: false,
                                });
                            }
                        });
                    } else {
                        this.setState({
                            error: {
                                ...this.state.error,
                                subdomain: true,
                            },
                            enableNext: false,
                            loading: false,
                        });
                    }
                }
            } else {
                let eNext = false;
                if (nextStep.id) {
                    if (nextStep.id === "config") {
                        eNext = this.state.config.access.length > 0;
                    } else {
                        eNext = this.state[nextStep.id].length > 0;
                    }
                } else {
                    eNext = true;
                }
                this.setState({
                    activeStep: this.state.activeStep + 1,
                    enableNext: eNext,
                });
            }
        }
    };

    handleBack = () => {
        if (this.state.activeStep === this.stepsArray.length - 1) {
            this.setState(
                {
                    activeStep: 0,
                    loading: false,
                    error: {
                        type: false,
                        org: false,
                        subdomain: false,
                    },
                },
                () => this.enableNextButton()
            );
        } else {
            this.setState(
                {
                    activeStep: this.state.activeStep - 1,
                    error: {
                        type: false,
                        org: false,
                        subdomain: false,
                    },
                },
                () => this.enableNextButton()
            );
        }
    };

    handleSubmit = async () => {
        const {
            type,
            category,
            org,
            location,
            email,
            phone,
            subdomain,
            config,
        } = this.state;
        const { userEmail } = this.props.userData;
        const username = this.props.userData["sub"];
        this.setState({
            loading: true,
        });
        let params;
        let res;
        if (this.props.orgData) {
            //it's a update no config required
            params = {
                orgType: type,
                category,
                orgName: org,
                orgLocation: location,
                orgEmail: email,
                orgPhone: phone,
                orgId: subdomain,
                // config: config,
            };
            res = await this.OrgServices.updateOrgData(params);
            if (res) {
                //toast.success(`${org} registered successfully`);
                this.setState({
                    loading: false,
                });
                this.props.handleClose();
                toast.success(`${org} updated successfully`);
                this.props.updateOrgData({ ...params });
            } else {
                toast.error(res.message);
                this.setState({
                    loading: false,
                });
            }
        } else {
            // it's creating record
            params = {
                orgType: type,
                category,
                orgName: org,
                orgLocation: location,
                orgId: subdomain,
                config: config,
            };
            res = await this.OrgServices.saveOrgData(params);
            if (res && res.org) {
                //toast.success(`${org} registered successfully`);
                this.setState({
                    loading: false,
                });
                this.props.updateIntroDialog({
                    showDialog: true,
                    type: "WELCOME",
                });
                this.props.updateOrgData(res.org);
            } else {
                toast.success(res.message);
                this.setState({
                    loading: false,
                });
            }
        }
    };

    getStepComponent = (stepIndex) => {
        const { orgData } = this.props;
        switch (stepIndex) {
            case 0:
                return (
                    <>
                        <Typography variant="body2">You are an...</Typography>
                        <RadioGroup
                            aria-label="organisation type"
                            name="type"
                            value={this.state.type}
                            onChange={this.onChange}
                        >
                            <FormControlLabel
                                label="ORGANISATION"
                                value="org"
                                control={<GreenRadio />}
                            />
                            <FormControlLabel
                                label="INDIVIDUAL"
                                value="ind"
                                control={<GreenRadio />}
                            />
                        </RadioGroup>
                    </>
                );
            case 1:
                return (
                    <>
                        <TextField
                            error={this.state.error.org}
                            variant="outlined"
                            required
                            fullWidth
                            id="orgName"
                            label={`${
                                this.state.type === "org"
                                    ? "Organisation Name"
                                    : "Your Name"
                            }`}
                            name="org"
                            value={this.state.org}
                            onChange={this.onChange}
                            inputProps={{ maxLength: 28 }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <span
                                            className={this.props.classes.chars}
                                            style={{
                                                color:
                                                    this.state.org.length > 20
                                                        ? colors.DANGER
                                                        : "inherit",
                                                borderColor:
                                                    this.state.org.length > 20
                                                        ? colors.DANGER
                                                        : "inherit",
                                            }}
                                        >
                                            {`${this.state.org.length} `}
                                        </span>
                                    </InputAdornment>
                                ),
                            }}
                            helperText={
                                this.state.type === "org"
                                    ? "Enter your school, college or coaching name. Try to keep it short, 28 characters is the limit."
                                    : "Enter Your Name. Try to keep it short, 28 characters is the limit."
                            }
                        />
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="location"
                            label="Location"
                            name="location"
                            value={this.state.location}
                            onChange={this.onChange}
                            style={{ marginTop: 10 }}
                            helperText={
                                this.state.type === "org"
                                    ? "Enter location of main office."
                                    : "Enter your location."
                            }
                        />
                        <TextField
                            error={this.state.error.email}
                            variant="outlined"
                            fullWidth
                            type="email"
                            id="email"
                            label="Official Email Address"
                            name="email"
                            onKeyDown={this.handleValidateField}
                            value={this.state.email}
                            onChange={this.onChange}
                            style={{ marginTop: 10 }}
                            helperText={
                                this.state.type === "org"
                                    ? "Enter the official email address."
                                    : "Enter your email address."
                            }
                        />
                        <MuiPhoneNumber
                            error={this.state.error.phone}
                            variant="outlined"
                            name="phone"
                            label="Official Phone No."
                            fullWidth
                            defaultCountry="in"
                            onlyCountries={["in"]}
                            value={this.state.phone}
                            style={{ marginTop: 10 }}
                            onChange={this.handlePhoneChange}
                            onKeyDown={this.handleValidateField}
                            autoFormat={false}
                            countryCodeEditable={false}
                            helperText="Enter the official Phone Number."
                        />
                        {/* <TextField
                            error={this.state.error.phone}
                            variant="outlined"
                            fullWidth
                            type="tel"
                            id="phone"
                            label="Phone No."
                            name="phone"
                            style={{ marginTop: 10 }}
                            onKeyDown={this.handleValidateField}
                            value={this.state.phone}
                            onChange={this.onChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        +91
                                    </InputAdornment>
                                ),
                            }}
                            style={{ marginTop: 10 }}
                            helperText={
                                this.state.type === "org"
                                    ? "Enter the official Phone Number."
                                    : "Enter your Phone Number."
                            }
                        /> */}
                        {/* {this.state.type === "org" && (
                            <>
                                <Divider
                                    variant="middle"
                                    className={this.props.classes.divider}
                                />
                                <Typography variant="body2">
                                    Select Category
                                </Typography>
                                <RadioGroup
                                    aria-label="manage private access"
                                    name="category"
                                    value={this.state.category}
                                    onChange={this.onChange}
                                >
                                    <FormControlLabel
                                        label="Regular Education"
                                        value="regular"
                                        control={<GreenRadio />}
                                    />
                                    <FormHelperText className="radio-helptext">
                                        Schools or Colleges
                                    </FormHelperText>
                                    <FormControlLabel
                                        label="Competitive Exams"
                                        value="competitive"
                                        control={<GreenRadio />}
                                    />
                                    <FormHelperText className="radio-helptext">
                                        SSC, IBPS, IIT etc.
                                    </FormHelperText>
                                </RadioGroup>
                            </>
                        )} */}
                    </>
                );
            case 2:
                return (
                    <>
                        <TextField
                            error={this.state.error.subdomain}
                            variant="outlined"
                            required
                            fullWidth
                            id="subdomain"
                            label="Website address"
                            placeholder="type here..."
                            type="text"
                            disabled={orgData && orgData.orgId ? true : false}
                            name="subdomain"
                            value={this.state.subdomain}
                            onChange={(e) => this.onChange(e, "subdomain")}
                            InputProps={{
                                // startAdornment: (
                                //     <InputAdornment position="start">
                                //         https://
                                //     </InputAdornment>
                                // ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        .gradout.com
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Typography variant="body1" component="div">
                            {this.state.subdomain && (
                                <>
                                    {this.state.error.subdomain ? (
                                        <div
                                            style={{
                                                marginTop: "10px",
                                                color: colors.DANGER,
                                            }}
                                        >
                                            Domain is already taken. Please try
                                            something else.
                                        </div>
                                    ) : (
                                        <div style={{ marginTop: "10px" }}>
                                            {`Your domain ${
                                                orgData && orgData.orgId
                                                    ? "is"
                                                    : "will be"
                                            } `}
                                            <span
                                                style={{
                                                    color: colors.GREEN,
                                                    fontWeight: 700,
                                                }}
                                            >
                                                {getTenantURL(
                                                    this.state.subdomain,
                                                    true
                                                )}
                                            </span>
                                            {orgData && orgData.orgId ? (
                                                <div
                                                    style={{
                                                        color: colors.DANGER,
                                                    }}
                                                >
                                                    The domain can't be changed,
                                                    once created.
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                        </Typography>
                    </>
                );

            case 3:
                return (
                    <div className="summary">
                        <Typography
                            variant="body2"
                            component="div"
                            className="heading"
                        >
                            Review the details
                        </Typography>
                        <Table aria-label="user profile details">
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        classes={{
                                            root: this.props.classes.cellRoot,
                                        }}
                                    >
                                        Type:
                                    </TableCell>
                                    <TableCell
                                        classes={{
                                            root: `${this.props.classes.cellRoot} ${this.props.classes.col2}`,
                                        }}
                                    >
                                        {this.state.type.length &&
                                        this.state.type === "org"
                                            ? "Organisation"
                                            : "Individual"}
                                    </TableCell>
                                </TableRow>
                                {this.state.org.length && (
                                    <TableRow>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            classes={{
                                                root: this.props.classes
                                                    .cellRoot,
                                            }}
                                        >
                                            {this.state.type === "org"
                                                ? "Organisation Name: "
                                                : "Your Name: "}
                                        </TableCell>
                                        <TableCell
                                            classes={{
                                                root: `${this.props.classes.cellRoot} ${this.props.classes.col2}`,
                                            }}
                                        >
                                            {this.state.org}
                                        </TableCell>
                                    </TableRow>
                                )}
                                {this.state.location.length > 0 && (
                                    <TableRow>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            classes={{
                                                root: this.props.classes
                                                    .cellRoot,
                                            }}
                                        >
                                            Location:
                                        </TableCell>
                                        <TableCell
                                            classes={{
                                                root: `${this.props.classes.cellRoot} ${this.props.classes.col2}`,
                                            }}
                                        >
                                            {this.state.location}
                                        </TableCell>
                                    </TableRow>
                                )}

                                <TableRow>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        classes={{
                                            root: this.props.classes.cellRoot,
                                        }}
                                    >
                                        Domain:
                                    </TableCell>
                                    <TableCell
                                        style={{ textTransform: "none" }}
                                        classes={{
                                            root: `${this.props.classes.cellRoot} ${this.props.classes.col2}`,
                                        }}
                                    >
                                        {getTenantURL(this.state.subdomain)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        classes={{
                                            root: this.props.classes.cellRoot,
                                        }}
                                    >
                                        Access:
                                    </TableCell>
                                    <TableCell
                                        classes={{
                                            root: `${this.props.classes.cellRoot} ${this.props.classes.col2}`,
                                        }}
                                    >
                                        {this.state.config.access}
                                    </TableCell>
                                </TableRow>
                                {this.state.config.accessType.length && (
                                    <TableRow>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            classes={{
                                                root: this.props.classes
                                                    .cellRoot,
                                            }}
                                        >
                                            Student Signup:
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                textTransform: "none",
                                            }}
                                            classes={{
                                                root: `${this.props.classes.cellRoot} ${this.props.classes.col2}`,
                                            }}
                                        >
                                            {this.state.config.accessType ===
                                            "signup"
                                                ? "Enabled"
                                                : "Disabled"}
                                        </TableCell>
                                    </TableRow>
                                )}

                                <TableRow>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        classes={{
                                            root: this.props.classes.cellRoot,
                                        }}
                                    >
                                        Groups
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            textTransform: "none",
                                        }}
                                        classes={{
                                            root: `${this.props.classes.cellRoot} ${this.props.classes.col2}`,
                                        }}
                                    >
                                        {this.state.config.groupsEnabled
                                            ? "Enabled"
                                            : "Disabled"}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        {/* <span>
                            Please change the settings in "Organisation" tab.
                        </span> */}
                    </div>
                );
            default:
                return null;
        }
    };

    render() {
        const { classes } = this.props;
        let finalSteps = this.stepsArray.filter((s) => {
            if (this.props.orgData) {
                return s.id === "type" || s.id === "org";
            }
            return true;
        });
        return (
            <>
                <Stepper
                    activeStep={this.state.activeStep}
                    className={`orgSteppter ${classes.stepper}`}
                >
                    {this.stepsArray.map((step, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        // if (index === 3) {
                        //     labelProps.optional = (
                        //         <Typography variant="caption">
                        //             Optional
                        //         </Typography>
                        //     );
                        // }
                        // if (isStepSkipped(index)) {
                        //     stepProps.completed = false;
                        // }
                        return (
                            <Step key={step.name} {...stepProps}>
                                <StepLabel
                                    {...labelProps}
                                    StepIconProps={{
                                        classes: {
                                            root: classes.icon,
                                            active: classes.active,
                                            completed: classes.completed,
                                        },
                                    }}
                                >
                                    {/* {step.name} */}
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {this.getStepComponent(this.state.activeStep)}
                    </Grid>
                </Grid>
                <div className={classes.buttonWrapper}>
                    <ButtonComp
                        fullWidth
                        variant="contained"
                        bckColor={colors.PRIMARY}
                        color="#ffffff"
                        className={classes.button}
                        onClick={this.handleNext}
                        endIcon={
                            this.state.activeStep !==
                                this.stepsArray.length - 1 && (
                                <ArrowForwardIosIcon />
                            )
                        }
                        disabled={this.state.enableNext ? false : true}
                    >
                        {this.state.activeStep === this.stepsArray.length - 1
                            ? this.props.orgData
                                ? "Save"
                                : "Submit"
                            : this.stepsArray[this.state.activeStep].id ===
                              "subdomain"
                            ? this.props.orgData && this.props.orgData.orgId
                                ? "Next"
                                : "Verify & Next"
                            : "Next"}
                    </ButtonComp>
                    {this.state.loading && (
                        <CircularLoader
                            size={24}
                            className={classes.buttonProgress}
                            color={colors.PRIMARY}
                        />
                    )}
                </div>
                <ButtonComp
                    size="small"
                    onClick={this.handleBack}
                    className={classes.button}
                    disabled={this.state.activeStep === 0}
                >
                    {this.state.activeStep === this.stepsArray.length - 1
                        ? "Edit Again"
                        : "Back"}
                </ButtonComp>
            </>
        );
    }
}

export default withStyles(useStyles)(OrgStepper);
