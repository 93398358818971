import { actionConstants } from "../Utilities/Constants";

const defaultState = {
    seriesData: null,
    examsData: null,
    qData: {},
    qBankData: null,
    libraryQsData: null,
    seriesArchives: null,
    examArchives: null,
};

const examsStateReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actionConstants.RESET_EXAMS_DATA:
            return defaultState;
        case actionConstants.FETCH_SERIES_DATA:
            return {
                ...state,
                seriesData:
                    state.seriesData && state.seriesData.length > 1
                        ? [...state.seriesData, ...action.payload]
                        : action.payload,
            };
        case actionConstants.UPDATE_SERIES_DATA:
            let id = action.payload.seriesId;
            let newSeriesData = state.seriesData ? [...state.seriesData] : [];
            let index = newSeriesData.findIndex((s) => s.seriesId === id);
            const { type, ...newData } = action.payload;
            //let newDashboardData = { ...state.dashboardData };
            if (index !== -1) {
                if (type === "item") {
                    newSeriesData[index] = newData;
                } else if (type === "status") {
                    newSeriesData[index].itemStatus = newData.itemStatus;
                }
            } else {
                //newSeriesData.push(action.payload);
                newSeriesData.unshift(action.payload);

                // if (state.dashboardData.counts) {
                //     newDashboardData.counts.seriesCount++;
                // }
                // redirect to manageSeries Page
            }

            return {
                ...state,
                seriesData: newSeriesData,
                //dashboardData: newDashboardData,
            };
        case actionConstants.DELETE_SERIES:
            //let dData = { ...state.dashboardData };
            let sA = state.seriesArchives ? [...state.seriesArchives] : null;
            let newSeries = [...state.seriesData];
            let z = newSeries.findIndex((s) => s.seriesId === action.seriesId);
            if (z !== -1) {
                let deletedSeries = newSeries.splice(z, 1);
                if (sA) {
                    sA.push({ ...deletedSeries[0], ...action.payload });
                }
            }

            // if (dData.counts) {
            //     dData.counts.seriesCount--;
            // }

            return {
                ...state,
                seriesData: newSeries,
                //dashboardData: dData,
                seriesArchives: sA,
            };
        case actionConstants.FETCH_EXAMS_DATA:
            // let sId = action.payload.seriesId;
            // let newExamsData;
            // if(state.examsData){
            //     newExamsData = [...state.examsData, ...action.payload.exams];
            // }else{
            //     newExamsData = [...state.examsData, ...action.payload.exams]
            // }
            let nnExamsData = state.examsData ? [...state.examsData] : null;

            if (
                state.examsData &&
                state.examsData[0] &&
                state.examsData[0].seriesId === action.seriesId
            ) {
                nnExamsData = [...state.examsData, ...action.payload.exams];
            } else {
                nnExamsData = action.payload.exams;
            }
            return {
                ...state,
                examsData: nnExamsData,
            };
        case actionConstants.UPDATE_EXAMS_DATA:
            let seriesId = action.payload.seriesId;
            const changeType = action.payload.type;
            delete action.payload["type"];

            let seriesArray = state.seriesData ? [...state.seriesData] : null;
            let newQObj = { ...state.qData };
            let seriesObj = seriesArray
                ? seriesArray.find((s) => s.seriesId === seriesId)
                : null;
            let arr = state.examsData ? [...state.examsData] : [];
            //let nDashboardData = { ...state.dashboardData };
            let itemIndex = arr.findIndex(
                (e) => e.examId === action.payload.examId
            );

            if (itemIndex !== -1) {
                if (changeType === "item") {
                    arr[itemIndex] = { ...arr[itemIndex], ...action.payload };
                    if (action.payload.old_seriesId) {
                        let oldSeriesObj = seriesArray
                            ? seriesArray.find(
                                  (s) =>
                                      s.seriesId === action.payload.old_seriesId
                              )
                            : null;
                        // if no exams left in series, change it to draft
                        if (
                            arr.findIndex(
                                (e) =>
                                    e.seriesId === action.payload.old_seriesId
                            ) === -1
                        ) {
                            oldSeriesObj.itemStatus = "draft";
                        }
                        if (action.payload.itemStatus === "draft") {
                            if (seriesObj) {
                                seriesObj.examsCount.etotal += 1;
                            }

                            if (oldSeriesObj) {
                                oldSeriesObj.examsCount.etotal -= 1;
                            }
                        } else {
                            if (seriesObj) {
                                seriesObj.examsCount.etotal += 1;
                                seriesObj.examsCount.elive += 1;
                            }

                            if (oldSeriesObj) {
                                oldSeriesObj.examsCount.etotal -= 1;
                                oldSeriesObj.examsCount.elive -= 1;
                            }
                        }
                    }
                } else if (changeType === "status") {
                    arr[itemIndex].itemStatus = action.payload.itemStatus;
                    if (action.payload.itemStatus === "draft") {
                        seriesObj.examsCount.elive -= 1;
                    } else {
                        seriesObj.examsCount.elive += 1;
                    }
                } else if (changeType === "questions") {
                    arr[itemIndex].questions = action.payload.questions;
                    if (action.payload.action === "reorder") {
                        newQObj[action.payload.examId] = state.qData[
                            action.payload.examId
                        ].sort(function (a, b) {
                            return (
                                action.payload.questions.findIndex(
                                    (q) => q.qId === a.qId
                                ) -
                                action.payload.questions.findIndex(
                                    (q) => q.qId === b.qId
                                )
                            );
                        });
                    } else {
                        if (state.qBankData) {
                            // newQObj[
                            //     action.payload.examId
                            // ] = state.qBankData.filter((q) =>
                            //     action.payload.questions.find(
                            //         (slctedQ) => slctedQ.qId === q.qId
                            //     )
                            // );
                            let qqs = action.payload.questions.map((examQ) => {
                                let thisQ = newQObj[action.payload.examId].find(
                                    (q) => q.qId === examQ.qId
                                );
                                if (thisQ) {
                                    return thisQ;
                                } else if (examQ.orgId === "gradout") {
                                    //select question from library
                                    return state.libraryQsData.find(
                                        (q) => q.qId === examQ.qId
                                    );
                                } else {
                                    //select question from qBank
                                    return state.qBankData.find(
                                        (q) => q.qId === examQ.qId
                                    );
                                }
                            });
                            newQObj[action.payload.examId] = qqs;
                        }
                    }
                }
            } else {
                //arr.push(action.payload);
                if (
                    arr &&
                    arr[0] &&
                    arr[0].seriesId === action.payload.seriesId
                ) {
                    arr.unshift(action.payload);
                } else {
                    arr = [action.payload];
                }

                if (seriesObj) {
                    seriesObj.examsCount.etotal += 1;
                }
                // if (nDashboardData.counts) {
                //     nDashboardData.counts.examsCount++;
                // }
            }

            return {
                ...state,

                qData: newQObj,
                seriesData: seriesArray,
                examsData: arr,
                //dashboardData: nDashboardData,
            };
        case actionConstants.UPDATE_QUESTIONS_DATA:
            let eeeId = action.payload.examId;
            let newQData = state.qData;
            let qArray = [];
            if (Array.isArray(action.payload.data)) {
                if (newQData[eeeId]) {
                    let qA = newQData[eeeId];
                    qArray = qA.concat(action.payload.data);
                } else {
                    qArray = action.payload.data;
                }
            } else {
                if (newQData[eeeId]) {
                    qArray = newQData[eeeId];
                    let index = qArray.findIndex(
                        (e) => e.qId === action.payload.data.qId
                    );
                    if (index !== -1) {
                        qArray[index] = action.payload.data;
                    } else {
                        qArray.push(action.payload.data);
                    }
                } else {
                    qArray.push(action.payload.data);
                }
            }

            newQData[eeeId] = qArray;
            // let updatedQBank;
            // if (state.qBankData) {
            //     updatedQBank = [...state.qBankData];
            //     if (Array.isArray(action.payload.data)) {
            //         updatedQBank = [...updatedQBank, action.payload.data];
            //     } else {
            //         let obj = updatedQBank.find(
            //             (q) => q.qId === action.payload.data.qId
            //         );
            //         obj = action.payload.data;
            //     }
            // }
            // let newdashboarddData = {};
            // if (
            //     state.dashboardData.counts &&
            //     Array.isArray(action.payload.data)
            // ) {
            //     newdashboarddData = {
            //         ...state.dashboardData,
            //         counts: {
            //             ...state.dashboardData.counts,
            //             questionsCount: Array.isArray(action.payload.data)
            //                 ? state.dashboardData.counts.questionsCount +
            //                   action.payload.data.length
            //                 : state.dashboardData.counts.questionsCount,
            //         },
            //     };
            // }

            return {
                ...state,
                qData: newQData,
                // qBankData: updatedQBank ? updatedQBank : state.qBankData,
                //dashboardData: newdashboarddData,
            };

        case actionConstants.REMOVE_QUESTION_FROM_EXAM:
            let afterRemovalQData = state.qData;
            afterRemovalQData[action.payload.examId] = action.payload.data;
            return {
                ...state,
                qData: afterRemovalQData,
            };

        case actionConstants.DELETE_EXAM:
            //let ddData = { ...state.dashboardData };
            let eA = state.examArchives ? [...state.examArchives] : null;
            let newExams = [...state.examsData];
            let zin = newExams.findIndex(
                (e) => e.examId === action.payload.examId
            );

            if (zin !== -1) {
                let deletedExam = newExams.splice(zin, 1);
                if (eA) {
                    eA.push({ ...deletedExam[0], ...action.data });
                }
            }

            let sArray = [...state.seriesData];
            let objToModify = sArray.find(
                (s) => s.seriesId === action.payload.seriesId
            );
            if (action.payload.itemStatus === "live") {
                objToModify.examsCount.etotal -= 1;
                objToModify.examsCount.elive -= 1;
            } else {
                objToModify.examsCount.etotal -= 1;
            }

            // if (ddData.counts) {
            //     ddData.counts.examsCount--;
            // }

            return {
                ...state,
                examArchives: eA,
                seriesData: sArray,
                examsData: newExams,
                //dashboardData: ddData,
            };
        case actionConstants.FETCH_QUESTIONS_DATA:
            let eeId = action.payload.examId;
            return {
                ...state,
                qData: {
                    ...state.qData,
                    isLoading: false,
                    [eeId]: action.payload.qs,
                },
            };
        // case actionConstants.UPDATE_SERIES_STATUS:
        //     let newSData = [...state.seriesData];
        //     let ind = newSData.findIndex(
        //         (s) => s.seriesId === action.payload.seriesId
        //     );
        //     if (ind !== -1) {
        //         newSData[i].itemStatus = action.payload.newStatus;
        //     }

        //     return {
        //         ...state,
        //         seriesData: newSData,
        //     };
        case actionConstants.FETCH_QBANK_DATA:
            return {
                ...state,
                qBankData: state.qBankData
                    ? [...state.qBankData, ...action.payload]
                    : action.payload,
            };
        case actionConstants.FETCH_LIBRARY_QS_DATA:
            return {
                ...state,
                libraryQsData: state.libraryQsData
                    ? [...state.libraryQsData, ...action.payload]
                    : action.payload,
            };
        case actionConstants.UPDATE_QBANK_DATA:
            let newQBank = state.qBankData ? [...state.qBankData] : null;
            //let newdashboardddData = {};
            // if (state.dashboardData.counts) {
            //     newdashboardddData = {
            //         ...state.dashboardData,
            //         counts: {
            //             ...state.dashboardData.counts,
            //             questionsCount:
            //                 state.dashboardData.counts.questionsCount +
            //                 action.payload.length,
            //         },
            //     };
            // }
            return {
                ...state,
                qBankData: newQBank ? [...newQBank, ...action.payload] : null,
                //qData: {},
                //dashboardData: newdashboardddData,
            };
        case actionConstants.FETCH_SERIES_ARCHIVE_DATA:
            return {
                ...state,
                seriesArchives: action.payload,
            };
        case actionConstants.FETCH_EXAM_ARCHIVE_DATA:
            return {
                ...state,
                examArchives: action.payload,
            };
        case actionConstants.RESTORE_SERIES:
            //write logic for movement of entity from archives to main
            // let seriesData =

            let sArchives = [...state.seriesArchives];
            let sssData = state.seriesData ? [...state.seriesData] : null;
            let iin = sArchives.findIndex(
                (s) => s.seriesId === action.seriesId
            );
            if (iin !== -1) {
                let ser = sArchives.splice(iin, 1);
                delete ser[0].archivedAt;
                if (sssData) {
                    sssData.unshift(ser[0]);
                }
            }

            return {
                ...state,
                seriesArchives: sArchives,
                seriesData: sssData,
            };
        case actionConstants.RESTORE_EXAM:
            //write logic for movement of entity from archives to main
            // let seriesData =
            let eArchives = [...state.examArchives];
            let eeeData = state.examsData ? [...state.examsData] : null;
            let iiin = eArchives.findIndex((s) => s.examId === action.examId);
            if (iiin !== -1) {
                let ex = eArchives.splice(iiin, 1);
                delete ex[0].archivedAt;
                ex[0].seriesId = action.seriesId;

                if (
                    eeeData &&
                    eeeData[0] &&
                    eeeData[0].seriesId === action.seriesId
                ) {
                    eeeData.unshift(ex[0]);
                } else {
                    eeeData = [ex[0]];
                }
            }

            let ssArray = state.seriesData ? [...state.seriesData] : [];
            let oToModify = ssArray.find((s) => s.seriesId === action.seriesId);
            if (oToModify) {
                if (action.itemStatus === "live") {
                    oToModify.examsCount.etotal += 1;
                    oToModify.examsCount.elive += 1;
                } else {
                    oToModify.examsCount.etotal += 1;
                }
            }

            return {
                ...state,
                seriesData: ssArray,
                examArchives: eArchives,
                examsData: eeeData,
            };
        case actionConstants.USER_LOGOUT:
            return {
                ...defaultState,
            };
        default:
            return state;
    }
};

export default examsStateReducer;
