import axios from "axios";
import { API } from "aws-amplify";
import { getCurrentAuthenticatedUserDetails } from "../utilities/helper";

class HttpClient {
    constructor() {
        this.baseUrl = "http://localhost:5000/api/v1";
        this.config = {
            headers: { "X-Requested-With": "XMLHttpRequest" },
        };
    }

    // get(url, params = {}, cancelAllReq = false) {
    //     this.config.params = {};
    //     if (Object.keys(params).length) {
    //         this.config.params = params;
    //     }

    //     let endpoint = `${this.baseUrl}/${url}`;
    //     return axios.get(endpoint, this.config);
    // }

    async get(endpoint, path, params = {}, cancelAllReq = false) {
        const userData = await getCurrentAuthenticatedUserDetails();
        let payload;
        if (userData.username) {
            payload = {
                headers: { "x-amz-id-token": userData.token },
                body: { params },
            };
        }
        let res = API.get(endpoint, path, payload);
        return res;
    }

    async delete(endpoint, path, params = {}, cancelAllReq = false) {
        const userData = await getCurrentAuthenticatedUserDetails();
        let payload;
        if (userData.username) {
            payload = {
                headers: { "x-amz-id-token": userData.token },
                body: { params },
            };
        }
        let res = API.del(endpoint, path, payload);
        return res;
    }

    async post(endpoint, path, params = {}, cancelAllReq = false) {
        const userData = await getCurrentAuthenticatedUserDetails();
        let payload;
        if (userData.username) {
            payload = {
                headers: { "x-amz-id-token": userData.token },
                body: { params },
            };
        } else {
            payload = {
                body: { params },
            };
        }

        let res = await API.post(endpoint, path, payload);
        return res;
    }

    async put(endpoint, path, params = {}, cancelAllReq = false) {
        const userData = await getCurrentAuthenticatedUserDetails();
        let payload;
        if (userData.username) {
            payload = {
                headers: { "x-amz-id-token": userData.token },
                body: { params },
            };
        } else {
            payload = {
                body: { params },
            };
        }

        let res = await API.put(endpoint, path, payload);
        return res;
    }
}

export default HttpClient;
