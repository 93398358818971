import React from "react";
import { makeStyles, lighten } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { colors } from "../../utilities/Constants";

const StyledLinearProgress = makeStyles({
    root: {
        height: (props) =>
            props.size ? `${props.size}px !important` : "5px !important",
        color: (props) => (props.color ? props.color : colors.PRIMARY),
        backgroundColor: (props) =>
            props.color
                ? lighten(props.color, 0.7)
                : lighten(colors.PRIMARY, 0.7),
        position: "relative",
        // left: "calc(50% - 25px)",
        borderRadius: (props) =>
            props.borderRadius ? `${props.borderRadius}px` : 0,
    },
    bar: {
        borderRadius: (props) =>
            props.borderRadius ? `${props.borderRadius}px` : 0,
        backgroundColor: (props) =>
            props.color ? props.color : colors.PRIMARY,
    },
    alignCenter: {
        textAlign: "center",
        alignSelf: "stretch",
        height: "inherit",
    },
});

const LinearLoader = (props) => {
    const { color, size, borderRadius, backgroundColor, className, ...rest } =
        props;
    let styleClasses = StyledLinearProgress(props);
    return (
        <div className={styleClasses.alignCenter}>
            <LinearProgress
                thickness={props.thickness ? props.thickness : 6}
                classes={{
                    root: `${styleClasses.root} ${className}`,
                    bar: `${styleClasses.bar}`,
                }}
                {...rest}
            />
        </div>
    );
};

export default LinearLoader;
