import React, { Component } from "react";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { Button, Tooltip } from "@material-ui/core";
import { colors } from "../../Utilities/Constants";

/*
 * * @props
 * * label: string
 * * variant: string (values: contained/outlined)
 * * classes:
 * * onClick: function
 * * startIcon: component
 * * endIcon: component
 * * fullWidth: bool
 */

const useStyles = makeStyles({
    arrow: {
        color: "#000000",
    },
    tooltip: {
        background: "#000000",
        color: "#ffffff",
        padding: "10px",
        fontSize: "0.8em",
        borderRadius: 8,
    },
});

const TooltipComp = React.forwardRef((props, ref) => {
    const {
        textColor,
        indicator,
        selectionFollowsFocus,
        fullWidth,
        ...rest
    } = props;
    let styledClasses = useStyles(props);
    return <Tooltip arrow ref={ref} classes={styledClasses} {...rest} />;
});

export default TooltipComp;
