import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import queryString from "query-string";
import {
    Paper,
    Container,
    Typography,
    IconButton,
    TextField,
} from "@material-ui/core";
import {
    isLoading,
    registerStaffMember,
    fetchStaffMembers,
    deleteStaffMember,
    updateStaffMember,
} from "../../store/action";
import ConfirmDialog from "../../components/Dialogs/ConfirmDialog";
import TooltipComp from "../../components/UIComponents/TooltipComp";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import MActionCard from "../../components/UIComponents/MActionCard";
import ButtonComp from "../../components/UIComponents/ButtonComp";
import CircularLoader from "../../components/UIComponents/CircularLoader";
import EmptyComp from "../../components/UIComponents/EmptyComp";
import RegisterMember from "../../components/Dialogs/RegisterMember";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import OrgServices from "../../services/org.services";
import MembersListing from "../../components/UIComponents/MembersListing";
import UserRolesChangeDialog from "../../components/Dialogs/UserRoleChangeDialog";
import { STAFF_MEMBERS_TBL_COL } from "../../utilities/material-table-contants";

class RolesDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isRegisterMemberOpen: false,
            isChangeRoleDialogOpen: false,
            selectedMember: null,
            term: "",
            controlData: null,
        };
        this.OrgServices = new OrgServices();
    }

    componentDidMount() {
        if (!this.props.staffMembersData) {
            this.props.fetchStaffMembers(this.props.orgData.orgId);
        }
    }

    onChange = (e, value, type) => {
        if (type) {
            this.setState({
                [type]: value.trim(),
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value,
            });
        }
    };

    toggleRegisterMemberDialog = (e) => {
        this.setState((prevState) => {
            return {
                isRegisterMemberOpen: !prevState.isRegisterMemberOpen,
            };
        });
    };

    toggleChangeRoleDialog = (e, data) => {
        this.setState((prevState) => {
            return {
                isChangeRoleDialogOpen: !prevState.isChangeRoleDialogOpen,
                controlData: data ? data : null,
            };
        });
    };

    render() {
        const { orgData, groups } = this.props;

        return (
            <>
                <div className="members-dashboard">
                    <div className="page-title">
                        <Typography
                            variant="h1"
                            component="h1"
                            className="page-header"
                        >
                            Manage Staff & Roles
                        </Typography>
                        <ButtonComp
                            variant="outlined"
                            color="#000000"
                            onClick={() => null}
                            startIcon={<AddCircleIcon />}
                            onClick={(e) => this.toggleRegisterMemberDialog(e)}
                            className="page-create-btn"
                            style={{
                                margin: "5px 0 0",
                                marginLeft: "auto",
                            }}
                        >
                            Add New Staff Member
                        </ButtonComp>
                        <TooltipComp placement="top" title="Register New Event">
                            <IconButton
                                aria-label="Register New Event"
                                className="mobile-only-page-create-btn"
                                style={{
                                    margin: "0",
                                    marginLeft: "auto",
                                }}
                                onClick={(e) =>
                                    this.toggleRegisterMemberDialog(e)
                                }
                            >
                                <AddCircleIcon
                                    fontSize="inherit"
                                    // style={{ color: colors.DANGER }}
                                />
                            </IconButton>
                        </TooltipComp>
                    </div>
                    <Paper elevation={0} className={`cardList`}>
                        <MActionCard
                            data={{
                                media: (
                                    <PersonAddIcon style={{ fontSize: 50 }} />
                                ),
                                title: "New Staff Member",
                                description:
                                    "Register new staff member for your organisation. The newly registered member will recieve username and temporary password to their email address.",
                            }}
                            btnLabel="Add New Staff Member"
                            onClick={this.toggleRegisterMemberDialog}
                            noEllipsis={true}
                        />
                    </Paper>
                    <MembersListing
                        //noTitle
                        title="Staff Members"
                        noElevation
                        noControls
                        staffMembers
                        columns={STAFF_MEMBERS_TBL_COL}
                        fetchStudentResults={this.props.fetchStudentResults}
                        deleteMember={this.props.deleteUser}
                        changeRole={this.toggleChangeRoleDialog}
                        orgData={this.props.orgData}
                        data={this.props.staffMembersData}
                    />
                </div>

                <RegisterMember
                    open={this.state.isRegisterMemberOpen}
                    handleClose={this.toggleRegisterMemberDialog}
                    isUser={true}
                    groupsData={this.props.groups}
                    registerMember={this.props.registerUser}
                    orgData={this.props.orgData}
                    isLoading={this.props.isLoading}
                    message={this.props.message}
                />

                {this.state.isChangeRoleDialogOpen && (
                    <UserRolesChangeDialog
                        open={this.state.isChangeRoleDialogOpen}
                        handleClose={this.toggleChangeRoleDialog}
                        updateUser={this.props.updateUser}
                        message={this.props.message}
                        type="roles"
                        data={this.state.controlData}
                        orgData={this.props.orgData}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        orgData: state.appState.orgData,
        isLoading: state.appState.isLoading,
        message: state.appState.message,
        staffMembersData: state.appState.staffMembersData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchStaffMembers: (orgId) => (
            dispatch(fetchStaffMembers(orgId)), dispatch(isLoading(true))
        ),
        registerUser: (params) => (
            dispatch(registerStaffMember(params)), dispatch(isLoading(true))
        ),
        deleteUser: (params) => dispatch(deleteStaffMember(params)),
        updateUser: (params) => dispatch(updateStaffMember(params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RolesDashboard);
