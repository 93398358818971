import { actionButton } from "aws-amplify";
import { actionConstants } from "../Utilities/Constants";
import moment from "moment";

const defaultState = {
    insights: {},
    orgInsights: {},
    groupInsights: {},
    monthlyPerf: null,
};

const insightStateReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actionConstants.FETCH_INSIGHT:
            let insght = { ...state.insights };
            insght[action.payload.year] = insght[action.payload.year]
                ? { ...insght[action.payload.year] }
                : {};
            insght[action.payload.year][action.payload.insightType] = insght[
                action.payload.year
            ][action.payload.insightType]
                ? insght[action.payload.year][action.payload.insightType]
                : {};
            if (action.payload.type === "month") {
                insght[action.payload.year][action.payload.insightType][
                    action.payload.type
                ] = insght[action.payload.year][action.payload.insightType][
                    action.payload.type
                ]
                    ? {
                          ...insght[action.payload.year][
                              action.payload.insightType
                          ][action.payload.type],
                      }
                    : {};
                insght[action.payload.year][action.payload.insightType][
                    action.payload.type
                ][action.payload.period] = action.payload.data
                    ? action.payload.data
                    : {};
            } else {
                insght[action.payload.year][action.payload.insightType][
                    action.payload.type
                ] = action.payload.data ? action.payload.data : {};
            }
            return {
                ...state,
                insights: insght,
            };
        case actionConstants.FETCH_GROUP_INSIGHT:
            return {
                ...state,
                groupInsights: {
                    ...state.groupInsights,
                    [action.payload.id]: action.payload.data,
                },
            };
        case actionConstants.FETCH_ORG_INSIGHT:
            return {
                ...state,
                orgInsights: {
                    ...state.orgInsights,
                    [action.payload.id]: action.payload.data,
                },
            };
        case actionConstants.FETCH_MONTHLY_PERF:
            return {
                ...state,
                monthlyPerf: action.payload,
            };
        case actionConstants.USER_LOGOUT:
            return {
                ...defaultState,
            };
        default:
            return state;
    }
};

export default insightStateReducer;
