import React from "react";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { Fab } from "@material-ui/core/";
import TooltipComp from "./TooltipComp";
import { colors } from "../../utilities/Constants";

const useStyles = makeStyles({
    root: {
        background: (props) =>
            props.bckColor ? lighten(props.bckColor, 0.2) : "inherit",
        color: (props) => (props.color ? props.color : "inherit"),
        "&:hover": {
            background: (props) =>
                props.bckColor ? props.bckColor : "inherit",
        },
    },
});

const FloatingActionButton = React.forwardRef((props, ref) => {
    const { bckColor, color, className, title, children, ...rest } = props;
    let styleClasses = useStyles(props);
    return (
        <TooltipComp title={props.title ? props.title : ""}>
            <Fab
                ref={ref}
                variant="round"
                size="large"
                classes={{
                    root: `floating-left-button ${styleClasses.root} ${className}`,
                }}
                {...rest}
            >
                {props.children}
            </Fab>
        </TooltipComp>
    );
});

export default FloatingActionButton;
