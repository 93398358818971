import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Checkbox } from "@material-ui/core";
import { colors } from "../../utilities/constants";

const GreenCheckbox = withStyles({
    root: {
        "&$checked": {
            color: colors.GREEN,
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default GreenCheckbox;
