import HttpClient from "./HttpClient";

class MainServices {
    constructor() {
        this.HttpClient = new HttpClient();
    }

    saveSeries(params = {}) {
        return this.HttpClient.post("manageExams", "/series", params);
    }

    getSeries(params = {}) {
        let path;
        if (params.orgId) {
            path = `/series?orgId=${params.orgId}`;
            if (params.key) {
                path = `${path}&offset=${params.key}`;
            }
            return this.HttpClient.get("manageExams", path);
        } else {
            throw new Error("Invalid request");
        }
    }

    updateSeries(params = {}) {
        const { type, ...newParams } = params;
        const path = `/series?type=${type}`;
        return this.HttpClient.put("manageExams", path, newParams);
    }

    seriesStatus(params = {}) {
        const { type, ...newParams } = params;
        const path = `/series?type=${type}`;
        return this.HttpClient.put("manageExams", path, newParams);
    }

    deleteSeries(params = {}) {
        let path;
        if (params.seriesId && params.orgId) {
            path = `/series?seriesId=${params.seriesId}&orgId=${params.orgId}`;
        }
        return this.HttpClient.delete("manageExams", path, {});
    }

    saveExam(params = {}) {
        return this.HttpClient.post("manageExams", "/exam", params);
    }

    updateExam(params = {}) {
        const { type, action, ...newParams } = params;
        let path;
        if (newParams.examId && newParams.seriesId) {
            path = `/exam?type=${type}`;
        } else {
            return Promise.reject(new Error("Invalid request!"));
        }

        return this.HttpClient.put("manageExams", path, newParams);
    }

    getExamDetails(params = {}) {
        let path;
        if (params.examId && params.orgId && params.seriesId) {
            path = `/examDetails?examId=${params.examId}&seriesId=${params.seriesId}&orgId=${params.orgId} `;
        } else {
            return Promise.reject(new Error("Invalid request!"));
        }
        return this.HttpClient.get("manageExams", path);
    }

    deleteExam(params = {}) {
        let path;
        if (params.examId && params.orgId && params.seriesId) {
            path = `/exam?examId=${params.examId}&seriesId=${params.seriesId}&orgId=${params.orgId}`;
        } else {
            return Promise.reject(new Error("Invalid request!"));
        }
        return this.HttpClient.delete("manageExams", path, {});
    }

    getExams(params = {}) {
        let path;
        if (params.seriesId) {
            path = `/exams?seriesId=${params.seriesId}`;
            if (params.key) {
                path = `${path}&offset=${params.key}`;
            }
        } else {
            return Promise.reject(new Error("Invalid request!"));
        }
        return this.HttpClient.get("manageExams", path);
    }

    saveQuestions(params = {}) {
        return this.HttpClient.post("manageExams", "/questions", params);
    }

    getQuestions(params = {}) {
        let path;
        if (params.examId && params.orgId && params.seriesId) {
            path = `/questions/?examId=${params.examId}&seriesId=${params.seriesId}&orgId=${params.orgId}`;
        } else {
            return Promise.reject(new Error("Invalid request!"));
        }
        return this.HttpClient.get("manageExams", path, params);
    }

    updateQuestion(params = {}) {
        return this.HttpClient.put("manageExams", "/question", params);
    }

    getQBank(params = {}) {
        let path;
        if (params.orgId) {
            path = `/qbank?orgId=${params.orgId}`;
            if (params.key) {
                path = `${path}&offset=${params.key}`;
            }
        } else {
            return Promise.reject(new Error("Invalid request!"));
        }
        return this.HttpClient.get("manageExams", path, params);
    }

    getLibraryQs(params = {}) {
        let path;
        if (params.orgId) {
            path = `/qlib?orgId=${params.orgId}`;
            if (params.key) {
                path = `${path}&offset=${params.key}`;
            }
        } else {
            return Promise.reject(new Error("Invalid request!"));
        }
        return this.HttpClient.get("manageExams", path, params);
    }

    getDashboardCounts(params = {}) {
        let path;
        if (params.orgId) {
            path = `/dashboard?orgId=${params.orgId}`;
        } else {
            return Promise.reject(new Error("Invalid request!"));
        }
        return this.HttpClient.get("manageExams", path, params);
    }

    getSeriesArchives(params = {}) {
        let path;
        if (params.orgId) {
            path = `/seriesArchives/?orgId=${params.orgId}`;
        } else {
            return Promise.reject(new Error("Invalid request!"));
        }
        return this.HttpClient.get("manageExams", path, {});
    }

    getExamArchives(params = {}) {
        let path;
        if (params.orgId) {
            path = `/examArchives/?orgId=${params.orgId}`;
        } else {
            return Promise.reject(new Error("Invalid request!"));
        }
        return this.HttpClient.get("manageExams", path, {});
    }

    restoreEntity(params = {}) {
        let path;
        const { type, itemStatus, ...newParams } = params;
        if (params.type) {
            path = `/restore/?type=${params.type}`;
        } else {
            return Promise.reject(new Error("Invalid request!"));
        }
        return this.HttpClient.post("manageExams", path, newParams);
    }
}

export default MainServices;
