import React, { useState, useEffect } from "react";
import {
    withStyles,
    createMuiTheme,
    ThemeProvider,
    fade,
} from "@material-ui/core/styles";
import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    CardActionArea,
    IconButton,
    InputBase,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import FilterListIcon from "@material-ui/icons/FilterList";
import SortIcon from "@material-ui/icons/Sort";
import AddBoxIcon from "@material-ui/icons/AddBox";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import FolderSpecialIcon from "@material-ui/icons/FolderSpecial";
import TooltipComp from "./TooltipComp";
import ButtonComp from "./ButtonComp";
import { colors } from "../../utilities/constants";
import { debounceFn } from "../../utilities/helper";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = (theme) => ({
    root: {
        width: 200,
    },
    media: {
        height: 80,
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        border: `1px solid ${fade(colors.BODY1, 0.15)}`,
        //backgroundColor: fade(colors.BODY1, 0.15),
        // "&:hover": {
        //     backgroundColor: fade(colors.BODY1, 0.25),
        // },
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            //marginLeft: theme.spacing(1),
            width: "auto",
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    inputRoot: {
        color: "inherit",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "12ch",
            "&:focus": {
                width: "20ch",
            },
        },
    },
    dense: {
        padding: "10px 14px",
    },
});

const ListPageControls = (props) => {
    const { classes, data } = props;
    // const [searchQuery, setSearchQuery] = useState("");
    // useEffect(() => {
    //     if (props.updateSearchQuery) {
    //         //debounceFn(props.updateSearchQuery(searchQuery), 250);
    //         props.updateSearchQuery(searchQuery);
    //     }
    // }, [searchQuery]);

    return (
        <div className="listControls" style={props.style}>
            <div className="control-block listControls__left">
                {props.showAddQ && (
                    <>
                        <ButtonComp
                            varinat="outlined"
                            color="#ffffff"
                            size="small"
                            bckColor={
                                props.disableCondition
                                    ? colors.BODY2
                                    : colors.PRIMARY
                            }
                            startIcon={<AddBoxIcon />}
                            onClick={props.addNewQuestions}
                            disabled={props.disableCondition}
                        >
                            New Questions
                        </ButtonComp>

                        <ButtonComp
                            varinat="outlined"
                            color="#ffffff"
                            size="small"
                            bckColor={
                                props.disableCondition
                                    ? colors.BODY2
                                    : colors.PRIMARY
                            }
                            //startIcon={<LibraryAddIcon />}
                            startIcon={<FolderSpecialIcon />}
                            onClick={props.toggleQBank}
                            disabled={props.disableCondition}
                        >
                            Add from Bank
                        </ButtonComp>
                    </>
                )}
                {props.showSearch && (
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            style={{ backgroundColor: "transparent" }}
                            inputProps={{
                                "aria-label": "search",
                            }}
                            value={props.searchQuery}
                            //onChange={(e) => setSearchQuery(e.target.value)}
                            onChange={(e) =>
                                props.updateSearchQuery(e.target.value)
                            }
                        />
                    </div>
                )}
                {props.showFilterDropdown && (
                    <FormControl
                        variant="outlined"
                        // className={classes.formControl}
                        style={{ minWidth: 150 }}
                    >
                        <InputLabel id="list-control-dropdown">
                            Filter
                        </InputLabel>
                        <Select
                            labelId="list-control-dropdown"
                            label="Filter"
                            value={props.filterValue}
                            onChange={(e) =>
                                props.handleChange(e, "filter", e.target.value)
                            }
                            //displayEmpty
                            // className={classes.selectEmpty}
                            classes={{
                                root: classes.dense,
                            }}
                            inputProps={{
                                "aria-label": "Dorpdown Filter for Series",
                            }}
                        >
                            <MenuItem value="all">All</MenuItem>
                            {props.menuItems.map((item, i) => (
                                <MenuItem
                                    key={`${item.value}-i`}
                                    value={item.value}
                                >
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </div>
            <div className="control-block listControls__right">
                {props.showSort && (
                    <ButtonComp
                        varinat="outlined"
                        color={colors.BODY1}
                        startIcon={<SortIcon />}
                        onClick={(e) => props.handleChange(e, "sort", true)}
                    >
                        Sort
                    </ButtonComp>
                )}

                {props.showFilter && (
                    <TooltipComp title="Filter List" placement="top">
                        <ButtonComp
                            varinat="outlined"
                            color={colors.BODY1}
                            startIcon={<FilterListIcon />}
                        >
                            Filter
                        </ButtonComp>
                    </TooltipComp>
                )}
            </div>
        </div>
    );
};

export default withStyles(useStyles)(ListPageControls);
