import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
    ClickAwayListener,
    Container,
    IconButton,
    Paper,
    Typography,
    FormControl,
    InputLabel,
    Select,
} from "@material-ui/core";
import DashboardCard from "../components/UIComponents/DashboardCard";
import {
    getDashboardCounts,
    fetchQuote,
    fetchGroups,
    updateDashboard,
    fetchInsight,
    updateMonthlyPerf,
} from "../store/action";
import LineChartComp from "../components/Dashboard/LineChartComp";
import InsightCard from "../components/Insights/InsightCard";
import LeaderBoard from "../assets/LeaderBoard.svg";
import EditIcon from "@material-ui/icons/Edit";
import EmptyComp from "../components/UIComponents/EmptyComp";
import AnalyticsArt from "../assets/AnalyticsArt.svg";
import { loadingBar } from "aws-amplify";
import CircularLoader from "../components/UIComponents/CircularLoader";
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editLeaderBoardGroup: false,
            period: moment().subtract(1, "months").format("MMM").toLowerCase(),
            year: moment().subtract(1, "months").format("YYYY"),
        };
    }

    toggleLeaderBoardSelect = (bool) => {
        this.setState({
            editLeaderBoardGroup: bool,
        });
    };

    updateLeadeBoardSelect = (e, value) => {
        if (value) {
            this.props.updateDashboard({
                orgId: this.props.orgData.orgId,
                leaderBoardView: value,
            });
        } else if (
            !this.props.orgData.dashboard ||
            this.props.orgData.dashboard.leaderBoardView !== e.target.value
        ) {
            this.props.updateDashboard({
                orgId: this.props.orgData.orgId,
                leaderBoardView: e.target.value,
            });
        }
        // fetch or check leaderboard data in didUPdate
        this.toggleLeaderBoardSelect(false);
    };

    fetchLeaderBoardData = () => {
        if (
            this.props.orgData.dashboard.leaderBoardView ===
            this.props.orgData.orgId
        ) {
            if (
                !this.props.orgInsights[
                    `${this.props.orgData.orgId}-${this.state.period}-${this.state.year}`
                ]
            ) {
                this.props.fetchInsight({
                    insightType: "org",
                    type: "month",
                    entityType: "org",
                    orgId: this.props.orgData.orgId,
                    period: this.state.period,
                    year: this.state.year,
                });
            }
        } else {
            if (
                !this.props.groupInsights[
                    `${this.props.orgData.orgId}-${this.state.period}-${this.state.year}`
                ]
            ) {
                this.props.fetchInsight({
                    insightType: "org",
                    type: "month",
                    entityType: "groups",
                    orgId: this.props.orgData.orgId,
                    period: this.state.period,
                    year: this.state.year,
                });
            }
        }
    };

    componentDidUpdate(prevProps) {
        if (
            prevProps.orgData.dashboard.leaderBoardView !==
            this.props.orgData.dashboard.leaderBoardView
        ) {
            this.fetchLeaderBoardData();
        }
    }

    componentDidMount() {
        const { period, year } = this.state;
        if (!this.props.quote) {
            this.props.fetchQuote();
        }

        // if (!document.getElementById("twitterTimeline")) {
        const script = document.createElement("script");
        script.src = "https://platform.twitter.com/widgets.js";
        script.type = "text/javascript";
        script.id = "twitterTimeline";
        script.async = true;
        script.defer = true;

        document.body.appendChild(script);
        //}

        // if (insightType === "year") {
        //     id = `${this.props.orgData.orgId}-${year}`;
        // } else {
        //     id = `${this.props.orgData.orgId}-${period}-${year}`;
        // }

        let id = `${this.props.orgData.orgId}-${period}-${year}`;
        // Fetch Monthly Performance Data
        if (!this.props.monthlyPerf) {
            let mPerf = JSON.parse(localStorage.getItem("monthlyPerf"));
            if (
                mPerf &&
                mPerf.data &&
                mPerf.timestamp &&
                moment(moment.unix(mPerf.timestamp)).month() ===
                    moment().month()
                // && moment(moment.unix(mPerf.timestamp)).diff(moment(), "days") <
                //moment().date()
            ) {
                this.props.updateMonthlyPerf(mPerf.data);
            } else {
                this.props.fetchInsight({
                    entityType: "monthlyPerf",
                    orgId: this.props.orgData.orgId,
                });
            }
        }

        // fetch leaderboard Data for selected Entity
        this.fetchLeaderBoardData();
        // Fetch Groups
        let groups = this.props.groups;
        if (!groups || (groups.length > 0 && groups.length === 1)) {
            // Fetch Groups
            this.props.fetchGroups(this.props.orgData.orgId);
        }
    }

    getGroupName = (id) => {
        if (id === this.props.orgData.orgId) {
            return this.props.orgData.orgName;
        } else {
            // return this.props.groups
            //     ? this.props.groups.find((g) => g.groupId === id).title
            //     : "";

            if (this.props.groups) {
                if (this.props.groups.find((g) => g.groupId === id)) {
                    return this.props.groups.find((g) => g.groupId === id)
                        .title;
                } else {
                    setTimeout(() => {
                        this.updateLeadeBoardSelect(
                            null,
                            this.props.orgData.orgId
                        );
                    }, 10);
                }
            }
        }
    };

    formatPerfData = (data) => {
        if (this.props.groups && data) {
            let formattedData = data.map((d) => {
                if (d.groupId === "notGrouped") {
                    // return {
                    //     name: "Not Grouped",
                    //     average: 10,
                    // };
                } else {
                    let group = this.props.groups.find(
                        (g) => g.groupId === d.groupId
                    );

                    return {
                        name: group && group.title ? group.title : "",
                        average: Number(
                            ((d.marksObtained / d.totalMarks) * 100).toFixed(2)
                        ),
                    };
                }
            });
            return formattedData;
        }
        return null;
    };

    render() {
        const {
            monthlyPerf,
            groupInsights,
            orgInsights,
            dashboardData,
            orgData,
        } = this.props;
        const { period, year } = this.state;
        let leaderBoardData;
        let perfData;
        let id = `${orgData.orgId}-${period}-${year}`;

        // if (groupInsights && groupInsights[id]) {
        //     if (groupInsights[id].groupWise) {
        //         perfData = this.formatPerfData(
        //             this.props.groupInsights[id].groupWise
        //         );
        //     } else {
        //         perfData = [];
        //     }
        // }

        if (orgData.dashboard.leaderBoardView === orgData.orgId) {
            if (orgInsights[id]) {
                leaderBoardData = orgInsights[id].studentWise
                    ? orgInsights[id].studentWise.slice(0, 5)
                    : [];
            } else {
                leaderBoardData = null;
            }
        } else if (groupInsights && groupInsights[id]) {
            if (groupInsights[id].groupWise) {
                let x = groupInsights[id].groupWise.find(
                    (g) => g.groupId === orgData.dashboard.leaderBoardView
                );
                if (x) {
                    leaderBoardData = x.studentWise.slice(0, 5);
                } else {
                    leaderBoardData = [];
                }
            } else {
                leaderBoardData = [];
            }
        } else {
            leaderBoardData = null;
        }

        // if (this.props.groupInsights && this.props.groupInsights[id]) {
        //     if (orgData.dashboard.leaderBoardView === orgData.orgId) {
        //         leaderBoardData = this.props.insights[id].studentWise.slice(
        //             0,
        //             5
        //         );
        //         perfData = this.formatPerfData(
        //             this.props.insights[id].groupWise
        //         );
        //     } else if (
        //         this.props.insights[
        //             moment().subtract(1, "months").format("YYYY")
        //         ]["org"]["month"][
        //             moment().subtract(1, "months").format("MMM").toLowerCase()
        //         ].groupWise
        //     ) {
        //         let x = this.props.insights[
        //             moment().subtract(1, "months").format("YYYY")
        //         ]["org"]["month"][
        //             moment().subtract(1, "months").format("MMM").toLowerCase()
        //         ].groupWise.find(
        //             (g) =>
        //                 g.groupId ===
        //                 this.props.orgData.dashboard.leaderBoardView
        //         );
        //         if (x) {
        //             leaderBoardData = x.studentWise.slice(0, 5);
        //         } else {
        //             leaderBoardData = [];
        //         }
        //         perfData = this.formatPerfData(
        //             this.props.insights[
        //                 moment().subtract(1, "months").format("YYYY")
        //             ]["org"]["month"][
        //                 moment()
        //                     .subtract(1, "months")
        //                     .format("MMM")
        //                     .toLowerCase()
        //             ].groupWise
        //         );
        //     }
        // }

        return (
            <>
                <div className="dashboard">
                    <Paper elevation={0} className="qod fadeInUp">
                        {this?.props?.quote?.text && (
                            <>
                                {`${this.props.quote.text} ${
                                    this.props.quote.author
                                        ? `- ${this.props.quote.author}`
                                        : ""
                                }`}
                            </>
                        )}
                    </Paper>
                    <div className="infoBlocks">
                        <DashboardCard
                            onClick={() => null}
                            title="Series"
                            data={
                                orgData.resources
                                    ? new Intl.NumberFormat("en-IN", {
                                          notation: "compact",
                                          compactDisplay: "short",
                                      }).format(orgData.resources.series)
                                    : null
                            }
                        />
                        <DashboardCard
                            onClick={() => null}
                            title="Exams"
                            data={
                                orgData.resources
                                    ? new Intl.NumberFormat("en-IN", {
                                          notation: "compact",
                                          compactDisplay: "short",
                                      }).format(orgData.resources.exams)
                                    : null
                            }
                        />
                        <DashboardCard
                            onClick={() => null}
                            title="Questions"
                            data={
                                orgData.resources
                                    ? new Intl.NumberFormat("en-IN", {
                                          notation: "compact",
                                          compactDisplay: "short",
                                      }).format(orgData.resources.questions)
                                    : null
                            }
                        />
                        {/* <DashboardCard
                onClick={() => null}
                title="Groups"
                data={
                    orgData.resources
                        ? new Intl.NumberFormat("en-IN", {
                              notation: "compact",
                              compactDisplay: "short",
                          }).format(orgData.resources.groups)
                        : null
                }
            /> */}
                        <DashboardCard
                            onClick={() => null}
                            title="Students"
                            data={
                                orgData.resources
                                    ? new Intl.NumberFormat("en-IN", {
                                          notation: "compact",
                                          compactDisplay: "short",
                                      }).format(orgData.resources.members)
                                    : null
                            }
                        />

                        {/* <DashboardCard
                        onClick={() => null}
                        title="Earnings"
                        data={
                            orgData.resources
                                ? new Intl.NumberFormat("en-GB", {
                                      style: "currency",
                                      currency: "INR",
                                      notation: "compact",
                                      //compactDisplay: "short",
                                  })
                                      .format(0)
                                      .replace("T", "K")
                                : null
                        }
                    /> */}
                        <DashboardCard
                            onClick={() => null}
                            title="Groups"
                            data={
                                orgData.resources
                                    ? new Intl.NumberFormat("en-IN", {
                                          notation: "compact",
                                          compactDisplay: "short",
                                      }).format(orgData.resources.groups)
                                    : null
                            }
                        />
                    </div>
                    {/* <Paper className="leaderBoard block" elevation={2}>
                <div className="title">Leader Board</div>
                <InsightCard
                    // title="Top 5 Performers"
                    // description="This list is prepared based on the aggregate percentage achieved by the student in the selected duration."
                    type="top"
                    data={null}
                />
        
            </Paper> */}
                    <InsightCard
                        noTitleIcon
                        title="Leader Board"
                        style={{
                            width: "100%",
                        }}
                        secondaryTitle={
                            this.props.orgData.timestamp <
                            moment().startOf("month").unix() ? (
                                <>
                                    for{" "}
                                    {moment()
                                        .subtract(1, "months")
                                        .format("MMM, YYYY")}
                                    {" of "}
                                    {this.state.editLeaderBoardGroup ? (
                                        <ClickAwayListener
                                            onClickAway={() =>
                                                this.toggleLeaderBoardSelect(
                                                    false
                                                )
                                            }
                                        >
                                            <FormControl>
                                                <Select
                                                    native
                                                    value={
                                                        this.props.orgData
                                                            .dashboard
                                                            .leaderBoardView
                                                    }
                                                    onChange={
                                                        this
                                                            .updateLeadeBoardSelect
                                                    }
                                                    inputProps={{
                                                        name: "age",
                                                        id: "age-native-simple",
                                                    }}
                                                    classes={{
                                                        select: "nativeInlineSelect",
                                                    }}
                                                >
                                                    {this.props.orgData.config
                                                        .groupsEnabled ? (
                                                        <>
                                                            {this.props
                                                                .groups ? (
                                                                <>
                                                                    {this.props.groups.map(
                                                                        (
                                                                            g,
                                                                            i
                                                                        ) => (
                                                                            <option
                                                                                key={`lb-g-${i}`}
                                                                                className="opt"
                                                                                value={
                                                                                    g.groupId
                                                                                }
                                                                            >
                                                                                {
                                                                                    g.title
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )}
                                                                    <option
                                                                        className="opt"
                                                                        value={
                                                                            this
                                                                                .props
                                                                                .orgData
                                                                                .orgId
                                                                        }
                                                                    >
                                                                        {
                                                                            this
                                                                                .props
                                                                                .orgData
                                                                                .orgName
                                                                        }
                                                                    </option>
                                                                </>
                                                            ) : (
                                                                <option
                                                                    aria-label="None"
                                                                    value=""
                                                                    className="opt"
                                                                >
                                                                    Loading...
                                                                </option>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <option
                                                            className="opt"
                                                            value={
                                                                this.props
                                                                    .orgData
                                                                    .orgId
                                                            }
                                                        >
                                                            {
                                                                this.props
                                                                    .orgData
                                                                    .orgName
                                                            }
                                                        </option>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </ClickAwayListener>
                                    ) : (
                                        <span>
                                            {this.props.orgData.dashboard
                                                .leaderBoardView ===
                                            this.props.orgData.orgId
                                                ? this.props.orgData.orgName
                                                : this.props.groups
                                                ? this.getGroupName(
                                                      this.props.orgData
                                                          .dashboard
                                                          .leaderBoardView
                                                  )
                                                : `loading...`}
                                            <span
                                                onClick={() =>
                                                    this.toggleLeaderBoardSelect(
                                                        true
                                                    )
                                                }
                                            >
                                                <EditIcon
                                                    fontSize="inherit"
                                                    className="editIcon"
                                                />
                                            </span>
                                        </span>
                                    )}
                                </>
                            ) : (
                                ""
                            )
                        }
                        // description="This list is prepared based on the aggregate percentage achieved by the student in the selected duration."
                        type="top"
                        className="leaderBoard block"
                        emptyDesc={`${
                            this.props.orgData.timestamp >=
                            moment().startOf("month").unix()
                                ? "The data will start showing up from the 1st of next month."
                                : `No exams has been attempted by the students in ${period}, ${year} of ${this.getGroupName(
                                      this.props.orgData.dashboard
                                          .leaderBoardView
                                  )}`
                        }`}
                        data={leaderBoardData}
                        art={LeaderBoard}
                        history={this.props.history}
                    />

                    <Paper className="licenseInfo block" elevation={2}>
                        <div className="title">
                            Performance
                            <span className="subtitle">
                                Overall Monthly Performance
                            </span>
                            {/* {perfData && perfData.length > 0 && (
                                <span className="subtitle">
                                    Last updated on{" "}
                                {moment
                                    .unix(
                                        this.props.groupInsights[id]
                                            .updatedOn
                                    )
                                    .format("MMM DD, YYYY")}
                                    Groupwise
                                </span>
                            )} */}
                        </div>
                        {monthlyPerf ? (
                            monthlyPerf.length > 0 ? (
                                <LineChartComp data={monthlyPerf} />
                            ) : (
                                <EmptyComp
                                    art={AnalyticsArt}
                                    message="No Performance Data Found"
                                    description={`${
                                        this.props.orgData.timestamp >=
                                        moment().startOf("month").unix()
                                            ? "Monthly performance analytics will show up from the 1st of next month."
                                            : ""
                                    }`}
                                />
                            )
                        ) : (
                            <CircularLoader wrapperClass="cloader" />
                        )}
                    </Paper>

                    {/* <Paper className="licenseInfo block" elevation={2}>
                        <div className="title">Earnings</div>
                        <LineChartComp />
                    </Paper> */}
                    {/* <Paper className="tweetBoard block" elevation={2}>
                        <a
                            className="twitter-timeline title"
                            href="https://twitter.com/EduMinOfIndia?ref_src=twsrc%5Etfw"
                        >
                            Tweets by Ministry of Education
                        </a>{" "}
                    </Paper> */}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        dashboardData: state.examsState.dashboardData,
        orgData: state.appState.orgData,
        quote: state.appState.quote,
        groups: state.groupsState.groups,
        groupInsights: state.insightState.groupInsights,
        orgInsights: state.insightState.orgInsights,
        monthlyPerf: state.insightState.monthlyPerf,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchDashboardCounts: (params) => dispatch(getDashboardCounts(params)),
        fetchQuote: () => dispatch(fetchQuote()),
        fetchGroups: (orgId) => dispatch(fetchGroups(orgId)),
        updateDashboard: (params) => dispatch(updateDashboard(params)),
        fetchInsight: (params) => dispatch(fetchInsight(params)),
        updateMonthlyPerf: (data) => dispatch(updateMonthlyPerf(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
