import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Avatar,
    Paper,
    Button,
    CssBaseline,
    TextField,
    FormControlLabel,
    Checkbox,
    Icon,
    Grid,
    Box,
    Container,
    Typography,
    InputAdornment,
    IconButton,
    Stepper,
    Step,
    StepLabel,
    RadioGroup,
    Radio,
    Backdrop,
    Divider,
    FormHelperText,
} from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import { withStyles } from "@material-ui/core/styles";
import ButtonComp from "../../components/UIComponents/ButtonComp";
import CircularLoader from "../../components/UIComponents/CircularLoader";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import BusinessIcon from "@material-ui/icons/Business";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DoneIcon from "@material-ui/icons/Done";
import { colors } from "../../utilities/Constants";
import Logo from "../../assets/logo-2x.png";
import {
    isValidURL,
    renderCopyright,
    getTenantURL,
} from "../../utilities/helper";
import OrgServices from "../../services/org.services";
import {
    updateOrgData,
    isLoading,
    updateIntroDialog,
    switchOrgs,
} from "../../store/action";
import { toast } from "react-toastify";
import OrgStepper from "../../components/OrgStepper";

const useStyles = (theme) => ({
    paper: {
        marginTop: theme.spacing(4),
        paddingTop: 70,
    },
    logo: {
        marginBottom: theme.spacing(1),
        maxWidth: 200,
    },
    stepper: {
        width: "100%",
        padding: "0 0 30px 0",
    },
    active: {
        color: `${colors.PRIMARY} !important`,
    },
    completed: {
        color: `${colors.GREEN} !important`,
    },
    button: {
        margin: theme.spacing(2, 0, 0),
    },
    buttonWrapper: {
        width: "100%",
        margin: theme.spacing(1),
        position: "relative",
    },
    fileInput: {
        display: "none",
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -5,
        marginLeft: -12,
    },
    divider: {
        margin: "10px 0",
    },
});

const GreenRadio = withStyles({
    root: {
        "&$checked": {
            color: colors.GREEN,
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

class SetupOrg extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "",
            category: "",
            org: "",
            subdomain: "",
            config: {
                access: "private",
                accessType: "signup",
            },
            logo: "",
            activeStep: 0,
            loading: false,
            error: {
                type: false,
                org: false,
                subdomain: false,
                access: false,
            },
            enableNext: false,
        };
        this.stepsArray = [
            { name: "Select Type", id: "type" },
            { name: "Name", id: "org" },
            { name: "SubDomain", id: "subdomain" },
            { name: "Configuration", id: "config" },
            { name: "Summary" },
        ];
        this.OrgServices = new OrgServices();
    }

    componentDidMount() {
        if (!this.props.userData) {
            this.props.history.push("/login");
        }
    }

    onChange = (e, specialType) => {
        e.preventDefault();
        e.persist();

        if (
            this.state.error.type ||
            this.state.error.org ||
            this.state.error.subdomain ||
            this.state.error.access
        ) {
            this.setState({
                error: {
                    type: false,
                    org: false,
                    subdomain: false,
                    access: false,
                },
            });
        }

        if (specialType === "config") {
            this.setState(
                {
                    config: {
                        ...this.state.config,
                        [e.target.name]: e.target.value,
                    },
                },
                () => this.enableNextButton()
            );
        } else if (specialType === "subdomain") {
            let k;
            document.all ? (k = e.keyCode) : (k = e.which);
            console.log(k);
            if (
                (k > 64 && k < 91) ||
                (k > 96 && k < 123) ||
                k == 8 ||
                (k >= 48 && k <= 57)
            ) {
                this.setState(
                    {
                        [e.target.name]: e.target.value.toLowerCase(),
                    },
                    () => this.enableNextButton()
                );
            } else {
                alert("Please enter only letter and numeric characters");
            }
        } else {
            this.setState(
                {
                    [e.target.name]: e.target.value,
                },
                () => this.enableNextButton()
            );
        }
    };

    enableNextButton = () => {
        const { activeStep, error } = this.state;
        let newError = error;
        switch (activeStep) {
            case 0:
                if (this.state.type.length === 0) {
                    newError.type = true;
                }
                break;
            case 1:
                if (this.state.org.length === 0) {
                    newError.org = true;
                }
                break;
            case 2:
                if (
                    this.state.subdomain.length === 0 &&
                    !isValidURL(this.state.subdomain)
                ) {
                    newError.subdomain = true;
                }
                break;
            case 3:
                if (this.state.config.access.length === 0) {
                    newError.access = true;
                }
                break;
        }
        if (
            newError.type ||
            newError.org ||
            newError.subdomain ||
            newError.access
        ) {
            this.setState({
                error: newError,
                enableNext: false,
            });
        } else {
            this.setState({
                enableNext: true,
            });
        }
    };

    handleNext = () => {
        const nextStep = this.stepsArray[this.state.activeStep + 1];
        if (this.state.activeStep === this.stepsArray.length - 1) {
            this.setState(
                {
                    enableNext: false,
                    loading: true,
                },
                () => this.handleSubmit()
            );
        } else {
            if (this.stepsArray[this.state.activeStep].id === "subdomain") {
                if (this.state.subdomain.length > 0) {
                    this.setState({
                        loading: true,
                        enableNext: false,
                    });
                    this.OrgServices.verifyOrg({
                        orgId: this.state.subdomain,
                    }).then((res) => {
                        if (res.isOrgAvailable) {
                            this.setState({
                                loading: false,
                                activeStep: this.state.activeStep + 1,
                                enableNext: nextStep.id
                                    ? nextStep.id === "config"
                                        ? this.state.config.access.length > 0
                                        : this.state[nextStep.id].length > 0
                                        ? true
                                        : false
                                    : true,
                            });
                        } else {
                            // toast.error(
                            //     "Domain is already taken. Please try again."
                            // );
                            this.setState({
                                error: {
                                    ...this.state.error,
                                    subdomain: true,
                                },
                                enableNext: false,
                                loading: false,
                            });
                        }
                    });
                } else {
                    this.setState({
                        error: {
                            ...this.state.error,
                            subdomain: true,
                        },
                        enableNext: false,
                        loading: false,
                    });
                }
            } else {
                let eNext = false;
                if (nextStep.id) {
                    if (nextStep.id === "config") {
                        eNext = this.state.config.access.length > 0;
                    } else {
                        eNext = this.state[nextStep.id].length > 0;
                    }
                } else {
                    eNext = true;
                }
                this.setState({
                    activeStep: this.state.activeStep + 1,
                    enableNext: eNext,
                });
            }
        }
    };

    handleBack = () => {
        if (this.state.activeStep === this.stepsArray.length - 1) {
            this.setState(
                {
                    activeStep: 0,
                    loading: false,
                    error: {
                        type: false,
                        org: false,
                        subdomain: false,
                    },
                },
                () => this.enableNextButton()
            );
        } else {
            this.setState(
                {
                    activeStep: this.state.activeStep - 1,
                    error: {
                        type: false,
                        org: false,
                        subdomain: false,
                    },
                },
                () => this.enableNextButton()
            );
        }
    };

    handleSubmit = async () => {
        const { type, category, org, subdomain, config } = this.state;
        const { email } = this.props.userData;
        const username = this.props.userData["sub"];
        this.setState({
            loading: true,
        });
        const res = await this.OrgServices.saveOrgData({
            type,
            category,
            orgName: org,
            orgId: subdomain,
            config: config,
            admins: [username],
            editors: [],
        });
        if (res && res.org) {
            toast.success(`${org} registered successfully`);
            this.setState({
                loading: false,
            });
            this.props.updateOrgData(res.org);
        } else {
            toast.success(res.message);
        }
    };

    getStepComponent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return (
                    <>
                        <Typography variant="body2">You are an...</Typography>
                        <RadioGroup
                            aria-label="organisation type"
                            name="type"
                            value={this.state.type}
                            onChange={this.onChange}
                        >
                            <FormControlLabel
                                label="ORGANISATION"
                                value="org"
                                control={<GreenRadio />}
                            />
                            <FormControlLabel
                                label="INDIVIDUAL"
                                value="ind"
                                control={<GreenRadio />}
                            />
                        </RadioGroup>
                    </>
                );
            case 1:
                return (
                    <>
                        <TextField
                            error={this.state.error.org}
                            variant="outlined"
                            required
                            fullWidth
                            id="orgName"
                            label={`${
                                this.state.type === "org"
                                    ? "Organisation Name"
                                    : "Your Name"
                            }`}
                            name="org"
                            value={this.state.org}
                            onChange={this.onChange}
                            helperText={
                                this.state.type === "org"
                                    ? "Enter your school name, college or coaching name etc. Try to keep it short, 20 characters are good."
                                    : "Enter Your Name. Try to keep it short, 20 characters are good."
                            }
                        />

                        {/* {this.state.type === "org" && (
                            <>
                                <Divider
                                    variant="middle"
                                    className={this.props.classes.divider}
                                />
                                <Typography variant="body2">
                                    Select Category
                                </Typography>
                                <RadioGroup
                                    aria-label="manage private access"
                                    name="category"
                                    value={this.state.category}
                                    onChange={this.onChange}
                                >
                                    <FormControlLabel
                                        label="Regular Education"
                                        value="regular"
                                        control={<GreenRadio />}
                                    />
                                    <FormHelperText className="radio-helptext">
                                        Schools or Colleges
                                    </FormHelperText>
                                    <FormControlLabel
                                        label="Competitive Exams"
                                        value="competitive"
                                        control={<GreenRadio />}
                                    />
                                    <FormHelperText className="radio-helptext">
                                        SSC, IBPS, IIT etc.
                                    </FormHelperText>
                                </RadioGroup>
                            </>
                        )} */}
                    </>
                );
            case 2:
                return (
                    <>
                        <TextField
                            error={this.state.error.subdomain}
                            variant="outlined"
                            required
                            fullWidth
                            id="subdomain"
                            label="Website address"
                            placeholder="type here..."
                            type="text"
                            name="subdomain"
                            value={this.state.subdomain}
                            onChange={(e) => this.onChange(e, "subdomain")}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        https://
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        .gradout.com
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Typography variant="body1" component="div">
                            {this.state.subdomain && (
                                <>
                                    {this.state.error.subdomain ? (
                                        <div
                                            style={{
                                                marginTop: "10px",
                                                color: colors.DANGER,
                                            }}
                                        >
                                            Domain is already taken. Please try
                                            something else.
                                        </div>
                                    ) : (
                                        <div style={{ marginTop: "10px" }}>
                                            Your domain will be{" "}
                                            <span
                                                style={{
                                                    color: colors.GREEN,
                                                    fontWeight: 700,
                                                }}
                                            >
                                                {getTenantURL(
                                                    this.state.subdomain
                                                )}
                                            </span>
                                        </div>
                                    )}
                                </>
                            )}
                        </Typography>
                    </>
                );
            case 3:
                return (
                    <>
                        <Typography variant="body2">
                            Student website access is...
                        </Typography>
                        <RadioGroup
                            aria-label="organisation access"
                            name="access"
                            value={this.state.config.access}
                            onChange={(e) => this.onChange(e, "config")}
                        >
                            <FormControlLabel
                                label="Public. No login required to access the website."
                                value="public"
                                control={<GreenRadio />}
                            />
                            <FormControlLabel
                                label="Private. Login is required to access the website."
                                value="private"
                                control={<GreenRadio />}
                            />
                        </RadioGroup>

                        <Divider className={this.props.classes.divider} />
                        <Typography variant="body2">
                            Manage student signup
                        </Typography>
                        <RadioGroup
                            aria-label="manage private access"
                            name="accessType"
                            value={this.state.config.accessType}
                            onChange={(e) => this.onChange(e, "config")}
                        >
                            <FormControlLabel
                                label="Enable student's signup."
                                value="signup"
                                control={<GreenRadio />}
                            />
                            <FormHelperText className="radio-helptext">
                                Students can register themselves.
                            </FormHelperText>
                            <FormControlLabel
                                label="Disable student's signup."
                                value="none"
                                control={<GreenRadio />}
                            />
                            <FormHelperText className="radio-helptext">
                                No one can register, admin has to register all
                                the students manually.
                            </FormHelperText>
                        </RadioGroup>
                    </>
                );
            case 4:
                return (
                    <>
                        {/* <input
                            accept="image/*"
                            className={this.props.classes.fileInput}
                            id="contained-button-file"
                            type="file"
                        />
                        <label htmlFor="contained-button-file">
                            <Button
                                variant="contained"
                                color="primary"
                                component="span"
                            >
                                Upload Logo
                            </Button>
                        </label> */}
                        <div className="summary">
                            <Typography variant="body2" component="div">
                                Review the details
                            </Typography>
                            <div className="row">
                                {this.state.type.length &&
                                    `Type: ${
                                        this.state.type === "org"
                                            ? "Organisation"
                                            : "Individual"
                                    }`}
                            </div>
                            {/* <div className="row">
                                {this.state.type.length &&
                                    `Category: ${this.state.category}`}
                            </div> */}
                            <div className="row">
                                {this.state.org.length &&
                                    `${
                                        this.state.type === "org"
                                            ? "Organisation Name: "
                                            : "Your Name: "
                                    }${this.state.org}`}
                            </div>
                            <div className="row">
                                {this.state.subdomain.length &&
                                    `Domain: ${getTenantURL(
                                        this.state.subdomain
                                    )}`}
                            </div>
                            <div className="row">
                                {this.state.config.access.length &&
                                    `Access: ${this.state.config.access}`}
                            </div>
                            <div className="row">
                                {this.state.config.access === "private" &&
                                    this.state.config.accessType.length &&
                                    `Access Type: ${this.state.config.accessType}`}
                            </div>
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    render() {
        const { classes } = this.props;
        console.log(this.state);
        return (
            <Container
                component="main"
                maxWidth="sm"
                className="startScreenContainer"
            >
                <img src={Logo} className={classes.logo}></img>
                <CssBaseline />
                <Paper
                    className={`${classes.paper} startScreenPaper setupOrg`}
                    elevation={5}
                >
                    <div className="paper-top-navigation">
                        {this.props.location.src === "/selectOrg" && (
                            <IconButton
                                onClick={() =>
                                    this.props.switchOrgs(this.props.userData)
                                }
                            >
                                <KeyboardBackspaceIcon />
                            </IconButton>
                        )}
                        <ButtonComp
                            onClick={this.props.handleLogout}
                            className="closeIcon"
                            startIcon={<ExitToAppIcon />}
                        >
                            Logout
                        </ButtonComp>
                    </div>
                    <OrgStepper
                        userData={this.props.userData}
                        updateOrgData={this.props.updateOrgData}
                        updateIntroDialog={this.props.updateIntroDialog}
                        history={this.props.history}
                    />
                </Paper>
                <Box mt={5}>{renderCopyright()}</Box>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    console.log(state);
    return {
        isLoading: state.appState.isLoading,
        message: state.appState.message,
        userData: state.appState.userData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        validateUser: (username, password) => (
            dispatch(validateUser()), dispatch(isLoading(true))
        ),
        updateLoading: (bool) => dispatch(isLoading(bool)),
        updateMessage: (msg) => dispatch(updateMessage(msg)),
        updateOrgData: (org) => dispatch(updateOrgData(org)),
        updateIntroDialog: (params) => dispatch(updateIntroDialog(params)),
        switchOrgs: (user) => (
            dispatch(isLoading(true)), dispatch(switchOrgs(user))
        ),
    };
};

export default withStyles(useStyles)(
    connect(mapStateToProps, mapDispatchToProps)(SetupOrg)
);
