import { combineReducers } from "redux";
import { createBrowserHistory } from "history";
import appState from "./appState.reducer";
import groupsState from "./groupsState.reducer";
import examsState from "./examsState.reducer";
import insightState from "./insightState.reducer";
import { connectRouter } from "connected-react-router";

export const history = createBrowserHistory();

const rootReducers = combineReducers({
    router: connectRouter(history),
    appState,
    examsState,
    groupsState,
    insightState,
});

export default rootReducers;
