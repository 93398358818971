import { actionConstants } from "../Utilities/Constants";
import MainServices from "../Services/main.services";
import { push } from "connected-react-router";
import OrgServices from "../services/org.services";
import GroupServices from "../services/group.services";
import InsightsServices from "../services/insights.services";
import { updateDocTitle } from "../utilities/helper";
import moment from "moment";

const MServices = new MainServices();
const OServices = new OrgServices();
const GServices = new GroupServices();
const IServices = new InsightsServices();

export function isLoading(bool) {
    return {
        type: actionConstants.IS_LOADING,
        payload: bool,
    };
}

export function updateAuthentication(bool, userData) {
    return {
        type: actionConstants.UPDATE_AUTHENTICATION,
        payload: {
            status: bool,
            user: userData,
        },
    };
}

export function userLogout() {
    return (dispatch) => {
        return new Promise(function (res, rej) {
            // document.title = "Management Console - Gradout";
            updateDocTitle("Gradout+ • Admin Console", true);
            dispatch({
                type: actionConstants.USER_LOGOUT,
            });
            res();
        }).then(() =>
            dispatch({
                type: actionConstants.RESET_EXAMS_DATA,
            })
        );
    };
}

export function updateUserData(userData) {
    return {
        type: actionConstants.UPDATE_USER_DATA,
        payload: userData,
    };
}

export function updateOrgData(orgData) {
    if (orgData) {
        updateDocTitle(orgData.orgName);
    } else {
        updateDocTitle("Gradout+ • Admin Console", true);
    }

    return {
        type: actionConstants.UPDATE_ORG_DATA,
        payload: orgData,
    };
}

export function updateOrgConfig(config) {
    return (dispatch) => {
        // dispatch(isLoading(true));
        OServices.updateOrgData(config, "config")
            .then((resp) => {
                return new Promise(function (res, rej) {
                    dispatch({
                        type: actionConstants.UPDATE_ORG_CONFIG,
                        payload: config,
                    });
                    res();
                }).then(() => {
                    dispatch(
                        updateMessage({
                            type: "SUCCESS",
                            content: "Configuration updated successfully!",
                        })
                    );
                });
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function updateOrgResources(resourceData) {
    return {
        type: actionConstants.UPDATE_ORG_RESOURCES,
        payload: resourceData,
    };
}

export function updateMessage(obj = {}) {
    return {
        type: actionConstants.UPDATE_MESSAGE,
        payload: obj,
    };
}

export function updateKeys(obj = {}) {
    return {
        type: actionConstants.UPDATE_KEYS,
        payload: obj,
    };
}

export function updateQuestionsData(data, examId) {
    return {
        type: actionConstants.UPDATE_QUESTIONS_DATA,
        payload: {
            data,
            examId,
        },
    };
}

export function updateQBankData(data) {
    return {
        type: actionConstants.UPDATE_QBANK_DATA,
        payload: data,
    };
}

export function updateMonthlyPerf(data) {
    return {
        type: actionConstants.FETCH_MONTHLY_PERF,
        payload: data,
    };
}

export function removeQuestionFromExam(data, examId) {
    return {
        type: actionConstants.REMOVE_QUESTION_FROM_EXAM,
        payload: {
            data,
            examId,
        },
    };
}

export function updateIntroDialog(params) {
    return {
        type: actionConstants.UPDATE_INTRO_DIALOG,
        payload: {
            showDialog: params.showDialog,
            type: params.type,
            data: params.data,
        },
    };
}

export function updateSeriesData(newItem) {
    return {
        type: actionConstants.UPDATE_SERIES_DATA,
        payload: newItem,
    };
}

export function updateDashboardData(data) {
    return {
        type: actionConstants.UPDATE_DASHBOARD_DATA,
        payload: data,
    };
}

export function updateExamsData(newItem) {
    return {
        type: actionConstants.UPDATE_EXAMS_DATA,
        payload: newItem,
    };
}

export function switchOrgs(user) {
    return (dispatch) => {
        return new Promise(function (res, rej) {
            dispatch(
                push({
                    pathname: "/",
                })
            );
            dispatch(userLogout());
            localStorage.removeItem("monthlyPerf");
            dispatch(updateOrgData(null));
            res();
        }).then(() => dispatch(validateOrg(user, false, null, true)));
    };
}

export function validateOrg(user, isLoggedIn, selectedOrgId, isSwitchOrg) {
    return (dispatch) => {
        dispatch(updateAuthentication(isLoggedIn ? true : false, user));
        dispatch(isLoading(true));
        OServices.getOrgData()
            .then((orgData) => {
                if (
                    orgData &&
                    orgData.org &&
                    (orgData.org[0] || orgData.org.orgId)
                ) {
                    if (Array.isArray(orgData.org)) {
                        if (orgData.org.length > 1) {
                            if (!selectedOrgId) {
                                return new Promise(function (res, rej) {
                                    dispatch(isLoading(false));
                                    res();
                                }).then(() =>
                                    dispatch(
                                        push({
                                            pathname: "/selectOrg",
                                            orgs: orgData.org,
                                        })
                                    )
                                );
                            } else {
                                dispatch(
                                    updateOrgData(
                                        orgData.org.find(
                                            (org) => org.orgId === selectedOrgId
                                        )
                                    )
                                );
                            }
                        } else {
                            dispatch(updateOrgData(orgData.org[0]));
                            let daysLeftToExpire = moment
                                .unix(orgData.org[0].licRules.expiry)
                                .diff(moment(), "days");
                            if (daysLeftToExpire <= 5) {
                                // plan is about to expire
                                setTimeout(() => {
                                    dispatch(
                                        updateIntroDialog({
                                            showDialog: true,
                                            type: "PLAN_EXPIRE",
                                            data: daysLeftToExpire,
                                        })
                                    );
                                }, 400);
                            }
                        }
                    } else {
                        if (!isSwitchOrg) {
                            dispatch(updateOrgData(orgData.org));
                        } else {
                            return new Promise(function (res, rej) {
                                dispatch(isLoading(false));
                                res();
                            }).then(() =>
                                dispatch(
                                    push({
                                        pathname: "/selectOrg",
                                        orgs: [orgData.org],
                                    })
                                )
                            );
                        }
                    }
                } else {
                    console.log("redirect for onboarding");
                    return new Promise(function (res, rej) {
                        dispatch(isLoading(false));
                        res();
                    }).then(() => dispatch(push("onboarding")));
                }
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function updateBranding(config, orgData) {
    return (dispatch) => {
        let params = {
            type: config.type,
            action: config.action,
            fileType: config.fileType,
            orgId: orgData.orgId,
            url: config.url,
        };
        if (!orgData[config.type]) {
            params.firstUpload = true;
        }
        // if (config.type === "logo") {
        //     params.logo = config.url;
        // } else if (config.type === "icon") {
        //     params.icon = config.url;
        // }
        OServices.updateBranding(params)
            .then((response) => {
                return new Promise(function (res, rej) {
                    let data = {
                        ...orgData,
                    };
                    if (config.type === "logo") {
                        if (config.url) {
                            // add version to update the state as url will be same.
                            data.logo = `${config.url}?v=${Math.floor(
                                Math.random() * 90 + 10
                            )}`;
                        } else {
                            data.logo = null;
                        }
                    } else if (config.type === "icon") {
                        if (config.url) {
                            data.icon = config.url;
                        } else {
                            data.icon = null;
                        }
                    }

                    dispatch(updateOrgData(data));
                    res();
                }).then(() => {
                    dispatch(isLoading(false));
                    dispatch(
                        updateMessage({
                            type: "SUCCESS",
                            content: `${config.type} ${
                                config.action === "delete"
                                    ? "deleted"
                                    : "updated"
                            } successfully.`,
                        })
                    );
                });
            })
            .catch((error) => {
                if (condif.action === "update") {
                    dispatch(
                        updateBranding(
                            {
                                type: config.type,
                                action: config.action,
                                url: null,
                                fileType: config.fileType,
                            },
                            this.props.orgData
                        )
                    );
                }
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function updateDashboard(params) {
    return (dispatch) => {
        OServices.updateDashboard(params)
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch(updateDashboardData(params.leaderBoardView));
                    res();
                }).then(() => {
                    dispatch(isLoading(false));
                    // dispatch(
                    //     updateMessage({
                    //         type: "SUCCESS",
                    //         content: `${params.title} updated successfully.`,
                    //     })
                    // );
                });
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function fetchQuote() {
    // return (dispatch) => {
    //     fetch("https://quotes.rest/qod")
    //         .then((res) => res.json())
    //         .then((response) => {
    //             dispatch({
    //                 type: actionConstants.UPDATE_DASHBOARD_QUOTE,
    //                 payload: response.contents.quotes[0],
    //             });
    //             // this.setState({
    //             //     qod: response.contents.quotes[0].quote,
    //             // });
    //         });
    // };
    return (dispatch) => {
        fetch("https://type.fit/api/quotes")
            .then((res) => res.json())
            .then((response) => {
                dispatch({
                    type: actionConstants.UPDATE_DASHBOARD_QUOTE,
                    payload: response[moment().dayOfYear()],
                });
                // this.setState({
                //     qod: response.contents.quotes[0].quote,
                // });
            });
    };
}

export function updateBankData(params) {
    return (dispatch) => {
        OServices.updateBankData(params)
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch({
                        type: actionConstants.UPDATE_BANK_DATA,
                        payload: params,
                    });
                    res();
                }).then(() => dispatch(isLoading(false)));
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function fetchBankData(orgId) {
    return (dispatch) => {
        OServices.getBankData({ orgId })
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch({
                        type: actionConstants.UPDATE_BANK_DATA,
                        payload: response.data,
                    });
                    res();
                }).then(() => dispatch(isLoading(false)));
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function fetchMemberTranx(orgId) {
    return (dispatch) => {
        OServices.getMemberTranx({ orgId })
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch({
                        type: actionConstants.UPDATE_MEMBER_TRANX,
                        payload: response.data,
                    });
                    res();
                }).then(() => dispatch(isLoading(false)));
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function fetchLastPayout(orgId) {
    return (dispatch) => {
        OServices.getLastPayout({ orgId })
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch({
                        type: actionConstants.UPDATE_LAST_PAYOUT,
                        payload: response.data,
                    });
                    res();
                }).then(() => dispatch(isLoading(false)));
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function fetchSeriesData(orgId, key) {
    let params = {
        orgId,
    };
    if (key) {
        params.key = key;
    }
    return (dispatch) => {
        MServices.getSeries(params)
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch({
                        type: actionConstants.FETCH_SERIES_DATA,
                        payload: response.data,
                    });
                    dispatch(
                        updateKeys({ type: "series", key: response.offset })
                    );
                    res();
                }).then(() => dispatch(isLoading(false)));
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function fetchExamsData(seriesId, key) {
    //seriesId will be passed as a params
    let params = {
        seriesId,
    };
    if (key) {
        params.key = key;
    }
    return (dispatch) => {
        MServices.getExams(params)
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch({
                        type: actionConstants.FETCH_EXAMS_DATA,
                        payload: {
                            seriesId,
                            exams: response.data,
                        },
                    });
                    dispatch(
                        updateKeys({
                            type: "exams",
                            key: response.offset,
                        })
                    );
                    res();
                }).then(() => dispatch(isLoading(false)));
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function fetchQuestionsData(params, page) {
    return (dispatch) => {
        MServices.getQuestions(params)
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch({
                        type: actionConstants.FETCH_QUESTIONS_DATA,
                        payload: {
                            examId: params.examId,
                            qs: response.data,
                        },
                    });
                    res();
                }).then(() => dispatch(isLoading(false)));
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function fetchQBankData(orgId, key) {
    let params = {
        orgId,
    };
    if (key) {
        params.key = key;
    }
    return (dispatch) => {
        MServices.getQBank(params)
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch({
                        type: actionConstants.FETCH_QBANK_DATA,
                        payload: response.data,
                    });
                    dispatch(
                        updateKeys({ type: "qbank", key: response.offset })
                    );
                    res();
                }).then(() => dispatch(isLoading(false)));
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function fetchLibraryQs(orgId, key) {
    let params = {
        orgId,
    };
    if (key) {
        params.key = key;
    }
    return (dispatch) => {
        MServices.getLibraryQs(params)
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch({
                        type: actionConstants.FETCH_LIBRARY_QS_DATA,
                        payload: response.data,
                    });
                    dispatch(
                        updateKeys({ type: "qbanklib", key: response.offset })
                    );
                    res();
                }).then(() => dispatch(isLoading(false)));
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function registerMember(params) {
    return (dispatch) => {
        OServices.addMember(params)
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch({
                        type: actionConstants.REGISTER_MEMBER,
                        userId: params.userId,
                        groupId: params.mygroup,
                    });
                    dispatch(updateOrgResources({ type: "members", value: 1 }));
                    res();
                    // }).then(() => {
                    dispatch(isLoading(false));
                    dispatch(
                        updateMessage({
                            type: "SUCCESS",
                            content: `Student added successfully.`,
                        })
                    );
                });
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function registerStaffMember(params) {
    return (dispatch) => {
        OServices.addUser(params)
            .then((response) => {
                return new Promise(function (res, rej) {
                    // dispatch({
                    //     type: actionConstants.REGISTER_USER,
                    //     userId: params.userId,
                    //     groupId: params.mygroup,
                    // });
                    dispatch(updateOrgResources({ type: "users", value: 1 }));
                    res();
                    // }).then(() => {
                    dispatch(isLoading(false));
                    dispatch(
                        updateMessage({
                            type: "SUCCESS",
                            content: `Staff Member added successfully. An email has been sent with the credentials.`,
                        })
                    );
                });
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function fetchStaffMembers(orgId) {
    return (dispatch) => {
        OServices.getUsers({ orgId })
            .then((response) => {
                dispatch({
                    type: actionConstants.FETCH_STAFF_MEMBERS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

// deletions

export function deleteSeries(params) {
    return (dispatch) => {
        MServices.deleteSeries(params)
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch({
                        type: actionConstants.DELETE_SERIES,
                        seriesId: params.seriesId,
                        payload: response.data,
                    });
                    dispatch(updateOrgResources({ type: "series", value: -1 }));
                    res();
                }).then(() => {
                    dispatch(isLoading(false));
                    dispatch(
                        updateMessage({
                            type: "SUCCESS",
                            content: `Series deleted successfully.`,
                        })
                    );
                });
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function deleteExam(params) {
    return (dispatch) => {
        MServices.deleteExam({
            examId: params.examId,
            seriesId: params.seriesId,
            orgId: params.orgId,
        })
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch({
                        type: actionConstants.DELETE_EXAM,
                        payload: params,
                        data: response.data,
                    });
                    dispatch(updateOrgResources({ type: "exams", value: -1 }));
                    res();
                }).then(() => {
                    dispatch(isLoading(false));
                    dispatch(
                        updateMessage({
                            type: "SUCCESS",
                            content: `Exam deleted successfully.`,
                        })
                    );
                });
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function deleteStudent(params) {
    return (dispatch) => {
        OServices.deleteMember(params)
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch({
                        type: actionConstants.DELETE_STUDENT,
                        userId: params.userId,
                    });
                    dispatch(
                        updateOrgResources({ type: "members", value: -1 })
                    );
                    res();
                }).then(() => {
                    dispatch(isLoading(false));
                    dispatch(
                        updateMessage({
                            type: "SUCCESS",
                            content: `User deleted successfully.`,
                        })
                    );
                });
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function deleteStaffMember(params) {
    return (dispatch) => {
        OServices.deleteUser(params)
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch({
                        type: actionConstants.DELETE_STAFF_MEMBER,
                        userId: params.userId,
                    });
                    dispatch(
                        updateOrgResources({ type: "staffMembers", value: -1 })
                    );
                    res();
                }).then(() => {
                    dispatch(isLoading(false));
                    dispatch(
                        updateMessage({
                            type: "SUCCESS",
                            content: `Staff Member ${
                                params.name ? params.name : ""
                            } deleted successfully.`,
                        })
                    );
                });
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

// updates

export function updateStaffMember(params) {
    return (dispatch) => {
        OServices.updateUser(params)
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch({
                        type: actionConstants.UPDATE_STAFF_MEMBER,
                        payload: {
                            ...params,
                            rolePermissions: response.rolePermissions,
                        },
                    });
                    res();
                }).then(() => {
                    dispatch(
                        updateMessage({
                            type: "SUCCESS",
                            content: `Staff member ${params.name} updated successfully.`,
                        })
                    );
                });
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function updateSeries(params) {
    return (dispatch) => {
        MServices.updateSeries(params)
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch(updateSeriesData(params));
                    res();
                }).then(() => {
                    dispatch(isLoading(false));
                    dispatch(
                        updateMessage({
                            type: "SUCCESS",
                            content: `Series ${params.title} updated successfully.`,
                        })
                    );
                });
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function updateExam(params) {
    return (dispatch) => {
        MServices.updateExam(params)
            .then((response) => {
                return new Promise(function (res, rej) {
                    if (params.type !== "questions") {
                        dispatch(
                            updateMessage({
                                type: "SUCCESS",
                                content: `${params.title} updated successfully.`,
                            })
                        );
                    }
                    dispatch(updateExamsData(params));
                    res();
                }).then(() => {
                    dispatch(isLoading(false));
                });
            })
            .catch((error) => {
                console.log(error);
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function updateQuestion(params, examId) {
    return (dispatch) => {
        MServices.updateQuestion(params)
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch(updateQuestionsData(params, examId));
                    res();
                }).then(() => {
                    dispatch(isLoading(false));
                    dispatch(
                        updateMessage({
                            type: "SUCCESS",
                            content: `Question updated successfully.`,
                        })
                    );
                });
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

// Save Data
export function saveExam(params, isTopMenu) {
    return (dispatch) => {
        MServices.saveExam({
            ...params,
        })
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch(updateExamsData(response.data));
                    dispatch(updateOrgResources({ type: "exams", value: 1 }));
                    res();
                }).then(() => {
                    dispatch(isLoading(false));
                    dispatch(
                        updateMessage({
                            type: "SUCCESS",
                            content: `${params.title} created successfully.`,
                        })
                    );
                    if (isTopMenu) {
                        dispatch(
                            push({
                                pathname: `/${response.data.seriesId}/examDetails/${response.data.examId}`,
                            })
                        );
                    }
                });
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function saveSeries(params, isTopMenu) {
    return (dispatch) => {
        MServices.saveSeries({
            ...params,
        })
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch(updateSeriesData(response.data));
                    dispatch(updateOrgResources({ type: "series", value: 1 }));
                    res();
                }).then(() => {
                    dispatch(isLoading(false));
                    dispatch(
                        updateMessage({
                            type: "SUCCESS",
                            content: `${params.title} created successfully.`,
                        })
                    );
                    if (isTopMenu) {
                        dispatch(
                            push({
                                pathname: `/seriesDetails/${response.data.seriesId}`,
                            })
                        );
                    }
                });
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function saveQuestions(params) {
    return (dispatch) => {
        MServices.saveQuestions({
            qs: params.qs,
        })
            .then((response) => {
                localStorage.removeItem("qData");
                return new Promise(function (res, rej) {
                    if (params.examId) {
                        dispatch(updateQuestionsData(params.qs, params.examId));
                    }
                    dispatch(updateQBankData(params.qs));
                    dispatch(
                        updateOrgResources({
                            type: "questions",
                            value: params.qs.length,
                        })
                    );
                    res();
                }).then(() => {
                    dispatch(isLoading(false));
                    dispatch(
                        updateMessage({
                            type: "SUCCESS",
                            content: `Questions saved successfully.`,
                        })
                    );
                });
                // .catch((error) => {
                //     dispatch(
                //         updateMessage({
                //             type: "ERROR",
                //             content: error.response
                //              ? error.response.data.message
                //              : error.message,
                //         })
                //     );
                // });
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function getDashboardCounts(params) {
    return (dispatch) => {
        MServices.getDashboardCounts(params)
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch(updateDashboardCounts(response.data));
                    res();
                }).then(() => {
                    dispatch(isLoading(false));
                });
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function fetchStudents(orgId, term) {
    return (dispatch) => {
        OServices.getMembers({ orgId, term })
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch({
                        type: actionConstants.FETCH_STUDENTS_DATA,
                        payload: response.data,
                    });
                    res();
                }).then(() => dispatch(isLoading(false)));
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function fetchStudentResults(params) {
    return (dispatch) => {
        OServices.getStudentResults(params)
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch({
                        type: actionConstants.UPDATE_STUDENT_RESULT,
                        userId: params.userId,
                        // type: params.type,
                        // //period: params.period ? params.period : null,
                        payload: response.data,
                    });
                    res();
                }).then(() => dispatch(isLoading(false)));
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

/* *
 * * -----------------------------
 * * GROUP ACTIONS
 * * -----------------------------
 * */

export function updateGroupData(data) {
    return {
        type: actionConstants.UPDATE_GROUP_DATA,
        payload: data,
    };
}

export function addGroup(params, source) {
    return (dispatch) => {
        GServices.addGroup({
            ...params,
        })
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch({
                        type: actionConstants.ADD_NEW_GROUP,
                        payload: {
                            groupId: response.data,
                            ...params,
                        },
                    });
                    dispatch(updateOrgResources({ type: "groups", value: 1 }));
                    res();
                }).then(() => {
                    dispatch(isLoading(false));
                    dispatch(
                        updateMessage({
                            type: "SUCCESS",
                            content: `${params.title} group created successfully.`,
                        })
                    );
                    if (source) {
                        dispatch(
                            push({
                                pathname: `/groupDetails/${response.data}`,
                            })
                        );
                    }
                });
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function updateGroup(params) {
    const { action, ...newParams } = params;
    return (dispatch) => {
        GServices.updateGroup({
            ...params,
        })
            .then((response) => {
                return new Promise(function (res, rej) {
                    res();
                }).then(() => {
                    return new Promise(function (res, rej) {
                        dispatch(
                            updateGroupData({
                                ...newParams,
                                memAction: action,
                            })
                        );
                        res();
                    }).then(() => {
                        dispatch(isLoading(false));
                        if (params.type === "item") {
                            dispatch(
                                updateMessage({
                                    type: "SUCCESS",
                                    content: `${params.title} updated successfully.`,
                                })
                            );
                        } else if (params.type === "members") {
                            dispatch(
                                updateMessage({
                                    type: "SUCCESS",
                                    content: `Member${
                                        params.members.length > 1 ? "s" : ""
                                    } ${params.action}${
                                        params.action === "add" ? "ed" : "d"
                                    } successfully.`,
                                })
                            );
                        }
                    });
                });
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function fetchGroups(orgId) {
    return (dispatch) => {
        GServices.fetchGroups({
            orgId,
        })
            .then((response) => {
                return new Promise(function (res, rej) {
                    response.data.sort((a, b) => a.timestamp - b.timestamp);
                    dispatch({
                        type: actionConstants.FETCH_GROUPS_DATA,
                        payload: response.data,
                    });
                    res();
                }).then(() => dispatch(isLoading(false)));
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function fetchGroup(params) {
    return (dispatch) => {
        GServices.fetchGroup({
            groupId: params.groupId,
            orgId: params.orgId,
        })
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch(
                        updateGroupData({
                            type: "item",
                            ...response.data,
                        })
                    );
                    res();
                }).then(() => dispatch(isLoading(false)));
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function deleteGroup(params) {
    return (dispatch) => {
        GServices.deleteGroup({
            groupId: params.groupId,
            orgId: params.orgId,
        })
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch({
                        type: actionConstants.DELETE_GROUP,
                        payload: params.groupId,
                    });
                    dispatch(updateOrgResources({ type: "groups", value: -1 }));
                    res();
                }).then(() => {
                    dispatch(isLoading(false));
                    dispatch(
                        updateMessage({
                            type: "SUCCESS",
                            content: `Group deleted successfully.`,
                        })
                    );
                });
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function updateGroupMembers(params) {
    //members is array of userIds
    const { action, newParams } = params;
    return (dispatch) => {
        GServices.updateGroupMembers(params)
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch({
                        type: actionConstants.UPDATE_GROUP_MEMBERS,
                        payload: {
                            ...newParams,
                            memAction: action,
                        },
                    });
                    res();
                }).then(() => {
                    dispatch(isLoading(false));
                    dispatch(
                        updateMessage({
                            type: "SUCCESS",
                            content: `Members ${params.type}ed successfully.`,
                        })
                    );
                });
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

/*
 ** Events
 **
 */

export function addEvent(params) {
    return (dispatch, getState) => {
        OServices.addEvent({
            ...params,
        })
            .then((response) => {
                return new Promise(function (res, rej) {
                    let userData = getState().appState.userData;
                    dispatch({
                        type: actionConstants.UPDATE_EVENT_DATA,

                        payload: {
                            eAction: "add",
                            ...params,
                            ...response.data,
                            creatorId: userData.sub,
                            creatorName: `${userData.name} ${userData.family_name}`,
                        },
                    });
                    // dispatch(updateOrgResources({ type: "groups", value: 1 }));
                    res();
                }).then(() => {
                    dispatch(isLoading(false));
                    dispatch(
                        updateMessage({
                            type: "SUCCESS",
                            content: `${params.title} event created successfully.`,
                        })
                    );
                });
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function fetchEvents(orgId, key) {
    let params = {
        orgId,
    };
    if (key) {
        params.key = key;
    }
    return (dispatch) => {
        OServices.fetchEvents(params)
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch({
                        type: actionConstants.FETCH_EVENTS_DATA,
                        payload: response.data,
                    });
                    dispatch(
                        updateKeys({ type: "events", key: response.offset })
                    );
                    res();
                }).then(() => dispatch(isLoading(false)));
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function updateEvent(params) {
    const { action, ...newParams } = params;
    return (dispatch) => {
        OServices.updateEvent({
            ...params,
        })
            .then((response) => {
                return new Promise(function (res, rej) {
                    res();
                }).then(() => {
                    return new Promise(function (res, rej) {
                        dispatch(
                            updateGroupData({
                                ...newParams,
                                memAction: action,
                            })
                        );
                        res();
                    }).then(() => {
                        dispatch(isLoading(false));
                        if (params.type === "item") {
                            dispatch(
                                updateMessage({
                                    type: "SUCCESS",
                                    content: `${params.title} event updated successfully.`,
                                })
                            );
                        } else if (params.type === "members") {
                            dispatch(
                                updateMessage({
                                    type: "SUCCESS",
                                    content: `Member${
                                        params.members.length > 1 ? "s" : ""
                                    } ${params.action}${
                                        params.action === "add" ? "ed" : "d"
                                    } successfully.`,
                                })
                            );
                        }
                    });
                });
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function fetchSeriesArchives(orgId) {
    return (dispatch) => {
        MServices.getSeriesArchives({ orgId })
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch({
                        type: actionConstants.FETCH_SERIES_ARCHIVE_DATA,
                        payload: response.data,
                    });
                    res();
                }).then(() => dispatch(isLoading(false)));
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function fetchExamArchives(orgId) {
    return (dispatch) => {
        MServices.getExamArchives({ orgId })
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch({
                        type: actionConstants.FETCH_EXAM_ARCHIVE_DATA,
                        payload: response.data,
                    });
                    res();
                }).then(() => dispatch(isLoading(false)));
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function restoreEntity(params) {
    return (dispatch) => {
        MServices.restoreEntity(params)
            .then((response) => {
                return new Promise(function (res, rej) {
                    if (params.type === "series") {
                        dispatch({
                            type: actionConstants.RESTORE_SERIES,
                            seriesId: params.seriesId,
                        });
                    } else if (params.type === "exam") {
                        dispatch({
                            type: actionConstants.RESTORE_EXAM,
                            examId: params.examId,
                            seriesId: params.seriesId,
                            itemStatus: params.itemStatus,
                        });
                    }
                    res();
                }).then(() => {
                    dispatch(isLoading(false));
                    dispatch(
                        updateMessage({
                            type: "SUCCESS",
                            content: `${params.type} restored successfully.`,
                        })
                    );
                });
            })
            .catch((error) => {
                console.log(error);
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

/* *
 * * -----------------------------
 * * INSIGHTS ACTIONS
 * * -----------------------------
 * */

export function fetchInsight(params) {
    let formattedId;
    if (params.type === "month") {
        formattedId = `${params.orgId}-${params.period}-${params.year}`;
    } else if (params.type === "year") {
        formattedId = `${params.orgId}-${params.year}`;
    } else {
        formattedId = params.orgId;
    }
    return (dispatch) => {
        IServices.getInsight({
            id: formattedId,
            type: params.entityType,
        })
            .then((response) => {
                return new Promise(function (res, rej) {
                    if (params.entityType === "org") {
                        dispatch({
                            type: actionConstants.FETCH_ORG_INSIGHT,
                            payload: {
                                type: params.type,
                                period: params.period,
                                year: params.year,
                                insightType: params.insightType,
                                id: formattedId,
                                data: response.data,
                            },
                        });
                    } else if (params.entityType === "groups") {
                        dispatch({
                            type: actionConstants.FETCH_GROUP_INSIGHT,
                            payload: {
                                type: params.type,
                                period: params.period,
                                year: params.year,
                                insightType: params.insightType,
                                id: formattedId,
                                data: response.data,
                            },
                        });
                    } else if (params.entityType === "monthlyPerf") {
                        localStorage.setItem(
                            "monthlyPerf",
                            JSON.stringify({
                                data: response.data,
                                timestamp: moment().unix(),
                            })
                        );
                        dispatch(updateMonthlyPerf(response.data));
                    }

                    res();
                }).then(() => dispatch(isLoading(false)));
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

/* *
 * * -----------------------------
 * * LICENSE ACTIONS
 * * -----------------------------
 * */

export function fetchPlans() {
    return (dispatch) => {
        OServices.getPlans()
            .then((response) => {
                return new Promise(function (res, rej) {
                    dispatch({
                        type: actionConstants.UPDATE_PLANS_DATA,
                        payload: response.data,
                    });
                    res();
                }).then(() => dispatch(isLoading(false)));
            })
            .catch((error) => {
                dispatch(
                    updateMessage({
                        type: "ERROR",
                        content: error.response
                            ? error.response.data.message
                            : error.message,
                    })
                );
            });
    };
}

export function upgradePlan(plan) {
    return {
        type: actionConstants.UPDATE_LICENSE,
        payload: plan,
    };
}
