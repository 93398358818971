import React from "react";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { TextField, CircularProgress } from "@material-ui/core";
import Autocomplete, {
    createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { colors } from "../../Utilities/Constants";

/*
 * * @props
 * * label: string
 * * variant: string (values: contained/outlined)
 * * classes:
 * * onClick: function
 * * startIcon: component
 * * endIcon: component
 * * fullWidth: bool
 */

const filter = createFilterOptions();

const useStyles = makeStyles({
    seriesSelectBox: {
        margin: "10px 0",
    },
    field: {
        borderColor: colors.PRIMARY,
    },
});

const SearchSelector = React.forwardRef((props, ref) => {
    const {
        data,
        handleChange,
        freeTags,
        helperText = "",
        error = false,
        index,
        className,
        ...rest
    } = props;
    let classes = useStyles(props);
    return (
        <Autocomplete
            required
            {...rest}
            options={props.data ? props.data : []}
            className={`${classes.seriesSelectBox} ${
                className ? className : ""
            }`}
            getOptionLabel={(option) =>
                option.title
                    ? option.title
                    : option.userId
                    ? `${option.name} ${
                          option.family_name ? option.family_name : ""
                      }`
                    : option.length
                    ? option
                    : JSON.stringify(option)
            }
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                // Suggest the creation of a new value
                if (params.inputValue !== "" && props.freeTags) {
                    filtered.push(`${params.inputValue}`);
                }

                return filtered;
            }}
            closeIcon={null}
            clearOnEscape={true}
            onChange={(e, val) => {
                props.onChange(e, val, props.name, props.index);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={error}
                    fullWidth
                    required={props.required}
                    className={classes.field}
                    label={props.label}
                    variant="outlined"
                    helperText={helperText}
                    // InputProps={{
                    //     ...params.InputProps,
                    //     endAdornment: (
                    //         <React.Fragment>
                    //             {!data ? (
                    //                 <CircularProgress
                    //                     color="inherit"
                    //                     size={20}
                    //                 />
                    //             ) : null}
                    //             {params.InputProps.endAdornment}
                    //         </React.Fragment>
                    //     ),
                    // }}
                />
            )}
        />
    );
});

export default SearchSelector;
