import React, { Component } from "react";
import { lighten, withStyles } from "@material-ui/core/styles";
import {
    Dialog,
    DialogTitle,
    SvgIcon,
    Typography,
    IconButton,
} from "@material-ui/core";
import { colors, RolesData } from "../../utilities/constants";
import ButtonComp from "../UIComponents/ButtonComp";
import CircularLoader from "../UIComponents/CircularLoader";
import DeleteIcon from "@material-ui/icons/Delete";
import PublicIcon from "@material-ui/icons/Public";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SearchSelector from "../UIComponents/SearchSelector";
import FaceIcon from "@material-ui/icons/Face";
import FolderMoveIcon from "../../assets/svgIcons/FolderMoveIcon";
import AccountRemoveIcon from "../../assets/svgIcons/AccountRemoveIcon";

const useStyles = (theme) => ({
    rightMargin: {
        marginRight: 20,
    },

    icon: {
        fontSize: 80,
    },
    content: {
        textAlign: "center",
        paddingBottom: theme.spacing(1),
    },
    controls: {
        marginTop: theme.spacing(2),
    },
    buttonWrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -8,
        marginLeft: -20,
    },
});

class UserRoleChangeDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roles: [],
            errorMessage: "",
            loading: false,
        };
    }

    onChange = (e, value, name) => {
        if (this.state.errorMessage.length > 0) {
            this.setState({
                errorMessage: "",
            });
        }

        this.setState({
            [name]: value,
        });
    };

    componentDidUpdate(prevProps, prevState) {
        console.log(this.props);
        if (this.props.open) {
            if (
                !prevProps.message.type &&
                this.props.message.type == "SUCCESS"
            ) {
                this.props.handleClose();
            } else if (
                !prevProps.message.type &&
                this.props.message.type == "ERROR"
            ) {
                this.setState({
                    loading: false,
                });
            }
        }
    }

    updateUser = (e) => {
        e.preventDefault();
        const { roles } = this.state;
        let params = {
            userId: this.props.data.userId,
            name: this.props.data.name,
            rolePermissions: roles.map((r) => r.code),
            orgId: this.props.orgData.orgId,
            type: "roles",
        };
        this.setState(
            {
                loading: true,
            },
            () => {
                this.props.updateUser(params);
            }
        );
    };

    render() {
        const { classes, studentsData, groupsData, group, type, data } =
            this.props;

        return (
            <Dialog
                //onClose={this.props.handleClose}
                aria-labelledby="create-new-dialog"
                open={this.props.open}
                classes={{ root: "dialog", paper: "delete-dialog" }}
                BackdropProps={{
                    style: { backgroundColor: "rgba(0,0,0,0.7)" },
                }}
                maxWidth="xs"
                fullWidth
            >
                <div className="paper-top-navigation">
                    <IconButton
                        onClick={this.props.handleClose}
                        className="closeIcon"
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                {type === "roles" && (
                    <FolderMoveIcon className={`${classes.icon} deleteIcon`} />
                )}

                <DialogTitle id="create-new-dialog" className={"paper-title"}>
                    {type === "roles" && "Change Roles & Permissions"}
                </DialogTitle>
                <Typography
                    variant="body1"
                    component="p"
                    className={classes.content}
                >
                    {type === "roles" && (
                        <>
                            {`Chnage the role & permissions for the user ${
                                data.name
                            } ${
                                data.family_name ? data.family_name : ""
                            }. Currently user has ${data.rolePermissions.join(
                                ", "
                            )} roles.`}
                            <span
                                style={{ fontWeight: "bold", display: "block" }}
                            >
                                Select the Roles.
                            </span>
                        </>
                    )}
                </Typography>
                {type === "roles" && (
                    <SearchSelector
                        error={this.state.errorMessage.length > 0}
                        id="roles"
                        name="roles"
                        fullWidth
                        multiple={type === "roles" ? true : false}
                        noOptionsText={
                            type === "roles"
                                ? "No roles found."
                                : "No data found."
                        }
                        data={RolesData ? RolesData : []}
                        loading={RolesData ? false : true}
                        variant="outlined"
                        margin="dense"
                        onChange={this.onChange}
                        value={this.state.roles}
                        label="Select Roles"
                        // ChipProps={{
                        //     icon: <FaceIcon />,
                        // }}
                        helperText={this.state.errorMessage}
                    />
                )}

                <div className={`controls ${classes.controls}`}>
                    <div className={classes.buttonWrapper}>
                        <ButtonComp
                            variant="contained"
                            bckColor={colors.PRIMARY}
                            color="#ffffff"
                            onClick={this.updateUser}
                            classes={{ root: classes.rightMargin }}
                            disabled={this.state.loading}
                        >
                            {type === "roles" ? "Update Roles" : "Update"}
                        </ButtonComp>
                        {this.state.loading && (
                            <CircularLoader
                                size={24}
                                className={classes.buttonProgress}
                                color={colors.PRIMARY}
                            />
                        )}
                    </div>

                    {/* <ButtonComp
                        variant="outlined"
                        color="#000000"
                        onClick={this.props.handleClose}
                    >
                        Close
                    </ButtonComp> */}
                </div>
            </Dialog>
        );
    }
}

export default withStyles(useStyles)(UserRoleChangeDialog);
