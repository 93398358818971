import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Avatar,
    Paper,
    Button,
    CssBaseline,
    TextField,
    FormControlLabel,
    Checkbox,
    Icon,
    Grid,
    Box,
    Container,
    Typography,
    InputAdornment,
    IconButton,
    Stepper,
    Step,
    StepLabel,
    RadioGroup,
    Radio,
    Backdrop,
} from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import { withStyles } from "@material-ui/core/styles";
import ButtonComp from "../../components/UIComponents/ButtonComp";
import CircularLoader from "../../components/UIComponents/CircularLoader";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import BusinessIcon from "@material-ui/icons/Business";
import PersonIcon from "@material-ui/icons/Person";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DoneIcon from "@material-ui/icons/Done";
import { colors } from "../../utilities/Constants";
import {
    isValidURL,
    renderCopyright,
    getTenantURL,
} from "../../utilities/helper";
import OrgServices from "../../services/org.services";
import { updateOrgData, isLoading } from "../../store/action";
import { toast } from "react-toastify";
import ListItemCard from "../../components/UIComponents/ListItemCard";
import MActionCard from "../../components/UIComponents/MActionCard";

const useStyles = (theme) => ({
    paper: {
        marginTop: theme.spacing(4),
        paddingTop: 50,
    },
    stepper: {
        width: "100%",
        padding: "0 0 30px 0",
    },
    active: {
        color: `${colors.PRIMARY} !important`,
    },
    completed: {
        color: `${colors.GREEN} !important`,
    },
    button: {
        margin: theme.spacing(2, 0, 0),
    },
    buttonWrapper: {
        width: "100%",
        margin: theme.spacing(1),
        position: "relative",
    },
    fileInput: {
        display: "none",
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -5,
        marginLeft: -12,
    },
});

const GreenRadio = withStyles({
    root: {
        "&$checked": {
            color: colors.GREEN,
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

class SelectOrg extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (!this.props.userData) {
            this.props.history.push("/");
        }
    }

    handleSelect = (org) => {
        if (org === "newOrg") {
            this.props.history.push({
                pathname: "/onboarding",
                src: "/selectOrg",
            });
        } else {
            localStorage.setItem("orgData", JSON.stringify(org));
            this.props.updateOrgData(org);
        }
    };

    render() {
        const { classes, location } = this.props;

        return (
            <Container
                component="main"
                //maxWidth="md"
                className="startScreenContainer fadeInUp"
            >
                <CssBaseline />
                <Paper
                    className={`${classes.paper} startScreenPaper dialog`}
                    elevation={5}
                >
                    <div className="paper-top-navigation">
                        <ButtonComp
                            onClick={this.props.handleLogout}
                            className="closeIcon"
                            startIcon={<ExitToAppIcon />}
                        >
                            Logout
                        </ButtonComp>
                    </div>

                    <h2 className="paper-title">
                        {`Hello, ${this.props.userData.name}`}
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                        >
                            You are registered with multiple Organisations.
                            Please select the one to proceed or create new
                            organisation.
                        </Typography>
                    </h2>
                    <Paper elevation={0} className={`cardList `}>
                        {location.orgs &&
                            location.orgs.map((org) => (
                                <MActionCard
                                    className="cardClick"
                                    key={org.orgId}
                                    cardClick
                                    data={{
                                        media:
                                            org.orgType === "org" ? (
                                                <BusinessIcon
                                                    style={{ fontSize: 50 }}
                                                />
                                            ) : (
                                                <PersonIcon
                                                    style={{ fontSize: 50 }}
                                                />
                                            ),
                                        title: org.orgName,
                                        description: `Student portal is ${getTenantURL(
                                            org.orgId
                                        )}`,
                                    }}
                                    btnLabel="Select"
                                    onClick={() => this.handleSelect(org)}
                                />
                            ))}
                        <MActionCard
                            className="cardClick"
                            key="newOrgCard"
                            cardClick
                            data={{
                                media: (
                                    <AddCircleIcon style={{ fontSize: 50 }} />
                                ),
                                title: "Create new Org",
                                description:
                                    "Register a new organisation with the same account.",
                            }}
                            btnLabel="Select"
                            onClick={() => this.handleSelect("newOrg")}
                        />
                    </Paper>
                </Paper>
                <Box mt={5}>{renderCopyright()}</Box>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    console.log(state);
    return {
        isLoading: state.appState.isLoading,
        message: state.appState.message,
        userData: state.appState.userData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        validateUser: (username, password) => (
            dispatch(validateUser()), dispatch(isLoading(true))
        ),
        updateLoading: (bool) => dispatch(isLoading(bool)),
        updateMessage: (msg) => dispatch(updateMessage(msg)),
        updateOrgData: (org) => dispatch(updateOrgData(org)),
    };
};

export default withStyles(useStyles)(
    connect(mapStateToProps, mapDispatchToProps)(SelectOrg)
);
