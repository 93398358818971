import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { colors } from "../../utilities/constants";

const AntTabs = withStyles({
    root: {
        borderBottom: "1px solid #e8e8e8",
    },
    indicator: {
        backgroundColor: colors.PRIMARY,
        height: 4,
        borderRadius: 4,
    },
})(({ textColor, indicator, selectionFollowsFocus, ...rest }) => (
    <Tabs {...rest} />
));

const AntTab = withStyles((theme) => ({
    root: {
        textTransform: "none",
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(3),
        "&:hover": {
            color: colors.PRIMARY,
            opacity: 1,
        },
        "&$selected": {
            color: colors.PRIMARY,
            // fontWeight: theme.typography.fontWeightMedium,
        },
        "&:focus": {
            color: colors.PRIMARY,
        },
    },
    selected: {},
}))(({ textColor, indicator, selectionFollowsFocus, ...rest }) => (
    <Tab disableRipple {...rest} />
));

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
    },
    padding: {
        padding: theme.spacing(2),
    },
    button: {
        position: "absolute",
        right: 0,
        bottom: 0,
    },
    noTransform: {
        textTransform: "none",
    },
}));

const TabsComp = (props) => {
    const classes = useStyles();
    return (
        <div className={`${classes.root} tabs`}>
            <AntTabs
                value={props.value}
                onChange={props.handleChange}
                aria-label="Tabs"
                classes={props.classes}
                variant="scrollable"
                scrollButtons="auto"
            >
                {props.tabs.map((t, i) => (
                    <AntTab
                        key={`tab-${i}`}
                        label={t.label}
                        value={i}
                        classes={{ root: classes.noTransform }}
                    />
                ))}
                {props.children && props.children}
                {/* <Typography className={classes.padding} /> */}
            </AntTabs>
            {/* <div className={classes.button}>
                {props.children && props.children}
            </div> */}
        </div>
    );
};

export default TabsComp;
